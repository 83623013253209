import CalendarSingle from "components/inputs/calendar/Single";
import ListItem from "./ListItem";
import BasicModal from "components/modals/Basic";
import { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { listBillboardAvailabilityByRange } from "api/gql/ssp";
import { Billboard, BillboardAvailability } from "graphql/ssp/generated";
import BasicContainer from "components/containers/Basic";
import { fixStatus, monthsBounds, statuses } from "./utils";
import AvailabilityTooltip from "./Tooltip";
import { getWeekBounds } from "components/inputs/calendar/utils";
import { Matcher } from "react-day-picker";
import { getMonday } from "utils/dateFormatter";

type Props = {
  // onMonthChange?: MonthChangeEventHandler;
  // onDayMouseEnter?: DayMouseEventHandler;
  // onDayMouseLeave?: DayMouseEventHandler;
  // modifiers?:DayModifiers;
  data: Billboard;
  Button?: React.FC<{ onClick: () => void }>;
  startDate?: Date;
  open: boolean;
  close: () => void;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
const dayBeforeFirstMonday = (() => {
  const date = new Date();
  date.setDate(1);
  const monday = new Date(getMonday(date));
  monday.setDate(monday.getDate() - 1);
  return monday;
})();

export const AvailabilityModal: React.FC<Props> = ({
  children,
  data,
  open,
  close,
  className,
  startDate = dayBeforeFirstMonday,
  ...attrs
}) => {
  const [selected, setSelected] = useState<Date | undefined>(new Date());
  const [availabilities, setAvailabilities] = useState<BillboardAvailability[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(startDate);
  const [hovered, setHovered] = useState<Date | null>(null);

  const [popover, setPopover] = useState<{
    anchor: Element;
    date: Date;
    info?: BillboardAvailability;
    billboard: Billboard;
  } | null>(null);
  const updateAvailabilitiesList = useCallback(async () => {
    setIsLoading(true);
    const [from, to] = monthsBounds(dateStart, 2);

    const res = await listBillboardAvailabilityByRange({
      billboard_id: data.id,
      start_date: from,
      stop_date: to,
    });
    console.log(res);
    setAvailabilities(res || []);
    setIsLoading(false);
  }, [data, dateStart]);

  const thisSunday = new Date();
  thisSunday.setDate(thisSunday.getDate() - thisSunday.getDay());

  const createModifiers = () => {
    const modifiers: Record<(typeof statuses)[number], Matcher[]> = {
      BOOKED: [],
      MAINTENANCE: [],
      UNAVAILABLE: [],
    };
    availabilities.forEach(
      ({ start_busy_date, status, is_maintenance, order_data }) => {
        // Note: the backend doesn't have a status for maintenance or unavailable
        // so we infer it by setting it to "UNAVAILABLE" if the status is null
        // or "MAINTENANCE" when status is null and isMaintenance === true
        let statusFixed = fixStatus(
          status,
          is_maintenance,
          order_data?.advertiser
        );
        const { from, to } = getWeekBounds(new Date(start_busy_date));
        from.setDate(from.getDate() - 1);
        const matcher: Matcher = (date) => date > from && date < to;
        modifiers[statusFixed].push(matcher);
      }
    );
    return modifiers;
  };

  const statusModifiers: Record<(typeof statuses)[number], string> = {
    UNAVAILABLE: "bg-red-400",
    MAINTENANCE: "bg-yellow-400",
    BOOKED: "bg-blue-400",
  };
  useEffect(() => {
    updateAvailabilitiesList();
  }, [updateAvailabilitiesList, dateStart]);
  let hoveredMatcher: Matcher = () => false;
  const matchers = createModifiers();
  if (hovered) {
    const { from, to } = getWeekBounds(hovered);
    from.setDate(from.getDate() - 1);
    hoveredMatcher = (date) =>
      date > from &&
      date < to &&
      !Object.values(matchers)
        .flat()
        .find((matcher) =>
          typeof matcher === "function" ? matcher(date) : false
        );
  }

  return (
    <>
      <BasicModal
        open={open}
        title="Disponibilità impianto"
        close={() => close()}
        className={twMerge("w-fit", className)}
        bodyClassName="grid grid-cols-[auto_auto]"
        {...attrs}
      >
        <CalendarSingle
          selected={selected}
          setSelected={setSelected}
          pagedNavigation
          className={twMerge("")}
          modifiersClassNames={{ HOVERED: "bg-[#ddeaff] ", ...statusModifiers }}
          modifiers={{ ...matchers, HOVERED: [hoveredMatcher] }}
          onMonthChange={(d) => setDateStart(d)}
          onDayMouseEnter={(date) => setHovered(date)}
          onDayMouseLeave={() => setHovered(null)}
          onDayClick={(date, _, event) => {
            const info = availabilities.find(({ start_busy_date }) => {
              const busy = new Date(start_busy_date);
              const { from, to } = getWeekBounds(date);
              console.log({ from, to, busy });
              return busy >= from && busy < to;
            });
            setPopover({
              anchor: event.target as Element,
              date: date,
              info: info,
              billboard: data,
            });
          }}
        />
        <BasicContainer
          loading={isLoading}
          className="flex h-full w-full min-w-[480px] flex-col justify-start rounded-none border-l py-5"
        >
          {!availabilities.length && (
            <i className="text-gray-400 first">
              Nessuna data di indisponibilità
            </i>
          )}
          {availabilities.map(
            ({ start_busy_date, status, is_maintenance, order_data }, i) => {
              const { from, to } = getWeekBounds(new Date(start_busy_date));
              return (
                <ListItem
                  key={i}
                  dateFrom={from}
                  dateTo={to}
                  status={fixStatus(
                    status,
                    is_maintenance,
                    order_data?.advertiser
                  )}
                />
              );
            }
          )}
        </BasicContainer>
      </BasicModal>

      {popover && (
        <AvailabilityTooltip
          refresh={() => updateAvailabilitiesList()}
          close={() => setPopover(null)}
          {...popover}
        />
      )}
    </>
  );
};

export default AvailabilityModal;
