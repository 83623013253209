import {
  CheckCircleIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import BasicContainer from "components/containers/Basic";
import { ProgressTime } from "components/others/Progress/Time";
import { Advertiser } from "graphql/ssp/generated";
import { relativeTimeFromDates } from "utils/dateFormatter";

type Props = {
  discount: number;
  billboardsN: number;
  conditions: string[]; //unknown yet
  active: boolean;
  dateFrom: string; //date format
  dateTo: string; //date format
  clients: Advertiser[];
};

export const PricingCard: React.FC<Partial<Props>> = ({
  discount = 20,
  billboardsN = 15,
  conditions = ["Prova"],
  active = Boolean((Math.random() * 2) | 0),
  dateFrom = "2022-12-24",
  dateTo = "2023-10-25",
  clients = [],
}) => {
  return (
    <BasicContainer className="flex-row items-center justify-between w-full">
      <div className="flex flex-col">
        <div className="font-semibold">Sconto del {discount}%</div>
        <div className="flex items-center text-xs text-primary-light">
          <MapPinIcon className="h-4" />
          {billboardsN} Impianti selezionati
        </div>
      </div>
      <div
        className="flex items-center justify-center w-8 h-8 border border-black rounded-full"
        role="button"
      >
        <span>+{conditions.length}</span>
      </div>
      {active ? (
        <div className="flex items-center gap-1 p-1 text-xs font-medium text-green-500">
          Attiva <CheckCircleIcon className="h-4" />
        </div>
      ) : (
        <div className="flex items-center gap-1 p-1 text-xs font-medium text-gray-500">
          Non attiva <XCircleIcon className="h-4" />
        </div>
      )}
      <div className="flex items-center gap-1 p-1 text-xs rounded-lg bg-slate-100 text-gray-500">
        <ClockIcon className="h-4" />{" "}
        {relativeTimeFromDates(new Date(dateFrom))}
      </div>
      <div className="w-60">
        <ProgressTime from={dateFrom} to={dateTo} />
      </div>
      <div className="text-sm text-gray-400">{clients.length} clienti</div>
      <div className="text-gray-400">
        <EllipsisHorizontalIcon className="h-6" />
      </div>
    </BasicContainer>
  );
};
export default PricingCard;
