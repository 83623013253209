import { Campaign, Order } from "graphql/dsp/generated";
import { configuration } from "../../config-dsp";
const { REACT_APP_GUEST_KEY, REACT_APP_ENV } = process.env;

const graphql_endpoint: string =
  configuration[REACT_APP_ENV!].aws_appsync_graphqlEndpoint;

export const guestGetAdvertiserCampaign = async (
  id: string,
  advertiser: string
) => {
  const res = await fetch(graphql_endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      "x-api-key": REACT_APP_GUEST_KEY!,
    },
    body: JSON.stringify({
      query: `
              query getAdvertiserCampaign($id: ID = "", $advertiser: String = "") {
                getAdvertiserCampaign(advertiser: $advertiser, id: $id) {
                  advertiser
                  billboards {
                    billboard_id
                    price
                  }
                  discount
                  draft_orders
                  fee
                  filters
                  from
                  id
                  n_municipality
                  order_date
                  name
                  orders
                  total_amount
                  to
                  target
                  status
                  print_amount
                }
              }
            `,
      variables: {
        id,
        advertiser,
      },
    }),
  }).then((res) => res.json());
  console.log({getAdvertiserCampaignRes:res})
  return res.data.getAdvertiserCampaign as Campaign
};
export const guestBatchGetOrders = async (campaign: string, orders: string[]) => {
  const res = await fetch(graphql_endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      "x-api-key": REACT_APP_GUEST_KEY!,
    },
    body: JSON.stringify({
      query: `
              query batchGetOrders($campaign: ID, $orders: [ID]) {
                batchGetOrders(campaign: $campaign, orders: $orders) {
                  advertiser
                  amount
                  billboards {
                    address
                    available
                    base
                    billalo_code
                    billboard_class
                    calendar
                    city_code
                    cod_cimasa
                    facade
                    format
                    height
                    height_ground
                    iV_municipality
                    iV_normalized
                    id
                    visible
                    size
                    property_type
                    pricing_id
                    price
                    press_type
                    panel_index
                    other_location
                    orientation
                    notes
                    n_sheet
                    n_authorization
                    municipality
                    min_period
                    media_type
                    media_owner_type
                    media_owner
                    longitude
                    location_name
                    location
                    lighting
                    imageUrl
                    indice_visibilita
                    is_draft
                    latitude
                  }
                  campaign
                  campaign_name
                  discount
                  id
                  from
                  is_draft
                  media_owner
                  order_date
                  printer
                  status
                  to
                }
              }
            `,
      variables: {
        campaign,
        orders,
      },
    }),
  }).then((res) => res.json());
  console.log({batchGetOrdersRes:res})
  return res.data.batchGetOrders as Order[]
};
