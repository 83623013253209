import { twMerge } from "tailwind-merge";
type Props<T> = React.ButtonHTMLAttributes<HTMLButtonElement>&{
  toggle: T;
  activeClassName?: string;
  onClick:(toggleValue:T)=>void
} ;

export function ToggleableButton<T> ({
  toggle,
  activeClassName="",
  className,
  children,
  onClick,
  ...attributes
}:Props<T>)  {
  return (
    <button
      className={twMerge(
        "select-none flex w-fit items-center gap-1 rounded-lg  bg-transparent p-2 font-medium text-gray-600 transition-all hover:text-black",
        "disabled:hover:text-gray-600 disabled:bg-gray-100",
        className,
        toggle ? activeClassName : ""
      )}
      onClick={()=>onClick(toggle)}
      {...attributes}
    >
      {children}
    </button>
  );
};
export default ToggleableButton;
