import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Billboard } from "graphql/ssp/generated";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { billboardF } from "utils/formatters";

type Props = {
  data: Partial<Billboard>[];
  open: boolean;
  close: () => void;
};
export const BatchDeleteBillboardModal: React.FC<Props> = ({
  data,
  open,
  close,
}) => {
  const dispatch = useDispatch();
  return (
    <BasicModal
      open={open}
      close={close}
      title={"Eliminazione impianto"}
      closableOnOverlayClick
    >
      <div className="flex flex-col px-2">
        <div className="font-semibold">
          Sei sicuro di voler eliminare{" "}
          {data.length > 1 ? data.length + " impianti?" : "questo impianto?"}
        </div>
        <div className="flex flex-col gap-1 px-1 my-4 hover-scrollbar max-h-40">
          {data.map((billboard, i) => {
            return (
              <div
                key={i}
                className="flex items-center gap-3 p-2 rounded-lg shadow"
              >
                {billboardF["address"] &&
                  billboardF["address"](billboard.address, billboard)}
                {billboardF["format"] &&
                  billboardF["format"](billboard.format, billboard)}
                {billboardF["media_type"] &&
                  billboardF["media_type"](billboard.media_type, billboard)}
              </div>
            );
          })}
        </div>

        <div className="flex justify-end w-full gap-3">
          <BasicButton
            className="text-gray-600 w-fit bg-slate-200"
            onClick={close}
          >
            Annulla
          </BasicButton>
          <BasicButton
            className="bg-red-400 w-fit"
            onClick={() => {
              const toBeDeleted = data
                .filter(({ id, city_code }) => id && city_code)
                .map(({ id, city_code }) => ({
                  id: String(id),
                  city_code: String(city_code),
                }));

              dispatch(
                billboardsActions.willBatchDeleteBillboards(toBeDeleted)
              );

              close();
            }}
          >
            Elimina
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
};
export default BatchDeleteBillboardModal;
