import {
  normalizeBillboards,
  NormalizedBillboard,
} from "utils/billboards/normalizeBillboards";
import { useEffect, useState } from "react";
import FilterInputForm from "./FilterInput";
import { listFilteredBillboards } from "api/gql/dsp";
import BillboardsMap from "components/maps/BillboardsMap";
import { Billboard, FilterInput } from "graphql/dsp/generated";
import { getMondays } from "utils/dateFormatter";
import BasicContainer from "components/containers/Basic";
import { useGmap } from "components/maps/GoogleMapComponent/hooks";
import LoadingSpinner from "components/others/LoadingSpinner";
type Props = {
  filters: FilterInput;
  campaignId: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setLockedSteps: React.Dispatch<React.SetStateAction<number[]>>;
  setFilters: React.Dispatch<React.SetStateAction<FilterInput>>;
  billboards: Billboard[];
  setBillboards: (billboards: Billboard[]) => void;
};
export const Search: React.FC<Props> = ({
  filters,
  campaignId,
  setFilters,
  setCurrentStep,
  setLockedSteps,
  billboards,
  setBillboards,
}) => {
  const requirements = !!(filters.from && filters.city);
  const gmap = useGmap<NormalizedBillboard>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (filters.from && filters.to && filters.city) {
      setLoading(true);
      const filtersInput = {
        campaign_id: campaignId,
        input: {
          ...filters,
        },
        start_busy_dates: getMondays(
          new Date(filters.from),
          new Date(filters.to)
        ).map((d) => d.toISOString().slice(0, 10)),
      };
      console.log("Calling listFilteredBillboards with inputs: ", filtersInput);
      listFilteredBillboards(filtersInput).then((res) => {
        setBillboards(normalizeBillboards(res || []));
        setLoading(false);
      });
    } else {
      setBillboards([]);
    }
    console.log(filters);
  }, [filters, campaignId, setBillboards]);
  const disabledConditions =
    !filters.city || !filters.from || !filters.to || !billboards.length;
  useEffect(() => {
    setLockedSteps((locked) => {
      if (disabledConditions) {
        return locked.includes(1) ? locked : [...locked, 1];
      } else if (locked.includes(1)) {
        locked.splice(locked.indexOf(1), 1);
        return [...locked];
      }
      return locked;
    });
  }, [disabledConditions, setLockedSteps]);

  useEffect(() => {
    // Saves the filters in the localStorage
    if (Object.keys(filters).length > 1) {
      const jsonFilters = JSON.stringify(filters);
      localStorage.setItem("billalo-advanced-search-filters", jsonFilters);
    }
  }, [filters]);
  return (
    <>
      <FilterInputForm
        handleSubmit={(data) => {
          setFilters(data);
          setCurrentStep((prev) => prev + 1);
        }}
        disabled={disabledConditions}
        billboards={billboards}
        filterInput={filters}
        setFilterInput={setFilters}
      />
      <BasicContainer className="relative p-0 overflow-hidden bg-transparent grow">
        <BillboardsMap
          showStreetView
          trafficLayerControl
          billboards={normalizeBillboards(billboards)}
          gmap={gmap}
        />
        {(!requirements || !billboards.length) && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <span className="text-xl font-semibold text-center text-white">
              {loading ? (
                <LoadingSpinner className="h-8 fill-white" />
              ) : !requirements ? (
                "Seleziona almeno Periodo e Località per iniziare una ricerca"
              ) : (
                "Nessun impianto trovato. Riprova rimuovendo alcuni filtri."
              )}
            </span>
          </div>
        )}
      </BasicContainer>
    </>
  );
};
export default Search;
