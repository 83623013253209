import FloatingBasic from "components/inputs/Floating/Basic";
import {
  UserIcon,
  EnvelopeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import PasswordStrength from "components/others/PasswordStrength";

const data = {
  given_name: "",
  family_name: "",
  email: "",
  password: "",
};

type Props = {
  /** Contains the form data and the submit event as parameters*/
  handleSubmit: (
    formData: typeof data,
    event: React.FormEvent<HTMLFormElement>
  ) => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

export type SubmitCallback = Props["handleSubmit"];
/** Used during the user registration (both for clients and mediaOwners) */
export const BasicInfoForm: React.FC<Props> = ({
  handleSubmit,
  className,
  ...attributes
}) => {
  const [formData, setFormData] = useState(data);
  return (
    <form
      className={twMerge(
        "mt-5 flex w-fit flex-col items-center gap-5 rounded-3xl",
        className
      )}
      onSubmit={(e) => {
        e.preventDefault();
        e.currentTarget.reportValidity();
        handleSubmit(formData, e);
      }}
      {...attributes}
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center col-span-2 ">
          <EnvelopeIcon className="h-6 mx-2 text-gray-400" />
          <FloatingBasic
            label="Indirizzo email"
            type="email"
            autoComplete="email"
            required
            /** @see https://regexr.com/2rhq7 */
            pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
            title="L'email non è valida"
            className="w-full"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div className="flex items-center">
          <UserIcon className="h-6 mx-2 text-gray-400" />
          <FloatingBasic
            label="Nome"
            autoComplete="first-name"
            required
            onChange={(e) =>
              setFormData({ ...formData, given_name: e.target.value })
            }
          />
        </div>
        <FloatingBasic
          label="Cognome"
          autoComplete="family-name"
          required
          onChange={(e) =>
            setFormData({ ...formData, family_name: e.target.value })
          }
        />

        <div className="flex items-center col-span-2 ">
          <LockClosedIcon className="h-6 mx-2 text-gray-400" />
          <FloatingBasic
            label="Password"
            type="password"
            autoComplete="password"
            required
            className="w-full"
            /** @see https://regexr.com/3bfsi */
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
            title="La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
        </div>
        {/** Password strength check */}
        <PasswordStrength
          className="w-1/2 col-span-2 m-auto"
          password={formData.password}
        />
        <div className="flex items-center col-span-2">
          <LockClosedIcon className="h-6 mx-2 text-gray-400" />
          <FloatingBasic
            label="Conferma password"
            type="password"
            autoComplete="password"
            required
            className="w-full"
            pattern={formData.password}
            title="Le password non corrispondono"
          />
        </div>
      </div>
    </form>
  );
};

export default BasicInfoForm;
