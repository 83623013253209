import { Advertiser } from "graphql/dsp/generated";
import { MediaOwner } from "graphql/ssp/generated";
import { Navigate, Outlet } from "react-router-dom";

type Props<T extends Advertiser | MediaOwner> = {
  group: T;
  not?: boolean;
};
/** Check whenever the user has access to normal content or the kyc pages */
export function HasSubscription<T extends Advertiser | MediaOwner>({
  group,
  not,
}: Props<T>) {
  const hasSub = !!group.plan;
  if (!hasSub && !not) {
    console.log("User doesn't have a subscription");
    return <Navigate replace to={"/subscription"} />;
  }
  if (hasSub && not) {
    console.log("User has a subscription");
    return <Navigate replace to={"/dashboard"} />;
  }

  return <Outlet />;
}
export default HasSubscription;
