import { combineReducers } from 'redux'
import  {reducer as userReducer}  from './slices/user'
import  {reducer as billboardsReducer}  from './slices/billboards'
import  {reducer as ordersReducer}  from './slices/orders'
import  {reducer as clientsReducer}  from './slices/clients'
import  {reducer as teamReducer}  from './slices/team'
import  {reducer as notificationsReducer}  from './slices/notifications'
import  {reducer as campaignsReducer}  from './slices/campaigns'

const appReducer=combineReducers({
    user: userReducer,
    billboards: billboardsReducer,
    orders: ordersReducer,
    clients: clientsReducer,
    team: teamReducer,
    notifications:notificationsReducer,
    campaigns:campaignsReducer,
})
export const rootReducer:typeof appReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        // Resets the store on "USER_LOGOUT" action
      return appReducer(undefined, action)
    }
    return appReducer(state, action)
  }

export type RootState = ReturnType<typeof rootReducer>