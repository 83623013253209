import * as SSPApi from "api/gql/ssp";
import * as DSPApi from "api/gql/dsp";
import { Order } from "graphql/ssp/generated";
import { put, takeLatest } from "redux-saga/effects";
import { notificationsActions } from "store/slices/notifications";
import { ordersActions } from "store/slices/orders";
import { sortByKey } from "utils/dateFormatter";

function* fetchOrders() {
  const orders: Order[] = yield SSPApi.getMediaOwnerOrders() || [];
  sortByKey(orders, "order_date");
  yield put(ordersActions.didFetchOrders(orders || []));
}
function* updateOrderState({
  payload,
}: ReturnType<typeof ordersActions.willUpdateOrderState>) {
  yield SSPApi.updateOrderState(payload);
  yield put(
    notificationsActions.addToast({
      title: "Modifica ordine",
      text: `Le modifiche all'ordine sono state applicate`,
      type: "INFO",
    })
  );
  yield fetchOrders();
}
function* deleteOrders({
  payload,
}: ReturnType<typeof ordersActions.willDeleteOrders>) {
  const { campaign, ids } = payload;
  yield DSPApi.batchDeleteOrders({ campaign, ids });
  yield fetchOrders();
}
export function* sagas() {
  yield takeLatest(ordersActions.willFetchOrders.type, fetchOrders);
  yield takeLatest(ordersActions.willDeleteOrders.type, deleteOrders);
  yield takeLatest(ordersActions.willUpdateOrderState.type, updateOrderState);
}
