import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { resourcesUrl } from "api/gql/ssp";
import Table from "components/lists/table";
import {
  AcceptUserButton,
  RejectUserButton,
} from "components/lists/Team/Actions";
import PfpHash from "components/lists/Team/PfpHash";
import ImageDiv from "components/others/ImageDiv";
import Tooltip from "components/popovers/Tooltip";
import { AdvertiserUser } from "graphql/dsp/generated";
import { MediaOwnerUser } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";
import { formatAwsDate } from "utils/dateFormatter";
import { shorten } from "utils/strings/shorten";

type Props = {
  myTeam: (MediaOwnerUser|AdvertiserUser)[];
};
const PendingCircle: React.FC = () => {
  return (
    <Tooltip tip="Utente in attesa di accettazione">
      <QuestionMarkCircleIcon className="h-8 text-yellow-100 bg-yellow-400 border rounded-full" />
    </Tooltip>
  );
};
export const TeamList = ({ myTeam }: Props) => {
  const isPending = (user: MediaOwnerUser|AdvertiserUser) => !!user.pending;
  console.log(myTeam)
  // tr:has(td > .pending) > *
  return (
    <Table
      data={myTeam}
      className="w-[840px] [&_tr:has(td>.pending)>*]:bg-yellow-100/50 hover:[&_tr:has(td>.pending)]:bg-yellow-100/50"
      noSelect
      cols={{
        given_name: [
          "Nome",
          (val, user) => (
            <div
              className={twMerge(
                "flex items-center gap-2",
                isPending(user!) && "pending"
              )}
            >
              {isPending(user!) ? (
                <PendingCircle />
              ) : (
                <ImageDiv
                  withModal
                  title={val || "Immagine profilo"}
                  className="w-8 h-8 rounded-full"
                  src={resourcesUrl + "pfp_personal_" + user?.id + ".png"}
                  fallback={<PfpHash user={[user?.given_name,user?.family_name,user?.email].join("")}/>}
                />
              )}
              <div>{val + " " + (user && user["family_name"])}</div>
            </div>
          ),
        ],
        // enabled: ["Ruolo", (val) => (val ? "Admin" : "Standard")],

        creation_date: [
          "Iscritto dal",
          (val) => <small>{formatAwsDate(val)}</small>,
        ],
        email: [
          "Email",
          (val) => <div title={String(val)}>{shorten(val, 26)}</div>,
        ],
        status: [
          "",
          (_val, user) =>
            isPending(user!) ? (
              <div className="flex gap-2">
                <AcceptUserButton user={user!} />
                <RejectUserButton user={user!} />
              </div>
            ) : (
              <></>
            ),
        ],
      }}
    />
  );
};

export default TeamList;
