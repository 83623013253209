import { twMerge } from "tailwind-merge";
import { CampaignStatus } from "types/Statuses";
import Tooltip from "components/popovers/Tooltip";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { campaignStatuses, getCachedOrders } from "./campaign";
import { Campaign } from "graphql/dsp/generated";
import DraftTimer from "components/cards/Campaign/DraftTimer";
import { useEffect, useState } from "react";

const MAX_DAYS_BEFORE_WARNING = 30;

/** Checks whether the campaign status is unexpected */
const isValidStatus = (val: string): val is CampaignStatus => {
  return Object.keys(campaignStatuses).includes(val);
};


/** Checks whether the order status is about to start and not yet on the "delivered materials" state */
const hasMissingOrderMaterials = async (
  campaign: Partial<Campaign> | undefined
) => {
  if (!campaign?.id || !campaign.orders) return false;
  if (campaign.status !== "scheduled") return false;
  const msDifference = new Date(campaign.from).getTime() - new Date().getTime();
  const days = msDifference / (1000 * 3600 * 24);
  if (days >= 0 && days <= MAX_DAYS_BEFORE_WARNING) {
    const orders = await getCachedOrders(
      campaign.id,
      campaign.orders.filter((id): id is string => id !== null)
    );
    if(!orders) return false
    const unreceivedMaterials = orders.some((order) => {
      if (order.status === "paid") {
        return true;
      }
      return false;
    });
    return unreceivedMaterials;
  }
  return false;
};
type Props = {
  campaign: Partial<Campaign> | undefined;
};
export const CampaignStatusTip: React.FC<Props> = ({ campaign }) => {
  const status = campaign?.status;
  const [hasMissingMaterials, setHasMissingMaterials] = useState(false);
  useEffect(() => {
    hasMissingOrderMaterials(campaign).then((res) =>
      setHasMissingMaterials(res)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!status) return <></>;
  return isValidStatus(status) ? (
    <div className="flex items-center justify-center gap-1">
      {hasMissingMaterials && <WarningTip />}
      <div
        className={twMerge(
          "flex grow flex-nowrap justify-center gap-1 whitespace-nowrap rounded-lg p-1 px-2 text-sm",
          campaignStatuses[status] && campaignStatuses[status][1]
        )}
      >
        {campaignStatuses[status][0]}
        {status === "draft" && campaign?.order_date && (
          <DraftTimer date={String(campaign?.order_date)} />
        )}
      </div>
    </div>
  ) : (
    <div className="text-center">{status}</div>
  );
};
const WarningTip: React.FC = () => {
  return (
    <Tooltip
      placement="bottom-end"
      tip={
        <div className="max-w-[300px] whitespace-normal">
          <h3 className="mb-1">
            <b>Materiali mancanti</b>
          </h3>
          <span className="text-gray-500">
            Una o più concessionarie non hanno ricevuto i materiali per la campagna.
          </span>
        </div>
      }
    >
      <ExclamationTriangleIcon className="w-6 h-6 text-yellow-400 stroke-2" />
    </Tooltip>
  );
};
