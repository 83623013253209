import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Billboard } from "graphql/ssp/generated";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { billboardF } from "utils/formatters";

type Props = {
  data: Partial<Billboard>;
  open: boolean;
  close: () => void;
}
export const DeleteBillboardModal:React.FC<Props> = ({
  data,
  open,
  close,
}) => {
  const dispatch = useDispatch();
  return (
    <BasicModal
      open={open}
      close={close}
      title={"Eliminazione impianto "+(data.address||data.location)}
      closableOnOverlayClick
    >
      <div className="flex flex-col px-2">
        <div className="font-semibold">
          Sei sicuro di voler Eliminare questo impianto?
        </div>
        <div className="flex items-center gap-3 p-2 my-3 rounded-lg shadow ">
          {billboardF["address"] && billboardF["address"](data.address, data)}
          {billboardF["format"] && billboardF["format"](data.format, data)}
          {billboardF["media_type"] &&
            billboardF["media_type"](data.media_type, data)}
        </div>
        <div className="flex justify-end w-full gap-3">
          <BasicButton
            className="text-gray-600 w-fit bg-slate-200"
            onClick={close}
          >
            Annulla
          </BasicButton>
          <BasicButton
            className="bg-red-400 w-fit"
            onClick={() => {
              if (!(data.id && data.city_code)) return;
              dispatch(
                billboardsActions.willDeleteBillboard({
                  id: data.id,
                  city_code: data.city_code,
                })
              );
              close();
            }}
          >
            Elimina
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
};

export default DeleteBillboardModal;
