import BillaloLogo from "components/others/BillaloLogo";
import { Outlet, useNavigate } from "react-router-dom";

import SimplifiedNavbar from "components/navbars/Simplified";
type Props = {
  navbar?: boolean;
  /** Whether to display a primary color bg or a lightgray one */
  primary?: boolean;
  hideLogo?: boolean;
};
export const OnboardingLayout: React.FC<Props> = ({
  navbar,
  primary,
  hideLogo,
}) => {
  const navigate = useNavigate();
  // if transition
  const primaryBg = "bg-primary text-lighter-gray";
  const lightBg = "bg-lighter-gray text-primary";
  return (
    <div
      className={
        "min-w-screen no-scrollbar h-screen overflow-scroll bg-lighter-gray bg-cover bg-fixed bg-center "
      }
    >
      <div
        className={`min-w-screen flex min-h-screen flex-col items-center gap-6 transition-all duration-[1s] ${
          primary? primaryBg:lightBg
        }`}
      >
        <>
          {navbar ? (
            <SimplifiedNavbar />
          ) : hideLogo ? (
            <div className="mt-10" />
          ) : (
            <BillaloLogo
              fill={primary ? "white" : "#0062ff"}
              role="button"
              className="mt-10 select-none "
              onClick={() => navigate("/")}
            />
          )}
          <Outlet />
        </>
      </div>
    </div>
  );
};
export default OnboardingLayout;
