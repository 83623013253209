import BasicButton from "components/buttons/Basic";
import Select from "components/inputs/Select";
import BasicModal from "components/modals/Basic";
import { Campaign } from "graphql/dsp/generated";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { campaignsActions } from "store/slices/campaigns";
import { campaignStateMachine, CampaignStatus } from "types/Statuses";
import { campaignF } from "utils/formatters";

type Props = {
  campaign: Partial<Campaign>;
  open: boolean;
  close: () => void;
};
const campaignActionsMap: Record<string, string> = {
  confirm: "Confermato",
  reject: "Rifiutato",
  cancel: "Cancellato",
  post: "In esposizione",
  complete: "Completata",
};
const actionParser = (actions: string[]) => {
  const arr: [string, string][] = actions.map((action) => {
    let alias = campaignActionsMap[action] || "";
    return [action, alias];
  });
  return arr.filter(([, alias]) => !!alias);
};
export const UpdateStateModal: React.FC<Props> = ({
  campaign,
  open,
  close,
}) => {
  const dispatch = useDispatch();
  const [campaignAction, setCampaignAction] = useState("");
  const availableActions =
    campaignStateMachine.states[campaign.status as CampaignStatus]?.on || {};
  const actions = actionParser(Object.keys(availableActions));
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    console.log("Updating campaign state to ", campaignAction);
    console.log(campaignAction, campaign.id);
    if (campaignAction && campaign.id) {
      dispatch(
        campaignsActions.willUpdateCampaignState({
          action: campaignAction,
          id: campaign.id,
        })
      );
    }
    close();
  };
  return (
    <BasicModal
      open={open}
      close={close}
      title={`Cambiamento di stato della campagna`}
      closableOnOverlayClick
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-3 px-2">
          <div className="font-semibold">
            Seleziona uno stato da applicare a questa campagna
          </div>
          <Select
            onChange={(e) => setCampaignAction(e.target.value)}
            required
            defaultValue={""}
          >
            {actions.map(([value, displayName], i) => {
              return (
                <option
                  key={i}
                  value={value}
                  placeholder="nessuna azione"
                  className="flex items-center gap-3 p-2 rounded-lg shadow"
                >
                  {displayName}
                </option>
              );
            })}
          </Select>
          <div className="flex items-center gap-2">
            <>
              <span className="text-gray-400">Stato corrente:</span>
              {campaignF["status"]?.(campaign.status)}
            </>
          </div>

          <div className="flex justify-end w-full gap-3">
            <BasicButton
              className="text-gray-600 w-fit bg-slate-200"
              onClick={close}
            >
              Annulla
            </BasicButton>
            <BasicButton className="w-fit" type="submit">
              Modifica stato
            </BasicButton>
          </div>
        </div>
      </form>
    </BasicModal>
  );
};
export default UpdateStateModal;
