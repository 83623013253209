import { CognitoUser } from "amazon-cognito-identity-js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { MediaOwner, MediaOwnerInput } from "graphql/ssp/generated";
import { Advertiser, AdvertiserInput } from "graphql/dsp/generated";
import { CognitoAttributesObject } from "types/CognitoAttributesObject";

const initialState = {
  isInitialized: false,
  cognitoUser: [null, "idle"] as DataWithState<CognitoUser>,
  /** The Cognito user attributes in the form of an object */
  userAttributes: [null, "idle"] as DataWithState<CognitoAttributesObject>,
  // Initially gets the isMediaOwner from localStorage (every login will set this value)
  isMediaOwner: localStorage.getItem("isMediaOwner") === "true",
  advertiser: [null, "idle"] as DataWithState<Advertiser>,
  mediaOwner: [null, "idle"] as DataWithState<MediaOwner>,
};
// type UserState = typeof initialState;
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    /** Gets called once by the sagas at app launch */
    willInitialize: (
      state,
      _payload: PayloadAction<{
        isMediaOwner: boolean;
        loadCached: boolean;
      }>
    ) => {
      state.isInitialized = false;
      return state;
    },
    didInitialize: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialized = true;
      state.isMediaOwner = payload;
      return state;
    },
    willLogout: (state) => state,
    didLogout: (state) => {
      // Removes cognito user data
      state.cognitoUser = [null, "idle"];
      state.userAttributes = [null, "idle"];
      // Also resets user data
      state.advertiser = [null, "idle"];
      state.mediaOwner = [null, "idle"];
      state.isInitialized = false;
      return state;
    },
    willLogin: (
      state,
      {
        payload,
      }: PayloadAction<{
        email: string;
        password: string;
        isMediaOwner: boolean;
      }>
    ) => {
      state.cognitoUser = [null, "pending"];
      state.userAttributes = [null, "pending"];
      state.isMediaOwner = payload.isMediaOwner;
      return state;
    },
    didLogin: (
      state,
      {
        payload: [cognito_user, cognito_user_attributes, group],
      }: PayloadAction<
        [
          CognitoUser | Error,
          CognitoAttributesObject | Error,
          (MediaOwner | Advertiser | Error)?
        ]
      >
    ) => {
      state.cognitoUser =
        cognito_user instanceof Error
          ? [null, cognito_user]
          : [cognito_user, "idle"];
      state.userAttributes =
        cognito_user_attributes instanceof Error
          ? [null, cognito_user_attributes]
          : [cognito_user_attributes, "idle"];
      if (!group) return state;
      if (state.isMediaOwner) {
        state.mediaOwner =
          group instanceof Error
            ? [null, group]
            : [group as MediaOwner, "idle"];
      } else {
        state.advertiser =
          group instanceof Error
            ? [null, group]
            : [group as Advertiser, "idle"];
      }
      return state;
    },
    willAddAdvertiser: (state, { payload }: PayloadAction<AdvertiserInput>) => {
      state.advertiser = [state.advertiser[0], "pending"];
      return state;
    },
    willGetAdvertiser: (state) => {
      state.advertiser = [null, "pending"];
      return state;
    },
    didGetAdvertiser: (
      state,
      { payload }: PayloadAction<Advertiser | Error>
    ) => {
      state.advertiser =
        payload instanceof Error ? [null, payload] : [payload, "idle"];
      return state;
    },
    willAddMediaOwner: (state, { payload }: PayloadAction<MediaOwnerInput>) => {
      state.mediaOwner = [payload, "pending"];
      return state;
    },
    willGetMediaOwner: (state) => {
      state.mediaOwner = [null, "pending"];

      return state;
    },
    didGetMediaOwner: (
      state,
      { payload }: PayloadAction<MediaOwner | Error>
    ) => {
      state.mediaOwner =
        payload instanceof Error ? [null, payload] : [payload, "idle"];
      return state;
    },
    setIsMediaOwner: (state, { payload }: PayloadAction<boolean>) => {
      localStorage.setItem("isMediaOwner", String(payload));
      state.isMediaOwner = payload;
      return state;
    },
    willExportFreshdesk: (state) => state,
    willFetchAttributes: (state, _action: PayloadAction<CognitoUser>) => state,
    didFetchAttributes: (
      state,
      { payload }: PayloadAction<CognitoAttributesObject>
    ) => {
      state.userAttributes = [payload, "idle"];
      return state;
    },
  },
});

export const userSelectors = {
  isInitialized: (state: RootState) => state.user.isInitialized,
  isLoggedIn: (state: RootState) => state.user.cognitoUser[0] !== null,
  cognitoUser: (state: RootState) => state.user.cognitoUser,
  isMediaOwner: (state: RootState) =>
    state.user.isInitialized
      ? state.user.isMediaOwner
      : localStorage.getItem("isMediaOwner") === "true",
  advertiser: (state: RootState) => state.user.advertiser,
  mediaOwner: (state: RootState) => state.user.mediaOwner,
  userAttributes: (state: RootState) => state.user.userAttributes,
};

const { actions, reducer } = userSlice;
export { reducer, actions as userActions };
