import { forgotPasswordRequest, forgotPasswordConfirm } from "api/auth";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import BillaloLogo from "components/others/BillaloLogo";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "components/inputs/Otp";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import ResendRequestButton from "components/others/ResendRequestButton";
import BasicButton from "components/buttons/Basic";
import FloatingBasic from "components/inputs/Floating/Basic";
import PasswordStrength from "components/others/PasswordStrength";

// TODO:
// Show first request processing
// Show if OTP has an invalid format? maybe not
// Show email input only before the otp/password input
// Reroute to login at successful response
// Show error feedback in case of wrong otp
// Show password discrepancies
// Show password security index
type Props = {};
export const PasswordRecovery: React.FC<Props> = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const urlEmail = queryParams.get("email") || "";
  const otpDigitLength = 6;
  const digitsArray = Array.apply(null, Array(otpDigitLength)).map((_) => "");
  const [values, setValues] = useState(digitsArray);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [email, setEmail] = useState(urlEmail || "");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState<{
    password: string;
    confirm: string;
  }>({ password: "", confirm: "" });

  const sendRequest = async () => {
    const res = await forgotPasswordRequest(email);
    console.log("Forgot password response", res);
    setIsRequestSent(true);
  };

  const confirmRequest = async () => {
    setError(false);
    setIsLoading(true);
    const otpCode = values.join("");
    try {
      const res = await forgotPasswordConfirm(
        email,
        otpCode,
        password.password
      );
      console.log("Forgot password confirm response", res);
      navigate(`/login?${new URLSearchParams({ email }).toString()}`);
    } catch (_e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const canConfirm =
    password.password === password.confirm && !!email && !!values.join();
  const confirmation = (
    <>
      Inserisci il codice che hai ricevuto via mail:
      <div className="flex flex-col justify-center w-full gap-2">
        <OtpInput digits={6} values={values} setValues={setValues} />
        <div className="flex justify-center my-2">
          <ResendRequestButton callback={sendRequest} />
        </div>

        <div className="flex flex-col gap-3">
          <div> Inserisci nuova password</div>
          <div className="flex items-center col-span-2 ">
            <LockClosedIcon className="h-6 mx-2 text-gray-400" />
            <FloatingBasic
              label="Password"
              type="password"
              autoComplete="off"
              required
              className="w-full"
              /** @see https://regexr.com/3bfsi */
              pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
              title="La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero"
              onChange={(e) => {
                setPassword((prev) => ({ ...prev, password: e.target.value }));
              }}
            />
          </div>
          {/** Password strength check */}
          <PasswordStrength
            className="w-1/2 col-span-2 m-auto"
            password={password.password}
          />
          <div className="flex items-center col-span-2">
            <LockClosedIcon className="h-6 mx-2 text-gray-400" />
            <FloatingBasic
              label="Conferma password"
              type="password"
              autoComplete="off"
              required
              className="w-full"
              pattern={password.password}
              title="Le password non corrispondono"
              onChange={(e) => {
                setPassword((prev) => ({ ...prev, confirm: e.target.value }));
              }}
            />
          </div>
          {error && (
            <div className="flex justify-center text-red-400">
              <div className="max-w-[300px] text-center">
                Si è verificato un errore, controlla che i dati inseriti siano
                corretti
              </div>
            </div>
          )}
          <BasicButton
            role="submit"
            form="password-recovery"
            loading={isLoading}
            disabled={!canConfirm}
          >
            Conferma
          </BasicButton>
        </div>
      </div>
    </>
  );
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-10 min-w-screen bg-primary">
      <div className="flex flex-col justify-center max-w-sm gap-5 font-bold text-white rounded">
        <span className="text-2xl">Recupero password</span>
        <BillaloLogo fill="white" className="m-auto" />
      </div>
      <BasicContainer className="min-w-[450px]">
        <form
          id="password-recovery"
          onSubmit={(e) => {
            e.preventDefault();
            isRequestSent ? confirmRequest() : sendRequest();
          }}
          className="flex flex-col items-center w-full gap-3"
        >
          <div className="flex items-center w-full gap-2">
            <EnvelopeIcon className="h-6 text-gray-400" />
            <BasicInput
              type="email"
              placeholder="Inserisci il tuo indirizzo email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {isRequestSent ? (
            confirmation
          ) : (
            <BasicButton
              form="password-recovery"
              role="submit"
              disabled={!email}
            >
              Invia codice di recupero
            </BasicButton>
          )}
        </form>
      </BasicContainer>
    </div>
  );
};

export default PasswordRecovery;
