import { uploadCsv } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import FilesUploader from "components/inputs/FilesUploader";
import BasicModal from "components/modals/Basic";
import { useState } from "react";
type Props = {
  open: boolean;
  close: () => void;
};
export const CsvUploader: React.FC<Props> = ({ open, close }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([] as File[]);
  // const [billboards, setBillboards] = useState<null | Billboard[]>(null);
  const [isDone, setIsDone] = useState(false);
  return (
    <BasicModal
      open={open}
      title="Importa impianti da file CSV"
      close={close}
      loading={loading}
      bodyClassName="gap-5 flex flex-col"
    >
      <FilesUploader files={files} setFiles={setFiles} accept="text/csv" />
      <BasicButton
        disabled={!files.length}
        onClick={() => {
          setLoading(true);
          setIsDone(false);
          const promises = files.map((f) => uploadCsv(f));

          Promise.all(promises).then((res) => {
            console.log(res);
            setLoading(false);
            setIsDone(true);
            setFiles([])
          });
        }}
      >
        Elabora i file selezionati
      </BasicButton>
      {isDone && (
        <div className="flex flex-col">
          <div className="text-center text-primary">
            File caricati correttamente. Il Team di Billalo provvederà a
            lavorarli e caricherà gli impianti nel minor tempo possibile.
          </div>
        </div>
      )}
    </BasicModal>
  );
};

export default CsvUploader;
