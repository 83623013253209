import Confirm from "./Confirm";
import Search from "./Search";
import Plan from "./Plan";
import FixedSpaceContainer from "components/containers/FixedSpace";
import SubNavbar from "components/navbars/Sub";
import StepProgress from "components/others/StepProgress";
import { useEffect, useState } from "react";
import BasicInput from "components/inputs/Basic";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Billboard, FilterInput } from "graphql/dsp/generated";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";
import BasicModal from "components/modals/Basic";
import TagsContainer from "pages/Planning/Advanced/Search/FilterInput/TagsContainer";
import BasicButton from "components/buttons/Basic";
import { useSearchParams } from "react-router-dom";
import { listFilteredBillboards } from "api/gql/dsp";
import { normalizeBillboards } from "utils/billboards/normalizeBillboards";
import { getMondays } from "utils/dateFormatter";

const steps: [string, string][] = [
  ["search", "Ricerca impianti"],
  ["plan", "Pianifica campagna"],
  ["confirm", "Conferma"],
];
export const Advanced: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [lockedSteps, setLockedSteps] = useState<number[]>([1, 2]);
  const [billboards, setBillboards] = useState<Billboard[]>([]);
  const [selectedBillboards, setSelectedBillboards] = useState<Billboard[]>([]);
  const [filters, setFilters] = useState<FilterInput>({});
  const [campaignId, setCampaignId] = useState<string>(crypto.randomUUID());
  const [campaignName, setCampaignName] = useState<string>("");
  const [advertiser] = useSelector(userSelectors.advertiser);
  const [searchParams] = useSearchParams();

  const restoreCampaign = async () => {
    const param_filters: FilterInput = JSON.parse(
      JSON.parse(searchParams.get("filters")!)
    );
    const param_campaign_id: string = JSON.parse(searchParams.get("id")!);
    const param_campaign_name: string = JSON.parse(searchParams.get("name")!);
    const param_billboards: string[] = JSON.parse(
      searchParams.get("billboards")!
    );
    console.log("Restoring campaign... " + param_campaign_name);
    const filtersInput = {
      campaign_id: param_campaign_id,
      input: {
        ...param_filters,
      },
      start_busy_dates: getMondays(
        new Date(param_filters.from),
        new Date(param_filters.to)
      ).map((d) => d.toISOString().slice(0, 10)),
    };
    console.log(filtersInput);
    console.log("Calling listFilteredBillboards with inputs: ", filtersInput);
    const billboardsSelection = (
      await listFilteredBillboards(filtersInput).then((res) => {
        return normalizeBillboards(res || []);
      })
    ).filter((b) => param_billboards.includes(b.id));
    setSelectedBillboards(billboardsSelection);
    setFilters(param_filters);
    setCampaignName(param_campaign_name);
    setCampaignId(param_campaign_id);
    setCurrentStep(2);
    setLockedSteps([0, 1]);
    console.log("Restored data ", {
      param_filters,
      param_campaign_id,
      param_campaign_name,
      param_billboards,
    });
  };
  const [storedFilters, setStoredFilters] = useState<FilterInput | null>(null);

  useEffect(() => {
    // Checks for filters object in local storage and shows the restore campaign modal

    const storedFiltersJson = localStorage.getItem(
      "billalo-advanced-search-filters"
    );
    if (searchParams.get("filters")) {
      restoreCampaign();
      return;
    }
    if (storedFiltersJson) {
      try {
        const storedFiltersParsed: FilterInput = JSON.parse(storedFiltersJson);
        if (new Date() < new Date(storedFiltersParsed.from)) {
          console.log(new Date(), new Date(storedFiltersParsed.from));
          setStoredFilters(storedFiltersParsed);
        }
      } catch {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col w-full h-full">
      {storedFilters && (
        <BasicModal
          open={true}
          close={() => setStoredFilters(null)}
          title="Filtri di ricerca"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFilters(storedFilters);
              setStoredFilters(null);
            }}
            className="flex flex-col items-center gap-2"
          >
            Recuperare i filtri utilizzati nell'ultima ricerca?
            <div className="flex flex-wrap justify-center">
              <TagsContainer filterInput={storedFilters} />
            </div>
            <div className="flex justify-end w-full gap-3 mt-4">
              <BasicButton
                className="py-1 bg-gray-400 w-fit"
                onClick={() => setStoredFilters(null)}
              >
                Annulla
              </BasicButton>
              <BasicButton className="py-1 w-fit" type="submit">
                Conferma
              </BasicButton>
            </div>
          </form>
        </BasicModal>
      )}
      <SubNavbar className="flex justify-start gap-6 pb-6">
        <div className="relative flex items-end pr-2 font-semibold w-96">
          {currentStep < 2 && (
            <>
              <BasicInput
                onChange={(e) => setCampaignName(e.currentTarget.value)}
                className="bg-white"
                placeholder="Inserisci il nome della campagna"
              />
              <PencilIcon className="absolute top-[15px] right-4 h-4 text-gray-400" />
            </>
          )}
        </div>
        <StepProgress
          onStepClick={(step) => step < 2 && setCurrentStep(step)}
          lockedSteps={lockedSteps}
          currentStep={currentStep}
          steps={steps.map(([, name]) => name)}
          className="w-[430px]"
        />
      </SubNavbar>
      <FixedSpaceContainer bodyClassName="gap-6 p-4">
        {
          [
            <Search
              setCurrentStep={setCurrentStep}
              setLockedSteps={setLockedSteps}
              filters={filters}
              setFilters={setFilters}
              campaignId={campaignId}
              billboards={billboards}
              setBillboards={setBillboards}
            />,
            <Plan
              setCurrentStep={setCurrentStep}
              setLockedSteps={setLockedSteps}
              filters={filters}
              campaignId={campaignId}
              billboards={billboards}
              campaignName={campaignName}
              selectedBillboards={selectedBillboards}
              setSelectedBillboards={setSelectedBillboards}
            />,
            <Confirm
              campaignId={campaignId}
              advertiser={advertiser?.advertiser || ""}
              filters={filters}
              selectedBillboards={selectedBillboards}
            />,
          ][currentStep]
        }
      </FixedSpaceContainer>
    </div>
  );
};
export default Advanced;
