import { refreshCognitoSession } from "api/auth";
import { addAdvertiserGroup } from "api/gql/dsp";
import { addMediaOwnerGroup } from "api/gql/ssp";
import { CognitoUser } from "amazon-cognito-identity-js";

export const addUserToGroup = async (
  /** Wether to add user to media owner or advertiser group */
  isMediaOwner: boolean,
  /** Currently connected cognito user */
  cognitoUser: CognitoUser,
  /** Name of business, - no spaces allowed - */
  businessName: string
) => {
  const username = cognitoUser.getUsername();
  const addToGroup = isMediaOwner ? addMediaOwnerGroup : addAdvertiserGroup;
  // Assigns the user to an userGroup
  const addedOrCreated = await addToGroup(businessName, username);
  const added = addedOrCreated === "added";
  // If the user has been added to an existing group flag them as pending
  if (added) flagAsPending(cognitoUser);
  // Refreshes the session in order to make it available to the graphql client
  await refreshCognitoSession(cognitoUser);
  return added;
};

/** Sets cognito "pending" attribute as true */
export const flagAsPending = (
  cognitoUser: CognitoUser
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    cognitoUser.updateAttributes(
      [{ Name: "custom:pending", Value: "true" }],
      (err, result) => {
        if (err) reject(err);
        resolve(result);
      }
    );
  });
};
