import LoadingSpinner from "components/others/LoadingSpinner";
import { twMerge } from "tailwind-merge";

type Props = { loading?: boolean } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const MiniButton: React.FC<Props> = ({
  children,
  className,
  loading,
  disabled,
  ...attributes
}) => {
  return (
    <button
      disabled={loading || disabled}
      className={twMerge(
        "flex w-fit items-center justify-center rounded-lg bg-opacity-0 ",
        "p-2 transition-all hover:bg-white hover:bg-opacity-100 hover:shadow-sm",
        className
      )}
      {...attributes}
    >
      {loading ? (
        <LoadingSpinner className="w-6 h-6 p-1 fill-gray-400" />
      ) : (
        children
      )}
    </button>
  );
};
