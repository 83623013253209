import {
  CalendarDaysIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import ActionsButton from "components/buttons/Actions";
import { MiniButton } from "components/buttons/Mini";
import VisibilityToggle from "components/cards/Billboard/VisibilityToggle";
import { csvExportBillboards } from "components/CsvUploader/billboards/export";
import { BillboardsActionsManager } from "./Actions";
import Table, { Props as TableProps } from "components/lists/table";
import Tooltip from "components/popovers/Tooltip";
import { Billboard } from "graphql/ssp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { billboardF, billboardS } from "utils/formatters";
type Props = {
  myBillboards: Billboard[];
  onRowClick?: TableProps<Billboard>["onRowClick"];
};

export const BillboardsList: React.FC<Props> = ({
  myBillboards,
  onRowClick,
}) => {
  const [currentAction, setCurrentAction] = useState<
    null | [string, Billboard | Billboard[]]
  >(null);

  return (
    <>
      <Table
        onRowClick={onRowClick}
        data={myBillboards}
        additionalSorting={{
          last_maintenance: ["Ultima manutenzione", billboardF["last_maintenance"]],
        }}
        className="w-[1024px]"
        actions={[
          [
            "Imposta Pricing",
            (selection) => setCurrentAction(["pricing", selection]),
          ],
          [
            "Imposta Visibilità",
            (selection) => setCurrentAction(["visibility", selection]),
          ],
          ["Esporta CSV", (selection) => csvExportBillboards(selection)],
          [
            <span className="text-red-400">Elimina impianti</span>,
            (data) => setCurrentAction(["delete", data]),
          ],
        ]}
        rowActions={[
          [
            (billboard) => (
              <div className="flex items-center gap-0">
                <VisibilityToggle billboard={billboard} />
                <Tooltip tip="Regole di pricing">
                  <MiniButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentAction(["pricing", billboard!]);
                    }}
                    className={twMerge(
                      billboard?.pricing_id && "text-green-500"
                    )}
                  >
                    <ReceiptPercentIcon className="h-6" />
                  </MiniButton>
                </Tooltip>
                <Tooltip tip="Calendario disponibilità">
                  <MiniButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentAction(["availabilities", billboard!]);
                    }}
                  >
                    <CalendarDaysIcon className="h-6" />
                  </MiniButton>
                </Tooltip>
                <ActionsButton
                  data={billboard || {}}
                  actions={[
                    [
                      <span>Modifica impianto</span>,
                      (data) => setCurrentAction(["edit", data as Billboard]),
                    ],
                    [
                      <span className="text-red-400">Elimina impianto</span>,
                      (data) => setCurrentAction(["delete", data as Billboard]),
                    ],
                  ]}
                />
              </div>
            ),
          ],
        ]}
        cols={{
          address: ["Località", billboardF["address"]],
          media_type: ["Tipologia", billboardF["media_type"]],
          format: ["Formato", billboardF["format"], billboardS["format"]],
          cod_cimasa: ["Cimasa"],
          price: ["Prezzo", billboardF["price"], billboardS["price"]],
          iV_normalized: ["Visibilità", billboardF["iV_normalized"]],
        }}
      />
      <BillboardsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};

export default BillboardsList;
