import { listOrderImages } from "api/gql/ssp";
import { OrderImage } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
const baseUrl =
  window.location.origin.includes("8080") ||
  window.location.origin.includes("3000")
    ? "https://app-dev.billalo.it"
    : window.location.origin;
export const useReportImages = (orderId?: string | null) => {
  const [reportImages, setReportImages] =
    useState<(OrderImage & { url: string })[]>();
  const [updateCount, setUpdateCount] = useState(0);
  useEffect(() => {
    orderId &&
      listOrderImages(orderId).then((res) => {
        const withUrl = res.map((orderImage) => ({
          ...orderImage,
          url: `${baseUrl}/resources/ordersImage/${orderImage.order}/${orderImage.id}`,
        }));
        setReportImages(withUrl);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCount]);

  return [
    reportImages || [],
    () => setUpdateCount((prev) => prev + 1),
  ] as const;
};
export default useReportImages;
