import ProgressCircle from "components/others/ProgressCircle";

type Props = {
  type: "iV_municipality" | "iV_normalized";
  val: number | undefined | null;
};
const colors = {
  iV_municipality: "#0062FF",
  iV_normalized: "#3DD598",
};
export const IndexCardMini: React.FC<Props> = ({ type, val }) => {
  return (
    (val && (
      <div
        style={{ color: colors[type] }}
        className="relative z-0 flex items-center justify-center gap-2 font-medium"
      >
        <div className="absolute">
          <ProgressCircle
            size={40}
            amount={val / 10}
            strokeWidth={3}
            color={colors[type]}
          />
        </div>
        {val}
      </div>
    )) || (
      <div className="absolute w-full italic text-center text-gray-400 ">
        N/D
      </div>
    )
  );
};
