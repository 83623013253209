import { XMarkIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicInput from "components/inputs/Basic";
import { PricingInput } from "graphql/ssp/generated";
import { useRef } from "react";

type Props = {
  pricing: PricingInput;
  setPricing: React.Dispatch<React.SetStateAction<PricingInput>>;
};

export const DateCondition: React.FC<Props> = ({ pricing, setPricing }) => {
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between pb-2 mb-2 border-b ">
        <div>Periodo</div>
        <BasicButton
          className="py-1 text-sm text-gray-500 bg-gray-100 hover:bg-gray-200 w-fit"
          onClick={() => {
            setPricing((prev) => {
              const { from, to, ...rest } = prev;
              return rest;
            });
            formRef.current?.reset();
          }}
        >
          Rimuovi condizione <XMarkIcon className="h-4" />
        </BasicButton>
      </div>
      <form
        className="grid w-fit grid-cols-[auto_auto_auto_auto] items-center gap-2 text-end"
        onSubmit={(e) => e.preventDefault()}
        ref={formRef}
      >
        <div className="text-gray-500">Dal:</div>
        <BasicInput
          defaultValue={pricing.from || undefined}
          onChange={(e) =>
            setPricing((prev) => ({
              ...prev,
              from: new Date(e.target.value).toISOString() || undefined,
            }))
          }
          type="date"
        />

        <div className="text-gray-500">Al:</div>
        <BasicInput
          defaultValue={pricing.to || undefined}
          onChange={(e) =>
            setPricing((prev) => ({
              ...prev,
              to: new Date(e.target.value).toISOString() || undefined,
            }))
          }
          type="date"
        />
      </form>
    </div>
  );
};
export default DateCondition;
