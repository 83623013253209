import { Auth, Amplify } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import dspConfiguration from "./config-dsp";
import sspConfiguration from "./config-ssp";
const { REACT_APP_ENV } = process.env;

export const configure = (isMediaOwner: boolean) => {
  localStorage.setItem("isMediaOwner", String(isMediaOwner));
  console.log("changed to mediaowner=" + String(isMediaOwner));
  const configuration = isMediaOwner ? sspConfiguration : dspConfiguration;
  const environment: string = REACT_APP_ENV || "dev";
  Amplify.configure(configuration[environment]);
  Auth.configure(configuration[environment]);
};
export const getAuthenticatedUser = async () => {
  const user = (await Auth.currentAuthenticatedUser({
    bypassCache: true,
  })) as Promise<CognitoUser>;
  return user;
};

export const login = async (username: string, password: string) => {
  const user = (await Auth.signIn(username, password)) as Promise<CognitoUser>;
  // console.log(user)
  return user;
};

export const logout = async () => {
  return await Auth.signOut();
};

/** NOTE: we are using the email as username */
export const confirmSignup = async (username: string, code: string) => {
  try {
    const confirm = await Auth.confirmSignUp(username, code);
    console.log("Confirm signup result", confirm);
    return "SUCCESS";
  } catch (error) {
    console.log("Failed confirm signup", error);
    return error as Error;
  }
};

/** Creates a new cognito user */
export const signup = async (
  email: string,
  password: string,
  given_name: string,
  family_name: string
) => {
  try {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name,
        family_name,
      },
    });
    // console.log("Signup result: ", user);
    return user;
  } catch (error) {
    return error as Error;
  }
};

export const forgotPasswordRequest = async (email: string) => {
  try {
    const result = await Auth.forgotPassword(email);
    // console.log("Forgot password request result", result);
    return result;
  } catch (error) {
    console.log("Failed forgot password request", error);
  }
};

export const forgotPasswordConfirm = async (
  email: string,
  code: any,
  password: string
) => {
  const result = await Auth.forgotPasswordSubmit(email, code, password);
  // console.log("Forgot password confirm result", result);
  return result;
};

export const resendSignuUpCode = async (email: string) => {
  try {
    const result = await Auth.resendSignUp(email);
    // console.log("Resend signup code result", result);
    return result;
  } catch (error) {
    console.log("Failed resend signup", error);
  }
};

export const resetPassword = async (
  user: CognitoUser,
  oldPassword: string,
  newPassword: string
) => {
  try {
    const result = await Auth.changePassword(user, oldPassword, newPassword);
    // console.log("Reset password result", result);
    return result;
  } catch (error) {
    console.log("Failed forgot password", error);
  }
};

export const getUserAttributes = async (user: CognitoUser) => {
  return await Auth.userAttributes(user);
};
export const updateUserAttributes = async (
  user: CognitoUser,
  attributes: object
) => {
  return await Auth.updateUserAttributes(user, attributes);
};

export const refreshCognitoSession = async (cognitoUser: CognitoUser) => {
  const refreshToken = cognitoUser.getSignInUserSession()?.getRefreshToken();
  if (!refreshToken) return false;
  // Wraps the refreshSession method in a promise to make it synchronous from outside
  await new Promise((resolve, reject) => {
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) reject(err);
      resolve(session);
    });
  });
};
