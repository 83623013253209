import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdvertiserUser } from "graphql/dsp/generated";
import { MediaOwnerUser } from "graphql/ssp/generated";
import { RootState } from "store/reducers";

const initialState = {
  myTeam: [null, "idle"] as DataWithState<MediaOwnerUser[] | AdvertiserUser[]>,
};
export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    willFetchTeam: (state) => {
      state.myTeam = [state.myTeam[0], "pending"];
      return state;
    },
    didFetchTeam: (
      state,
      { payload }: PayloadAction<MediaOwnerUser[] | AdvertiserUser[]>
    ) => {
      state.myTeam = [payload, "idle"];
      return state;
    },
  },
});

export const teamSelectors = {
  myTeam: (state: RootState) => state.team.myTeam,
};

const { actions, reducer } = teamSlice;
export { reducer, actions as teamActions };
