import { Billboard } from "graphql/ssp/generated";
import sequenceColor from "utils/sequenceColor";

const uniqueMunicipalities = (billboards: Billboard[]) => {
  type Municipality = [string, number];
  const municipalities: Municipality[] = [];
  billboards.forEach((b) => {
    if (!b.municipality) return;
    const m = b.municipality.toLowerCase();
    const index = municipalities.findIndex(([ms]) => ms === m);
    if (index === -1) {
      municipalities.push([m, 1]);
      return;
    }
    municipalities[index][1]++;
  });
  return municipalities;
};

type Props = {
  billboards: Billboard[];
};
export const CoveredMunicipalities: React.FC<Props> = ({ billboards }) => {
    const municipalities = uniqueMunicipalities(billboards)
  return (
    <>
      <div className="font-bold">Comuni coperti ({municipalities.length})</div>
      <div className="flex w-full flex-col justify-between">
        {municipalities.map(([name, amount]) => {
          return (
            <div
              key={name}
              className="flex w-full items-center justify-between py-2"
            >
              <span className="flex items-center gap-2 font-medium text-gray-500">
                <div
                  style={{ backgroundColor: sequenceColor() }}
                  className="h-3 w-3 rounded-full"
                />
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </span>
              <span className="font-medium text-gray-800">{amount}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default CoveredMunicipalities;
