import BillboardsMap from "components/maps/BillboardsMap";
import { useParams } from "react-router-dom";
import LoadingSpinner from "components/others/LoadingSpinner";
import { Campaign, Billboard, Order } from "graphql/dsp/generated";
import { useEffect, useState } from "react";
import {
  normalizeBillboards,
  NormalizedBillboard,
} from "utils/billboards/normalizeBillboards";
import { batchGetOrders, getAdvertiserCampaign } from "api/gql/dsp";
import BillboardCard from "components/cards/Billboard";
import { CampaignCard } from "components/cards/Campaign";
import {
  toStreetViewLocation,
  zoomToPlace,
} from "components/maps/GoogleMapComponent/utils";
import { useGmap } from "components/maps/GoogleMapComponent/hooks";

const fetchCampaignData = async (campaignId: string, advertiser: string) => {
  const campaign = await getAdvertiserCampaign({ id: campaignId, advertiser });
  if (!campaign) return null;
  const orderIds = campaign.orders ? campaign.orders.map((o) => String(o)) : [];
  const orders = await batchGetOrders(campaignId, orderIds);
  const billboards =
    orders
      ?.map((o) =>
        o.billboards ? normalizeBillboards(o.billboards as Billboard[]) : []
      )
      .flat()
      .filter((b) => !!b) || [];
  return { campaign, orders, billboards };
};
type Props = {
  fetchData?: (
    id: string,
    adv: string
  ) => Promise<{
    campaign: Campaign;
    orders: Order[] | null;
    billboards: NormalizedBillboard[];
  }>;
};

export const Details: React.FC<Props> = ({ fetchData = fetchCampaignData }) => {
  const { campaignId, advertiser } = useParams();
  const [campaign, setCampaign] = useState<Campaign>();
  const [orders, setOrders] = useState<Order[]>();
  const [billboards, setBillboards] = useState<NormalizedBillboard[]>();
  const gmap = useGmap<NormalizedBillboard>();
  const [filteredBillboards, setFilteredBillboards] = useState<
    NormalizedBillboard[]
  >([]);

  console.log(campaign);

  useEffect(() => {
    if (!campaignId || !advertiser || !!campaign) return;
    fetchData(campaignId, advertiser).then((res) => {
      if (!res) return;
      setCampaign(res.campaign);
      setBillboards(normalizeBillboards(res.billboards as void | Billboard[]));
      setOrders(res.orders || undefined);
    });
  }, []);

  if (!campaign) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner />
      </div>
    );
  }
  // if (campaign.status === "draft"||campaign.status === "rejected") {
  //   return (
  //     <div className="flex grow animate-down-up">
  //       <div className="flex items-center justify-center w-full h-20 text-xl font-semibold text-gray-400">
  //         Questa campagna non è stata confermata
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="flex grow animate-down-up">
      {gmap.selected ? (
        <BillboardCard
          className="m-3 w-96 shrink-0 animate-down-up"
          zoomIn={(b) => zoomToPlace(b, gmap.map)}
          streetView={(b) => toStreetViewLocation(b, gmap.sview, gmap.pano)}
          onClose={() => {
            gmap.setSelected(undefined);
            if (gmap.pano) {
              gmap.pano.setZoom(1);
              gmap.pano.setVisible(false);
            }
          }}
          data={gmap.selected}
        />
      ) : (
        <CampaignCard
          campaign={campaign}
          orders={orders}
          billboards={billboards}
          setHoveredBillboard={gmap.setHovered}
          setSelectedBillboard={gmap.setSelected}
          setFilteredBillboards={setFilteredBillboards}
        />
      )}
      <BillboardsMap
        billboards={filteredBillboards}
        showStreetView
        placesApi
        gmap={gmap}
        trafficLayerControl
        className="z-0 w-full h-full p-3 rounded-xl"
      />
    </div>
  );
};
export default Details;
