import { all } from "redux-saga/effects";
import { sagas as userSagas } from "./user";
import { sagas as billboardsSagas } from "./billboards";
import { sagas as ordersSaga } from "./orders";
import { sagas as clientsSaga } from "./clients";
import { sagas as teamSaga } from "./team";
import { sagas as campaignsSaga } from "./campaigns";

function* runAllSagas() {
  yield all([
    userSagas(),
    billboardsSagas(),
    ordersSaga(),
    clientsSaga(),
    teamSaga(),
    campaignsSaga(),
  ]);
}

export default runAllSagas;
