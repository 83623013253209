import { twMerge } from "tailwind-merge";
import passwordStrength from "./checkPasswordStrength";

type Props = {
  password: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const PasswordStrength: React.FC<Props> = ({ password, className }) => {
  const strength = passwordStrength(password);
  const strengthClass = [
    "bg-red-300",
    "bg-red-300",
    "bg-yellow-300",
    "bg-yellow-300",
    "bg-primary-light",
  ];
  return (
    <div className={twMerge("grid w-full grid-cols-4 gap-2", className)}>
      {Array.from(Array(4).keys()).map((i) => {
        return (
          <div
            key={i}
            className={twMerge(
              "h-1 rounded-sm bg-gray-200 transition-all",
              i < strength && strengthClass[strength]
            )}
          />
        );
      })}
    </div>
  );
};
export default PasswordStrength;
