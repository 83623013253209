import { useState } from "react";
import GoogleMapComponent from "components/maps/GoogleMapComponent";
import { geocodeLocation } from "utils/location/geocoding";
type Props = {
  /** This function will be called when a location is selected.
   *
   * A location gets selected when:
   * - *The user moves the marker*
   * - *The user selects a location from the search input*
   * - *The user clicks on the map*
   */
  onSelected: (
    /** Exact selected coordinates */
    latLng:google.maps.LatLng,
    /** Address data about the selected location */
    location: google.maps.GeocoderResult | google.maps.places.PlaceResult
  ) => void;
  markerIconSelector?: Parameters<
    typeof GoogleMapComponent
  >[0]["markerIconSelector"];
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

/** This component is used to select a location on a map */
export const LocationSelector: React.FC<Props> = ({
  onSelected = () => {},
  markerIconSelector,
  ...attributes
}) => {
  const [focalMarker, setFocalMarker] = useState({
    lat: 0,
    lng: 0,
    id: "0",
  });

  const handleLocationChange = async (latLng: google.maps.LatLng) => {
    const locationData = await geocodeLocation(latLng).then(
      (response) => response.results[0]
    );
    if (!latLng) return;
    setFocalMarker({ lat: latLng.lat(), lng: latLng.lng(), id: "0" });
    onSelected(latLng,locationData);
    return locationData.geometry?.viewport;
  };
  return (
    <GoogleMapComponent
      dataArray={focalMarker ? [focalMarker] : []}
      onPlaceChanged={async (latLng) => {
        handleLocationChange(latLng);
      }}
      onMarkerMove={({ latLng }) => {
        if (!latLng) return;
        handleLocationChange(latLng);
      }}
      onMapClick={(latLng, gmap) => {
        if (!latLng) return;
        handleLocationChange(latLng);
        gmap.panTo(latLng);
      }}
      markerIconSelector={markerIconSelector}
      placesApi
      showStreetView
      className="cursor-pointer h-80 rounded-xl"
      mapContainerStyle={{ cursor: "pointer !important" }}
      searchInputProps={attributes}
    />
  );
};

export default LocationSelector;
