import BasicModal from "components/modals/Basic";
import { Billboard, Pricing } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
import { getBillboard, getBillboardPricing } from "api/gql/ssp";
import AppliedRule from "./AppliedRule";
import NewRule from "./NewRule";

type Props = {
  data: Partial<Billboard>[];
  open: boolean;
  close: () => void;
};
/*
  A list of selected billboards has to be shown
  User has to specify a percentage
  User has to specify if it's a discount or surcharge
  User has to specify at least 1 condition and a maximum of 3.

  The 3 selectable, exclusive conditions are: 
  - Total amount
    - Greater than:number or Lesser than:number
  - Period
    - Start date: Date or End date: Date
  - Client
    - Select with a list of clients

*/
export const PricingModal: React.FC<Props> = ({ data, open, close }) => {
  const [billboards, setBillboards] = useState<Billboard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pricing, setPricing] = useState<Pricing | null>();

  const refreshBillboards = async (data: Partial<Billboard>[]) => {
    setIsLoading(true);
    const promises = data.map(({ id, city_code }) =>
      getBillboard({ id: String(id), city_code })
    );
    const results = (await Promise.all(promises)).filter(
      (b): b is Billboard => !!b
    );
    const existingRule =
      results.length === 1 && results[0].pricing_id
        ? results[0].pricing_id
        : false;
    const pricing = await getBillboardPricing({ id: String(existingRule) });
    setPricing(pricing || null);
    setBillboards(results);
    setIsLoading(false);
  };

  useEffect(() => {
    refreshBillboards(data);
  }, []);
  return (
    <BasicModal
      open={open}
      close={close}
      title={"Creazione regola di pricing"}
      bodyClassName="p-6 pt-2"
      className="w-[650px]"
      loading={isLoading}
    >
      {isLoading ? (
        <div className="h-60" />
      ) : pricing ? (
        <AppliedRule
          pricing={pricing}
          billboard={billboards[0]}
          refresh={() => refreshBillboards(data)}
        />
      ) : (
        <NewRule
          billboards={billboards}
          close={close}
          refresh={() => refreshBillboards(data)}
          setIsLoading={setIsLoading}
        />
      )}
    </BasicModal>
  );
};

export default PricingModal;
