import {
  billboardsSelectors,
  billboardsActions,
} from "store/slices/billboards";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export const useMyBillboards = () => {
  const [billboards, status] = useSelector(billboardsSelectors.myBillboards);
  const dispatch = useDispatch();
  useEffect(() => {
    if (billboards === null && status === "idle") {
      console.log("No billboards in store, fetching...");
      dispatch(billboardsActions.willFetchBillboards());
    }
  }, [billboards, status, dispatch]);
  return;
};
export default useMyBillboards;
