import {
  ArrowUpTrayIcon,
  // CalendarDaysIcon,
  GlobeAltIcon,
  ListBulletIcon,
  MapPinIcon,
  QueueListIcon,
  // ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import FixedSpaceContainer from "components/containers/FixedSpace";
import NewBillboardModal from "./NewBillboard";
import SubNavbar from "components/navbars/Sub";
import { SubNavButton } from "components/navbars/SubNavButton";
import { useState } from "react";
import { useSearchParams, Outlet, useLocation } from "react-router-dom";
import CsvUploadModal from "components/CsvUploader/CsvUploader";
import Tooltip from "components/popovers/Tooltip";

const Billboards: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const viewMode = searchParams.get("view");

  const [openBillboardModal, setOpenBillboardModal] = useState(false);
  const [openCsvModal, setOpenCsvModal] = useState(false);

  // View modes for the "My billboards" page
  /** Shows the map alongside the list */
  const mapView = viewMode === "map";
  /** Shows the calendar alongside the list */
  const calendarView = viewMode === "calendar";
  /** Shows only the list */
  const listView = viewMode === "list" || (!mapView && !calendarView);

  const { pathname: currentLocation } = useLocation();
  const inExplore = currentLocation.includes("explore");
  const inPricing = currentLocation.includes("pricing-rules");
  const inMain = !(inExplore || inPricing || mapView);

  return (
    <div className="flex flex-col w-full h-full max-h-full">
      <SubNavbar>
        <div className="flex gap-1">
          <SubNavButton to="/billboards" active={inMain} Icon={QueueListIcon}>
            Lista impianti
          </SubNavButton>
          {/* TODO: Need to have more specifications about the explore page */}

          <SubNavButton to="explore" active={inExplore} Icon={GlobeAltIcon}>
            Esplora
          </SubNavButton>
          {/*
          <SubNavButton
            to="pricing-rules"
            active={inPricing}
            Icon={ReceiptPercentIcon}
          >
            Regole pricing
          </SubNavButton> */}
        </div>
        <div className="flex items-center gap-2 ">
          {inPricing && (
            <>
              <BasicButton
                onClick={() => setOpenBillboardModal(true)}
                className="py-1"
              >
                Nuova regola
              </BasicButton>
            </>
          )}
          {inMain && (
            <>
              <BasicButton
                onClick={() => setOpenCsvModal(true)}
                className="gap-2 py-1 text-gray-500 bg-slate-200 hover:brightness-100"
              >
                <ArrowUpTrayIcon className="w-4" />
                CSV
                <CsvUploadModal
                  open={openCsvModal}
                  close={() => setOpenCsvModal(false)}
                />
              </BasicButton>
              <BasicButton
                onClick={() => setOpenBillboardModal(true)}
                className="py-1"
              >
                Nuovo
              </BasicButton>
              <div role="row" className="flex gap-[2px] text-gray-500">
                <Tooltip tip="Visualizzazione Lista">
                  <ListBulletIcon
                    role="button"
                    strokeWidth={listView ? 2 : 1}
                    className={`h-8 rounded-l-xl bg-white p-1 transition-all ${
                      listView ? "bg-white text-primary shadow" : "bg-slate-100"
                    }`}
                    onClick={() =>
                      setSearchParams((params) => {
                        params.set("view", "list");
                        return params;
                      })
                    }
                  />
                </Tooltip>
                <Tooltip tip="Visualizzazione Mappa">
                  <MapPinIcon
                    role="button"
                    strokeWidth={mapView ? 2 : 1}
                    className={`h-8 rounded-r-xl p-1 transition-all ${
                      mapView ? "bg-white text-primary shadow" : "bg-slate-100"
                    }`}
                    onClick={() =>
                      setSearchParams((params) => {
                        params.set("view", "map");
                        return params;
                      })
                    }
                  />
                </Tooltip>
                {/* <CalendarDaysIcon
              role="button"
              strokeWidth={calendarView ? 2 : 1}
              className={`h-8 rounded-r-xl p-1 transition-all ${
                calendarView
                  ? "bg-white text-primary shadow"
                  : "bg-slate-100"
              }`}
              onClick={() =>
                setSearchParams((params) => {
                  params.set("view", "calendar");
                  return params;
                })
              }
            /> */}
              </div>
            </>
          )}
        </div>
      </SubNavbar>
      <NewBillboardModal
        open={openBillboardModal}
        close={() => setOpenBillboardModal(false)}
      />
      <FixedSpaceContainer>
        <Outlet />
      </FixedSpaceContainer>
    </div>
  );
};

export default Billboards;
