
import {
  ArchiveBoxIcon,
  ArrowLeftIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import FixedSpaceContainer from "components/containers/FixedSpace";
import SubNavbar from "components/navbars/Sub";
import SubNavButton from "components/navbars/SubNavButton";
import useMyCampaigns from "hooks/useMyCampaigns";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Campaigns:React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname: currentLocation } = useLocation();
  useMyCampaigns();
  const inDetails = currentLocation.includes("details");
  const archiveView = searchParams.get("view") === "archive";

  return (
    <div className="flex flex-col w-full h-full animate-down-up">
      <SubNavbar>
        <TransparentButton
          className={twMerge("gap-2", !inDetails && "hidden")}
          onClick={() => navigate("/campaigns")}
        >
          <ArrowLeftIcon className="h-4" /> Torna alle campagne
        </TransparentButton>
        <div className={twMerge("flex gap-1", inDetails && "hidden")}>
          <SubNavButton
            newParams={[["view", "current"]]}
            active={!archiveView}
            Icon={ClockIcon}
          >
            In corso
          </SubNavButton>
          <SubNavButton
            newParams={[["view", "archive"]]}
            active={archiveView}
            Icon={ArchiveBoxIcon}
          >
            Archivio
          </SubNavButton>
        </div>
      </SubNavbar>
      <FixedSpaceContainer>
        <Outlet />
      </FixedSpaceContainer>
    </div>
  );
};

export default Campaigns;
