import { XMarkIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import { twMerge } from "tailwind-merge";

type Props = {
  file: File;
  remove: (file: File) => void;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const FileDetails: React.FC<Props> = ({
  file,
  remove,
  className,
  ...attributes
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center justify-between gap-1 text-sm text-gray-400",
        className
      )}
      {...attributes}
    >
      <div
        title={file.name}
        className="flex-[5] overflow-hidden text-ellipsis whitespace-nowrap font-medium"
      >
        {file.name}
      </div>
      <div
        title={file.size + " B"}
        className="flex-[2] grow whitespace-nowrap text-end text-xs"
      >
        {formatByteSize(file.size)}
      </div>
      <TransparentButton
        className="flex-1 bg-red-50"
        onClick={() => remove(file)}
      >
        <XMarkIcon className="h-4" />
      </TransparentButton>
    </div>
  );
};
/** Transforms a byte size number into human readable */
const formatByteSize = (size: number): string => {
  const sizes:[number,string][] = [
    [1024**3, "GB"],
    [1024**2, "MB"],
    [1024, "KB"],
    [1, "B"],
  ];
  const targetSize = sizes.find(([sz]) => size > sz) || sizes[sizes.length-1];

  return (size / targetSize[0]).toFixed(2)+" "+targetSize[1];
};

export default FileDetails;
