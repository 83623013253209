export const configuration: any = {
  dev: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "eu-west-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_jnp83j8Rf",
      userPoolWebClientId: "5vjah04j78eqsn3ufkms2n4m3h",
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
    aws_appsync_graphqlEndpoint:
      "https://5fvvrcavjjgvfdpaqolkxjpesa.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
  stage: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "eu-west-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_MRfWMDQE9",
      userPoolWebClientId: "631mbl9vi7i3cu4g93svkv27i9",
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
    aws_appsync_graphqlEndpoint:
      "https://354dshgxorftfnepoh6q4rb2bi.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
};

export default configuration;
