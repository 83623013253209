import BillaloLogo from "components/others/BillaloLogo";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileMiniCard from "components/cards/ProfileMini";
import Tooltip from "components/popovers/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import MainNavButton from "components/navbars/MainNavButton";
import { useEffect } from "react";
import { NotificationBell } from "components/notifications/NotificationBell";
import { resourcesUrl } from "api/gql/ssp";

export const MainNavbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);

  const mediaOwnerSections = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Impianti", path: "/billboards" },
    { name: "Ordini", path: "/orders" },
    // {name:"Aiuto", path:"/help"},
  ];
  const clientSections = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Pianificazione", path: "/planning" },
    { name: "Campagne", path: "/campaigns" },
    // {name:"Aiuto", path:"/help"},
  ];
  const sections = isMediaOwner ? mediaOwnerSections : clientSections;

  const profile = { name: "Profilo", path: "/profile" };
  const notifications = { name: "Notifiche", path: "/notifications" };

  const { pathname: currentLocation } = useLocation();
  const [advertiser, advertiserStatus] = useSelector(userSelectors.advertiser);
  const [mediaOwner, mediaOwnerStatus] = useSelector(userSelectors.mediaOwner);
  const [userAttributes] = useSelector(userSelectors.userAttributes);

  useEffect(() => {
    // Fetches user data depending on the user type (advertiser or mediaOwner)
    if (
      isLoggedIn &&
      isMediaOwner &&
      !mediaOwner &&
      mediaOwnerStatus === "idle"
    ) {
      dispatch(userActions.willGetMediaOwner());
    }
    if (
      isLoggedIn &&
      !isMediaOwner &&
      !advertiser &&
      advertiserStatus === "idle"
    ) {
      dispatch(userActions.willGetAdvertiser());
    }
  });
  console.log(mediaOwner?.mediaowner);
  const imagePreview =
    String(resourcesUrl) +
    "pfp_business_" +
    (isMediaOwner ? mediaOwner?.mediaowner : advertiser?.advertiser) +
    "_preview.png";
  return (
    <div className="sticky top-0 z-10 grid grid-flow-col px-3 bg-white border-b shadow auto-cols-fr">
      <div>
        <div className="relative flex items-baseline gap-1 m-5 mb-7 w-fit">
          <BillaloLogo className="self-center h-7" />

          <div className="absolute bottom-0 right-0 text-sm font-extrabold tracking-tighter translate-y-full text-primary">
            {isMediaOwner ? "Media Owner SSP" : "Advertiser DSP"}
          </div>
        </div>
      </div>
      <div className="flex items-stretch justify-center gap-10">
        {sections.map(({ name, path }) => (
          <MainNavButton
            key={name}
            role="button"
            active={currentLocation.includes(path)}
            onClick={() => navigate(path)}
          >
            <span className="font-semibold ">{name}</span>
          </MainNavButton>
        ))}
      </div>

      <div>
        <div className="flex items-center justify-end h-full gap-4">
          {/** Notifications Icon */}

          <MainNavButton
            key={notifications.name}
            role="button"
            active={currentLocation.includes(notifications.path)}
            onClick={() => navigate(notifications.path)}
            className="h-full min-w-fit"
          >
            {/** TODO: implement notifications with consumer sub in BE */}
            <NotificationBell />
          </MainNavButton>

          <MainNavButton
            key={profile.name}
            role="button"
            active={currentLocation.includes(profile.path)}
            onClick={() => navigate(profile.path)}
            className="h-full"
          >
            <Tooltip tip={profile.name}>
              <ProfileMiniCard
                picture={imagePreview}
                business={advertiser?.name || mediaOwner?.name || ""}
                name={
                  userAttributes?.given_name + " " + userAttributes?.family_name
                }
              />
            </Tooltip>
          </MainNavButton>

          <Tooltip offsets={[-10, 0]} tip="Logout">
            <TransparentButton
              className="h-full text-gray-600 hover:text-black"
              onClick={() => {
                dispatch(userActions.willLogout());
              }}
            >
              <ArrowRightOnRectangleIcon className="h-6" />
            </TransparentButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default MainNavbar;
