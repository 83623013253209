import BasicButton from "components/buttons/Basic";
import Select from "components/inputs/Select";
import BasicModal from "components/modals/Basic";
import { Order } from "graphql/ssp/generated";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ordersActions } from "store/slices/orders";
import { orderStateMachine, OrderStatus } from "types/Statuses";
import { orderF } from "utils/formatters";

type Props = {
  order: Partial<Order>;
  open: boolean;
  close: () => void;
};
const orderActionsMap: Record<string, string> = {
  confirm: "Confermato",
  reject: "Rifiutato",
  cancel: "Cancellato",
  put_materials: "Materiali ricevuti",
  post: "In esposizione",
  complete: "Completata",
};
const actionParser = (actions: string[]) => {
  const arr: [string, string][] = actions.map((action) => {
    let alias = orderActionsMap[action] || "";
    return [action, alias];
  });
  return arr.filter(([, alias]) => !!alias);
};
export const UpdateStateModal: React.FC<Props> = ({ order, open, close }) => {
  const dispatch = useDispatch();
  const [orderAction, setOrderAction] = useState("");
  const availableActions =
    orderStateMachine.states[order.status as OrderStatus]?.on || {};
  const actions = actionParser(Object.keys(availableActions));

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    console.log("Updating order state to ", orderAction);
    console.log(orderAction, order.campaign, order.id);
    if (orderAction && order.campaign && order.id) {
      dispatch(
        ordersActions.willUpdateOrderState({
          action: orderAction,
          campaign: order.campaign,
          id: order.id,
        })
      );
    }
    close();
  };
  return (
    <BasicModal
      open={open}
      close={close}
      title={`Cambiamento di stato dell'ordine`}
      closableOnOverlayClick
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-3 px-2">
          <div className="font-semibold">
            Seleziona uno stato da applicare a questo ordine
          </div>
          <Select
            name="state"
            defaultValue={""}
            onChange={(e) => setOrderAction(e.target.value)}
            required
          >
            {actions.map(([value, displayName], i) => {
              return (
                <option
                  key={i}
                  value={value}
                  className="flex items-center gap-3 p-2 rounded-lg shadow"
                >
                  {displayName}
                </option>
              );
            })}
          </Select>
          <div className="flex items-center gap-2">
            <>
              <span className="text-gray-400">Stato corrente:</span>
              {orderF["status"]?.(order.status)}
            </>
          </div>

          <div className="flex justify-end w-full gap-3">
            <BasicButton
              className="text-gray-600 w-fit bg-slate-200"
              onClick={close}
            >
              Annulla
            </BasicButton>
            <BasicButton className="w-fit" type="submit">
              Modifica stato
            </BasicButton>
          </div>
        </div>
      </form>
    </BasicModal>
  );
};
export default UpdateStateModal;
