import FixedSpaceContainer from "components/containers/FixedSpace";
import BillaloLogo from "components/others/BillaloLogo";
import { Outlet } from "react-router-dom";

type Props = {
  hideNavbar?: boolean;
};
export const OnboardingLayout: React.FC<Props> = () => {
  return (
    <div
      className={
        "flex h-screen max-h-screen w-screen min-w-[900px] flex-col overflow-hidden bg-lighter-gray"
      }
    >
      <div className="flex p-2 bg-white border-b shadow-sm h-30">
        <BillaloLogo />
      </div>
      <FixedSpaceContainer>
        <Outlet />
      </FixedSpaceContainer>
    </div>
  );
};
export default OnboardingLayout;
