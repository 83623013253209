import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  open?: boolean;
  title: React.ReactNode;
  classNames?: {
    chevron?: string;
    title?: string;
    content?: string;
  };
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "title"
>;

export const DropdownContainer: React.FC<Props> = ({
  open = false,
  className,
  classNames,
  children,
  title,
  ...attributes
}) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className={twMerge("flex flex-col gap-1", className)} {...attributes}>
      <div
        role="button"
        onClick={() => setIsOpen((prev) => !prev)}
        className={twMerge("flex items-center gap-2", classNames?.title)}
      >
        <ChevronDownIcon
          className={twMerge(
            "-ml-6 h-4 -rotate-90 transition-all",
            isOpen && "rotate-0",
            classNames?.chevron
          )}
        />
        {title}
      </div>
      <div className={twMerge("", classNames?.content, !isOpen && "hidden")}>
        {children}
      </div>
    </div>
  );
};
