import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { exportItemList } from "components/CsvUploader/billboards/export";
import BasicButton from "components/buttons/Basic";
import BasicInput from "components/inputs/Basic";
import Tooltip from "components/popovers/Tooltip";
import { CsvBillboardInput } from "pages/BatchUpload/utils";
import { inputCol } from "pages/BatchUpload/validations";
import { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  /** Expects a string csv */
  items: CsvBillboardInput[];
  setItems: React.Dispatch<
    React.SetStateAction<CsvBillboardInput[] | undefined>
  >;
  uploadItems: (items: CsvBillboardInput[]) => void;
  itemKeys: (keyof CsvBillboardInput)[];
};
export const CsvTable: React.FC<Props> = ({
  items,
  setItems,
  itemKeys,
  uploadItems,
}) => {
  const [validityMap, setValidityMap] = useState(
    items.map((item) => Object.values(item).map(() => true))
  );
  const validIndexes = validityMap.reduce<number[]>((acc, validProps, i) => {
    return validProps.includes(false) ? acc : [...acc, i];
  }, []);

  const columns = {
    gridTemplateColumns: `auto ${"120px ".repeat(itemKeys.length)}`,
  };
  return (
    <div className="flex max-h-full max-w-full flex-col">
      <div className="mb-4 flex justify-end gap-2">
        <BasicButton
          className="w-fit bg-green-500 py-1"
          onClick={() => exportItemList(items)}
        >
          Esporta
          <DocumentArrowDownIcon className="h-5" />
        </BasicButton>
        <BasicButton
          className="w-fit"
          onClick={() =>
            uploadItems(items.filter((_i, i) => validIndexes.includes(i)))
          }
        >
          Carica impianti validi{" "}
          <span className="font-normal">
            ({validIndexes.length}/{items.length})
          </span>
        </BasicButton>
      </div>
      <div
        className="relative grid overflow-y-auto overflow-x-scroll"
        style={{
          gridTemplateRows: `repeat(${items.length}, 40px)`,
        }}
      >
        <div className="sticky top-0 z-30 grid bg-white" style={columns}>
          <div
            className={twMerge(
              "sticky left-0 z-20 my-auto h-full w-full border bg-gray-100"
            )}
          />

          {itemKeys.map((v, i) => (
            <div
              key={i}
              className="z-30 flex items-center overflow-hidden border text-xs"
            >
              {(inputCol as Record<string, string>)?.[String(v)]?.[0] ||
                String(v)}
            </div>
          ))}
        </div>
        {items.map((d, idx) => (
          <div
            key={idx}
            className="grid"
            style={{
              gridTemplateRows: `40px`,
              ...columns,
            }}
          >
            <div
              className={twMerge(
                "sticky left-0 z-20 my-auto flex items-center justify-center border p-1 text-black",
                "h-full w-full text-sm font-bold",
                validIndexes.includes(idx) ? "bg-green-300" : "bg-red-300"
              )}
            >
              {idx}
            </div>
            {Object.entries(d).map(([key, value], i) => (
              <GridCell
                className="overflow-hidden text-ellipsis whitespace-nowrap border text-xs"
                key={i}
                object={d}
                index={idx}
                propIndex={i}
                inputKey={key}
                value={String(value)}
                setItems={setItems}
                setValidityMap={setValidityMap}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

type GCProps = {
  value: string;
  inputKey: string;
  object: CsvBillboardInput;
  index: number;
  propIndex: number;
  setItems: React.Dispatch<
    React.SetStateAction<CsvBillboardInput[] | undefined>
  >;
  setValidityMap: React.Dispatch<React.SetStateAction<boolean[][]>>;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const GridCell: React.FC<GCProps> = ({
  object,
  index,
  propIndex,
  inputKey,
  value,
  className,
  setItems,
  setValidityMap,
  ...attributes
}) => {
  const asCsvBkey = inputKey as keyof CsvBillboardInput;
  const maybeValue = object[asCsvBkey] === undefined ? "" : object[asCsvBkey];
  const [isValid, reason] = useMemo(() => {
    return inputCol?.[asCsvBkey]?.[1]?.(maybeValue, object) || [false];
  }, [asCsvBkey, maybeValue, object]);
  useEffect(() => {
    let valid = isValid || isValid === undefined;
    setValidityMap((prev) => {
      if (prev[index][propIndex] === valid) return prev;
      prev[index][propIndex] = valid;
      return [...prev];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maybeValue]);
  return (
    <Tooltip
      className={twMerge(
        className,
        "relative flex items-center justify-center hover:z-20 hover:overflow-visible",
        isValid === true && "bg-green-100",
        isValid === false && "bg-red-100"
      )}
      {...attributes}
      disabled={!reason}
      tip={reason}
    >
      {/* <div className="absolute hover:z-50 hover:w-fit">{children}</div> */}
      <BasicInput
        defaultValue={maybeValue}
        className="absolute left-0 bg-transparent hover:z-20 hover:w-fit"
        onChange={(e) =>
          setItems((prev) => {
            if (!prev) return prev;
            prev[index][asCsvBkey] = e.target.value;
            return [...prev];
          })
        }
      />
    </Tooltip>
  );
};
