import BasicContainer from "components/containers/Basic";
import MutableField from "components/inputs/MutableField";
import { MediaOwner } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import EditApply from "components/buttons/EditApply";
import ImageDivInput from "components/inputs/ImageDiv";
import { createStripeLoginLink, uploadImage } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import ActivateStripe from "pages/Profile/MediaOwner/Business/ActivateStripe";

export const Business = () => {
  const dispatch = useDispatch();
  const [mediaOwner, mediaOwnerStatus] = useSelector(userSelectors.mediaOwner);
  const [mediaOwnerData, setMediaOwnerData] = useState({} as MediaOwner);
  const [editProfile, setEditProfile] = useState(false);
  const [editRegistry, setEditRegistry] = useState(false);

  type FormatterFn<T> = (key: T) => string;
  type UserFieldArray = [
    keyof MediaOwner,
    string,
    string,
    FormatterFn<string>?
  ];
  const registryData: UserFieldArray[] = [
    ["p_iva", "P.IVA", "auto"],
    ["phone", "Telefono", "tel"],
    ["website", "Sito web", "website"],
    ["email", "Email", "email"],
    ["rea", "REA", "auto"],
    ["recipient_code", "Codice destinatario", "auto"],
    ["pec", "PEC", "auto"],
    ["society_form", "Forma societaria", "organization"],
  ];
  const paymentData: UserFieldArray[] = [
    ["recipient_code", "Codice destinatario", "auto"],
    ["funds_transfer", "Trasferimento fondi", "auto"],
  ];
  /** Updates the Advertiser structure with new data */
  const editMediaOwner = (flag: boolean, updated?: typeof mediaOwner) => {
    const updatedMediaOwner = updated || mediaOwnerData;
    if (!(flag && mediaOwner)) return;
    const { mediaowner: _1, ...oldUser } = mediaOwner;
    const { mediaowner: _2, ...updatedUser } = updatedMediaOwner;
    dispatch(
      userActions.willAddMediaOwner({
        ...oldUser,
        ...updatedUser,
      })
    );
  };
  const placeholder = "/assets/images/placeholder_profile.png";

  useEffect(() => {
    if (!mediaOwner && mediaOwnerStatus === "idle") {
      dispatch(userActions.willGetMediaOwner());
    }
  }, [mediaOwner, dispatch, mediaOwnerStatus]);
  return (
    <>
      <BasicContainer className="flex-row w-full p-5 animate-down-up">
        <ImageDivInput
          image={null}
          setImage={() => {}}
          className="m-4 mr-12 h-24 w-24 shrink-0 translate-x-1/4 -translate-y-[40%] scale-[1.7] rounded-xl border-4 border-lighter-gray"
          // src={mediaOwner?.avatar}
          src={mediaOwner?.avatar}
          placeholder={placeholder}
          alt="Business pic/logo"
          withModal
          onImageChange={(image) => {
            if (!mediaOwner?.mediaowner) return;
            const imageId = `pfp_business_${mediaOwner.mediaowner}`;
            uploadImage(image, imageId).then((url) => {
              if (!url) return;
              const updatedMediaOwnerData = { ...mediaOwnerData, avatar: url };
              setMediaOwnerData(updatedMediaOwnerData);
              editMediaOwner(true, updatedMediaOwnerData);
            });
          }}
        />
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between mb-8">
            <div>
              <MutableField
                setter={setMediaOwnerData}
                state={mediaOwner}
                stateKey="name"
                switchInput={editProfile}
                autoComplete={"organization"}
                className="text-2xl font-bold"
              />
              <div className="flex text-base text-gray-400 select-all">
                <div className="select-none">@</div>
                <div>{mediaOwner?.mediaowner}</div>
              </div>
            </div>
            <EditApply
              flag={editProfile}
              setter={setEditProfile}
              pending={mediaOwnerStatus === "pending"}
              onClick={(flag) => editMediaOwner(flag)}
            />
          </div>
          <div className="grid grid-cols-[auto_auto_auto_auto] gap-2">
            <div className="text-gray-600 ">Spazi venduti</div>
            <div>{mediaOwner?.n_orders || 0}</div>
            <div className="text-gray-600 ">Piano di abbonamento</div>
            <div>{mediaOwner?.plan}</div>
            <div className="text-gray-600 ">Iscritto dal</div>
            <div>
              <i>{mediaOwner?.subscription_date || "-"}</i>
            </div>
            <div className="text-gray-600 ">Livello Fee</div>
            <div>{mediaOwner?.fees}</div>
          </div>
        </div>
      </BasicContainer>
      <BasicContainer className="flex-col w-full p-5 animate-down-up">
        <div className="flex items-start justify-between w-full">
          <div className="font-semibold">Anagrafica</div>
          <EditApply
            flag={editRegistry}
            setter={setEditRegistry}
            pending={mediaOwnerStatus === "pending"}
            onClick={(flag) => editMediaOwner(flag)}
          />
        </div>
        <div className="grid w-full grid-cols-[auto_auto_auto_auto] gap-2">
          {registryData.map(([stateKey, label, autoComplete], i) => {
            return (
              <MutableField
                key={i}
                setter={setMediaOwnerData}
                state={mediaOwner}
                stateKey={stateKey}
                switchInput={editRegistry}
                label={label}
                autoComplete={autoComplete}
              />
            );
          })}
        </div>
      </BasicContainer>

      <BasicContainer className="flex-col w-full p-5 animate-down-up">
        <div className="flex items-start justify-between w-full">
          <div className="font-semibold">Pagamenti</div>
        </div>
        <div className="grid w-full grid-cols-[auto_auto_auto_auto] gap-2">
          {paymentData.map(([stateKey, label, autoComplete], i) => (
            <MutableField
              key={i}
              setter={setMediaOwnerData}
              state={mediaOwner}
              stateKey={stateKey}
              switchInput={editRegistry}
              label={label}
              autoComplete={autoComplete}
            />
          ))}
        </div>
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            {!mediaOwner?.stripe_actived ? (
              <ActivateStripe />
            ) : (
              <ToStripeDashboard
                stripe_account={String(mediaOwner.stripe_account)}
              />
            )}
          </div>
          <div className="self-start h-16 ">
            <img
              className="object-contain w-full h-full"
              src="/assets/logos/stripe.png"
              alt="Stripe logo"
            />
          </div>
        </div>
      </BasicContainer>
    </>
  );
};

const ToStripeDashboard: React.FC<{ stripe_account: string }> = ({
  stripe_account,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const toStripeDashboard = async () => {
    setIsLoading(true);
    let url = await createStripeLoginLink({
      acct: stripe_account,
    });
    console.log("createStripeLoginLink res:", { url });
    window.open(String(url));
    setIsLoading(false);
    // window.open(String(url));
  };
  return (
    <BasicButton
      loading={isLoading}
      className="w-56"
      onClick={toStripeDashboard}
    >
      Vai alla dashboard Stripe
    </BasicButton>
  );
};
export default Business;
