import useCountdown from "hooks/useCountdown";

type Props = {
  end: number;
};
export const Timer: React.FC<Props> = ({ end }) => {
  const [hours, minutes, seconds] = useCountdown(end)
  return (
    <>
      <div>{hours}</div>
      <div>{minutes}</div>
      <div>{seconds}</div>
    </>
  );
};