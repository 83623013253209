import * as SSPApi from "api/gql/ssp";
import * as DSPApi from "api/gql/dsp";
import { AdvertiserUser } from "graphql/dsp/generated";
import { MediaOwnerUser } from "graphql/ssp/generated";
import { put, select, takeLatest } from "redux-saga/effects";
import { teamActions } from "store/slices/team";
import { userSelectors } from "store/slices/user";
import { sortByKey } from "utils/dateFormatter";

function* fetchTeam() {
  const isMediaOwner: boolean = yield select(userSelectors.isMediaOwner);
  const team: MediaOwnerUser[] | AdvertiserUser[] | null = isMediaOwner
    ? yield SSPApi.listMediaOwnerUsers()
    : yield DSPApi.listAdvertiserUsers();

  if (!team) {
    yield put(teamActions.didFetchTeam([]));
    return;
  }
  sortByKey<AdvertiserUser | MediaOwnerUser>(team, "creation_date");
  yield put(teamActions.didFetchTeam(team));
}
export function* sagas() {
  yield takeLatest(teamActions.willFetchTeam.type, fetchTeam);
}
