
export const readTextFile = (file: File) => {
  const promise: Promise<string|undefined> = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (e) => {
      const result = e.target?.result;
      if (!result || typeof result !== "string") return reject(result);

      resolve(result);
    });
    reader.addEventListener("error", (e) => {
      reject(e);
    });
    reader.addEventListener("progress", (event) => {
      if (event.loaded && event.total) {
        const percent = (event.loaded / event.total) * 100;
        console.log(`Progress: ${Math.round(percent)}`);
      }
    });
    reader.readAsText(file);
  });
  return promise;
};


export default readTextFile;
