import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import { addUserToGroup } from "./addUserToGroup";

export const BusinessName = () => {
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [cognitoUser] = useSelector(userSelectors.cognitoUser);
  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<string | null>(null);
  const dispatch = useDispatch();
  const username = cognitoUser?.getUsername();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!cognitoUser || !businessName || !username) return setLoading(false);
    // Spaces are not allowed in the group name
    const business_name = businessName.replaceAll(" ", "_");
    const added = await addUserToGroup(
      isMediaOwner,
      cognitoUser,
      business_name
    );
    dispatch(userActions.willFetchAttributes(cognitoUser));
    const getGroup = isMediaOwner
      ? userActions.willGetMediaOwner
      : userActions.willGetAdvertiser;
    // If user has been added into existing group, get existing data and return
    if (added) return dispatch(getGroup());

    const addGroup = isMediaOwner
      ? userActions.willAddMediaOwner
      : userActions.willAddAdvertiser;

    const addGroupArgs = {
      name: business_name,
      subscription_date: new Date().toISOString().substring(0, 10),
    };
    return dispatch(addGroup(addGroupArgs));
  };

  return (
    <div className="flex flex-col items-center w-full h-full items">
      <span className="my-10 text-2xl text-end text-primary">
        Abbiamo bisogno di più informazioni sulla tua azienda
      </span>
      <BasicContainer className="text-gray-700 shadow-lg">
        <div className="flex gap-6 justify-center-center">
          <form
            className="flex flex-col items-center gap-3 w-96"
            onSubmit={handleSubmit}
          >
            <div className="text-xl font-semibold text-gray-700">
              Scegli il nickname aziendale
            </div>
            <div className="flex items-center gap-3">
              <BasicInput
                placeholder="Nome azienda"
                name="business_name"
                className="my-2 text-lg font-medium text-center w-60"
                required
                minLength={3}
                maxLength={30}
                onChange={(e) => setBusinessName(e.target.value)}
                title="Inserisci il nome della tua azienda"
              />
            </div>
            <div className="text-sm text-gray-500">
              Il nickname aziendale è il nome con cui la tua azienda apparirà
              all'interno della piattaforma e con cui altri utenti di Billalo
              potranno trovarti. <br />
              <br />
              Suggeriamo di utilizzare il brand name, o una versione
              semplificata della ragione sociale. Scegli con cura il nickname
              aziendale in quanto non sarà possibile modificarlo in futuro.{" "}
              <br />
              <br />
              Per entrare in un account aziendale già esistente, inserisci il
              nickname aziendale fornito dalla tua azienda.
            </div>
            <BasicButton loading={loading} role="submit">
              Conferma
            </BasicButton>
          </form>
        </div>
      </BasicContainer>
    </div>
  );
};

export default BusinessName;
