import BasicButton from "components/buttons/Basic";
import BasicInput from "components/inputs/Basic";
import { PricingInput } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";

type Props = {
  pricing: PricingInput;
  setPricing: React.Dispatch<React.SetStateAction<PricingInput>>;
};

export const PricingType: React.FC<Props> = ({ pricing, setPricing }) => {
  const ruleTypes = ["Sconto", "Maggiorazione"] as const;
  const toggleRuleType = (type: typeof ruleTypes[number]) => {
    setPricing((prev) => {
      if (prev.rule_type === type) {
        const { rule_type, ...rest } = prev;
        return rest;
      }
      return { ...prev, rule_type: type };
    });
  };
  return (
    <div className="flex items-center justify-between w-full py-2">
      <div className="flex items-center gap-2">
        <div className="text-sm text-gray-500 select-none">Tipo:</div>
        {ruleTypes.map((type) => {
          return (
            <BasicButton
              key={type}
              onClick={() => toggleRuleType(type)}
              className={twMerge(
                "w-fit bg-gray-400 p-1",
                pricing.rule_type === type && "bg-primary-light"
              )}
            >
              {type}
            </BasicButton>
          );
        })}
      </div>
      <div className="flex items-center gap-2">
        <div className="text-sm text-gray-500 select-none">Percentuale:</div>
          <BasicInput
            required
            onChange={(e) =>
              setPricing((prev) => ({
                ...prev,
                percentage: Number(e.target.value),
              }))
            }
            className="w-24 text-lg font-semibold disabled:bg-gray-100"
            type="number"
            step={0.01}
          />
        <b>%</b>
      </div>
    </div>
  );
};

export default PricingType;
