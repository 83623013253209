/**
 * Contains the options for the select fields in the New Billboard modal
 * When values are arays, the first element is for the Outdoor billboard class
 * and the second for the Indoor billboard class
 */
export const billboardOptions = {
  media_type: [
    [
      "Impianto_illuminato",
      "Impianto_non_illuminato",
      "Striscione",
      "Vetrina",
      "Display_digitale",
      "Altro",
    ] as const,
    [
      "Affissione",
      "Cartellonistica",
      "Pensilina",
      "Mediapole",
      "Display_digitale",
      "Facciata",
      "Banner",
      "Transenne",
      "Altro",
    ] as const,
  ],
  billboard_class: ["Outdoor", "Indoor"] as const,
  press_type: [
    ["Carta_affissione", "PVC", "Microforato", "Telo", "Altro"] as const,
    ["Carta", "Banner_PVC", "Microforato", "Altro"] as const,
    ["Video", "Immagine", "Video_e_Immagine", "Altro"] as const,
  ],
  facade: ["Monofacciale", "Bifacciale"] as const,
  height_ground: [
    "0-1mt",
    "1-2mt",
    "2-4mt",
    "4-7mt",
    "7-10mt",
    "Oltre 10mt",
  ] as const,
  min_period: [
    ["2 settimane", 2],
    ["1 mese", 4],
    ["2 mesi", 8],
    ["6 mesi", 24],
    ["1 anno", 52],
  ] as const,
  location: [
    ["Centro_commerciale", "Stazione", "Aeroporto", "Altro"] as const,
    [
      "Zona_pedonale",
      "Autostrada",
      "Strada_statale",
      "Strada_Urbana",
      "Strada_provinciale",
      "Strada_rurale",
      "Parcheggio",
      "Centro_commerciale",
      "Stazione",
      "Aeroporto",
      "Stadio",
    ] as const,
  ],
  lighting: ["Attigua", "Nulla", "Propria"] as const,
  orientation: [
    "Parallelo",
    "Perpendicolare",
    "In_Rotonda",
    "Incrocio",
  ] as const,
  format: [
    "1200x600",
    "600x300",
    "400x300",
    "300x200",
    "140x200",
    "100x140",
    "70x100",
    "Altro",
  ],
  content_rotation: [] as const,
  maintenance_status: ["ottimo", "buono", "scarso"] as const,
};
Object.freeze(billboardOptions);
export default billboardOptions;
