import BasicContainer from "components/containers/Basic";
import { createRef, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { confirmSignup } from "api/auth";
import { twMerge } from "tailwind-merge";
import OtpInput from "components/inputs/Otp";
import { resendSignuUpCode } from "api/auth";
import ResendRequestButton from "components/others/ResendRequestButton";

/** Time to wait before being able to resend the confirmation email */
export const Confirmation: React.FC = () => {
  const otpDigitLength = 6;
  const digitsArray = Array.apply(null, Array(otpDigitLength)).map((_) => "");

  const [values, setValues] = useState(digitsArray);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInvalidData, setIsInvalidData] = useState(false);
  const { search } = useLocation();
  const urlEmail = new URLSearchParams(search).get("email");
  const formRef = createRef<HTMLFormElement>();
  const navigate = useNavigate();

  const handleSubmit = async (el: HTMLFormElement) => {
    const otpCode = values.join("");
    if (!(otpCode && otpCode.length === 6)) return;
    setIsProcessing(true);
    setIsInvalidData(false);
    try {
      const response = await confirmSignup(urlEmail!, otpCode);
      if (response !== "SUCCESS") throw response;
      const searchEmail = new URLSearchParams({ email: urlEmail! }).toString();
      navigate("/login?" + searchEmail);
    } catch (err) {
      setValues(values.map((_) => ""));
      setIsInvalidData(true);
    }
    setIsProcessing(false);
  };
  useEffect(() => {
    if (!(values.find((v) => v === "") === "")) {
      formRef.current?.requestSubmit();
    }
  }, [values, formRef]);
  if (!urlEmail) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <div className="w-3/4 text-center">
        <h1 className="mb-4 text-4xl font-bold">
          Conferma indirizzo di registrazione
        </h1>
      </div>

      <BasicContainer className="text-slate-700" loading={isProcessing}>
        <>
          <span className="px-6 text-2xl font-bold text-center">
            Inserisci il codice OTP
          </span>
          <div className="flex items-center justify-center w-full text-gray-500">
            <div className="flex flex-col items-center w-full">
              <div>
                Abbiamo inviato il codice di conferma all'indirizzo che ci hai
                fornito
              </div>
              <div className="font-medium text-gray-400 whitespace-nowrap">
                (<EnvelopeIcon className="inline h-6" /> {urlEmail})
              </div>
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e.currentTarget);
            }}
            ref={formRef}
            id="confirmation-form"
            className="flex items-center justify-center w-3/4"
          >
            <div className="flex flex-col justify-center">
              <OtpInput digits={6} values={values} setValues={setValues} />
            </div>
          </form>
          <div
            className={twMerge(
              "text-sm italic text-red-400",
              !isInvalidData && "hidden"
            )}
          >
            Codice di conferma non valido
          </div>
          <ResendRequestButton callback={() => resendSignuUpCode(urlEmail)} />
        </>
      </BasicContainer>
    </>
  );
};

export default Confirmation;
