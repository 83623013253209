import { getMarkerUrl } from "utils/billboards/BillboardSvg";
import { Billboard } from "graphql/ssp/generated";
import { currencyFormatter } from "utils/currencyFormatter";
import { shorten } from "utils/strings/shorten";
import ImageDiv from "components/others/ImageDiv";
import { resourcesUrl } from "api/gql/ssp";
import { IndexCardMini } from "components/cards/Billboard/IndexCardMini";
import { formatAwsDate } from "utils/dateFormatter";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Tooltip from "components/popovers/Tooltip";

const TaxExpiration: React.FC<{ lastPayment?: string | null }> = ({ lastPayment }) => {
  const MAX_DAYS = 60;
  if (!lastPayment) return <div className="w-5" />;
  const expiry=new Date(lastPayment);
  expiry.setFullYear(expiry.getFullYear() + 1)
  const unixDifference = expiry.getTime() - new Date().getTime();
  const daysDifference = unixDifference / (1000 * 3600 * 24);
  if (daysDifference < 1)
    return (
      <Tooltip tip={`Autorizzazione scaduta (ultimo pagamento il ${formatAwsDate(lastPayment)})`}>
        <ExclamationTriangleIcon className="h-5 text-red-400" />
      </Tooltip>
    );
  if (daysDifference < MAX_DAYS)
    return (
      <Tooltip
        tip={`Autorizzazione in scadenza prossima (ultimo pagamento il ${formatAwsDate(lastPayment)})`}
      >
        <ExclamationTriangleIcon className="h-5 text-yellow-400" />
      </Tooltip>
    );
  return <div className="w-5" />;
};
export const formatter: FormatterObj<Partial<Billboard>> = {
  address: (address, billboard) => {
    if (!billboard) return "Error";
    return (
      <div className="flex items-center gap-3 -ml-1">
        <TaxExpiration lastPayment={billboard.taxes?.last_payment} />
        <ImageDiv
          className="w-10 h-10 -ml-1 rounded-lg"
          src={
            String(resourcesUrl) +
            "billboard_" +
            billboard["id"] +
            "_preview.png"
          }
          placeholder={getMarkerUrl(
            billboard.billboard_class,
            billboard.media_type
          )}
        />
        <small title={(address || billboard["location_name"])?.toString()}>
          {shorten(address || billboard["location_name"], 30)}
          <br />
          <b className="font-semibold">{billboard["municipality"]}</b>
        </small>
      </div>
    );
  },
  format: (format) => (
    <span className="p-1 text-xs font-medium text-gray-700 rounded bg-slate-200">
      {format}
    </span>
  ),
  media_type: (media_type) => (
    <span className="font-semibold text-gray-400">
      {media_type?.replaceAll("_", " ")}
    </span>
  ),
  location: (location) => location?.replaceAll("_", " "),
  press_type: (press_type) => press_type?.replaceAll("_", " "),
  content_duration: (content_duration) =>
    content_duration && content_duration + "s",
  min_period: (min_period) =>
    min_period &&
    "" + min_period + (min_period > 1 ? " settimane" : " settimana"),
  content_rotation: (content_rotation) =>
    content_rotation && "1 su " + content_rotation,

  iV_municipality: (val) => (
    <IndexCardMini type={"iV_municipality"} val={val} />
  ),
  iV_normalized: (val) => <IndexCardMini type={"iV_normalized"} val={val} />,
  price: (price) => currencyFormatter.format(Number(price)),
  last_maintenance: (val) => formatAwsDate(val),
  authorization_expiration: (val) => formatAwsDate(val),
};
export const sorter: SorterObj<Partial<Billboard>> = {
  format: (a, b) => {
    const getArea = (val: typeof a) =>
      String(val)
        .split("x")
        .map((v) => Number(v))
        .reduce((a, b) => a * b, 1);
    return getArea(a) > getArea(b) ? 1 : -1;
  },
  price: (a, b) => (Number(a) > Number(b) ? 1 : -1),
};
