import { XMarkIcon } from "@heroicons/react/24/outline";
import { rejectAdvertiserUser } from "api/gql/dsp";
import { rejectMediaOwnerUser, resourcesUrl } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import { MiniButton } from "components/buttons/Mini";
import BasicModal from "components/modals/Basic";
import Tooltip from "components/popovers/Tooltip";
import { AdvertiserUser } from "graphql/dsp/generated";
import { MediaOwnerUser } from "graphql/ssp/generated";
import useNotification from "hooks/useNotification";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { teamActions } from "store/slices/team";
import { userSelectors } from "store/slices/user";

export const RejectUserButton: React.FC<{ user: MediaOwnerUser|AdvertiserUser }> = ({
  user,
}) => {
  // const acceptUser = acceptMediaOwnerUser({target_user:user?.sub});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { toast } = useNotification();
  
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const rejectGroupUser = isMediaOwner
    ? rejectMediaOwnerUser
    : rejectAdvertiserUser;

  const rejectUser = async () => {
    const id = String(user.id);
    setIsLoading(true);
    // Reject user
    await rejectGroupUser({ id });
    // Refetch team
    dispatch(teamActions.willFetchTeam());
    // Toast notification
    toast({
      title: `Utente rifiutato`,
      text: `Hai rifiutato l'accesso al tuo team per l'utente ${user.given_name} ${user.family_name}`,
      imageUrl: `${resourcesUrl}pfp_personal_${user.id}.png`,
      type: `SUCCESS`,
    });
    setIsLoading(false);
    setIsOpen(false);
  };
  return (
    <>
      <Tooltip tip="Rifiuta l'utente ">
        <MiniButton
          onClick={() => setIsOpen(true)}
          className="text-white bg-opacity-100 stroke-2 bg-red-400/50 hover:bg-red-400/75"
        >
          <XMarkIcon className="h-4" />
        </MiniButton>
      </Tooltip>
      <BasicModal
        open={isOpen}
        loading={isLoading}
        close={() => setIsOpen(false)}
        title={`Rifiuto membership utente ${user.given_name} ${user.family_name}`}
      >
        <div className="flex flex-col gap-5">
          <div className="text-lg">
            Sei sicuro di voler rifiutare questo utente?
          </div>
          <div className="text-gray-400">
            Una volta effettuata questa operazione l'utente in questione non
            farà più parte di questo gruppo
          </div>

          <div className="flex justify-end gap-3">
            <BasicButton
              className="py-1 bg-gray-400 w-fit"
              onClick={() => setIsOpen(false)}
            >
              Annulla operazione
            </BasicButton>
            <BasicButton
              className="py-1 bg-red-400 w-fit"
              onClick={() => rejectUser()}
            >
              Espelli utente
            </BasicButton>
          </div>
        </div>
      </BasicModal>
    </>
  );
};
export default RejectUserButton;
