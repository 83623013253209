import useCountdown from "hooks/useCountdown";

type Props = { date: string | null | undefined };

export const isDraftExpired = (val: string) => {
  return Date.now() > new Date(val).getTime() + 30 * 60 * 1000;
};
export const DraftTimer: React.FC<Props> = ({ date }) => {
  let unix = new Date(date || 0).getTime();
  const draftTime = 30 * 60 * 1000;
  const unixDraftTime = unix + draftTime
  const [, minutes, seconds] = useCountdown(unixDraftTime);
  if(Date.now()>unixDraftTime){
    return <i>{" (scaduta)"}</i>
  }
  return (
    <div>
      {minutes}:{seconds}
    </div>
  );
};

export default DraftTimer;
