import BillboardClass from "components/cards/Billboard/Class";
import ImageDiv from "components/others/ImageDiv";
import ProgressCircle from "components/others/ProgressCircle";
import { Billboard } from "graphql/ssp/generated";
import { billboardF } from "utils/formatters";
import { shorten } from "utils/strings/shorten";

type Props = {
  data: Billboard;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const BillboardMiniCard: React.FC<Props> = ({ data, ...attributes }) => {
  return (
    <div className="flex items-center gap-3 p-3 py-2 text-sm bg-white rounded-lg shadow-lg">
      {data["imageUrl"] && (
        <ImageDiv src={data["imageUrl"]} className="h-14 w-14 rounded-xl" />
      )}
      <div className="flex flex-col self-stretch justify-between gap-1">
        <span className="text-sm max-w-[200px]">
          {shorten(data["address"] || data["location_name"], 40)}
        </span>
        <div className="flex items-center gap-2" {...attributes}>
          {data.iV_normalized && (
            <div className="flex">
              <ProgressCircle
                size={18}
                amount={data["iV_normalized"]}
                strokeWidth={3}
                color="#0062ff"
              />
              <span className="text-gray-400">{data["iV_normalized"]}</span>
            </div>
          )}
          <span className="text-xs">
            {data["billboard_class"] && (
              <BillboardClass bbClass={data["billboard_class"]} />
            )}
          </span>
          <span className="text-xs whitespace-nowrap">{billboardF["media_type"]?.(data["media_type"], data)}</span>
        </div>
      </div>
    </div>
  );
};

export default BillboardMiniCard;
