import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import BasicContainer from "components/containers/Basic";
import { ProgressTime } from "components/others/Progress/Time";
import { Campaign } from "graphql/dsp/generated";
import { twMerge } from "tailwind-merge";
import { formatAwsDate } from "utils/dateFormatter";
import { campaignF } from "utils/formatters";
type Props = {
  data: Campaign;
} & React.HTMLAttributes<HTMLDivElement>;

export const CampaignMiniCard = ({ data, className }: Props) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const dateFrom = formatAwsDate(data.from, dateOptions);
  const dateTo = formatAwsDate(data.to, dateOptions);

  // Calculates the campaign progress
  return (
    <BasicContainer className={twMerge("justify-start", className)}>
      <div className="w-full text-left">
        <div className="max-w-[300px] text-sm font-semibold">{data.name}</div>
        <small className="font-medium text-gray-500">
          {dateFrom} / {dateTo}
        </small>
      </div>
      <div className="flex justify-start w-full gap-3">
        {data.target && (
          <div className="flex items-center w-full gap-4 font-medium text-gray-500">
            <PresentationChartBarIcon className="h-4 " /> {data.target}
          </div>
        )}
        <div>{campaignF["status"]&&campaignF["status"](data.status,data)}</div>
      </div>
      <ProgressTime from={data.from} to={data.to}/>
    </BasicContainer>
  );
};
export default CampaignMiniCard;
