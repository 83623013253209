import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import Select from "components/inputs/Select";
import Tooltip from "components/popovers/Tooltip";
import { PricingInput } from "graphql/ssp/generated";
import useMyClients from "hooks/useMyClients";
import { useSelector } from "react-redux";
import { clientsSelectors } from "store/slices/clients";

type Props = {
  pricing: PricingInput;
  setPricing: React.Dispatch<React.SetStateAction<PricingInput>>;
};

export const ClientsCondition: React.FC<Props> = ({ pricing, setPricing }) => {
  const [myClients] = useSelector(clientsSelectors.myClients);
  useMyClients();

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between pb-2 mb-2 border-b ">
        <div>Clienti</div>
        <BasicButton
          className="py-1 text-sm text-gray-500 bg-gray-100 w-fit hover:bg-gray-200"
          onClick={() => {
            setPricing((prev) => {
              const { customers, ...rest } = prev;
              return rest;
            });
          }}
        >
          Rimuovi condizione <XMarkIcon className="h-4" />
        </BasicButton>
      </div>
      <div
        className="flex items-center justify-between gap-2 text-end"
        onSubmit={(e) => e.preventDefault()}
      >
        <Select
          className="text-black w-fit"
          defaultValue=""
          onChange={(e) => {
            e.preventDefault();
            setPricing((prev) => {
              const prevCustomers = prev.customers ? prev.customers : [];
              const customer = e.target.value;
              return { ...prev, customers: [...prevCustomers, customer] };
            });
            e.target.value = "";
          }}
        >
          {myClients &&
            myClients
              .filter(
                ({ advertiser }) => !pricing?.customers?.includes(advertiser!)
              )
              .sort((a, b) => {
                const name_a = a.name || a.advertiser || "",
                  name_b = b.name || b.advertiser || "";
                return name_a > name_b ? 1 : name_b > name_a ? -1 : 0;
              })
              .map((client) => {
                return (
                  <option
                    key={client.advertiser}
                    value={client.advertiser || ""}
                  >
                    {client.name?.replaceAll("_", " ") || client.advertiser}
                  </option>
                );
              })}
        </Select>
      </div>
      {pricing.customers && myClients && (
        <div className="flex flex-col gap-2 my-2">
          <div>
            Clienti selezionati:{" "}
            <span className="text-gray-400">({pricing.customers.length})</span>
          </div>
          <div className="flex flex-wrap gap-1">
            {myClients
              .filter(
                ({ advertiser }) =>
                  advertiser && pricing?.customers?.includes(advertiser)
              )
              .map(({ name, advertiser }) => {
                return (
                  <Tooltip key={advertiser} tip="Rimuovi">
                    <div
                      onClick={() => {
                        setPricing((prev) => {
                          const prevCustomers = prev.customers
                            ? prev.customers
                            : [];
                          const idx = prevCustomers.indexOf(advertiser || "");
                          prevCustomers.splice(idx, 1);
                          if (prevCustomers.length === 0) {
                            const { customers, ...rest } = prev;
                            return { ...rest };
                          }
                          return { ...prev, customers: [...prevCustomers] };
                        });
                      }}
                      role="button"
                      className="flex items-center gap-1 px-1 font-semibold text-gray-500 border rounded bg-gray-50"
                    >
                      <div>
                        {name?.replaceAll("_", " ") ||
                          advertiser?.replaceAll("_", " ")}
                      </div>
                      <XCircleIcon className="h-4" />
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};
export default ClientsCondition;
