import FloatingSelect from "components/inputs/Floating/Select";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import options from "utils/formatters/billboardOptions";
import FloatingBasic from "components/inputs/Floating/Basic";
import ImageDivInput from "components/inputs/ImageDiv";
import {
  StepForm,
  formDataGenerator,
  parseFormData,
} from "components/Stepper";
import { BillboardInput } from "graphql/ssp/generated";

const getFormatSize = (formData: FormData) => {
  let format = "";
  let size = "";
  const height = formData.get("height");
  const base = formData.get("base");
  if (height && base) {
    format = Number(base) + "x" + Number(height);
    size = Number(base) * Number(height) + "";
  }
  return { format, size };
};
/** Used during the user login (both for clients and mediaOwners) */
export const Params: StepForm<Partial<BillboardInput>> = ({
  className,
  editHandler,
  inputData,
  formRef,
  isCurrentStep,
  prefill,
  ...attributes
}) => {
  const [formData, setFormData] = useState<
    Partial<BillboardInput & { image: string | null }>
  >({});
  const [image, setImage] = useState<File | null>(null);
  const isOutdoor = Number(formData.billboard_class === "Outdoor");
  const isDigitalDisplay =
    formData.media_type === "Display_digitale" ? 2 : false;
  const sizeRef = useRef<HTMLInputElement>(null);
  const formatRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    const { format, size } = getFormatSize(form);
    form.append("format", format);
    form.append("size", size);
    const image = imageRef.current?.files ? imageRef.current?.files[0] : "";
    form.append("image", image);
    setFormData(parseFormData(form));
    editHandler(form, valid);
  };
  const fill = (key: keyof typeof inputData) =>
    prefill ? inputData?.[key] || undefined : undefined;
  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      className={twMerge(
        "flex w-full flex-col items-center gap-3 rounded-3xl",
        className
      )}
      onChange={(e) => handleChange(e.currentTarget)}
      ref={formRef}
      {...attributes}
    >
      <ImageDivInput
        image={image}
        innerRef={imageRef}
        setImage={setImage}
        className="w-full h-56 rounded-2xl"
        inputProps={{
          required: false,
          name: "image",
        }}
      />
      <div className="grid w-full grid-cols-2 gap-3">
        <FloatingSelect
          required
          label="Classe"
          name="billboard_class"
          defaultValue={fill("billboard_class")}
        >
          {options.billboard_class.map((value, i) => (
            <option key={i} value={value}>
              {value.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
        <FloatingSelect
          name="media_type"
          required
          label="Tipologia"
          defaultValue={fill("media_type")}
        >
          {options.media_type[isOutdoor].map((value, i) => (
            <option key={i} value={value}>
              {value.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
        {isDigitalDisplay ? (
          <>
            <FloatingSelect
              name="content_duration"
              required
              type="number"
              label="Durata contenuto"
              defaultValue={fill("content_duration")}
            >
              {[15, 20, 30, 40, 60].map((value) => (
                <option key={value} value={value}>
                  {value} sec.
                </option>
              ))}
            </FloatingSelect>
            <FloatingSelect
              name="content_rotation"
              required
              type="number"
              label="Rotazione contenuto"
              defaultValue={fill("content_rotation")}
            >
              {Array(16)
                .fill(0)
                .map((_, i) => {
                  // Valori da 5 a 20
                  const value = i + 5;
                  return (
                    <option key={value} value={value}>
                      1 su {value}
                    </option>
                  );
                })}
            </FloatingSelect>
          </>
        ) : (
          <FloatingSelect
            defaultValue={fill("press_type")}
            name="press_type"
            required
            label="Tipo contenuto"
          >
            {options.press_type[isDigitalDisplay || isOutdoor].map(
              (value, i) => (
                <option key={i} value={value}>
                  {value.replaceAll("_", " ")}
                </option>
              )
            )}
          </FloatingSelect>
        )}

        {!isDigitalDisplay && (
          <FloatingSelect
            defaultValue={fill("facade")}
            name="facade"
            required
            label="Facciate"
          >
            {options.facade.map((value, i) => (
              <option key={i} value={value}>
                {value.replaceAll("_", " ")}
              </option>
            ))}
          </FloatingSelect>
        )}

        <FloatingSelect
          defaultValue={fill("height_ground")}
          name="height_ground"
          required
          label="Altezza da terra"
        >
          {options.height_ground.map((value, i) => (
            <option key={i} value={value}>
              {value.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
        <FloatingBasic
          defaultValue={fill("cod_cimasa")}
          name="cod_cimasa"
          label="Codice Cimasa"
        />
        <FloatingBasic
          name="price"
          min={0.0}
          step={0.01}
          type="number"
          required
          label="Prezzo"
          defaultValue={fill("price")}
        />
        <FloatingSelect
          defaultValue={fill("min_period")}
          name="min_period"
          required
          label="Uscita minima"
        >
          {options.min_period.map(([alias, value], i) => (
            <option key={i} value={value}>
              {alias.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
        {!!isOutdoor && (
          <>
            <FloatingSelect
              defaultValue={fill("orientation")}
              name="orientation"
              required
              label="Orientamento"
            >
              {options.orientation.map((value, i) => (
                <option key={i} value={value}>
                  {value.replaceAll("_", " ")}
                </option>
              ))}
            </FloatingSelect>
            <FloatingSelect
              defaultValue={fill("lighting")}
              name="lighting"
              required
              label="Illuminazione"
            >
              {options.lighting.map((value, i) => (
                <option key={i} value={value}>
                  {value.replaceAll("_", " ")}
                </option>
              ))}
            </FloatingSelect>
          </>
        )}

        <hr className="w-full col-span-2" />
        <FloatingBasic
          defaultValue={fill("base")}
          name="base"
          required
          type="number"
          label="Base"
          min={1}
        />
        <FloatingBasic
          defaultValue={fill("height")}
          name="height"
          required
          type="number"
          label="Altezza"
          min={1}
        />
        <FloatingBasic
          name="size"
          innerRef={sizeRef}
          required
          type="number"
          label="Superficie"
          disabled
          value={
            formData.base && formData.height
              ? formData.base * formData.height + ""
              : fill("size") || ""
          }
        />
        <FloatingBasic
          name="format"
          innerRef={formatRef}
          required
          label="Formato"
          disabled
          value={
            formData.base && formData.height
              ? formData.base + "x" + formData.height
              : fill("format") || ""
          }
        />
      </div>
    </form>
  );
};
export default Params;
