import { useNavigate } from "react-router-dom";
import PlanningCard from "components/cards/Planning";
import { useEffect, useState } from "react";

const campaigns = [
  {
    image: "/assets/images/popularity.svg",
    title: "Notorietà",
    description: `Raggiungi quante più persone del tuo target con gli spazi più visibili. 
            Selezioneremo gli impianti a più alta visibilità ed esposti al pubblico scelto.`,
    path: "popularity",
    disabled: true,
  },
  {
    image: "/assets/images/interactions.svg",
    title: "Interazioni",
    description: `Incentiva il tuo pubblico a compiere azioni online e/o offline. 
            Selezioneremo impianti di grande impatto ed in luoghi ad alto passaggio pedonale.`,
    path: "interactions",
    disabled: true,
  },
  {
    image: "/assets/images/advanced.svg",
    title: "Avanzata",
    description: `Pianifica la tua campagna in 3 steps.`,
    path: "advanced",
  },
];

export const Planning = () => {
  const navigate = useNavigate();
  const [imgLoaded, setImgLoaded] = useState(false);

  const loadImages = async () => {
    const promises = campaigns.map((c) => fetch(c.image));
    await Promise.all(promises);
  };
  useEffect(() => {
    if (!imgLoaded) {
      loadImages().then(() => setImgLoaded(true));
    }
  }, [imgLoaded]);
  return (
    <div className="flex justify-center h-full animate-down-up">
      <div className="flex flex-col w-full max-w-3xl pt-10 mx-5 h-fit gap-y-10">
        <div className="flex flex-col gap-5">
          <div className="text-4xl font-semibold text-gray-900">
            Pianifica la tua campagna
          </div>
          <div className="text-xl text-gray-500">
            Utilizza la pianificazione guidata attraverso gli obiettivi
            notorietà, copertura, interazioni, oppure utilizza i filtri
            avanzati.
          </div>
          <div className="flex gap-5">
            {imgLoaded &&
              campaigns.map(({ image, title, description, path, disabled }) => {
                return (
                  <PlanningCard
                    image={image}
                    title={title}
                    description={description}
                    className="w-80 animate-down-up"
                    onClick={() => {
                      !disabled && navigate(path);
                    }}
                    disabled={disabled}
                    key={title}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Planning;
