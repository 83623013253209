import { teamSelectors, teamActions } from "store/slices/team";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export const useMyTeam = () => {
  const [myTeam, status] = useSelector(teamSelectors.myTeam);
  const dispatch = useDispatch();
  useEffect(() => {
    if (myTeam === null && status === "idle") {
      console.log("No team in store, fetching...");
      dispatch(teamActions.willFetchTeam());
    }
  }, [myTeam, status, dispatch]);
  return;
};
export default useMyTeam;
