import { ChevronDownIcon } from "@heroicons/react/24/outline";
import BasicContainer from "components/containers/Basic";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { BillBoardSvg } from "utils/billboards/BillboardSvg";
import billboardOptions from "utils/formatters/billboardOptions";
import BasicButton from "components/buttons/Basic";
import { FilterInput } from "graphql/dsp/generated";

const classArrays = {
  indoor: billboardOptions["media_type"][0],
  outdoor: billboardOptions["media_type"][1],
};
type TypeClass = keyof typeof classArrays;
type IndoorClasses = typeof classArrays.indoor[number];
type OutdoorClasses = typeof classArrays.outdoor[number];

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};
export const Class: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  const [selectedClasses, setSelectedClasses] = useState<{
    indoor: IndoorClasses[];
    outdoor: OutdoorClasses[];
  }>({
    indoor: filterInput.media_type?.indoor
      ? (filterInput.media_type.indoor as IndoorClasses[])
      : [],
    outdoor: filterInput.media_type?.outdoor
      ? (filterInput.media_type.outdoor as OutdoorClasses[])
      : [],
  });
  const [open, setOpen] = useState([false, false]);
  const openDropdown = (i: number) => {
    const newOpen = [false, false];
    newOpen[i] = true;
    open[i] ? setOpen([false, false]) : setOpen(newOpen);
  };
  const toggleClass = (
    typeClass: TypeClass,
    type: IndoorClasses | OutdoorClasses
  ) => {
    const classes = selectedClasses[typeClass];
    if (classes.includes(type)) {
      classes.splice(classes.indexOf(type), 1);
    } else {
      classes.push(type);
    }
    setSelectedClasses({ ...selectedClasses });
  };
  const toggleAll = (typeClass: TypeClass) => {
    if (hasAllClasses(typeClass)) {
      setSelectedClasses({ ...selectedClasses, [typeClass]: [] });
    } else {
      setSelectedClasses({
        ...selectedClasses,
        [typeClass]: [...classArrays[typeClass]],
      });
    }
  };
  const [isModified, setIsModified] = useState(false);
  const hasAllClasses = (typeClass: TypeClass) => {
    for (const string of classArrays[typeClass]) {
      if (!selectedClasses[typeClass].includes(string)) return false;
    }
    return true;
  };
  return (
    <BasicContainer className="items-start w-96">
      <form
        className="flex flex-col w-full gap-3"
        onChange={() => setIsModified(true)}
        onSubmit={(e) => {
          e.preventDefault();
          let addFilters: FilterInput["media_type"] = {};
          if (selectedClasses.indoor.length) {
            addFilters = {
              ...addFilters,
              indoor: selectedClasses.indoor,
            };
          }
          if (selectedClasses.outdoor.length) {
            addFilters = {
              ...addFilters,
              outdoor: selectedClasses.outdoor,
            };
          }
          setFilterInput((data) => {
            const notUndefFilters = { ...addFilters };
            if (!addFilters?.indoor) delete addFilters?.indoor;
            if (!addFilters?.outdoor) delete addFilters?.outdoor;
            return { ...data, media_type: { ...notUndefFilters } };
          });
          setIsModified(false);
        }}
      >
        <div className="font-medium">Classe e tipologia</div>
        {(["indoor", "outdoor"] as const).map((typeClass, i) => {
          return (
            <div key={typeClass} className="flex flex-col w-full">
              <div className="flex justify-between w-full">
                <div
                  role="button"
                  onClick={() => openDropdown(i)}
                  className="flex items-center gap-1 cursor-pointer select-none grow"
                >
                  <ChevronDownIcon
                    className={twMerge(
                      "h-4 -rotate-90 transition-all",
                      open[i] && "rotate-0"
                    )}
                  />
                  <span>
                    {typeClass[0].toUpperCase() +
                      typeClass.slice(1, typeClass.length)}
                    {!!selectedClasses[typeClass].length && (
                      <span className="text-sm font-semibold">
                        {" "}
                        ({selectedClasses[typeClass].length})
                      </span>
                    )}
                  </span>
                </div>
                <input
                  className="w-5 h-5"
                  type="checkbox"
                  readOnly
                  onClick={() => toggleAll(typeClass)}
                  checked={hasAllClasses(typeClass)}
                />
              </div>
              <div className={twMerge("flex flex-col", !open[i] && "hidden")}>
                {classArrays[typeClass].map((type) => {
                  return (
                    <label
                      key={type}
                      className="flex items-center justify-between gap-4 cursor-pointer"
                    >
                      <div className="flex items-center gap-2 select-none">
                        <div className="pt-1">
                          <BillBoardSvg
                            size={30}
                            typeClass={typeClass}
                            type={type}
                          />
                        </div>
                        <span>{type.replaceAll("_", " ")} </span>
                      </div>
                      <input
                        className="w-5 h-5"
                        readOnly
                        type="checkbox"
                        checked={selectedClasses[typeClass].includes(type)}
                        onClick={() => toggleClass(typeClass, type)}
                      />
                    </label>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="flex justify-end w-full">
          <BasicButton
            role="submit"
            disabled={!isModified}
            className="p-1 px-3 w-fit"
          >
            Applica
          </BasicButton>
        </div>
      </form>
    </BasicContainer>
  );
};

export default Class;
