/**
 * Contains the options for the select fields in the New Billboard modal
 * When values are arays, the first element is for the Outdoor billboard class
 * and the second for the Indoor billboard class
 */
export const options = {
  society_form: {
    srl:"Società a responsabilità limitata",
    spa:"Società per azioni",
    sapa:"Società in accomandita per azioni",
    snc:"Società in nome collettivo",
    sas:"Società in accomandita semplice",
    sc:"Società cooperative",
    di:"Ditta individuale",
    sa:"Società anonima"
  },
  company_type: {
    Produttore: "Produttore",
    Distributore: "Distributore",
    Rivenditore: "Rivenditore",
    Servizi: "Servizi",
    Consulenza: "Consulenza",
    ICT: "ICT",
    Agricoltura: "Agricoltura",
    Alimentare: "Alimentare",
    Artigianato: "Artigianato",
    Commercio_al_dettaglio: "Commercio al dettaglio",
    Energia: "Energia",
    Finanza: "Finanza",
    Immobiliare: "Immobiliare",
    Industria: "Industria",
    Sanità: "Sanità",
    Trasporti: "Trasporti",
  },
};
export default options