import ImageDivInput from "components/inputs/ImageDiv";
import {
  formDataGenerator,
  StepForm,
} from "components/Stepper";
import { AdvertiserInput } from "graphql/dsp/generated";
import { useEffect, useState } from "react";

export const Confirm: StepForm<AdvertiserInput> = ({
  inputData,
  editHandler,
  formRef,
  isCurrentStep,
  ...attributes
}) => {
  const [image, setImage] = useState<File | null>(null);
  
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    form.set("image-file",image||"")
    editHandler(form, valid);
  };
  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep,image]);
  return (
    <form
      onChange={(e) => handleChange(e.currentTarget)}
      ref={formRef}
      {...attributes}
    >
      <div className="font-medium text-gray-500">
        Inserisci i seguenti campi (Opzionale) o concludi la registrazione
      </div>
      <div className="mt-5 flex max-w-[500px] gap-3 ">
        <div>
          <div className="mb-4 text-lg font-medium">Logo</div>
          <div className="overflow-hidden h-28 w-28 rounded-xl">
            <ImageDivInput
              inputProps={{name:"image-fakepath"}}
              image={image}
              setImage={setImage}
              src={inputData.image}
            />
          </div>
        </div>
        <div>
          <div className="grid w-full grid-cols-[auto_auto_20px_20px] gap-4 text-sm text-gray-500">
            <div className="col-span-2 text-lg font-medium text-black">
              Questionario
            </div>
            <div className="text-xs font-bold">SI</div>
            <div className="text-xs font-bold">NO</div>
            <div className="col-span-2">
              È la prima volta che ti occupi di pubblicità Out of home?
            </div>
            <input type="radio" name="firstJob" value={"true"} />
            <input type="radio" name="firstJob" value={"false"} />
            <div className="col-span-2">
              Hai realizzato nell’ultimo anno una campagna di pubblicità out of
              home?
            </div>
            <input type="radio" name="lastYearCampaign" value={"true"} />
            <input type="radio" name="lastYearCampaign" value={"false"} />
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-2 gap-8"></div>
    </form>
  );
};

export default Confirm;
