import { formDataGenerator, StepForm } from "components/Stepper";
import { AdvertiserInput } from "graphql/dsp/generated";
import { options } from "./options";
import FloatingBasic from "components/inputs/Floating/Basic";
import FloatingSelect from "components/inputs/Floating/Select";
import { useEffect } from "react";

export const Overview: StepForm<AdvertiserInput> = ({
  inputData,
  editHandler,
  formRef,
  isCurrentStep,
  ...attributes
}) => {
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    form.append("name", form.get("business_name")?.toString()||"");
    editHandler(form, valid);
  };

  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      onChange={(e)=>handleChange(e.currentTarget)}
      ref={formRef}
      {...attributes}
    >
      <div className="grid w-full grid-cols-2 gap-4">
        <FloatingBasic
          underline
          stayUp
          label="Ragione sociale"
          defaultValue={inputData?.name || ""}
          required
          name="business_name"
        />
        <FloatingBasic
          underline
          label="Partita IVA"
          required
          stayUp
          defaultValue={inputData.p_iva || ""}
          name="p_iva"
        />
        <FloatingSelect
          underline
          className="col-span-2"
          label="Forma societaria"
          required
          stayUp
          name="society_form"
          defaultValue={inputData.society_form || ""}
        >
          {Object.entries(options["society_form"]).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FloatingSelect>
        <FloatingBasic
          underline
          className="col-span-2"
          label="Codice fiscale"
          required
          stayUp
          defaultValue={inputData.fiscal_code || ""}
          name="fiscal_code"
        />
      </div>
    </form>
  );
};
export default Overview;
