import Stepper, { StepForm } from "components/Stepper";
import { AdvertiserInput } from "graphql/dsp/generated";
import { useState } from "react";
import Overview from "./forms/Overview";
import Contact from "./forms/Contact";
import HeadOffice from "./forms/HeadOffice";
import Confirm from "./forms/Confirm";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import { uploadImage } from "api/gql/ssp";

type AdvertiserInputExtra = AdvertiserInput & {
  advertiser?: string | null;
  "google-map-search"?: string;
  "image-fakepath"?: string;
  "image-file"?: File | null;
};
type Props = {
  className?: string;
};
export const AdvertiserKycForm: React.FC<Props> = ({ className }) => {
  const [advertiser] = useSelector(userSelectors.advertiser);
  const [inputData, setInputData] = useState<Partial<AdvertiserInputExtra>>(
    advertiser || {}
  );
  const dispatch = useDispatch();

  if (!advertiser) return null;
  const stepForms: [string, StepForm<Partial<AdvertiserInputExtra>>][] = [
    ["Anagrafica", Overview],
    ["Contatti", Contact],
    ["Sede aziendale", HeadOffice],
    ["Conferma", Confirm],
  ];
  return (
    <Stepper
      className={className}
      stepForms={stepForms}
      inputData={inputData}
      setInputData={setInputData}
      handleComplete={async (data) => {
        const {
          advertiser,
          "google-map-search": googleMapSearch,
          "image-fakepath": imageFakepath,
          "image-file": imageFile,
          ...inputData
        } = data;

        // Image upload
        let imageUrl: string | null | undefined = "";
        console.log({ imageFile });
        if (imageFile) {
          const imageId = `pfp_business_${advertiser}`;
          imageUrl = await uploadImage(imageFile, imageId);
        }
        console.log({ imageUrl });
        const inputFixed: AdvertiserInput = {
          ...inputData,
          // Conditionally add an image if any
          ...(imageUrl && { image: imageUrl }),
          // If subscription date is not present fill it with the current date
          subscription_date: inputData.subscription_date || new Date().toISOString().substring(0, 10),
        };
        console.log(inputFixed);
        dispatch(userActions.willAddAdvertiser(inputFixed));
        return data;
      }}
    />
  );
};

export default AdvertiserKycForm;
