import { CsvBillboardInput } from "./utils";

const corrections: CorrectorCol<CsvBillboardInput> = {
  media_type: (a) => String(a).trim().replaceAll(" ", "_"),
  press_type: (a) => String(a).trim().replaceAll(" ", "_"),
  location: (a) => String(a).trim().replaceAll(" ", "_"),
  maintenance_status: (a) => String(a).toLowerCase(),
};
const applyCorrections = (csvBillboardInput: CsvBillboardInput) => {
  let corrected = { ...csvBillboardInput };
  for (const k in corrections) {
    const key = k as keyof CsvBillboardInput;
    const val = corrected[key];
    corrected[key] = corrections[key]!(val, csvBillboardInput);
  }
  return corrected;
};

export default applyCorrections;
