import * as DSPApi from "api/gql/dsp";
import { Campaign } from "graphql/dsp/generated";
import { put, takeLatest } from "redux-saga/effects";
import { campaignsActions } from "store/slices/campaigns";
import { notificationsActions } from "store/slices/notifications";
import { sortByKey } from "utils/dateFormatter";

function* fetchCampaigns() {
  const campaigns: Campaign[] = yield DSPApi.listAdvertiserCampaigns() || [];
  sortByKey(campaigns, "order_date");
  yield put(campaignsActions.didFetchCampaigns(campaigns || []));
}
function* updateCampaignState({
  payload,
}: ReturnType<typeof campaignsActions.willUpdateCampaignState>) {
  yield DSPApi.updateCampaignState(payload);
  yield put(
    notificationsActions.addToast({
      title: "Modifica campagna",
      text: `Le modifiche alla campagna sono state applicate`,
      type: "INFO",
    })
  );
  yield fetchCampaigns();
}
export function* sagas() {
  yield takeLatest(campaignsActions.willFetchCampaigns.type, fetchCampaigns);
  yield takeLatest(campaignsActions.willUpdateCampaignState.type, updateCampaignState);
}
