import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MutationUpdateOrderStateArgs, Order } from "graphql/ssp/generated";
import { RootState } from "store/reducers";

const initialState = {
  myOrders: [null, "idle"] as DataWithState<Order[]>,
};
// type UserState = typeof initialState;
export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    willFetchOrders: (state) => {
      state.myOrders = [state.myOrders[0], "pending"];
      return state;
    },
    didFetchOrders: (state, { payload }: PayloadAction<Order[]>) => {
      state.myOrders = [payload, "idle"];
      return state;
    },
    willUpdateOrderState: (
      state,
      _payload: PayloadAction<MutationUpdateOrderStateArgs>
    ) => {
      state.myOrders = [state.myOrders[0], "pending"];
      return state;
    },
    willDeleteOrders: (
      state,
      { payload }: PayloadAction<{ campaign: string; ids: string[] }>
    ) => {
      state.myOrders = [state.myOrders[0], "pending"];
      return state;
    },
    // Add order
    // Edit order
    // Delete order
  },
});

export const ordersSelectors = {
  myOrders: (state: RootState) => state.orders.myOrders,
};

const { actions, reducer } = ordersSlice;
export { reducer, actions as ordersActions };
