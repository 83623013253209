import { twMerge } from "tailwind-merge";

type Props = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const SubNavbar: React.FC<Props> = ({
  children,
  className,
  ...attributes
}) => {
  return (
    <div
      className={twMerge(
        "z-10 flex w-full justify-between p-2 px-6 shadow sticky",
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};

export default SubNavbar;
