import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Advertiser } from "graphql/ssp/generated";
import { RootState } from "store/reducers";

const initialState = {
  myClients: [null, "idle"] as DataWithState<Advertiser[]>,
};
export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    willFetchClients: (state) => {
      state.myClients = [state.myClients[0], "pending"];
      return state;
    },
    didFetchClients: (state, { payload }: PayloadAction<Advertiser[]>) => {
      state.myClients = [payload, "idle"];
      return state;
    },
  },
});

export const clientsSelectors = {
  myClients: (state: RootState) => state.clients.myClients,
};

const { actions, reducer } = clientsSlice;
export { reducer, actions as clientsActions };
