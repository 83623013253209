import "./styles.css"
type Props = {};

export const SubscriptionCards: React.FC<Props> = () => {
  const { REACT_APP_STRIPE_KEY } = process.env;
  return (
    <>
      {/** @ts-ignore */}
      <stripe-pricing-table
        pricing-table-id="prctbl_1MMUOBBobj2XjFUxX59oNDtG"
        publishable-key="pk_test_51IEydlBobj2XjFUxEtd2thxFd9KNyXhidCMLvoqgEpaC9O4STRLnqMTQKAp4CXGa2s3811q6UACaHFDaLIu6KRwa00C8CaKvVN"
      />
    </>
  );
};
export default SubscriptionCards;
