import {
  ChevronDownIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicInput from "components/inputs/Basic";
import BasicModal from "components/modals/Basic";
import Tooltip from "components/popovers/Tooltip";
import TermsAndConditions from "./TermsAndConditions";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  handleConfirm: (printer?: boolean) => void;
  isLoading?:boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export const TosAgreementButton: React.FC<Props> = ({
  handleConfirm,
  children,
  isLoading,
  ...buttonAttributes
}) => {
  const [open, setOpen] = useState(false);
  const [openTos, setOpenTos] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [printAndDeliver, setPrintAndDeliver] = useState(false);
  return (
    <>
      <BasicButton onClick={() => setOpen(true)} {...buttonAttributes}>
        {children}
      </BasicButton>
      <BasicModal
        title="Prima di proseguire"
        close={() => setOpen(false)}
        open={open}
        className="w-[700px]"
        loading={isLoading}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm(printAndDeliver);
          }}
          className="flex flex-col w-full gap-2 p-4"
        >
          <div
            className="flex gap-3 py-4 border-b"
            role="button"
            onClick={() => setOpenTos((prev) => !prev)}
          >
            <DocumentTextIcon className="h-6" />
            <span className="select-none">
              Leggi e accetta le condizioni generali di utilizzo
            </span>
            <ChevronDownIcon
              className={twMerge(
                "self h-6 -rotate-90 transition-all",
                openTos && "rotate-0"
              )}
            />
          </div>
          <div
            className={twMerge(
              "max-h-0 overflow-y-scroll p-0 ",
              openTos && "max-h-64 p-3"
            )}
          >
            <div className="text-sm text-gray-500">
              <TermsAndConditions />
            </div>
            <label
              role="button"
              className="flex items-center gap-3 py-4 border-b"
            >
              <BasicInput
                onChange={(e) => setIsTosAccepted(e.target.checked)}
                className="w-4 h-4 py-3"
                type="checkbox"
                required
              />
              <div className="font-semibold select-none">
                Dichiaro di aver letto e accettato i termini e le condizioni di
                utilizzo
              </div>
            </label>
          </div>
          <label className="flex items-center gap-3 py-4 border-b">
            <DocumentCheckIcon className="h-6" />
            <div className="select-none">Stampa e consegna</div>
            <BasicInput
              onChange={(e) => setPrintAndDeliver(e.target.checked)}
              className="w-4 h-4 mx-3"
              type="checkbox"
              required={false}
            />
          </label>
          <div className="flex gap-3 my-3">
            <div>
              <ShieldExclamationIcon className="w-5 h-5 text-yellow-400 stroke-2" />
            </div>
            <div className="flex flex-col gap-1 text-sm">
              <div className="font-semibold">Attenzione!</div>
              <div className="text-gray-500">
                Cliccando sul pulsante "Continua" sarà avviata la sessione di
                pagamento. Avrai 30 minuti di tempo per completare l'acquisto.
                Decorso questo tempo senza aver completato l'acquisto, gli spazi
                pubblicitari selezionati per questa campagna saranno resi
                nuovamente disponibili per altri utenti.
              </div>
            </div>
          </div>
          <Tooltip
            disabled={isTosAccepted}
            placement="top"
            tooltipWrapperClass="shadow-xl"
            tip={
              <span className="font-semibold">
                Leggi e accetta i termini e le condizioni di utilizzo prima di
                continuare
              </span>
            }
          >
            <BasicButton disabled={!isTosAccepted} role="submit">
              Continua
            </BasicButton>
          </Tooltip>
        </form>
      </BasicModal>
    </>
  );
};

export default TosAgreementButton;
