import Table from "components/lists/table";
import { Advertiser } from "graphql/ssp/generated";
import { AdvertiserMini } from "components/cards/UserMini";

type Props = {
  myClients: Advertiser[];
};
export const ClientsList = ({ myClients }: Props) => {
  console.log(myClients)
  return (
    <Table
      data={myClients}
      className="w-[840px]"
      noSelect
      cols={{
        name: [
          "Nome azienda",
          (_v,adv) => (
            <AdvertiserMini
              advertiser={adv}
              className="w-full"
              buttonClassName="w-full justify-start"
            />),
        ],
        email: ["email",(v)=><small>{v}</small>],
        legal_address: ["sede legale",(v)=><small>{v}</small>],
        // TODO: MOCKED
        // bank_account: ["N.ordini", () => (Math.random() * 15 + 1) | 0],
        // business_name: ["Regione", () => "Sardegna"],
        // company_form: ["Settore", () => "ADV"],
        // company_type: [
        //   "Lifetime value",
        //   () => (
        //     <div className="text-end">
        //       {currencyFormatter.format((Math.random() * 5000) | 0)}
        //     </div>
        //   ),
        // ],
      }}
    />
  );
};
export default ClientsList;
