import BasicContainer from "components/containers/Basic";

export const BackofficeApproval = () => {
  return (
    <div className="flex justify-center pt-20 ">
      <BasicContainer className="text-white bg-black bg-opacity-50 shadow-lg">
        
      <div className="max-w-lg text-xl font-medium text-center">
        <span className="text-2xl font-medium ">
          Contattare l'assistenza di Billalo tramite email su{" "}
          <a
            className="text-primary-light visited:text-primary-dark hover:text-primary"
            rel="noreferrer"
            href="mailto:support@billalo.com"
            target="_blank"
          >
            support@billalo.com
          </a>
        </span>
        <br /> <br />
        Il tuo account è in attesa di approvazione da parte del nostro team di
         Backoffice.
      </div>
      </BasicContainer>
    </div>
  );
};

export default BackofficeApproval;
