import { ChevronDownIcon } from "@heroicons/react/24/outline";
import BillboardsMiniList from "components/lists/Billboards/Mini";
import { Billboard } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";

type Props = {
  billboards: Partial<Billboard>[];
  setShowBillboards: React.Dispatch<React.SetStateAction<boolean>>;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const ShowBillboards: React.FC<Props> = ({
  billboards,
  setShowBillboards,
  className,
  ...attrs
}) => {
  return (
    <div className={twMerge("flex flex-col", className)} {...attrs}>
      <div
        onClick={() => setShowBillboards(false)}
        className="flex items-center gap-2 text-gray-400 select-none hover:text-black"
        role="button"
      >
        <ChevronDownIcon className="h-4 transition-all rotate-90" />
        Torna alla creazione della regola
      </div>
      <div className="mt-3 mb-2">{billboards.length} {billboards.length===1?"impianto selezionato":"impianti selezionati"}</div>
      <div className="px-1 py-2 rounded bg-gray-50 max-h-80 hover-scrollbar">
      <BillboardsMiniList
        myBillboards={billboards as Billboard[]}
        noSelect
        showTitles={false}
        showHeader={false}
        hideActions
      />
      </div>
    </div>
  );
};

export default ShowBillboards;
