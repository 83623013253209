//import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";
import BillboardsMiniList from "components/lists/Billboards/Mini";
import { Billboard, OrderBillboard } from "graphql/dsp/generated";
import { useEffect } from "react";

type Props = {
  billboards: OrderBillboard[];
  className?: string;
};
export const BillboardsSection: React.FC<Props> = ({
  billboards,
  className,
}) => {
  const billboardsLength = billboards.length;
  // const available = billboards.filter((b) => !!b.available).length;
  // const pending = "TBD";
  useEffect(() => {}, []);
  return (
    <div className={className}>
      <div className="flex flex-col">

        {/* Commented out because it has no BE implementation
        
        <div className="flex items-center justify-between p-2 text-gray-600 border-b">
          <div className="flex items-center gap-3">
            <CheckCircleIcon className="h-4 text-green-400" />
            Impianti disponibili
          </div>
          <div className="font-semibold">{available}</div>
        </div>

        <div className="flex items-center justify-between p-2 text-gray-600 border-b">
          <div className="flex items-center gap-3">
            <ClockIcon className="h-4 text-yellow-400" />
            Richiesta disponibilità (72h)
          </div>
          <div className="font-semibold">{pending}</div>
        </div> 
        */}

        <div className="text-base font-semibold">
          Impianti prenotati ({billboardsLength})
        </div>
        <BillboardsMiniList
          transparentRows
          hideActions={true}
          myBillboards={(billboards as Billboard[]) || []}
          showHeader={false}
          showTitles={false}
        />
      </div>
    </div>
  );
};
export default BillboardsSection;
