import { twMerge } from "tailwind-merge";

type Props = {noDefault?:boolean}&React.SelectHTMLAttributes<HTMLSelectElement>;

export const Select: React.FC<Props> = ({
  className,
  children,
  noDefault,
  ...attributes
}) => {
  return (
    <select
      className={twMerge(
        "w-full rounded-lg border border-gray-200 bg-gray-50 px-2 py-1 font-medium text-gray-500 outline-0 focus-within:bg-white",
        className
      )}
      {...attributes}
    >
      {!noDefault&&<option value="" className="italic" disabled  >Seleziona</option>}
      {children}
    </select>
  );
};

export default Select;
