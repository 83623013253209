import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  batchAddBillboardAvailability,
  batchDeleteBillboardAvailabilities,
} from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import TransparentButton from "components/buttons/Transparent";
import BasicContainer from "components/containers/Basic";
import { getWeekBounds } from "components/inputs/calendar/utils";
import { fixStatus, formatStatus } from "./utils";
import Tooltip from "components/popovers/Tooltip";
import {
  Billboard,
  BillboardAvailability,
  MutationBatchAddBillboardAvailabilityArgs,
} from "graphql/ssp/generated";
import useNotification from "hooks/useNotification";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  anchor: Element;
  date: Date;
  info?: BillboardAvailability;
  billboard: Billboard;
  close: () => void;
  refresh: () => void;
};

export const AvailabilityTooltip: React.FC<Props> = ({
  anchor,
  date,
  info,
  billboard,
  close,
  refresh,
}) => {
  const { from, to } = getWeekBounds(date);
  from.setDate(from.getDate() + 1);
  const { toast } = useNotification();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const addAvailability = async (is_maintenance: boolean) => {
    if (!!info) {
      console.log(
        "Has unavailabilities, removing it before adding a new one..."
      );
      await removeAvailability();
    }
    console.log("Adding...");
    setIsLoading(true);
    let availabilityInput: MutationBatchAddBillboardAvailabilityArgs = {
      input: [
        {
          id: billboard?.id!,
          city_code: billboard.city_code!,
        },
      ],
      dates: [from.toISOString()],
      is_maintenance,
    };
    if (is_maintenance) {
      availabilityInput = {
        ...availabilityInput,
        last_maintenance: to.toISOString(),
      };
    }
    const res = await batchAddBillboardAvailability(availabilityInput);
    refresh();
    if (res)
      toast({
        title: `Impostazione disponibilità impianto`,
        text: `L'impianto "${
          billboard.address || billboard.location
        }" è stato impostato come ${
          is_maintenance ? "'in manutenzione'" : "'non disponibile'"
        }.`,
        type: "SUCCESS",
      });
    setIsLoading(false);
    close();
  };
  const removeAvailability = async () => {
    console.log("Removing...");
    setIsLoading(true);
    const res = await batchDeleteBillboardAvailabilities({
      dates: [info?.start_busy_date],
      input: [
        { id: info?.billboard_id || "", city_code: info?.city_code || "" },
      ],
    });
    refresh();
    if (res)
      toast({
        title: `Disponibilità impianto`,
        text: `L'impianto "${
          billboard.address || billboard.location
        }" è stato impostato come disponibile.`,
        type: "SUCCESS",
      });
    setIsLoading(false);
    close();
  };
  const statusButtons = {
    AVAILABLE: (
      <BasicButton
        key={"AVAILABLE"}
        className="bg-primary-light"
        onClick={() => removeAvailability()}
      >
        {" "}
        Imposta come disponibile
      </BasicButton>
    ),
    MAINTENANCE: (
      <BasicButton
        key={"MAINTENANCE"}
        className="bg-yellow-400"
        onClick={() => addAvailability(true)}
      >
        {" "}
        Pianifica manutenzione
      </BasicButton>
    ),
    UNAVAILABLE: (
      <BasicButton
        key={"UNAVAILABLE"}
        className="bg-red-400"
        onClick={() => addAvailability(false)}
      >
        {" "}
        Imposta come non disponibile
      </BasicButton>
    ),
    ORDER: (
      <BasicButton
        key={"ORDER"}
        className="gap-2 bg-blue-400"
        onClick={() =>
          navigate(
            "/orders/details/" + info?.campaign_id + "/" + info?.order_id
          )
        }
      >
        Vai all'ordine
        <ArrowRightIcon className="h-4" />
      </BasicButton>
    ),
  };
  const fixedStatus = fixStatus(
    info?.status,
    info?.is_maintenance,
    info?.order_data?.advertiser
  );
  return (
    <Tooltip
      tip={
        <BasicContainer loading={isLoading} className="gap-1 p-1">
          <div className="flex items-center justify-between">
            <div className="text-lg font-semibold">
              {info ? formatStatus(fixedStatus)[1] : "Impianto disponibile"}
            </div>
            <TransparentButton onClick={close}>
              <XMarkIcon className="h-4 text-gray-500" />
            </TransparentButton>
          </div>
          <div className="font-medium">
            {from.toISOString().slice(0, 10).replaceAll("-", "/") +
              " - " +
              to.toISOString().slice(0, 10).replaceAll("-", "/")}
          </div>
          {/** Action buttons */}
          {(() => {
            let elements: JSX.Element[] = [];
            if (!info) {
              elements.push(statusButtons.UNAVAILABLE);
              elements.push(statusButtons.MAINTENANCE);
              return elements;
            }
            if (fixedStatus === "BOOKED") {
              elements.push(statusButtons.ORDER);
              return elements;
            }
            elements.push(statusButtons.AVAILABLE);
            fixedStatus === "UNAVAILABLE" &&
              elements.push(statusButtons.MAINTENANCE);
            fixedStatus === "MAINTENANCE" &&
              elements.push(statusButtons.UNAVAILABLE);
            return elements;
          })()}
        </BasicContainer>
      }
      active={true}
      controlled={true}
      anchorEl={anchor}
    />
  );
};
export default AvailabilityTooltip;
