import ToggleableButton from "components/buttons/Toggleable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  /* Navigation path */
  to?: string;
  active: boolean;
  Icon: React.FC<{ className?: string }>;
  children?: React.ReactNode;
  /** [key, value] */
  newParams?: [string, string][];
};
export const SubNavButton = ({
  to,
  active,
  Icon,
  children,
  newParams,
}: Props) => {
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();
  const setParameters = (paramList: Props["newParams"]) => {
    if (!paramList) return false;

    setSearchParams((searchParams) => {
      paramList.forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });
  };
  return (
    <ToggleableButton
      toggle={active}
      onClick={(inPage) => {
        if (!inPage) {
          if (to) return navigate(to);
          if (newParams) return setParameters(newParams);
        }
      }}
      activeClassName="shadow bg-white text-gray-900"
    >
      <Icon className={twMerge("w-6", active && "text-primary")} />
      {children}
    </ToggleableButton>
  );
};
export default SubNavButton;
