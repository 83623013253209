import TextButton from "components/buttons/Text";
import BillaloLogo from "components/others/BillaloLogo";
import BasicContainer from "components/containers/Basic";
import IsMediaOwnerSwitch from "components/others/IsMediaOwnerSwitch";
import BasicButton from "components/buttons/Basic";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "store/slices/user";
import LoadingSpinner from "components/others/LoadingSpinner";
import BasicInput from "components/inputs/Basic";
import { twMerge } from "tailwind-merge";

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const urlEmail = queryParams.get("email");

  const [formData, setFormData] = useState({
    email: urlEmail || "",
    password: "",
  });
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [loginData, loginStatus] = useSelector(userSelectors.cognitoUser);

  const searchEmail = () =>
    new URLSearchParams({ email: formData.email }).toString();
  const handleSubmit = ({ email, password }: typeof formData) => {
    dispatch(userActions.willLogin({ email, password, isMediaOwner }));
  };
  useEffect(() => {
    if (loginStatus === "idle" && loginData) {
      const queryParams = new URLSearchParams(search);
      navigate(queryParams.get("path") || "/dashboard");
    }
  });

  const ErrorParser = ({ error }: { error: Error }) => {
    let message = "";
    console.log({ error });
    switch (error.name) {
      case "UserNotConfirmedException":
        if (searchEmail()) {
          return <Navigate to={"/signup/confirm?" + searchEmail()} />;
        }
        message = `Errore durante il login (${error.name})`;
        break;
      default:
        message = `Errore durante il login (${error.name})`;
    }
    return (
      <div className="flex justify-center text-center">
        <span className="max-w-sm text-red-400">{message}</span>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-10 min-w-screen bg-primary">
      <div className="flex flex-col justify-center max-w-sm gap-5 font-bold text-white rounded">
        <span className="text-5xl">Welcome to</span>
        <BillaloLogo fill="white" className="m-auto" />
      </div>
      <BasicContainer>
        <IsMediaOwnerSwitch />

        <form
          id="login"
          className={twMerge(
            "flex w-full flex-col items-center gap-5 rounded-3xl"
          )}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(formData);
          }}
        >
          <span className="text-lg font-medium text-gray-600">
            Accedi al tuo account
          </span>

          <BasicInput
            autoFocus={!Boolean(urlEmail)}
            required
            placeholder="La tua email"
            type="email"
            autoComplete="email"
            name="email"
            defaultValue={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <BasicInput
            required
            placeholder="Password"
            autoFocus={Boolean(urlEmail)}
            type="password"
            name="password"
            autoComplete="current-password"
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </form>

        {loginStatus instanceof Error && <ErrorParser error={loginStatus} />}
        <TextButton
          type="button"
          className="text-sm font-medium text-primary-light"
          onClick={() => navigate(`/password-recovery?${searchEmail()}`)}
        >
          Password dimenticata
        </TextButton>
        <BasicButton
          form="login"
          type="submit"
          disabled={loginStatus === "pending"}
        >
          {loginStatus === "pending" ? (
            <span className="flex justify-center h-5">
              <LoadingSpinner className="w-4 h-4 fill-white" />
            </span>
          ) : (
            <span>Login - {isMediaOwner ? "rivenditore" : "acquirente"}</span>
          )}
        </BasicButton>

        <hr className={"my-1 h-px w-80 border-0 bg-gray-200"} />
        <TextButton
          type="button"
          className="text-sm font-medium text-primary"
          onClick={() => navigate("/signup")}
        >
          Non hai un account Billalo? Registrati
        </TextButton>
      </BasicContainer>
      {/* <div className="text-white">
        <TextButton className="text-sm">Politica sulla privacy</TextButton>
        <small className="opacity-80"> • </small>
        <TextButton className="text-sm">Condizioni di utilizzo</TextButton>
      </div> */}
    </div>
  );
};
export default Login;
