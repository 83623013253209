import { HomeIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";
import BasicContainer from "components/containers/Basic";
import IndexCard from "components/cards/Billboard/IndexCard";
import ProgressCircle from "components/others/ProgressCircle";
import { OrderBillboard } from "graphql/ssp/generated";
import { Fragment } from "react";
import { BillBoardSvg } from "utils/billboards/BillboardSvg";
import sequenceColor from "utils/sequenceColor";

const calcTypeClassData = (
  billboards: OrderBillboard[]
): [number, number, string, string][] => {
  /** ["type/typeclass"]:amount */
  const types: Record<string, number> = {};
  billboards.forEach((b) => {
    const typeClass = [b.billboard_class, b.media_type].join("/");
    if (!types[typeClass]) types[typeClass] = 0;
    types[typeClass]++;
  });

  const percent = (n: number) =>
    Number(((n / billboards.length) * 100).toFixed(2));

  return Object.entries(types).map(([typeClass, amount]) => {
    const [c, t] = typeClass.split("/");
    return [percent(amount), amount, c, t];
  });
};

const getAvgVisIndexes = (billboards: OrderBillboard[]) => {
  const municipality = billboards
    .map((b) => Number(b.iV_municipality))
    .reduce((sum, v) => sum + v, 0);
  const state = billboards
    .map((b) => Number(b.indice_visibilita))
    .reduce((sum, v) => sum + v, 0);
  return [municipality, state];
};

const getMunicipalities = (billboards: OrderBillboard[]) => {
  const municipalities: Record<string, number> = {};
  billboards.forEach((b) => {
    const m = String(b.municipality);
    if (!municipalities[m]) municipalities[m] = 0;
    municipalities[m]++;
  });
  return Object.entries(municipalities);
};

type Props = {
  className?: "string";
  billboards: OrderBillboard[];
};
export const InfoSection: React.FC<Props> = ({ className, billboards }) => {
  const [visIndexM, visIndexN] = getAvgVisIndexes(billboards);
  const typeClassData = calcTypeClassData(billboards);
  const municipalities = getMunicipalities(billboards);
  return (
    <div className={className}>
      <div className="mb-2 text-base font-semibold">Tipologie</div>
      <BasicContainer className="w-full bg-transparent border">
        <div className="grid grid-cols-[auto_auto_auto] items-center gap-3 text-base">
          {typeClassData.map(([percent, amount, typeClass, type]) => {
            return (
              <Fragment key={type}>
                <div className="relative w-fit">
                  <ProgressCircle
                    amount={percent / 100}
                    size={80}
                    strokeWidth={5}
                    color={typeClass === "indoor" ? "#FF9AD5" : "#50B5FF"}
                  />
                  <div className="absolute inset-0 flex items-center justify-center font-semibold">
                    <span className="text-xl">{Math.round(percent*10)/10}%</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <BillBoardSvg typeClass={typeClass} type={type} size={20} />
                  <div className="font-semibold">{amount.toFixed(2)}</div>
                </div>
                <div>{type.replaceAll("_", " ")}</div>
              </Fragment>
            );
          })}
        </div>
      </BasicContainer>
      <div className="my-2 text-base font-semibold">Indice di visibilità</div>
      <div className="flex">
        <BasicContainer className="w-full bg-transparent border">
          <IndexCard
            amount={visIndexM}
            amountMax={10}
            Icon={HomeIcon}
            amountText={(val, max) => val + "/" + max}
            className="w-full py-0"
          >
            <div className="text-xs font-medium text-gray-400">
              Indice visibilità medio Comune
            </div>
          </IndexCard>
          <hr />
          <IndexCard
            amount={visIndexN}
            amountMax={10}
            Icon={BuildingLibraryIcon}
            amountText={(val, max) => val + "/" + max}
            className="w-full py-0 "
            color="#3DD598"
          >
            <div className="text-xs font-medium text-gray-400">
              Indice visibilità medio Italia
            </div>
          </IndexCard>
        </BasicContainer>
      </div>
      <div className="my-2 text-base font-semibold">
        Comuni coperti ({municipalities.length})
      </div>
      <div className="flex">
        <BasicContainer className="w-full bg-transparent border">
          {municipalities.map(([name, amount]) => {
            return (
              <div
                key={name}
                className="flex items-center justify-between w-full py-2"
              >
                <span className="flex items-center gap-2 font-medium text-gray-500">
                  <div
                    style={{ backgroundColor: sequenceColor() }}
                    className="w-3 h-3 rounded-full"
                  />
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </span>
                <span className="font-medium text-gray-800">{amount}</span>
              </div>
            );
          })}
        </BasicContainer>
      </div>
    </div>
  );
};
export default InfoSection;
