type Props<T> = {
    data:T;
  infoList: Col<Partial<T>>;
};

export function InfoList<T>({ data,infoList }: Props<T>) {
  return (
    <>
      <div className="font-bold">Informazioni</div>

      <div className="flex flex-col justify-between w-full border-b">
        {Object.entries(infoList).map(([key, colRow ], i) => {
            const [name, resolver] = colRow as [string, Formatter<T, keyof T>?]
          const value = data[key as keyof T];
          return (
            <div
              key={i}
              className="flex items-center justify-between py-2 border-b"
            >
              <span className="text-gray-500">{name}</span>
              {resolver ? (
                resolver(value, data)
              ) : (
                <span className="font-medium text-gray-800">{String(value)}</span>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
export default InfoList;
