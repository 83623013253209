import { getOrder } from "api/gql/ssp";
import { OrderCard } from "components/cards/Order";
import { Order, OrderBillboard } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BillboardsMap from "components/maps/BillboardsMap";
import {
  normalizeBillboards,
  NormalizedBillboard,
} from "utils/billboards/normalizeBillboards";
import {
  toStreetViewLocation,
  zoomToPlace,
} from "components/maps/GoogleMapComponent/utils";
import { useGmap } from "components/maps/GoogleMapComponent/hooks";

export const Details = () => {
  const { campaignId, orderId } = useParams();
  const [[order], setOrder] = useState<[Order | null, boolean]>([null, true]);
  const gmap = useGmap<NormalizedBillboard>();
  const maybeBillboards = order?.billboards?.length ? order.billboards : [];
  const billboards = normalizeBillboards(maybeBillboards as OrderBillboard[]);
  const refresh = (campaignId?: string, orderId?: string) => {
    if (campaignId && orderId) {
      getOrder(campaignId, orderId).then((res) => setOrder([res, false]));
    }
    console.log("REFRESHING");
  };
  useEffect(() => {
    refresh(campaignId, orderId);
  }, [campaignId, orderId]);
  return (
    <>
      {order && (
        <>
          <OrderCard
            data={order}
            refresh={() => refresh(campaignId, orderId)}
            selectedBillboard={gmap.selected}
            setSelectedBillboard={gmap.setSelected}
            zoomIn={(b) => zoomToPlace(b, gmap.map)}
            streetView={(b) => toStreetViewLocation(b, gmap.sview, gmap.pano)}
            className="m-3 w-96 animate-down-up"
            onClose={() => {
              gmap.setSelected(undefined);
              if (gmap.pano) {
                gmap.pano.setZoom(1);
                gmap.pano.setVisible(false);
              }
            }}
          />
          <div className="grow">
            <BillboardsMap
              trafficLayerControl
              billboards={billboards}
              className="z-0 h-full w-full rounded-xl p-3"
              showStreetView
              placesApi
              gmap={gmap}
            />
          </div>
        </>
      )}
    </>
  );
};
export default Details;
