
import { Order } from "graphql/ssp/generated";
import UpdateStateModal from "./UpdateState";

type Props = {
  action: [string, Order] | null;
  closeAction: () => void;
};
/** Handles actions and actions confirmation for multiple or single row selections */
export const OrdersActionsManager = ({ action, closeAction }: Props) => {
  if (!action) return <></>;
  const [actionName, actionData] = action;

  if (Array.isArray(actionData)) {
    return <></>;
  }
  return (
    <>
      <UpdateStateModal
        order={actionData}
        open={actionName === "update"}
        close={closeAction}
      />
    </>
  );
};

export default OrdersActionsManager;
