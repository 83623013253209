import { LocationTag } from "pages/Planning/Advanced/Search/FilterInput/Location";
import BasicInput from "components/inputs/Basic";
import { Office } from "graphql/ssp/generated";
import useLocationAutocomplete from "hooks/useLocationAutocomplete";
import { useState } from "react";

type Props = {
  officeData: Office | undefined;
  setOfficeData: React.Dispatch<React.SetStateAction<Office | undefined>>;
};

export const AddMunicipalities: React.FC<Props> = ({
  officeData,
  setOfficeData,
}) => {
  const locations = (officeData?.municipalities as string[]) || [];

  const [isModified, setIsModified] = useState(false);

  const addLocation = (location: string) => {
    setOfficeData((curr) => {
      const currMunic = curr?.municipalities || [];
      const municipalities = Array.from(new Set([...currMunic, location]));
      return { ...curr, municipalities };
    });
  };
  const removeLocation = (location: string) => {
    setOfficeData((curr) => {
      const currMunic = curr?.municipalities || [];
      const idx = currMunic.indexOf(location);
      if (idx >= 0) currMunic.splice(idx, 1);
      return { ...curr, municipalities: currMunic };
    });
  };
  const inputRef = useLocationAutocomplete({
    onPlaceChanged: (place) => addLocation(String(place.name)),
  });
  return (
    <div className="flex flex-col col-span-2 gap-3">
      <div className="font-medium">Comuni associati</div>
      <div className="text-sm">
        Digita nel campo qui sotto, e seleziona uno dei suggerimenti per
        aggiungere all'input.
      </div>
      <div className="flex items-center gap-2">
        <BasicInput
          onChange={() => !isModified && setIsModified(true)}
          type="search"
          placeholder="Seleziona un comune"
          required={false}
          innerRef={inputRef}
        />
      </div>
      <div className="w-full">
        <div className="pb-2 text-gray-600">
          Località selezionate
          {locations.length ? `(${locations.length})` : null}:
        </div>
        <div className="flex flex-wrap justify-center gap-2">
          {!locations.length && <i className="text-gray-400">Nessuna</i>}
          {locations.map((location) => (
            <LocationTag
              key={location}
              location={location}
              removeLocation={removeLocation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddMunicipalities;
