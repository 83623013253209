import { twMerge } from "tailwind-merge";
import FloatingBasic from "components/inputs/Floating/Basic";
import { formDataGenerator, StepForm } from "components/Stepper";
import { BillboardInput } from "graphql/ssp/generated";
import TextArea from "components/inputs/Floating/TextArea";
import FloatingSelect from "components/inputs/Floating/Select";
import options from "../../../../utils/formatters/billboardOptions";
import { useEffect } from "react";

/** Used during the user login (both for clients and mediaOwners) */
export const MiscData: StepForm<Partial<BillboardInput>> = ({
  className,
  inputData,
  editHandler,
  onSubmit,
  formRef,
  isCurrentStep,
  prefill,
  ...attributes
}) => {
  const max = 450,
    min = 10;
  const Title = ({ title }: { title: string }) => {
    return (
      <div className="w-full mb-4 font-medium text-gray-800 border-b">
        {title}
      </div>
    );
  };
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    editHandler(form, valid);
  };

  const fill = (key: keyof typeof inputData) =>
    prefill ? inputData?.[key] || undefined : undefined;

  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      ref={formRef}
      className={twMerge(
        "flex w-full flex-col items-center rounded-3xl",
        className
      )}
      onChange={(e) => handleChange(e.currentTarget)}
      {...attributes}
    >
      <Title title="Manutenzione" />
      <div className="grid w-full grid-cols-2 gap-3 mb-4">
        {/** TODO: add maintenance_status to inputData (backend) */}
        <FloatingSelect
          label="Stato"
          name="maintenance_status"
          defaultValue={fill("maintenance_status")}
        >
          {options["maintenance_status"].map((value, i) => (
            <option key={i} value={value}>
              {value.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
        <FloatingBasic
          stayUp
          label="Ultimo intervento"
          name="last_maintenance"
          type="date"
          defaultValue={fill("last_maintenance")?.slice(0,10)||undefined}
          max={new Date().toISOString()?.slice(0,10)}
        />
      </div>
      <Title title="Tributi" />
      <div className="grid w-full grid-cols-2 gap-8 mb-4">
        <FloatingBasic
          label="Importo"
          name="municipal_tax"
          defaultValue={fill("taxes")?.municipal_tax || undefined}
          type="number"
          min={0.0}
          step={0.01}
        />
        <FloatingBasic
          defaultValue={fill("taxes")?.last_payment?.slice(0,10) || undefined}
          label="Ultimo pagamento"
          name="last_payment"
          type="date"
          stayUp
          max={new Date().toISOString().substring(0, 10)}
        />
      </div>
      <Title title="Autorizzazioni" />
      <div className="grid w-full grid-cols-2 gap-8 mb-4">
        <FloatingBasic label="Num. SCIA"
          defaultValue={fill("n_authorization")} name="n_authorization" required />
        <FloatingBasic
          stayUp
          label="Data di scadenza SCIA"
          name="authorization_expiration"
          defaultValue={fill("authorization_expiration")?.slice(0,10) || undefined}
          type="date"
          required
        />
      </div>
      <Title title="Descrizione" />
      <div className="grid w-full grid-cols-2 gap-8 -mt-2">
        <TextArea
          defaultValue={fill("notes")}
          title="La descrizione deve contenere almeno 30 caratteri, massimo 450."
          label="Scrivi qui una descrizione/nota per il tuo impianto"
          className="h-20 col-span-2"
          inputClassName="resize-none"
          name="notes"
          required
          maxLength={max}
          minLength={min}
        />
      </div>
    </form>
  );
};
export default MiscData;
