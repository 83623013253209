import BasicButton from "components/buttons/Basic";
import BasicInfoForm, { SubmitCallback } from "components/forms/BasicInfo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicContainer from "components/containers/Basic";
import IsMediaOwnerSwitch from "components/others/IsMediaOwnerSwitch";
import { configure, signup } from "api/auth";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";
import BillaloLogo from "components/others/BillaloLogo";
import Tooltip from "components/popovers/Tooltip";
import BasicInput from "components/inputs/Basic";
import TextButton from "components/buttons/Text";
export const Signup: React.FC = () => {
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const handleSubmit: SubmitCallback = (data) => {
    setIsProcessing(true);
    console.log("changed to mediaowner=" + String(isMediaOwner));
    localStorage.setItem("isMediaOwner", String(isMediaOwner));
    configure(isMediaOwner);
    signup(data.email, data.password, data.given_name, data.family_name).then(
      (res) => {
        console.log(res);
        if (res instanceof Error) {
          setError(res);
          setIsProcessing(false);
          return;
        }
        const searchEmail = new URLSearchParams({
          email: data.email,
        }).toString();
        res ? navigate("confirm?" + searchEmail) : setIsProcessing(false);

        setIsProcessing(false);
      }
    );
  };

  return (
    <>
      <div className="w-3/4 text-center text-white">
        <h1 className="text-4xl font-medium ">Benvenut* su</h1>
        <div className="w-full">
          <BillaloLogo
            role="button"
            fill="white"
            className="m-auto my-5"
            onClick={() => navigate("/")}
          />
        </div>
        <span className="text-lg">Crea il tuo account per iniziare</span>
      </div>
      <BasicContainer className="max-w-lg text-slate-700">
        <IsMediaOwnerSwitch
          advertiserText="Sono un acquirente"
          mediaOwnerText="Sono un rivenditore"
          textColor="text-primary"
          toggleColor="bg-primary"
        />

        <BasicInfoForm
          id="basic-info"
          className="mt-2"
          handleSubmit={handleSubmit}
        />
        <label role="button" className="flex gap-3">
          <BasicInput
            onChange={(e) => setIsTosAccepted(e.target.checked)}
            className="w-5 h-5 py-3"
            type="checkbox"
            required
          />
          <div className="text-sm select-none">
            Registrandoti, confermi di aver letto ed accettato le{" "}
            <a
              target="_blank"
              href="https://www.iubenda.com/privacy-policy/26553185"
              rel="noreferrer"
            >
              <TextButton className="font-medium text-primary">
                Condizioni generali
              </TextButton>
            </a>{" "}
            e la{" "}
            <a
              target="_blank"
              href="https://www.iubenda.com/privacy-policy/26553185"
              rel="noreferrer"
            >
              <TextButton className="font-medium text-primary">
                Privacy Policy
              </TextButton>
            </a>
            .
          </div>
        </label>
        <Tooltip
          placement="top"
          disabled={isTosAccepted}
          tip="Per registrarsi è necessario accettare i termini e le condizioni di utilizzo"
        >
          <BasicButton
            role="submit"
            className="gap-2 mt-2 bg-primary active:bg-primary-light"
            form="basic-info"
            disabled={!isTosAccepted}
            loading={isProcessing}
          >
            <span>Registrati come</span>{" "}
            {isMediaOwner ? <b>RIVENDITORE</b> : <b>ACQUIRENTE</b>}
          </BasicButton>
        </Tooltip>
        {error && <ErrorParser error={error} />}
        <div className="-mb-3">
          <TextButton
            className="text-sm font-semibold text-primary"
            onClick={() => navigate("/login")}
          >
            Possiedi già un account Billalo? Log in
          </TextButton>
        </div>
      </BasicContainer>
    </>
  );
};
const ErrorParser = ({ error }: { error: Error }) => {
  let message = "";
  switch (error.name) {
    case "UsernameExistsException":
      message = "L'indirizzo email è già collegato ad un account";
      break;
    default:
      message = `Errore nella creazione dell' account (${error.name})`;
  }
  return <span className="text-red-400">{message}</span>;
};

export default Signup;
