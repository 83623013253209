import ActionsButton from "components/buttons/Actions";
import OrdersActionsManager from "./Actions";
import Table from "components/lists/table";
import { Order } from "graphql/ssp/generated";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderF, orderS } from "utils/formatters";
import { useSelector } from "react-redux";
import { clientsSelectors } from "store/slices/clients";
import { AdvertiserMini } from "components/cards/UserMini";

type Props = {
  myOrders: Order[];
};
export const OrdersList = ({ myOrders }: Props) => {
  const navigate = useNavigate();
  const [currentAction, setCurrentAction] = useState<null | [string, Order]>(
    null
  );
  const [clients] = useSelector(clientsSelectors.myClients);
  return (
    <>
      <Table
        data={myOrders}
        className="w-[1024px]"
        noSelect
        onRowClick={(order) => {
          console.log(order);
          navigate("/orders/details/" + order.campaign + "/" + order.id);
        }}
        rowActions={[
          [
            (order) => (
              <ActionsButton
                data={order || {}}
                actions={[
                  [
                    <span>Modifica stato</span>,
                    (data) => setCurrentAction(["update", data]),
                  ],
                ]}
              />
            ),
          ],
        ]}
        cols={{
          status: ["Stato", orderF["status"]],
          advertiser: [
            "Cliente",
            (val) => (
              <AdvertiserMini
                advertiser={clients?.find(c=>c.advertiser===val)}
                className="w-full"
                buttonClassName="w-full justify-start"
              />
            ),
          ],
          campaign_name: ["Campagna"],
          from: ["Inizio/Fine", orderF["from"]],
          order_date: ["Data", orderF["order_date"]],
          // printer: ["Stampa", orderF["printer"]],
          amount: ["Totale", orderF["amount"], orderS["amount"]],
          billboards: ["N.Impianti", orderF["billboards"]],
        }}
      />
      <OrdersActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
export default OrdersList;
