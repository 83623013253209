import BasicContainer from "components/containers/Basic";
import SubscriptionCards from "components/others/SubscriptionCards";

export const Subscription: React.FC = () => {
  return (
    <div className="flex justify-center h-full">
      <div className="flex flex-col items-center justify-center h-full p-5 text-center">
        <BasicContainer className="gap-2">
          <div className="max-w-sm text-xl font-semibold text-gray-700">
            Seleziona un piano di abbonamento per usufruire dei servizi di
            Billalo
          </div>
          <div className="max-w-sm text-sm font-medium text-gray-400 ">
            Se hai già selezionato un piano resta in attesa della conferma dal backoffice
          </div>
          <SubscriptionCards />
        </BasicContainer>
      </div>
    </div>
  );
};
export default Subscription;
