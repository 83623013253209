import { ordersSelectors, ordersActions } from "store/slices/orders";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userSelectors } from "store/slices/user";
export const useMyOrders = () => {
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [orders, status] = useSelector(ordersSelectors.myOrders);
  const dispatch = useDispatch();
  useEffect(() => {
    if (orders === null && status === "idle" && isMediaOwner) {
      dispatch(ordersActions.willFetchOrders());
      console.log("No orders in store, fetching...");
    }
  }, [orders, status, dispatch,isMediaOwner]);
  return;
};
export default useMyOrders;
