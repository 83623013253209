import BasicContainer from "components/containers/Basic";
import TransparentButton from "components/buttons/Transparent";
import { twMerge } from "tailwind-merge";
import { Fragment, useEffect, useState } from "react";
import { InfoSection } from "./InfoSection";
import { BillboardsSection } from "./BillboardsSection";
import { MediaOwnersSection } from "./MediaOwnersSection";
import {
  Billboard,
  FilterInput,
  Order,
  OrderBillboard,
  Campaign,
} from "graphql/dsp/generated";
import { batchGetOrders, getAdvertiserCampaign } from "api/gql/dsp";
import PaymentModal from "pages/Planning/Advanced/Confirm/PaymentModal";
import { createDiscountInput } from "pages/Planning/Advanced/Plan/startCampaignCreation";
import { currencyFormatter } from "utils/currencyFormatter";
/** Returns: [percent,amount,typeClass,type]  */

type Props = {
  campaignId: string;
  advertiser: string;
  filters: FilterInput;
  selectedBillboards: Billboard[];
};
export const Confirm: React.FC<Props> = ({
  campaignId,
  advertiser,
  filters,
  selectedBillboards,
}) => {
  // TODO: Take into account the discounted billboards. Show total as prezzo_totale - totale_sconto
  // TODO: Display every discount applied to the checkout.
  const [{ campaign, orders, billboards }, setData] = useState<{
    campaign: Campaign | null;
    orders: Order[];
    billboards: OrderBillboard[];
  }>({ campaign: null, orders: [], billboards: [] });
  const [section, setSection] = useState<(typeof sections)[number][0]>("info");

  const getCampaignData = async (advertiser: string, id: string) => {
    const campaign = await getAdvertiserCampaign({ advertiser, id });
    if (!campaign) return;
    const orderIds = campaign.orders?.map((o) => String(o)) || [];
    const orders = await batchGetOrders(id, orderIds);
    if (!orders) return;
    const billboards: OrderBillboard[] = orders
      .map((o) => o.billboards?.map((b) => b!) || [])
      .flat();
    return { campaign, orders, billboards };
  };
  console.log(campaign);

  useEffect(() => {
    // Fetch billboards/orders/mediaowner from campaign
    getCampaignData(advertiser, campaignId).then((res) => {
      if (!res) return;
      setData(res);
    });
  }, [campaignId, advertiser]);
  if (!campaign) return <></>;
  const campaignDiscount = campaign.discount ?? 0;
  const totalAmount = campaign.total_amount ?? 0;
  const billingData = [
    [
      "Date",
      `dal ${campaign.from.slice(0, 10)} al ${campaign.to.slice(0, 10)}`,
    ],
    ["Obiettivo", campaign.target || "Nessuno"],
    [
      "Spesa spazi pubblicitari",
      (totalAmount + (campaignDiscount > 0 ? campaignDiscount : 0)).toFixed(2) +
        " €",
    ],
    // ["Acconto", campaign.print_amount?.toFixed(2) + " €"],
    ...(() =>
      campaignDiscount < 0
        ? [["Sconto:", campaignDiscount.toFixed(2) + " €"]]
        : [])(),
    ["Stampa e consegna", orders[0].printer === "true" ? "si" : "no"],
    ["Fee Billalo", campaign.fee?.toFixed(2) + " €"],
  ];
  const sections = [
    ["info", "Informazioni", <InfoSection billboards={billboards} />],
    ["billboards", "Impianti", <BillboardsSection billboards={billboards} />],
    ["mediaowners", "Concessionarie", <MediaOwnersSection orders={orders} />],
  ] as const;
  return (
    <div className="flex flex-col max-w-5xl max-h-full px-4 overflow-scroll hover-scrollbar animate-down-up">
      <div className="bottom-0 justify-start my-3 text-xl font-semibold text-gray-900 shrink-0">
        <span>Riepilogo</span>
      </div>
      <div className="flex gap-6 overflow-hidden grow">
        <div className="flex flex-col grow">
          <div className="grid shrink-0 grid-cols-3 rounded-lg border p-[2px]">
            {sections.map(([key, alias]) => {
              return (
                <TransparentButton
                  key={key}
                  onClick={() => setSection(key)}
                  className={twMerge(
                    " w-full rounded-lg py-0",
                    key === section && "bg-gray-400 bg-opacity-90 text-white"
                  )}
                >
                  {alias}
                </TransparentButton>
              );
            })}
          </div>
          <div className="p-3 pb-8 mb-4 overflow-scroll hover-scrollbar grow">
            {sections.map(([key, _, component]) => {
              return (
                <div
                  key={key}
                  className={twMerge(
                    "w-[430px] pb-3",
                    key !== section && "hidden"
                  )}
                >
                  {component}
                </div>
              );
            })}
          </div>
        </div>
        <BasicContainer className="h-fit min-w-[400px] grow items-stretch justify-start gap-6 p-4">
          <div className="font-semibold text-start">{campaign?.name}</div>

          <div className="grid grid-cols-[auto_auto] items-center gap-2 text-sm">
            {billingData.map(([key, value]) => {
              return (
                <Fragment key={key}>
                  <div className="border-b">{key}</div>
                  <div className="font-semibold border-b text-end">{value}</div>
                </Fragment>
              );
            })}
          </div>
          <div className="flex justify-between px-1 text-sm font-semibold">
            <span>TOTALE</span>
            <span>
              {currencyFormatter.format(
                (campaign.total_amount ?? 0) +
                  (campaign.fee ?? 0) +
                  (campaign.discount ?? 0)
              )}
            </span>
          </div>
          <PaymentModal
            campaign={campaign}
            orders={orders}
            pricingInput={createDiscountInput(selectedBillboards, filters)}
            className="self-end px-12 w-fit"
          />
        </BasicContainer>
      </div>
    </div>
  );
};
export default Confirm;
