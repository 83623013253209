import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "components/others/LoadingSpinner";
import OrdersList from "components/lists/Orders";
import { useSelector } from "react-redux";
import { ordersSelectors } from "store/slices/orders";
import { Order } from "graphql/ssp/generated";
import { OrderStatus } from "types/Statuses";
import useMyClients from "hooks/useMyClients";
import { clientsSelectors } from "store/slices/clients";

/** MediaOwner orders */
export const MyOrders = () => {
  useMyClients();
  const [searchParams] = useSearchParams();
  const [myOrders] = useSelector(ordersSelectors.myOrders);
  const [myClients] = useSelector(clientsSelectors.myClients);

  if (myOrders === null || myClients === null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className="fill-primary-light" />
      </div>
    );
  }

  const [currentOrders, archivedOrders] = myOrders.reduce(
    (prev, order) => {
      const status = order.status as OrderStatus;

      const okStatuses: OrderStatus[] = [
        "confirmed",
        "scheduled",
        "paid",
        "delivered_materials",
        "posted",
      ];

      okStatuses.includes(status) ? prev[0].push(order) : prev[1].push(order);
      return prev;
    },
    [[], []] as [Order[], Order[]]
  );

  const archiveView = searchParams.get("view") === "archive";
  return (
    <>
      <div className="flex flex-col items-center w-full p-3 animate-down-up">
        {archiveView ? (
          archivedOrders.length ? (
            <OrdersList key="archive" myOrders={archivedOrders} />
          ) : (
            <i className="text-2xl font-medium text-gray-500">
              Nessun ordine archiviato
            </i>
          )
        ) : currentOrders.length ? (
          <OrdersList key="current" myOrders={currentOrders} />
        ) : (
          <i className="text-2xl font-medium text-gray-500">
            Nessun ordine in corso
          </i>
        )}
      </div>
    </>
  );
};
export default MyOrders;
