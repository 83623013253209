import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { formDataGenerator, StepForm } from "components/Stepper";
import { BillboardInput } from "graphql/ssp/generated";
import BillboardCard from "components/cards/Billboard";
import TransparentButton from "components/buttons/Transparent";
import Toggle from "components/inputs/Toggle";
import useHasActiveStripe from "hooks/useHasActiveStripe";
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "components/popovers/Tooltip";

/** Used during the user login (both for clients and mediaOwners) */
export const Confirm: StepForm<
  Partial<BillboardInput & { image: File | null }>
> = ({
  className,
  inputData,
  editHandler,
  formRef,
  isCurrentStep,
  ...attributes
}) => {
  const hasActiveStripe = useHasActiveStripe();

  const imageBlob = inputData.image?.name
    ? URL.createObjectURL(inputData.image)
    : null;
  const [showPreview, setShowPreview] = useState(false);
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    form.set("visible", String(toggleRef.current?.checked || false));
    editHandler(form, valid);
  };
  const toggleRef = useRef<null | HTMLInputElement>(null);
  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      ref={formRef}
      className={twMerge(
        "flex w-full flex-col items-center gap-3 rounded-3xl",
        className
      )}
      onChange={(e) => handleChange(e.currentTarget)}
      {...attributes}
    >
      <div className="self-start font-medium">
        Visibilità
        {!hasActiveStripe && (
          <ExclamationTriangleIcon className="inline h-5 mx-2 stroke-2 text-adv" />
        )}
      </div>
      {!hasActiveStripe && (
        <div className="-mt-1 font-semibold">
          Prima di poter rendere uno spazio visibile per l'acquisto è necessario
          attivare l'account Stripe
          <Tooltip
            className="inline"
            tip={
              <div className="text-center">
                L'account Stripe può venire attivato dal bottone
                <br />{" "}
                <i>
                  <b>Attiva Stripe</b>
                </i>{" "}
                situato nella pagina del tuo profilo aziendale.
              </div>
            }
          >
            <QuestionMarkCircleIcon className="inline h-4 mb-1 ml-1 text-gray-400 stroke-2" />
          </Tooltip>
        </div>
      )}
      <div
        className={twMerge(
          "flex w-full justify-start gap-3",
          !hasActiveStripe && "opacity-50"
        )}
      >
        <Toggle
          innerRef={toggleRef}
          defaultChecked={inputData.visible || false}
          value="visibile"
          disabled={!hasActiveStripe}
          className="transition-all duration-300 bg-yellow-400 peer-checked:bg-primary"
        />
        <span className="text-gray-400">
          Spazio{" "}
          {inputData.visible ? (
            <span className="font-bold text-primary">visibile</span>
          ) : (
            <span className="font-bold text-yellow-400">NON visibile</span>
          )}{" "}
          per l'acquisto
        </span>
      </div>
      <div>
        Visualizza l'anteprima verificando che i dati inseriti siano corretti, o
        naviga agli step precedenti per modificarli.
      </div>
      <div className="flex flex-col items-center w-full">
        <TransparentButton
          className="self-start text-sm font-medium text-gray-500 border rounded-lg"
          onClick={() => setShowPreview((prev) => !prev)}
        >
          {showPreview ? "Nascondi" : "Visualizza"} Anteprima
        </TransparentButton>
        {showPreview && (
          <>
            <div className="w-full mb-4 font-semibold text-left text-gray-500">
              <br />
              Nota: <br />
              <i className="text-xs font-medium">
                Gli <b>indici visibilità</b> ed i <b>punteggi impianto</b>{" "}
                mostrati hanno il solo scopo illustrativo, i valori reali
                verranno calcolati all'ultimazione della creazione impianto.
              </i>
            </div>
            <BillboardCard
              data={{
                ...inputData,
                id: "preview",
                iV_municipality: Math.random() * 10,
                iV_normalized: Math.random() * 10,
                indice_visibilita: Math.random() * 10,
                panel_index: Math.random() * 10,
                imageUrl: imageBlob,
              }}
              className="mb-4 shadow w-96 rounded-xl"
            />
          </>
        )}
      </div>
    </form>
  );
};
export default Confirm;
