import { createRef, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import FloatingBasic from "components/inputs/Floating/Basic";
import LocationSelector from "components/LocationSelector";
import { formDataGenerator, StepForm } from "components/Stepper";
import { BillboardInput } from "graphql/ssp/generated";
import FloatingSelect from "components/inputs/Floating/Select";
import options from "../../../../utils/formatters/billboardOptions";
import { getMarkerUrl } from "utils/billboards/BillboardSvg";
import municipalities from "utils/location/municipalities.json";
import { geocodeAddress, getAddressKey } from "utils/location/geocoding";
/** Used during the user login (both for clients and mediaOwners) */
export const Location: StepForm<Partial<BillboardInput>> = ({
  className,
  inputData,
  editHandler,
  onSubmit,
  isCurrentStep,
  formRef,
  prefill,
  ...attributes
}) => {
  const [formData, setFormData] = useState<
    Partial<BillboardInput & { postal_code: string | null; city_code: string }>
  >({});
  const [isGeneric, setIsGeneric] = useState(true);

  const isOutdoor = Number(inputData.billboard_class === "Outdoor");
  const addressRef = createRef<HTMLInputElement>();
  const searchInputRef = useRef<null | HTMLInputElement>(null);

  const handleLocationChange = (
    latLng: google.maps.LatLng,
    locationData: google.maps.GeocoderResult | google.maps.places.PlaceResult,
    editInput: boolean = true
  ) => {
    const comps = locationData.address_components;
    if (!comps) return;
    const municipality = getAddressKey(comps, "political")?.short_name || "";
    const postal_code = getAddressKey(comps, "postal_code")?.short_name || "";
    const lcMunicipality=municipality.toLowerCase()
    const cityCode = municipalities.find(([name]) => name === lcMunicipality);
    const city_code = cityCode?.[1] || "";
    const street =
      getAddressKey(comps, "route")?.short_name ||
      getAddressKey(comps, "plus_code")?.short_name;
    const street_number =
      getAddressKey(comps, "street_number")?.short_name || "";
    const country = getAddressKey(comps, "country")?.short_name;
    let address = `${street} ${street_number}, ${municipality} (${postal_code}), ${country}`;
    let isTooGeneric = !(street && municipality && postal_code && country);
    if (isTooGeneric) address = "Indirizzo troppo generico";
    if (!city_code) {
      address = "Indirizzo non valido";
      isTooGeneric = true;
    }
    if (!editInput) return;
    if (addressRef.current) {
      addressRef.current.value = address;
    }
    setIsGeneric(isTooGeneric);
    setFormData({
      ...formData,
      address,
      municipality,
      postal_code,
      city_code,
      latitude: latLng.lat(),
      longitude: latLng.lng(),
    });
    if (formRef.current) {
      const form = new FormData(formRef.current);
      form.set("municipality", municipality);
      form.set("city_code", city_code);
      form.set("latitude", latLng.lat().toString());
      form.set("longitude", latLng.lng().toString());
      editHandler(form, !isTooGeneric && Boolean(form.get("location")));
    }

    return locationData.geometry?.viewport;
  };

  const fields: [keyof typeof formData, string, string][] = [
    ["city_code", "Codice catastale", "text"],
    ["postal_code", "CAP", "text"],
    ["latitude", "Latitudine", "text"],
    ["longitude", "Longitudine", "text"],
    ["municipality", "Comune", "text"],
  ];
  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    editHandler(form, valid && !isGeneric);
  };

  const fill = (key: keyof typeof inputData) =>
    prefill ? inputData?.[key] || undefined : undefined;

  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  useEffect(() => {
    const inputDataAddress: string = fill("address");
    if (!inputDataAddress) return;
    geocodeAddress(inputDataAddress).then((res) => {
      if (!res.results[0]) return;
      const latLng = res.results[0].geometry.location;
      handleLocationChange(latLng, res.results[0], false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form
      ref={formRef}
      className={twMerge(
        "flex w-full flex-col items-center gap-5 rounded-3xl",
        className
      )}
      onChange={(e) => handleChange(e.currentTarget)}
      {...attributes}
    >
      <LocationSelector
        onSelected={handleLocationChange}
        markerIconSelector={() =>
          getMarkerUrl(inputData.billboard_class, inputData.media_type)
        }
        // Prevent the form from submitting when pressing enter on the places searchbox
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
      <div className="text-sm text-gray-600">
        <b>Istruzioni:</b>
        <br /> Seleziona la posizione digitando l'indirizzo, o cliccando sulla
        mappa. Una volta posizionato il marker, è possibile modificarne la
        posizione tramite Street View (trascinando l'omino a destra sopra il
        marker).
      </div>
      <div className="grid w-full grid-cols-2 gap-3">
        <FloatingBasic
          required
          stayUp
          onInput={() => setIsGeneric(false)}
          name={"address"}
          innerRef={addressRef}
          type={"text"}
          label={"Indirizzo"}
          className={twMerge(
            "col-span-2 w-full",
            isGeneric && "peer !text-red-500"
          )}
          autoComplete="one-time-code"
        />
        {fields.map(([key, label, type], index) => {
          return (
            <FloatingBasic
              key={index}
              name={key}
              onFocus={(e) => e.target.blur()}
              onClick={(e) => {
                e.preventDefault();
                searchInputRef.current?.focus();
              }}
              value={
                (formData[key as keyof typeof formData] as
                  | string
                  | number
                  | readonly string[]) || ""
              }
              type={type}
              label={label}
              title={
                "Questi campi verranno riempiti automaticamente una volta selezionata la posizione dalla mappa"
              }
              className={className + " peer select-none"}
              autoComplete="one-time-code"
              onMouseDown={(e) => e.preventDefault()}
            />
          );
        })}
        <FloatingSelect
          defaultValue={fill("location")}
          name="location"
          label="Ubicazione"
          required
        >
          {options.location[isOutdoor].map((value, i) => (
            <option key={i} value={value}>
              {value.replaceAll("_", " ")}
            </option>
          ))}
        </FloatingSelect>
      </div>
    </form>
  );
};
export default Location;
