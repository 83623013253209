import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import { resourcesUrl } from "api/gql/ssp";
import ImageDiv from "components/others/ImageDiv";
import Tooltip from "components/popovers/Tooltip";
import { twMerge } from "tailwind-merge";

type Props = {
  mediaowner: string;
  billboardsAmount?: number;
  obscured?: boolean;
  rejected?: boolean;
};

export const MediaOwnerCountCard: React.FC<Props> = ({
  mediaowner,
  billboardsAmount,
  obscured,
  rejected,
}) => {
  return (
    <div
      key={mediaowner}
      className={twMerge("flex w-full select-none flex-col")}
    >
      <div className="flex items-center justify-between">
        <div
          className={twMerge("flex items-center gap-3", obscured && "blur-sm")}
        >
          <ImageDiv
            src={`${resourcesUrl}pfp_business_${mediaowner}.png`}
            className="w-12 h-12 rounded-full"
          />
          <div className={twMerge(obscured && "blur-sm")}>{mediaowner}</div>
        </div>
        {rejected && (
          <Tooltip tip="Quest'ordine è stato rifiutato.">
            <ExclamationTriangleIcon className="w-8 h-8 text-white fill-red-400" />
          </Tooltip>
        )}
        {(billboardsAmount || billboardsAmount === 0) && (
          <div className={twMerge("flex items-center gap-3",rejected&&"text-red-600")}>
            {" "}
            <div>
              {billboardsAmount} Impiant{billboardsAmount === 1 ? "o" : "i"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaOwnerCountCard;
