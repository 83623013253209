import { Order } from "graphql/dsp/generated";
import BasicContainer from "components/containers/Basic";
import MediaOwnerCountCard from "components/cards/MediaOwnerCount";

/** Returns : [imgUrl,name,amount, address, P.Iva] */

type Props = {
  className?: string;
  orders: Order[];
};
export const MediaOwnersSection: React.FC<Props> = ({ className, orders }) => {
  return (
    <div className={className}>
      <div className="mb-2 text-base font-semibold">Concessionarie</div>
      <BasicContainer className="w-full border bg-transparent">
        {orders.map(({ media_owner, billboards }) => {
          return (
            <MediaOwnerCountCard
              key={media_owner}
              mediaowner={String(media_owner)}
              billboardsAmount={billboards?.length}
              obscured
            />
          );
        })}
      </BasicContainer>
    </div>
  );
};
export default MediaOwnersSection;
