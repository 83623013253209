// This function resizes an image to a square of a given size
export const resizeImage = async (image: File, maxSize: number) => {
  const inputImage = document.createElement("img");
  inputImage.src = URL.createObjectURL(image);
  await new Promise((resolve) => {
    inputImage.onload = resolve;
  });
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  canvas.width = maxSize;
  canvas.height = maxSize;
  const imageAspectRatio = inputImage.width / inputImage.height;
  let scaledImageWidth = maxSize;
  let scaledImageHeight = maxSize;
  if (imageAspectRatio > 1) {
    scaledImageWidth *= imageAspectRatio;
  } else {
    scaledImageHeight /= imageAspectRatio;
  }
  ctx.drawImage(
    inputImage,
    (maxSize - scaledImageWidth) / 2,
    (maxSize - scaledImageHeight) / 2,
    scaledImageWidth,
    scaledImageHeight
  );

  const dataBlob = await new Promise<Blob | null>((resolve, reject) => {
    canvas.toBlob((res) => {
      res ? resolve(res) : reject(res);
    }, "image/png");
  });
  if (!dataBlob) return null;
  const file = new File([dataBlob], "temp.png", { type: "image/png" });
  return file;
};
