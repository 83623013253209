import TextButton from "components/buttons/Text";
import Toggle from "components/inputs/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "store/slices/user";

type Props = {
  advertiserText?: React.ReactNode;
  mediaOwnerText?: React.ReactNode;
  /** Optional custom text color, default is "text-primary-light" */
  textColor?:string,
  /** Optional toggle color, default is "bg-primary-light" */
  toggleColor?:string,
};
export const IsMediaOwnerSwitch: React.FC<Props> = ({
  advertiserText = "Acquirente",
  mediaOwnerText = "Rivenditore",
  textColor,
  toggleColor,
}) => {
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const dispatch = useDispatch();
  const setIsMediaOwner = (isMediaOwner: boolean) => {
    dispatch(userActions.setIsMediaOwner(isMediaOwner));
  };
  return (
    <div className="flex items-center justify-center gap-2">
      <TextButton
        className={`text-lg font-bold transition-all ${
          !isMediaOwner ? (textColor||"text-primary-light") : "text-gray-400"
        }`}
        onClick={() => setIsMediaOwner(false)}
      >
        {advertiserText}
      </TextButton>
      <Toggle
        checked={isMediaOwner}
        onChange={(e) => setIsMediaOwner(e.target.checked)}
        className={(toggleColor||"bg-primary-light")}
      />
      <TextButton
        className={`text-lg font-bold transition-all ${
          isMediaOwner ? (textColor||"text-primary-light") : "text-gray-400 "
        }`}
        onClick={() => setIsMediaOwner(true)}
      >
        {mediaOwnerText}
      </TextButton>
    </div>
  );
};

export default IsMediaOwnerSwitch;
