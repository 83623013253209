import { updateCampaignState } from "api/gql/dsp";
import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Campaign } from "graphql/dsp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  data: Campaign;
};
export const CancelCampaign: React.FC<Props> = ({ data, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const cancelCampaign = async () => {
    setIsLoading(true);
    if (!data.id) return;
    const res = await updateCampaignState({ id: data.id, action: "cancel" });
    console.log({ res });
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <>
      <div
        role="button"
        onClick={() => setIsOpen(true)}
        className={twMerge("", className)}
      >
        Cancella
      </div>
      <BasicModal
        open={isOpen}
        close={() => setIsOpen(false)}
        loading={isLoading}
        title="Conferma cancellazione campagna"
      >
        <div> 
            Confermare la cancellazione della campagna?
        </div>
        <div className="flex items-center justify-end gap-2 mt-5">
          <BasicButton
            onClick={() => setIsOpen(false)}
            className="py-1 bg-gray-400 w-fit"
          >
            Annulla
          </BasicButton>
          <BasicButton onClick={() => cancelCampaign()} className="py-1 w-fit">
            Conferma
          </BasicButton>
        </div>
      </BasicModal>
    </>
  );
};
export default CancelCampaign