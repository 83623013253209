import { ClipboardIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import Tooltip from "components/popovers/Tooltip";
import { useState } from "react";

export const CopyButton = ({
  longString,
  children,
  disabled = false,
  className=""
}: {
  longString: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?:string
}) => {
  const [show, setShow] = useState(false);
  const copyFn = () => {
    navigator.clipboard.writeText(longString);
    setShow(true);
    setTimeout(() => setShow(false), 1000);
  };
  return (
    <Tooltip disabled={show} tip={<span className="text-sm text-gray-500">Copia</span>} >
        <div className="relative flex items-center justify-center">
          {show ? (
              <div className="text-gray-500 select-none">Copiato negli appunti</div>
          ) : (
            <TransparentButton disabled={disabled} onClick={copyFn} className={className}>
              <div className="flex items-center gap-3">
                {children}
                <ClipboardIcon className="w-4 h-4 stroke-[2px]" />
              </div>
            </TransparentButton>
          )}
        </div>
    </Tooltip>
  );
};
export default CopyButton;
