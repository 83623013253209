import { NotificationCard } from "components/notifications/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationsActions,
  notificationsSelectors,
} from "store/slices/notifications";
import { PostNotification } from "types/notifications";

export const Notifications: React.FC = () => {
  const postNotifications = useSelector(notificationsSelectors.posts);
  // TODO:
  // Put orders list as notification ( it has to be an async task)
  // Transform orders fetched into a lsit of notifications
  // Set them as read in the cache if they're more than a week old (TEMPORARY!)
  // or as soon as the user opens them
  const [tempNotifications, setTempNotifications] =
    useState<PostNotification[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (postNotifications.length) {
      dispatch(notificationsActions.removeAllPosts());
      setTempNotifications([...postNotifications]);
    }
  }, [postNotifications, dispatch]);
  return (
    <div className="flex justify-center w-full h-full max-h-full p-3 pb-12 hover-scrollbar animate-down-up">
      <div className="flex flex-col items-center w-full max-w-2xl mx-5 mt-16 h-fit gap-y-2">
        {tempNotifications.length === 0 && (
          <i className="text-2xl font-medium text-slate-400">
            Nessuna notifica
          </i>
        )}
        {tempNotifications?.map((post) => {
          return <NotificationCard key={post.id} {...post} />;
        })}
      </div>
    </div>
  );
};
export default Notifications;
