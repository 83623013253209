import { ArrowRightIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import Table from "components/lists/table";
import { Billboard } from "graphql/ssp/generated";
import { useNavigate } from "react-router-dom";
import { billboardF } from "utils/formatters";

type Props = {
  myBillboards: Billboard[];
};
export const BillboardsOverview: React.FC<Props> = ({ myBillboards }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full font-medium text-left">
      <div className="flex items-center justify-between w-full gap-2 text-sm">
        <div className="w-full font-semibold text-left whitespace-nowrap">
          I miei impianti
        </div>
        <TransparentButton
          className="gap-1 text-gray-400 whitespace-nowrap hover:text-gray-700"
          onClick={() => navigate("/billboards")}
        >
          <div>Lista</div> <ArrowRightIcon className="h-4" />
        </TransparentButton>
      </div>
      <b className="text-3xl">{myBillboards.length}</b>
      <small className="text-gray-500 ">Numero impianti totali</small>
      {myBillboards && (
        <div className="overflow-y-scroll hover-scrollbar max-h-60">
          <Table
            data={myBillboards}
            cols={{
              format: ["F", billboardF["format"]],
              media_type: [
                "T",
                (val) => <div className="w-24">{billboardF["media_type"]?.(val)}</div>,
              ],
            }}
            showTitles={false}
            showHeader={false}
            transparentRows
          />
        </div>
      )}
    </div>
  );
};
