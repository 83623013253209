import { Advertiser } from "graphql/dsp/generated";
import { MediaOwner } from "graphql/ssp/generated";
import { Navigate, Outlet } from "react-router-dom";
type Props = {
  group: Advertiser | MediaOwner | null;
  not?: boolean;
  status:Status
};

/**
 * Checks if the user is associated to an userGroup.
 */
export const HasGroupWrapper: React.FC<Props> = ({ group, not,status }) => {
  const hasUserGroup = !!group; // Whether the user belongs to a group
  if(status === "pending") return <Outlet />;
  if (!not && !hasUserGroup) {
    return <Navigate replace to={"/complete-registration/business-name"} />;
  }
  if (not && hasUserGroup) {
    return <Navigate replace to={"/dashboard"} />;
  }
  return <Outlet />;
};
export default HasGroupWrapper;
