import { twMerge } from "tailwind-merge";

export const NavButton: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { active: boolean }
> = ({ active, className, children, ...attributes }) => {
  return (
    <div
      role="button"
      className={twMerge(
        "select-none relative flex h-full min-w-[4.5rem] flex-col items-center justify-center font-bold hover:text-primary-dark",
        active ? "text-primary" : "text-gray-700",
        className
      )}
      {...attributes}
    >
      <div className="pt-1">{children}</div>
      <div
        className={twMerge(
          "absolute bottom-0 m-auto h-0 rounded-t-lg bg-primary transition-all",
          active ? "h-1 w-full" : "w-0"
        )}
      ></div>
    </div>
  );
};
export default NavButton;
