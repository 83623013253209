import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import { FilterInput } from "graphql/dsp/generated";
import { useState } from "react";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};
export const Price: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  const [prices, setPrices] = useState<{
    price_from: FilterInput["price_from"];
    price_to: FilterInput["price_to"];
  }>({
    price_from: filterInput["price_from"],
    price_to: filterInput["price_to"],
  });
  const [isModified, setIsModified] = useState(false);

  const changePrices = (limit: "price_from" | "price_to", amount: number) => {
    setPrices((curr) => {
      return {
        ...curr,
        [limit]: amount,
      };
    });
  };
  return (
    <BasicContainer className="items-start">
      <form
        className="flex flex-col gap-3"
        onSubmit={(e) => {
          e.preventDefault();
          setFilterInput((filters) => ({ ...filters, ...prices }));
          setIsModified(false);
        }}
        onChange={() => setIsModified(true)}
      >
        <div className="font-medium">Prezzo</div>
        <div className="grid grid-cols-[auto_auto] items-center gap-2 gap-y-4">
          <div className="text-end">Da:</div>
          <div className="flex items-center gap-2">
            <BasicInput
              className="w-28"
              type="number"
              step="any"
              name="price_from"
              placeholder="Minimo"
              defaultValue={prices?.price_from || undefined}
              min={0}
              max={prices?.price_to || undefined}
              required={false}
              onChange={(e) =>
                changePrices("price_from", Number(e.currentTarget.value))
              }
            />
            €
          </div>
          <div className="text-end">A:</div>
          <div className="flex items-center gap-2">
            <BasicInput
              className="w-28"
              type="number"
              step="any"
              name="price_to"
              placeholder="Massimo"
              defaultValue={prices?.price_to || undefined}
              min={prices?.price_from || 0}
              required={false}
              onChange={(e) =>
                changePrices("price_to", Number(e.currentTarget.value))
              }
            />
            €
          </div>
        </div>
        <div className="flex justify-end w-full">
          <BasicButton
            role="submit"
            disabled={!isModified}
            className="p-1 px-3 w-fit"
          >
            Applica
          </BasicButton>
        </div>
      </form>
    </BasicContainer>
  );
};

export default Price;
