import BillboardClass from "./Class";
import CardClose from "components/cards/Close";
import CardSection from "components/cards/Section";
import IndexCard from "./IndexCard";
import { Billboard } from "graphql/ssp/generated";
import {
  HomeIcon,
  BuildingLibraryIcon,
  StarIcon,
  MagnifyingGlassPlusIcon,
  MapPinIcon,
  EllipsisHorizontalCircleIcon,
  CalendarDaysIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import ScrollableContainer from "components/containers/Scrollable";
import { twMerge } from "tailwind-merge";
import { billboardF } from "utils/formatters";
import ImageDiv from "components/others/ImageDiv";
import TransparentButton from "components/buttons/Transparent";
import ActionsButton from "components/buttons/Actions";
import { memo, useState } from "react";
import { BillboardsActionsManager } from "components/lists/Billboards";
import { MiniButton } from "components/buttons/Mini";
import Tooltip from "components/popovers/Tooltip";
import VisibilityToggle from "components/cards/Billboard/VisibilityToggle";

type Props = {
  data: Billboard;
  zoomIn?: (billboard: Billboard) => void;
  streetView?: (billboard: Billboard) => void;
  onClose?: () => void;
  /** MediaOwner View */
  ownerView?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const BillboardCard: React.FC<Props> = ({
  data,
  zoomIn,
  streetView,
  className,
  onClose,
  ownerView,
  ...attributes
}) => {
  console.log(data);

  const [currentAction, setCurrentAction] = useState<
    null | [string, Billboard | Billboard[]]
  >(null);
  type DataMap = [string, string, ((key: string) => string | JSX.Element)?][];

  const ownerViewData = () =>
    ownerView
      ? [
          ["Cimasa", data.cod_cimasa],
          ["Latitudine", data.latitude],
          ["Longitudine", data.longitude],
          ["Stato manutenzione", data.maintenance_status],
          ["Data ultimo intervento", data.last_maintenance, billboardF["last_maintenance"]],
          ["Ultimo pagamento", data.taxes?.municipal_tax, billboardF["price"]],
          ["Data ultimo pagamento", data.taxes?.last_payment, billboardF["authorization_expiration"]],//billboardF just to format date
          ["Numero SCIA", data.n_authorization],
          ["Scadenza SCIA", data.authorization_expiration, billboardF["authorization_expiration"]],
        ]
      : [];
  const listData = [
    ...ownerViewData(),
    ["Ubicazione", data.location, billboardF["location"]],
    ["Classe", data.billboard_class, (val) => <BillboardClass bbClass={val} />],
    ["Tipologia", data.media_type, billboardF["media_type"]],
    ["Durata contenuto", data.content_duration, billboardF["content_duration"]],
    [
      "Rotazione contenuto",
      data.content_rotation,
      billboardF["content_rotation"],
    ],
    ["Formato", data.format, billboardF["format"]],
    ["Superficie (cm\u00B2)", data.size],
    ["Facciate", data.facade],
    ["Tipo Contenuto", data.press_type, billboardF["press_type"]],
    ["Uscita minima", data.min_period, billboardF["min_period"]],
    ["Prezzo", data.price, billboardF["price"]],
    ["Altezza da terra", data.height_ground],
    ["Illuminazione", data.lighting],
    ["Orientamento", data.orientation],
  ] as DataMap;

  const scoresData = [
    ["Punteggio impianto", Math.round(Number(data.panel_index) * 10) / 10],
    ["Posizione", Math.round(Number(data.indice_visibilita) * 10) / 10],
    [
      "Traffico",
      "Lento",
      (val) => <span className="text-gray-500">{val}</span>,
    ],
  ] as DataMap;

  console.log("Billboard data: ", { billboard: data });
  return (
    <>
      <ScrollableContainer
        className={twMerge("relative max-h-full w-full bg-white", className)}
        deps={[data]}
        {...attributes}
      >
        <CardClose onClick={onClose} />
        <ImageDiv
          withModal
          className="w-full h-40"
          alt="billboard"
          title={data.address || ""}
          src={data.imageUrl}
        />
        <CardSection className="items-center text-base">
          <div className="font-medium text-gray-800">{data.address}</div>
          <div className="text-gray-500">{data.municipality}</div>
        </CardSection>
        {ownerView && (
          <CardSection className="flex-row justify-center w-full gap-3 pt-0 text-slate-500">
            <Tooltip tip="Regole di pricing">
              <MiniButton
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentAction(["pricing", data!]);
                }}
                className={twMerge(
                  "border shadow-sm hover:bg-slate-50",
                  data?.pricing_id && " text-green-500"
                )}
              >
                <ReceiptPercentIcon className="h-6" />
              </MiniButton>
            </Tooltip>
            <Tooltip tip="Calendario disponibilità">
              <MiniButton
                className="border shadow-sm hover:bg-slate-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentAction(["availabilities", data!]);
                }}
              >
                <CalendarDaysIcon className="h-6" />
              </MiniButton>
            </Tooltip>
            <VisibilityToggle
              billboard={data}
              className="border shadow-sm hover:bg-slate-50"
            />
          </CardSection>
        )}
        <CardSection className="flex-row items-center justify-around -my-4 text-base text-gray-500">
          {zoomIn && (
            <TransparentButton onClick={() => zoomIn(data)} className="text-sm">
              <MagnifyingGlassPlusIcon className="h-6" />
              Zoom (mappa)
            </TransparentButton>
          )}
          {streetView && (
            <TransparentButton
              onClick={() => streetView(data)}
              className="text-sm"
            >
              <MapPinIcon className="h-6" />
              Street View
            </TransparentButton>
          )}
          {ownerView && (
            <ActionsButton
              data={data}
              buttonContent={
                <>
                  <EllipsisHorizontalCircleIcon className="h-6" />
                  Azioni
                </>
              }
              className="text-sm"
              actions={[
                [
                  <span>Modifica impianto</span>,
                  (data) => setCurrentAction(["edit", data as Billboard]),
                ],
                [
                  <span className="text-red-400">Elimina impianto</span>,
                  (data) => setCurrentAction(["delete", data as Billboard]),
                ],
              ]}
            />
          )}
        </CardSection>
        <CardSection>
          {listData.map(([name, value, resolver], i) => {
            if (!value || value === "undefined") {
              return <div className="hidden" key={i}></div>;
            }
            return (
              <div
                key={i}
                className="flex items-center justify-between py-2 text-base border-t"
              >
                <span className="text-gray-500">{name}</span>
                {resolver ? (
                  resolver(value)
                ) : (
                  <span className="font-medium text-gray-800">{value}</span>
                )}
              </div>
            );
          })}
        </CardSection>
        {(Boolean(data.iV_municipality) || Boolean(data.iV_normalized)) && (
          <>
            <CardSection className="gap-2 border-t">
              {data.iV_municipality && (
                <IndexCard
                  amount={data.iV_municipality}
                  amountMax={10}
                  amountText={(amt, max) => amt.toFixed(1) + "/" + max}
                  Icon={HomeIcon}
                />
              )}
              {data.iV_normalized && (
                <IndexCard
                  color="#3DD598"
                  amount={data.iV_normalized}
                  amountMax={10}
                  amountText={(amt, max) => amt.toFixed(1) + "/" + max}
                  location={"Italia"}
                  Icon={BuildingLibraryIcon}
                />
              )}
            </CardSection>
          </>
        )}
        {data.notes && (
          <CardSection className="gap-2 pt-4 border-t">
            <div className="text-gray-500">Descrizione</div>
            <div className="px-2 text-gray-800 break-words">{data.notes}</div>
          </CardSection>
        )}

        <CardSection className="gap-2">
          {scoresData.map(([name, value, resolver], i) => {
            if (!value) {
              return <div key={i}></div>;
            }
            return (
              <div className="flex items-center justify-between" key={i}>
                <div className="flex items-center gap-2">
                  <StarIcon className="w-8 p-2 text-blue-300 rounded-xl bg-blue-50" />
                  <span>{name}</span>
                </div>
                {resolver ? (
                  resolver(value)
                ) : (
                  <div>
                    <span>{value}</span>
                    <span className="text-gray-500"> su 10</span>
                  </div>
                )}
              </div>
            );
          })}
        </CardSection>
      </ScrollableContainer>
      <BillboardsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
export default memo(BillboardCard);
