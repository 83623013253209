import { ArrowUturnRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import { FilterInput } from "graphql/dsp/generated";
import { useRef, useState } from "react";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};

export const MediaOwner: React.FC<Props> = ({
  filterInput,
  setFilterInput,
}) => {
  const filterInputMediaowners = filterInput?.media_owner?.filter(
    (c): c is string => !!c
  );
  const [mediaowners, setMediaowners] = useState<string[]>(
    filterInputMediaowners || []
  );
  const [isModified, setIsModified] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const addMediaowner = (mediaowner: string) => {
    setMediaowners((curr) => {
      return Array.from(new Set([...curr, mediaowner]));
    });
    setIsModified(true);
  };
  const removeMediaowner = (mediaowner: string) => {
    setMediaowners((curr) => {
      const idx = curr.indexOf(mediaowner);
      if (idx >= 0) curr.splice(idx, 1);
      return [...curr];
    });
    setIsModified(true);
  };
  const addInputValue = () => {
    const value = inputRef.current?.value;
    const form = formRef.current;
    if (value && form) {
      addMediaowner(value);
      form.reset();
    }
  };
  return (
    <BasicContainer className="items-start max-w-sm">
      <div className="flex flex-col gap-3">
        <div className="font-medium">Concessionarie</div>
        <div className="text-sm">
          Digita il nome/tag di una concessionaria e premi{" "}
          <span className="text-gray-400">
            INVIO <ArrowUturnRightIcon className="inline h-4 rotate-180" />
          </span>{" "}
          per aggiungere alla selezione.
        </div>
        <form
          ref={formRef}
          className="flex items-center gap-2"
          onSubmit={(e) => {
            e.preventDefault();
            addInputValue();
          }}
        >
          <BasicInput
            type="search"
            placeholder="Nome concessionaria"
            required={false}
            innerRef={inputRef}
          />
        </form>
        <div className="w-full">
          <div className="pb-2 text-gray-600">
            Concessionarie selezionate
            {mediaowners.length ? `(${mediaowners.length})` : null}:
          </div>
          <div className="flex flex-wrap justify-center gap-2">
            {!mediaowners.length && <i className="text-gray-400">Nessuna</i>}
            {mediaowners.map((mediaowner) => (
              <MediaOwnerTag
                key={mediaowner}
                mediaowner={mediaowner}
                removeMediaowner={removeMediaowner}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-end w-full">
          <BasicButton
            disabled={!isModified}
            role="submit"
            className="p-1 px-3 w-fit"
            onClick={() => {
              setIsModified(false);
              if (!mediaowners.length) return;
              setFilterInput((filters) => ({
                ...filters,
                media_owner: mediaowners,
              }));
            }}
          >
            Applica
          </BasicButton>
        </div>
      </div>
    </BasicContainer>
  );
};

type TagProps = {
  mediaowner: string;
  removeMediaowner?: (mediaowner: string) => void;
};
export const MediaOwnerTag: React.FC<TagProps> = ({
  mediaowner,
  removeMediaowner,
}) => {
  return (
    <div className="flex items-center gap-1 px-1 bg-gray-100 border rounded-lg">
      {removeMediaowner && (
        <div
          role="button"
          className="text-white bg-gray-400 rounded-full hover:bg-gray-700"
          onClick={() => removeMediaowner(mediaowner)}
        >
          <XMarkIcon className="h-4" />
        </div>
      )}
      <div>{mediaowner}</div>
    </div>
  );
};
export default MediaOwner;
