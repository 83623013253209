import { Outlet } from "react-router-dom";
import MainNavbar from "components/navbars/Main";

export const OnboardingLayout: React.FC = () => {
  return (
    <div
      className={
        "min-w-[900px] flex h-screen max-h-screen w-screen flex-col overflow-hidden bg-lighter-gray"
      }
    >
      <MainNavbar />
      <Outlet />
    </div>
  );
};
export default OnboardingLayout;
