import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import Dropdown from "components/inputs/Dropdown";
import { CampaignsActionsManager } from "components/lists/Campaigns/Actions";
import { Campaign } from "graphql/dsp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type ActionArray<T> = [
  JSX.Element | React.ReactNode,
  ((data: T) => void)?,
  string?
];
type Props<T> = {
  data: T;
  /** Button content, callback, button classname */
  actions: ActionArray<T>[];
  buttonContent?: JSX.Element | React.ReactNode;
  noButtonStyle?:boolean
  className?: string;
};
/** Generic action button which takes as input the data to be manipulated and actions to be dispatched upon it */
export function ActionsButton<T extends {}>({
  data,
  actions,
  noButtonStyle,
  buttonContent,
  className = "z-50 hover:bg-white hover:shadow-sm focus:bg-white focus:shadow",
}: Props<T>) {
  const [currentAction, setCurrentAction] = useState<null | [string, Campaign]>(
    null
  );
  return (
    <>
      <Dropdown
        disabled={false}
        offsets={[-2,-8]}
        placement="bottom-end"
        containerClassName={twMerge(
          "shadow right-0 overflow-hidden rounded-lg bg-transparent"
        )}
        optionsClassName={twMerge(
          "bg-white first:rounded-t-lg hover:border-primary whitespace-nowrap"
        )}
        className={twMerge(className)}
        buttonContent={
          buttonContent || <EllipsisHorizontalIcon className="h-6" />
        }
        noButtonStyle={noButtonStyle}
        noChevron
        options={actions.map(([content, onClick, className]) => {
          return [
            content,
            () => {
              onClick?.(data);
            },
            className,
          ];
        })}
      />
      <CampaignsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
}

export default ActionsButton;
