import { registerStripeAccount } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import { useState } from "react";

export const ActivateStripe: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const registerStripe = async () => {
    setIsLoading(true);
    const url = await registerStripeAccount();
    console.log("registerStripeAccount res:", {url});
    window.location.href=String(url)
    setIsLoading(false);
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm text-gray-400">
        {/* Per ricevere ordini su Billalo è necessario attivare l'Account
          Concessionaria su Stripe */}
        Se hai già attivato l'account stripe resta in attesa mentre il nostro
        backoffice provvederà ad aggiornare lo stato della tua subscription
      </div>
      <BasicButton
        loading={isLoading}
        className="w-56 py-1"
        onClick={registerStripe}
      >
        Attiva Stripe
      </BasicButton>
    </div>
  );
};
export default ActivateStripe;
