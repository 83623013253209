// docs: https://react-google-maps-api-docs.netlify.app/
import { MarkerClusterer,MarkerClustererProps } from "@react-google-maps/api";
import MarkerWithData, { Props as MarkerWithDataProps } from "./MarkerWithData";

const clusterIconStyles: MarkerClustererProps["styles"] = [
  {
    height: 60,
    width: 60,
    textColor: "white",
    fontFamily: "Poppins",
    textSize: 16,
    className: "hover:animate-wavepulse rounded-full hover:scale-150",
    url: "/assets/markers/clusters/m2.svg",
  },
];

export type Props<T> = {
  /** Arbitrary data structure T array, where `T` must contain the properties of the type `Coords` */
  dataArray: T[];
  /** Defines what happens when a cluster is clicked
   * @see https://react-google-maps-api-docs.netlify.app/#markerclusterer
   */
  onClusterClick?: MarkerClustererProps["onClick"];
  /** Callback to define what happens on click and to get the marker data*/
  onMarkerClick?: MarkerWithDataProps<T>["onClick"];

  /** Defines what happens when the user drags a marker. */
  onMarkerMove?: (event: google.maps.MapMouseEvent) => void;

  /** Defines what happens when the user hovers a marker. */
  onMarkerEnter?: MarkerWithDataProps<T>["onEnter"];
  /** Defines what happens when the user exits the area of a marker. */
  onMarkerLeave?: MarkerWithDataProps<T>["onLeave"];
  /** Callback to determine the icon to set on the marker, depending on its data */
  markerIconSelector?: (data: T) => string;
  /** The google.maps.Map instance where the cluster will be rendered*/
  map: google.maps.Map | null;
  /** The google.maps.StreetViewPanorama instance where markers will be rendered*/
  streetView?: google.maps.StreetViewPanorama | null;
  /** The size of the clusters and markers icon */
  iconSize?: number;
  selected?: T | null;
};
export function Clusterer<T extends Coords & MaybeHasId>({
  dataArray,
  map,
  iconSize = 60,
  streetView,
  onClusterClick = () => {},
  onMarkerClick = () => {},
  onMarkerEnter = () => {},
  onMarkerLeave = () => {},
  markerIconSelector = () => "",
  onMarkerMove,
  selected,
}: Props<T>) {
  if (clusterIconStyles) {
    clusterIconStyles[0].height = iconSize;
    clusterIconStyles[0].width = iconSize;
  }
  return (
    <>
      <MarkerClusterer
        averageCenter
        onClick={onClusterClick}
        zoomOnClick={true}
        imageExtension="svg"
        imagePath="/assets/markers/clusters/m"
        styles={clusterIconStyles}
        options={{ maxZoom: 16 }}
      >
        {(clusterer) => {
          // Overwrites bugged clusterer functions (the bug prevented them from rendering while zooming in/out)
          clusterer.onZoomChanged = () => {};
          clusterer.onIdle = () => {
            clusterer.repaint();
          };
          clusterer.repaint = function () {
            let oldClusters = this.clusters.slice();
            this.clusters = [];
            this.resetViewport(false);
            this.redraw();
            setTimeout(() => {
              for (let i = 0; i < oldClusters.length; i++) {
                oldClusters[i].remove();
              }
            }, 0);
          };
          return (
            <div key={"clusterer"}>
              {dataArray.map((data) => (
                <MarkerWithData
                  key={data.id ? data.id : data.lat + data.lng}
                  data={data}
                  position={data}
                  selected={selected}
                  icon={markerIconSelector(data)}
                  clusterer={clusterer}
                  onMarkerMove={onMarkerMove}
                  onClick={onMarkerClick}
                  onEnter={onMarkerEnter}
                  onLeave={onMarkerLeave}
                  streetView={streetView}
                  map={map}
                />
              ))}
            </div>
          );
        }}
      </MarkerClusterer>
    </>
  );
}
export default Clusterer;
