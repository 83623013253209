
import LoadingSpinner from "components/others/LoadingSpinner";
import TeamList from "components/lists/Team/Team";
import FixedSpaceContainer from "components/containers/FixedSpace";
import {teamSelectors} from "store/slices/team"
import {useSelector} from "react-redux"
import useMyTeam from "hooks/useMyTeam";
/** Shows mediaOwner team members */
export const Team = () => {
  const [myTeam] = useSelector(teamSelectors.myTeam)
  useMyTeam();
  if (myTeam===null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className=" fill-primary-light" />
      </div>
    );
  }
  return (
    <>
      <FixedSpaceContainer>
        <div className="flex justify-center w-full p-3 animate-down-up">
          <TeamList key="current" myTeam={myTeam} />
        </div>
      </FixedSpaceContainer>
    </>
  );
};
export default Team;
