import {
  InformationCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { batchSetPricing } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import TransparentButton from "components/buttons/Transparent";
import ClientsCondition from "./conditions/Clients";
import DateCondition from "./conditions/Date";
import TotalCondition from "./conditions/Total";
import ShowBillboards from "./ShowBillboards";
import PricingType from "./Type";
import Tooltip from "components/popovers/Tooltip";
import { Billboard, PricingInput } from "graphql/ssp/generated";
import useNotification from "hooks/useNotification";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { twMerge } from "tailwind-merge";

type Props = {
  billboards: Billboard[];
  close: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => void;
};
export const NewRule: React.FC<Props> = ({
  billboards,
  close,
  setIsLoading,
  refresh,
}) => {
  const [pricing, setPricing] = useState<PricingInput>({});
  const { toast } = useNotification();
  const [section, setSection] = useState<typeof sections[number][0]>("total");
  const [showInfo, setShowInfo] = useState(false);
  const [showBillboards, setShowBillboards] = useState(false);

  const dispatch = useDispatch();

  const sections = [
    ["total", "Spesa totale", TotalCondition],
    ["date", "Periodo", DateCondition],
    ["clients", "Clienti", ClientsCondition],
  ] as const;
  const conditions = {
    total: !!(pricing.condition_1 && pricing.amount_1),
    date: !!(pricing.from && pricing.to),
    clients: !!(pricing.customers && pricing.customers.length),
  };
  const disabledPercent = !pricing.percentage || !pricing.rule_type;

  const disabledConditions = !(
    conditions.total ||
    conditions.clients ||
    conditions.date
  );
  const validConditions = Object.values(conditions).reduce(
    (prev, bool) => prev + Number(bool),
    0
  );
  const createPricingRule = async () => {
    setIsLoading(true);
    await batchSetPricing(
      billboards as { id: string; city_code: string }[],
      pricing
    );
    toast({
      title: "Creazione regola di pricing",
      text: "Creazione regola di pricing avvenuta con successo",
      type: "SUCCESS",
    });
    refresh();
    dispatch(billboardsActions.willFetchBillboards());
    setIsLoading(false);
  };
  return (
    <>
      <ShowBillboards
        billboards={billboards}
        setShowBillboards={setShowBillboards}
        className={twMerge(!showBillboards && "hidden")}
      />
      <div
        className={twMerge("flex flex-col gap-1", showBillboards && "hidden")}
      >
        <div>
          <div className="flex items-center justify-between">
            <div
              onClick={() => setShowInfo((prev) => !prev)}
              className="flex items-center gap-2 text-gray-400 transition-all select-none hover:text-black"
              role="button"
            >
              <InformationCircleIcon className="h-5" />
              <ChevronDownIcon
                className={twMerge(
                  "h-4 -rotate-90 transition-all",
                  showInfo && "rotate-0"
                )}
              />
              {showInfo ? "Nascondi" : "Mostra"} informazioni
            </div>
            <div
              onClick={() => setShowBillboards(true)}
              className="flex items-center gap-2 text-gray-400 select-none hover:text-black"
              role="button"
            >
              Impianti selezionati ({billboards.length})
              <ChevronDownIcon className="h-4 transition-all -rotate-90" />
            </div>
          </div>
          <div
            className={twMerge(
              "max-h-32 overflow-hidden text-sm transition-all",
              !showInfo && "max-h-0"
            )}
          >
            <div>
              Segui la procedura guidata per impostare una regola di pricing
              sugli impianti selezionati. Ogni impianto può avere al massimo 1
              regola di pricing con un massimo di 3 condizioni.{" "}
            </div>
            <br />
            <div>
              <b>Attenzione</b>: Creando una regola di pricing su un gruppo di
              impianti, verranno eliminate eventuali altre regole applicate sui
              singoli impianti.
            </div>
          </div>
        </div>
        <div className="my-4 font-medium text-gray-500 text-start">
          Seleziona la percentuale di sconto/maggiorazione
        </div>
        <PricingType pricing={pricing} setPricing={setPricing} />
        <div className="my-4 mb-2 font-medium text-gray-500 text-start">
          Imposta da 1 a 3 condizioni{" "}
          <span className="text-sm text-gray-400">
            ({validConditions}{" "}
            {validConditions === 1
              ? "condizione selezionata"
              : "condizioni selezionate"}
            )
          </span>
        </div>
        <div className="flex items-center justify-center w-full pb-4">
          <div className="grid shrink-0 grid-cols-3 gap-1 rounded-lg border p-[2px]">
            {sections.map(([key, alias]) => {
              return (
                <TransparentButton
                  key={key}
                  onClick={() => setSection(key)}
                  className={twMerge(
                    " w-full rounded-lg py-0",
                    key === section &&
                      "bg-primary-light bg-opacity-90 text-white"
                  )}
                >
                  {alias}
                </TransparentButton>
              );
            })}
          </div>
        </div>
        <div className="p-4 border rounded-lg">
          {sections.map(([key, , Condition]) => {
            return (
              <div key={key} className={twMerge(section !== key && "hidden")}>
                <Condition pricing={pricing} setPricing={setPricing} />
              </div>
            );
          })}
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <BasicButton onClick={close} className="bg-gray-400 w-fit">
            Annulla
          </BasicButton>
          <Tooltip
            disabled={!(disabledPercent || disabledConditions)}
            tip={
              disabledPercent
                ? "Seleziona il tipo e la percentuale di sconto"
                : "Per creare una regola è necessario definire almeno una condizione"
            }
          >
            <BasicButton
              disabled={disabledPercent || disabledConditions}
              className="w-fit"
              onClick={createPricingRule}
            >
              Conferma Regola di pricing
            </BasicButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default NewRule;
