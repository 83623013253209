// import CalendarWeekSelector from "components/inputs/calendar";

export const Help:React.FC = () => {

  return (
    <div className="flex justify-center h-full animate-down-up">
      <div className="flex flex-col w-full max-w-3xl pt-10 mx-5 h-fit gap-y-10">
        {/* <CalendarWeekSelector /> */}
      </div>
    </div>
  );
};
export default Help;
