import LoadingSpinner from "components/others/LoadingSpinner";
import ClientsList from "components/lists/Clients";
import FixedSpaceContainer from "components/containers/FixedSpace";
import {clientsSelectors}from "store/slices/clients"
import {useSelector} from "react-redux"
import useMyClients from "hooks/useMyClients";

/** Shows mediaOwner clients **MOCKED** */
export const Clients = () => {
  const [myClients] = useSelector(clientsSelectors.myClients)
  useMyClients();
  if (myClients===null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className=" fill-primary-light" />
      </div>
    );
  }
  return (
    <>
      <FixedSpaceContainer>
        <div className="flex justify-center w-full p-3 animate-down-up">
          <ClientsList key="current" myClients={myClients} />
        </div>
      </FixedSpaceContainer>
    </>
  );
};
export default Clients;
