import BillboardsList from "components/lists/Billboards";
import BillboardsMiniList from "components/lists/Billboards/Mini";
import LoadingSpinner from "components/others/LoadingSpinner";
import BillboardCard from "components/cards/Billboard";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { NormalizedBillboard } from "utils/billboards/normalizeBillboards";
import { billboardsSelectors } from "store/slices/billboards";
import { useSelector } from "react-redux";
import useMyBillboards from "hooks/useMyBillboards";
import BillboardsMap from "components/maps/BillboardsMap";
import { useGmap } from "components/maps/GoogleMapComponent/hooks";
import {
  toStreetViewLocation,
  zoomToPlace,
} from "components/maps/GoogleMapComponent/utils";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

/** Shows user billboards */
export const MyBillboards = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [myBillboards] = useSelector(billboardsSelectors.myBillboards);
  useMyBillboards();
  const gmap = useGmap<NormalizedBillboard>();

  const [filteredBillboards, setFilteredBillboards] = useState<
    NormalizedBillboard[]
  >([]);

  const viewMode = searchParams.get("view");
  const mapView = viewMode === "map";
  const calendarView = viewMode === "calendar";
  const listView = viewMode === "list" || (!mapView && !calendarView);

  const selected = gmap.selected
    ? myBillboards?.find((b) => b.id === gmap.selected?.id)
    : undefined;
  if (myBillboards === null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className="fill-primary-light" />
      </div>
    );
  }
  return (
    <>
      {mapView && (
        <>
          {selected && (
            <BillboardCard
              ownerView
              zoomIn={(b) => zoomToPlace(b, gmap.map)}
              streetView={(b) => toStreetViewLocation(b, gmap.sview, gmap.pano)}
              onClose={() => {
                gmap.setSelected(undefined);
                if (gmap.pano) {
                  gmap.pano.setZoom(1);
                  gmap.pano.setVisible(false);
                }
              }}
              className="m-3 w-96 animate-down-up"
              data={selected}
            />
          )}
          <div
            className={twMerge(
              "m-3 mb-2  w-96 animate-down-up rounded-xl",
              selected && "hidden"
            )}
          >
            <BillboardsMiniList
              myBillboards={myBillboards}
              setHovered={(data) => {
                const assertNormalized = data as NormalizedBillboard;
                gmap.setHovered(assertNormalized);
              }}
              setFilteredBillboards={(b) =>
                setFilteredBillboards(b as NormalizedBillboard[])
              }
              onRowClick={(billboard) =>
                gmap.setSelected(billboard as NormalizedBillboard)
              }
            />
          </div>
          <div className="grow">
            <BillboardsMap
              showStreetView
              trafficLayerControl
              placesApi
              billboards={filteredBillboards}
              gmap={gmap}
              className="z-0 w-full h-full p-3 rounded-xl"
            />
          </div>
        </>
      )}
      {listView && (
        <div className="flex justify-center w-full p-3 animate-down-up bg-lighter-gray">
          {myBillboards.length ? (
            <BillboardsList
              myBillboards={myBillboards}
              onRowClick={(data) => {
                setSearchParams((params) => {
                  params.set("view", "map");
                  return params;
                });
                gmap.setSelected(data as NormalizedBillboard);
              }}
            />
          ) : (
            <div className="flex flex-col items-center p-4 max-w-[800px] text-center gap-3">
              <div className="text-2xl italic font-medium text-gray-500">
                Nessun impianto caricato.
              </div>
              <div className="text-lg font-medium text-gray-500">
                Per caricare un impianto manualmente, clicca sul bottone "
                <i className="text-gray-800">Nuovo</i>" nella barra superiore.
              </div>
              <div className="text-lg font-medium text-gray-500">
                Se sei munito di un file CSV rappresentante lo stato dei tuoi
                impianti, clicca sul bottone{" "}
                "<i className="inline-flex items-center gap-1 text-gray-800">
                  <ArrowUpTrayIcon className="w-4 stroke-2" />
                  CSV
                </i>", un operatore Billalo si occuperà del caricamento degli impianti.
              </div>
            </div>
          )}
        </div>
      )}
      {/* TODO: Make the calendar component */}
      {calendarView && (
        <div className="flex max-w-[900px] animate-down-up justify-center bg-lighter-gray p-3">
          <BillboardsMiniList className="w-3/6" myBillboards={myBillboards} />
          <div className="p-10 text-2xl font-bold text-center text-gray-400 grow">
            Calendar here
          </div>
        </div>
      )}
    </>
  );
};
export default MyBillboards;
