import BillaloLogo from "components/others/BillaloLogo";
import LoadingSpinner from "components/others/LoadingSpinner";

export const Loading:React.FC = () => {
  return (
    <div
      className={
        "flex h-screen max-h-screen w-screen flex-col items-center justify-center gap-3 bg-lighter-gray"
      }
    >
      <BillaloLogo />
      <LoadingSpinner className="fill-primary"/>
    </div>
  );
};
export default Loading;
