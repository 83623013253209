import { BillboardInput, Taxes } from "graphql/ssp/generated";
import { inputCol } from "pages/BatchUpload/validations";

export type CsvBillboardInput = Partial<
  BillboardInput & Taxes
>;
export const generateInput = (csvItems: Record<string, string>[]) => {
  return csvItems.map((item) => {
    const base = { ...baseInput };
    for (let key in base) {
      base[key as keyof typeof base] = item[key];
    }
    return base;
  });
};

/** Reverses the position of key and values */
export const flipEntries = (
  map: Record<string, string>
): Record<string, string> =>
  Object.fromEntries(Object.entries(map).map(([key, value]) => [value, key]));

/** Takes in input a csv string and returns it as an object list (where the parameters are the column titles)*/
export const listCsvItems = ([headers, ...items]: string[][]): Record<
  string,
  string
>[] => {
  return items.map((values) =>
    Object.fromEntries(values.map((v, i) => [headers[i], v]))
  ) satisfies Record<string, string>[];
};

/** Checks non-intersecting values of two lists */
export const exclusion = (a: string[], b: string[]) => {
  const excludedA = a.filter((v) => !b.includes(v));
  const excludedB = b.filter((v) => !a.includes(v));

  return [excludedA, excludedB];
};

export const baseInput: CsvBillboardInput = {
  billboard_class: undefined,
  media_type: undefined,
  content_duration: undefined,
  content_rotation: undefined,
  press_type: undefined,
  facade: undefined,
  height_ground: undefined,
  cod_cimasa: undefined,
  price: undefined,
  min_period: undefined,
  orientation: undefined,
  lighting: undefined,
  base: undefined,
  height: undefined,
  latitude: undefined,
  longitude: undefined,
  address: undefined,
  municipality: undefined,
  location: undefined,
  maintenance_status: undefined,
  last_maintenance: undefined,
  municipal_tax: undefined,
  last_payment: undefined,
  n_authorization: undefined,
  authorization_expiration: undefined,
  notes: undefined,
};

export const inputKeys = Object.keys(inputCol) as (keyof typeof inputCol)[];
