/** Returns a bidiminesional array of rows and string values */
export const textToCsvArray = (text: string) => {
  const commaRegex = /,(?=(?:[^"]*"[^"]*")*(?![^"]*"))/;
  return text.split("\r\n").map((t) =>
    t.split(commaRegex).map((val) => {
      let value = String(val);
      try {
        value = JSON.parse(value);
      } finally {
        return value;
      }
    })
  );
};

export default textToCsvArray;
