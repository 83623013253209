import { useEffect, useState } from "react";

type Params = {
  /** Duration expressend in seconds */
  duration: number;
  paused?: boolean;
  tick?: number;
};

export const useTimer = ({ duration, paused, tick = 100 }: Params) => {
  const [progress, setProgress] = useState(0);
  const computedTick = (tick / (duration * 1000)) * 100;
  useEffect(() => {
    if (paused || progress >= 100) return;
    setTimeout(() => setProgress(progress + computedTick), tick);
  }, [progress,paused,computedTick,tick]);
  return progress;
};
