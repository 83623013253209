
import Billboards from "./Billboards"
import Explore from "./Explore"
import MyBillboards from "./MyBillboards"
import PricingRules from "./PricingRules"

const pages = {
  Main: Billboards,
  MyBillboards,
  Explore,
  PricingRules,
};
export default pages