import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  // Billboard,
  MutationAddBillboardArgs,
  MutationDeleteBillboardArgs,
  MutationEditBillboardArgs,
} from "graphql/ssp/generated";
import { RootState } from "store/reducers";
import { NormalizedBillboard } from "utils/billboards/normalizeBillboards";
// import { RootState } from "store/reducers";

const initialState = {
  myBillboards: [null, "idle"] as DataWithState<NormalizedBillboard[]>,
  allBillboards: [null, "idle"] as DataWithState<NormalizedBillboard[]>,
};
// type UserState = typeof initialState;
export const billboardsSlice = createSlice({
  name: "billboards",
  initialState,
  reducers: {
    willFetchBillboards:(state)=>{
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
    didFetchBillboards:(state,{payload}:PayloadAction<NormalizedBillboard[]>)=>{
      state.myBillboards = [payload, "idle"];
      return state;
    },
    willFetchAllBillboards:(state)=>{
      state.allBillboards = [state.allBillboards[0], "pending"];
      return state;
    },
    didFetchAllBillboards:(state,{payload}:PayloadAction<NormalizedBillboard[]>)=>{
      state.allBillboards = [payload, "idle"];
      return state;
    },
    willAddBillboard: (state, _payload: PayloadAction<MutationAddBillboardArgs>) => {
      console.log("AddBillboard payload: ",{_payload})
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
    willEditBillboard: (state, _payload: PayloadAction<MutationEditBillboardArgs>) => {
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
    willBatchEditBillboards: (state, _payload: PayloadAction<MutationEditBillboardArgs[]>) => {
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
    willDeleteBillboard: (
      state,
      _payload: PayloadAction<MutationDeleteBillboardArgs>
    ) => {
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
    willBatchDeleteBillboards: (
      state,
      _payload: PayloadAction<MutationDeleteBillboardArgs[]>
    ) => {
      state.myBillboards = [state.myBillboards[0], "pending"];
      return state;
    },
  },
});

export const billboardsSelectors = {
  myBillboards: (state: RootState) => state.billboards.myBillboards,
  allBillboards: (state: RootState) => state.billboards.allBillboards,
};

const { actions, reducer } = billboardsSlice;
export { reducer, actions as billboardsActions };
