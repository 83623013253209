import CampaignsActionsManager from "./Actions";
import Table from "components/lists/table";
import { Campaign } from "graphql/dsp/generated";
import { useNavigate } from "react-router-dom";
import { campaignF, campaignS } from "utils/formatters";
import { useState } from "react";
import ActionsButton from "components/buttons/Actions";

type Props = {
  myCampaigns: Campaign[];
};

export const CampaignsList = ({ myCampaigns }: Props) => {
  const navigate = useNavigate();
  const [currentAction, setCurrentAction] = useState<null | [string, Campaign]>(
    null
  );
  return (
    <>
      <Table
        noSelect
        data={myCampaigns}
        className="w-[840px]"
        onRowClick={(campaign) =>
          navigate(
            "/campaigns/details/" + campaign.advertiser + "/" + campaign.id
          )
        }
        rowActions={[
          [
            (campaign) =>
              // The only actions manually available to the user is cancel (when `confirmed`) and put_materials (when `paid`)
              // as of 2023/04/07
              ["paid", "confirmed"].includes(String(campaign?.status)) && (
                <ActionsButton
                  data={campaign || {}}
                  actions={[
                    [
                      <span>Modifica stato</span>,
                      (data) => setCurrentAction(["update", data]),
                    ],
                  ]}
                />
              ),
          ],
        ]}
        cols={{
          status: ["Stato", campaignF["status"]],
          name: ["Nome Campagna"],
          from: ["Inizio/Fine", campaignF["from"]],
          orders: ["Concessionarie", campaignF["orders"], campaignS["orders"]],
          print_amount: [
            "Spesa totale",
            campaignF["print_amount"],
            campaignS["print_amount"],
          ],
        }}
      />
      <CampaignsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
export default CampaignsList;
