import { clientsSelectors, clientsActions } from "store/slices/clients";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export const useMyClients = () => {
  const [clients, status] = useSelector(clientsSelectors.myClients);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clients === null && status === "idle") {
      console.log("No clients in store, fetching...");
      dispatch(clientsActions.willFetchClients());
    }
  }, [clients, status, dispatch]);
  return;
};
export default useMyClients;
