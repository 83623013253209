import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Order } from "graphql/dsp/generated";

type Props = {
  orders: Order[] | null | undefined;
};
export const BillboardsStatusList: React.FC<Props> = ({ orders }) => {
  const [confirmedBillboards, rejectedBillboards] = orders?.reduce(
    ([confirmed, rejected], curr) => {
      const billboards = curr.billboards?.length || 0;
      return ["rejected", "canceled"].includes("" + curr?.status)
        ? [confirmed, rejected + billboards]
        : [confirmed + billboards, rejected];
    },
    [0, 0]
  ) || [0, 0];
  const statuses = [
    {
      Icon: CheckCircleIcon,
      iconClass: "text-green-400",
      text: "Impianti confermati",
      value: confirmedBillboards,
    },
    {
      Icon: XCircleIcon,
      iconClass: "text-red-400",
      text: "Impianti rifiutati",
      value: rejectedBillboards,
    },
  ];
  return (
    <div className="flex flex-col w-full">
      {statuses.map(({ Icon, iconClass, text, value }, i) => {
        return (
          <div className="flex justify-between py-3 border-b" key={i}>
            <div className="flex items-center gap-2">
              <Icon className={"h-5 " + iconClass} />
              <div className="text-gray-500">{text}</div>
            </div>
            <div>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default BillboardsStatusList;
