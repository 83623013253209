import BasicContainer from "components/containers/Basic";
import MutableField from "components/inputs/MutableField";
import { Advertiser } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import EditApply from "components/buttons/EditApply";
import ImageDivInput from "components/inputs/ImageDiv";
import { uploadImage } from "api/gql/ssp";

export const Business = () => {
  const placeholder = "/assets/images/placeholder_profile.png";
  const dispatch = useDispatch();
  const [advertiser, advertiserStatus] = useSelector(userSelectors.advertiser);
  const [advertiserData, setAdvertiserData] = useState({} as Advertiser);

  const [editProfile, setEditProfile] = useState(false);
  const [editRegistry, setEditRegistry] = useState(false);

  type UserFieldArray = [keyof Advertiser, string, string];

  const registryData: UserFieldArray[] = [
    ["p_iva", "P.IVA", "auto"],
    ["legal_address", "Sede legale", "street-address"],
    ["recipient_code", "Codice destinatario", "auto"],
    ["email", "Email", "email"],
    ["fiscal_code", "Codice fiscale", "auto"],
    ["pec", "PEC", "email"],
    ["society_form", "Forma societaria", "organization"],
    ["phone", "Telefono", "tel"],
  ];

  /** Updates the Advertiser structure with new data */
  const editAdvertiser = (flag: boolean, updated?: typeof advertiser) => {
    const updatedAdvertiser = updated || advertiserData;
    if (!(flag && advertiser)) return;
    const { advertiser: _1, ...oldUser } = advertiser;
    const { advertiser: _2, ...updatedUser } = updatedAdvertiser;
    dispatch(
      userActions.willAddAdvertiser({
        ...oldUser,
        ...updatedUser,
      })
    );
  };
  const [image, setImage] = useState<File | null>(null);
  useEffect(() => {
    if (!advertiser && advertiserStatus === "idle") {
      dispatch(userActions.willGetAdvertiser());
    }
  }, [advertiser, dispatch, advertiserStatus]);

  return (
    <>
      <BasicContainer className="w-full animate-down-up flex-row overflow-visible p-5">
        <ImageDivInput
          image={image}
          setImage={setImage}
          className="m-4 mr-12 h-24 w-24 shrink-0 translate-x-1/4 -translate-y-[40%] scale-[1.7] rounded-xl border-4 border-lighter-gray"
          src={advertiser?.image}
          placeholder={placeholder}
          alt="Business pic/logo"
          withModal
          onImageChange={(image) => {
            if (!advertiser?.advertiser) return;
            const imageId = `pfp_business_${advertiser.advertiser}`;
            uploadImage(image, imageId).then((url) => {
              if (!url) return;
              const updatedAdvertiserData = { ...advertiserData, image: url };
              setAdvertiserData(updatedAdvertiserData);
              editAdvertiser(true, updatedAdvertiserData);
            });
          }}
        />
        <div className="flex w-full flex-col">
          <div className="mb-8 flex items-center justify-between">
            <div>
              <MutableField
                setter={setAdvertiserData}
                state={advertiser}
                stateKey="name"
                switchInput={editProfile}
                autoComplete={"organization"}
                className="text-2xl font-bold"
              />
              <div className="flex select-all text-base text-gray-400">
                <div className="select-none">@</div>
                <div>{advertiser?.advertiser}</div>
              </div>
            </div>
            <EditApply
              flag={editProfile}
              setter={setEditProfile}
              pending={advertiserStatus === "pending"}
              onClick={(flag) => editAdvertiser(flag)}
            />
          </div>
          <div className="grid grid-cols-[auto_auto_auto_auto] gap-2">
            {/* <div className="text-gray-600 ">Spazi acquistati</div>
              <div>xx</div> */}
            <div className="text-gray-600 ">Piano di abbonamento</div>
            <div>{advertiser?.plan}</div>
            <div className="text-gray-600 ">Iscritto dal</div>
            <div>
              <i>{advertiser?.subscription_date || "-"}</i>
            </div>
            <div className="text-gray-600 ">Livello Fee</div>
            <div>{advertiser?.fees}</div>
          </div>
        </div>
      </BasicContainer>

      <BasicContainer className="w-full flex-col p-5">
        <div className="flex w-full items-start justify-between">
          <div className="font-semibold">Dati fatturazione</div>
          <EditApply
            flag={editRegistry}
            setter={setEditRegistry}
            pending={advertiserStatus === "pending"}
            onClick={(flag) => editAdvertiser(flag)}
          />
        </div>
        <div className="grid w-full grid-cols-[auto_auto_auto_auto] gap-2">
          {registryData.map(([stateKey, label, autoComplete], i) => (
            <MutableField
              key={i}
              title={advertiser?.[stateKey]}
              setter={setAdvertiserData}
              state={advertiser}
              stateKey={stateKey}
              switchInput={editRegistry}
              label={label}
              autoComplete={autoComplete}
            />
          ))}
        </div>
      </BasicContainer>
    </>
  );
};
export default Business;
