import { BriefcaseIcon } from "@heroicons/react/24/outline";
import ProgressCircle from "components/others/ProgressCircle";
import { twMerge } from "tailwind-merge";
type Props<T, M> = {
  color?: string;
  Icon?: React.FC;
  amount: T;
  amountMax: M;
  amountText?: (amount: T, amountMax: M) => string;
  location?: string;
  className?: string;
  children?: React.ReactNode;
};

/** Displays the billboard Visibility indexes */
export function IndexCard<T extends number, M extends number>({
  Icon = BriefcaseIcon,
  color = "#0062FF",
  amount,
  amountMax,
  location = "Comune",
  className = "",
  amountText = (n) => String(n),
  children,
}: Props<T, M>) {
  return (
    <div
      className={twMerge(
        "flex items-center gap-3 rounded-xl bg-lighter-gray p-2",
        className
      )}
    >
      <ProgressCircle
        size={60}
        amount={amount / amountMax || 0}
        strokeWidth={4}
        color={color}
        Icon={Icon}
      />
      <div className="flex flex-col justify-center">
        <b className="text-xl">
          {amountText(amount, amountMax) || amount.toFixed(2)}
        </b>
        {children || (
          <span className="text-sm text-gray-400">
            <span className="text-xs">Indice visibilità:</span>
            <br /> <span>{location}</span>
          </span>
        )}
      </div>
    </div>
  );
}
export default IndexCard;
