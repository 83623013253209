import { CsvBillboardInput } from "pages/BatchUpload/utils";
import billboardOptions from "utils/formatters/billboardOptions";
import municipalities from "utils/location/municipalities.json";

const rA = (a: ReadonlyArray<string>) => a as ReadonlyArray<string>;
const isOutdoor = (obj: CsvBillboardInput | undefined) =>
  Number(obj?.billboard_class === "Outdoor");

export const inputCol: ValidatorCol<CsvBillboardInput> = {
  billboard_class: [
    "Classe",
    (v) => [!!v && rA(billboardOptions["billboard_class"]).includes(v), ""],
  ],
  media_type: [
    "Tipo",
    (v, obj) => {
      if (!v) return [false, "Il campo è requisito"];
      const isValid = rA(
        billboardOptions["media_type"][
          Number(obj?.billboard_class === "Outdoor")
        ]
      ).includes(v);
      if (!isValid)
        return [false, "Input non compreso tra i valori accettabili"];
      return [true];
    },
  ],
  content_duration: [
    "Durata (Display_digitale)",
    (v, obj) => {
      if (obj?.media_type !== "Display_digitale") {
        return [!String(v).length, ""];
      }
      const num = Number(v);
      if (num >= 5 && num <= 20) return [true, ""];
      return [false, "Valore non valido"];
    },
  ],
  content_rotation: [
    "Rotazione (Display_digitale)",
    (v, obj) => {
      if (obj?.media_type !== "Display_digitale") {
        return [!String(v).length];
      }
      const num = Number(v);
      if ([15, 20, 30, 40, 60].includes(num)) return [true, ""];
      return [false, "Valore non valido"];
    },
  ],
  press_type: [
    "Materiale",
    (v, obj) => {
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["press_type"][isOutdoor(obj)]).includes(
        v
      );
      return valid
        ? [true]
        : [false, "Input non compreso tra i valori accettabili"];
    },
  ],
  facade: [
    "Facciate",
    (v, obj) => {
      if (obj?.media_type === "Display_digitale") {
        if (!v) return [true];
        return [
          false,
          "La proprietà 'Facciate' non è assegnabile ad un Display Digitale",
        ];
      }
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["facade"]).includes(v);
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  height_ground: [
    "Altezza da terra",
    (v) => {
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["height_ground"]).includes(v);
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  cod_cimasa: ["Codice cimasa", (v) => [true, "(Campo non requisito)"]],
  price: [
    "Prezzo",
    (v) => {
      const num = Number(v);
      if (!num || num < 0) return [false, "Valore non valido"];
      return [true];
    },
  ],
  min_period: [
    "Uscita min",
    (v) => {
      const num = Number(v);
      const valid = !!(billboardOptions["min_period"] as any).find(
        ([_a, n]: [string, number]) => n === num
      );
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  orientation: [
    "Orientamento",
    (v) => {
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["orientation"]).includes(v);
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  lighting: [
    "Luminosità",
    (v) => {
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["lighting"]).includes(v);
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  base: [
    "Base",
    (v) => {
      const num = Number(v);
      if (!num) return [false, "Valore non valido"];
      return [true];
    },
  ],
  height: [
    "Altezza",
    (v) => {
      const num = Number(v);
      if (!num) return [false, "Valore non valido"];
      return [true];
    },
  ],
  latitude: [
    "Latitudine",
    (v) => {
      const val = Number(v);
      if (!val) return [false, "Valore non valido"];
      const valid = val > ITALY_BOUNDS.south && val < ITALY_BOUNDS.north;
      if (!valid)
        return [false, "Coordinata non compresa nel territorio italiano"];
      return [true];
    },
  ],
  longitude: [
    "Longitudine",
    (v) => {
      const val = Number(v);
      if (!val) return [false, "Valore non valido"];
      const valid = val > ITALY_BOUNDS.west && val < ITALY_BOUNDS.east;
      if (!valid)
        return [false, "Coordinata non compresa nel territorio italiano"];
      return [true];
    },
  ],
  address: [
    "Indirizzo",
    (v) => {
      if (!v) return [false, "Il campo è requisito"];
      return [true];
    },
  ],
  municipality: [
    "Comune",
    (v) => {
      const found = !!municipalities.find(
        ([m]) => m === String(v).toLowerCase()
      );
      if (!found) return [false, "Valore non valido"];
      return [true];
    },
  ],
  location: [
    "Ubicazione",
    (v, obj) => {
      if (!v) return [false, "Il campo è requisito"];
      const valid = rA(billboardOptions["location"][isOutdoor(obj)]).includes(
        v
      );
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  maintenance_status: [
    "Stato manutenzione",
    (v) => {
      const val = String(v);
      if (val === "") return [true];
      const valid = rA(billboardOptions["maintenance_status"]).includes(val);
      if (!valid) return [false, "Valore non valido"];
      return [true];
    },
  ],
  last_maintenance: [
    "Ultima manutenzione",
    (v) => {
      return [!!new Date(String(v)).valueOf() || !v, ""];
    },
  ],
  municipal_tax: [
    "Tributi - importo",
    (v) => {
      return [String(v) === "" || Number(v) > 0, ""];
    },
  ],
  last_payment: [
    "Tributi - ultimo pagamento",
    (v) => {
      return [!!new Date(String(v)).valueOf() || !v, ""];
    },
  ],
  n_authorization: ["N. Autorizzazione", () => [true, ""]],
  authorization_expiration: [
    "Scadenza Autorizzazione",
    (v) => [!!new Date(String(v)).valueOf() || !v, ""],
  ],
  notes: ["Descrizione", () => [true, ""]],
};
const ITALY_BOUNDS = {
  west: 6.2499552751,
  south: 36.319987291,
  east: 18.9802470232,
  north: 47.2153931748,
};
