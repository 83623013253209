import { createCampaign } from "api/gql/dsp";
import {
  Billboard,
  BillboardInput,
  CreateCampaignInput,
  FilterInput,
  FilterPriceInput,
} from "graphql/dsp/generated";
import { getMondays, getWeekNumbersInRange } from "utils/dateFormatter";

type CreateCampaignArguments = {
  billboards: Billboard[];
  filters: FilterInput;
  campaignId: string;
  campaignName: string;
  printer?: boolean;
};

/** Creates a campaign, it is separated because most of this should be backend logic */

export const startCampaignCreation = async ({
  billboards, // Selected billboards
  filters,
  campaignId,
  campaignName,
  printer,
}: CreateCampaignArguments) => {
  let styles = "color:#d6ba06;font-weight:semibold;";
  let styles2 = "color:#b7d3ff;font-weight:semibold;";

  console.log("%cCreating campaign...", styles);
  const start_busy_dates = getMondays(
    new Date(filters.from),
    new Date(filters.to)
  ).map((d) => d.toISOString().slice(0, 10));

  // Generazione dell'input da mandare a setCampaignDiscount
  const campaignDiscountInput = createDiscountInput(billboards, filters);
  console.log("%c`setCampaignDiscount` inputs:", styles2, {
    campaignDiscountInput,
  });
  const createCampaignRes = await createCampaign({
    billboards: billboards.map(toInput),
    campaignDiscountInput,
    campaignId,
    campaignName,
    filters,
    input: campaignDiscountInput.input,
    printer: String(printer || false),
    start_busy_dates,
  });
  console.log({ createCampaignRes });
  return createCampaignRes;
};

export const createDiscountInput = (
  billboards: Billboard[],
  filters: FilterInput
): CreateCampaignInput => {
  const priceFilter: FilterPriceInput = {
    city: filters.city,
    period: {
      date: {
        from: filters.from,
        to: filters.to,
      },
      year: new Date(filters.from).getFullYear(),
      uscite: getWeekNumbersInRange(
        new Date(filters.from),
        new Date(filters.to)
      ),
    },
  };
  const discountedBillboards = billboards.filter((b) => !!b.pricing_id);
  const campaignDiscountInput = {
    filter: priceFilter,
    impianti_sconto: discountedBillboards.map(toInput),
    input: discountedBillboards.map((b) => ({
      media_owner: b.media_owner,
      pricing_id: b.pricing_id,
    })),
    plants: billboards.map(toInput),
  };
  return campaignDiscountInput;
};

/** Makes sure a billboard has only the Graphql BillboardInput fields */
const toInput = (billboard: Billboard): BillboardInput => {
  return {
    id: billboard.id,
    address: billboard.address,
    authorization_expiration: billboard.authorization_expiration,
    base: billboard.base,
    billalo_code: billboard.billalo_code,
    billalo_index: billboard.billalo_index,
    billboard_class: billboard.billboard_class,
    calendar: billboard.calendar,
    city_code: billboard.city_code,
    cod_cimasa: billboard.cod_cimasa,
    content_duration: billboard.content_duration,
    content_rotation: billboard.content_rotation,
    creation_date: billboard.creation_date,
    facade: billboard.facade,
    format: billboard.format,
    height: billboard.height,
    height_ground: billboard.height_ground,
    iV_municipality: billboard.iV_municipality,
    iV_normalized: billboard.iV_normalized,
    imageUrl: billboard.imageUrl,
    indice_visibilita: billboard.indice_visibilita,
    is_draft: billboard.is_draft,
    last_maintenance: billboard.last_maintenance,
    latitude: billboard.latitude,
    lighting: billboard.lighting,
    location: billboard.location,
    location_name: billboard.location_name,
    longitude: billboard.longitude,
    media_owner: billboard.media_owner,
    media_owner_type: billboard.media_owner_type,
    media_type: billboard.media_type,
    min_period: billboard.min_period,
    municipality: billboard.municipality,
    n_authorization: billboard.n_authorization,
    n_sheet: billboard.n_sheet,
    notes: billboard.notes,
    orientation: billboard.orientation,
    other_location: billboard.other_location,
    panel_index: billboard.panel_index,
    press_type: billboard.press_type,
    price: billboard.price,
    pricing_id: billboard.pricing_id,
    property_type: billboard.property_type,
    size: billboard.size,
    taxes: billboard.taxes,
    visible: billboard.visible,
  };
};
