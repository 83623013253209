import Tooltip from "components/popovers/Tooltip";
import { twMerge } from "tailwind-merge";

type LBProps = {
  src: string;
  tip?: string;
  toggled?: boolean;
  onClick?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["onClick"];
};
const LayerButton: React.FC<LBProps> = ({ toggled, src, onClick, tip }) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className={twMerge(
        "rounded-lg opacity-60  hover:opacity-75",
        toggled && "opacity-100 outline outline-2 outline-primary/50"
      )}
    >
      <Tooltip tip={tip} placement="top">
        <img src={src} alt="Layer Icon" />
      </Tooltip>
    </div>
  );
};
type Props = {
  toggleTraffic:()=>void;
  traffic?:boolean;
};
export const LayerToggler: React.FC<Props> = ({
  toggleTraffic,
  traffic,
}) => {
  return (
    <div className="absolute z-10 flex flex-col w-16 gap-2 p-1 bg-white rounded-xl top-7 left-7">
      <LayerButton
        onClick={toggleTraffic}
        src="/assets/images/ic_traffic-2x.png"
        toggled={traffic}
        tip={"Visualizza traffico"}
      />
    </div>
  );
};
