import Select from "components/inputs/Select";
type Props<D, T extends {}> = {
  setValue: React.Dispatch<React.SetStateAction<D>>;
  value: D;
  // Array of titles and keys
  cols: Col<Partial<T>>;
  additionalSorting?: Col<Partial<T>>;
};

export function SortBy<D, T extends {}>({
  setValue,
  cols,
  additionalSorting,
  value,
}: Props<D, T>) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-sm text-gray-400">Ordina per:</span>
      <Select
        onChange={(e) => {
          setValue(e.target.value as D);
        }}
        value={String(value)}
        className="w-fit max-w-[120px] text-ellipsis"
      >
        {Object.entries<any>(cols)
          .concat(additionalSorting ? Object.entries(additionalSorting) : [])
          .map(([field,[alias]], i) => {
            return (
              <option key={i} value={field}>
                {alias}
              </option>
            );
          })}
      </Select>
    </div>
  );
}

export default SortBy;
