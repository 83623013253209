
import { guestBatchGetOrders, guestGetAdvertiserCampaign } from "api/gql/guest";
import { Billboard } from "graphql/dsp/generated";
import CampaignDetails from "pages/Campaigns/Details";
import { normalizeBillboards } from "utils/billboards/normalizeBillboards";
// Pippo_competithor 0c0b3736-1102-4d82-97ab-1e95ac35f537

// http://localhost:3000/guest/Pippo_competithor/0c0b3736-1102-4d82-97ab-1e95ac35f537
export const Main: React.FC = () => {
  const fetchCampaignData = async (id: string, adv: string) => {
    const campaign = await guestGetAdvertiserCampaign(id, adv);
    const orderIds = (campaign.orders || []).filter((o): o is string => !!o);
    const orders = await guestBatchGetOrders(id, orderIds);
    const billboards =
      orders
        ?.map((o) =>
          o.billboards ? normalizeBillboards(o.billboards as Billboard[]) : []
        )
        .flat()
        .filter((b) => !!b) || [];
    return {
      campaign,
      orders,
      billboards,
    };
  };
  return <CampaignDetails fetchData={fetchCampaignData} />;
};

export default Main;
