import Advertiser from "./Advertiser";
import MediaOwner from "./MediaOwner";
import BusinessName from "./BusinessName";
import BackofficeApproval from "./BackofficeApproval";

const pages = {
    Advertiser,
    MediaOwner,
    BusinessName,
   BackofficeApproval,
};
export default pages;
