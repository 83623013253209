import { EyeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Tooltip from "components/popovers/Tooltip";
import { FilterInput } from "graphql/dsp/generated";
import { useState } from "react";
import notNullUndef from "types/notNullUndef";

type Props<K extends keyof FilterInput> = {
  Icon: typeof EyeIcon;
  text?: string;
  filterKey: K;
  setFilterInput?: React.Dispatch<React.SetStateAction<FilterInput>>;
  filterInput: FilterInput;
  parser?: (val: FilterInput[K], all: FilterInput) => React.ReactNode;
  remove?: React.SetStateAction<FilterInput>;
};
export type TagType<K extends keyof FilterInput> = [
  K,
  typeof EyeIcon,
  string,
  ((val: FilterInput[K], all: FilterInput) => React.ReactNode)?,
  React.SetStateAction<FilterInput>?
];
// string | number | null | undefined | InputMaybe<string>[];
export function FilterTag<K extends keyof FilterInput>({
  Icon,
  text,
  filterKey,
  filterInput,
  setFilterInput,
  parser = (val) => val,
  remove,
}: Props<K>) {
  const [isOver, setIsOver] = useState(false);

  if (!notNullUndef(filterInput[filterKey])) return <></>;
  const value = parser(filterInput[filterKey], filterInput);
  if (!notNullUndef(value)) return <></>;

  const removeFilter = () => {
    setFilterInput?.(
      remove
        ? remove
        : (prev) => {
            delete prev[filterKey];
            return { ...prev };
          }
    );
    setIsOver(false);
  };
  return (
    <div
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      role="button"
      className="flex items-center gap-1 rounded border border-gray-200 bg-gray-100 p-1 text-sm"
    >
      <div className="h-7">
        {setFilterInput && isOver ? (
          <Tooltip tip="Rimuovi Filtro" className="h-full">
            <XMarkIcon
              onClick={() => removeFilter()}
              className="h-full rounded bg-gray-400 stroke-2 p-1 text-white"
            />
          </Tooltip>
        ) : (
          <Icon className="h-full rounded bg-white stroke-2 p-1" />
        )}
      </div>{" "}
      {text && <span className="text-gray-500 ">{text}</span>}{" "}
      {typeof value === "object" ? (
        value
      ) : (
        <code className="text-base font-semibold">{value}</code>
      )}
    </div>
  );
}
export default FilterTag;
