import { getWeekBounds } from "components/inputs/calendar/utils";

/** Returns from, to dates */
export const randomDateRange = () => {
  /** A random day in the future */
  const randomDay = new Date();
  randomDay.setDate((randomDay.getDate() + Math.random() * 100) | 0);
  return getWeekBounds(randomDay);
};
export const randomStatus = () => {
  return statuses[(Math.random() * statuses.length) | 0];
};

export const statuses = ["MAINTENANCE", "UNAVAILABLE", "BOOKED"] as const;
export const fixStatus = (
  status: string | null | undefined,
  isMaintenance: boolean | null | undefined,
  advertiser: string | null | undefined
): (typeof statuses)[number] => {
  if (advertiser) return "BOOKED";
  if (!status && isMaintenance) return "MAINTENANCE";
  return "UNAVAILABLE";
};
export const formatStatus = (status: (typeof statuses)[number]) => {
  switch (status) {
    case "MAINTENANCE":
      return ["bg-yellow-400", "Impianto in manutenzione"];
    case "UNAVAILABLE":
      return ["bg-red-400", "Impianto non disponibile"];
    case "BOOKED":
      return ["bg-blue-400", "Impianto prenotato"];
    default:
      console.log(`Unrecognized status "${status}"`);
      return ["bg-gray-400", String(status)];
  }
};
export const monthsBounds = (date: Date, amount: number = 1) => {
  const dateStart = new Date(date);
  const dateStop = new Date(date);
  dateStop.setMonth(dateStop.getMonth() + amount);
  return [dateStart, dateStop];
};
export const months = [
  "gen",
  "feb",
  "mar",
  "apr",
  "mag",
  "giu",
  "lug",
  "ago",
  "set",
  "ott",
  "nov",
  "dic",
] as const;
