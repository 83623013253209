import Planning from "./Planning";
import Interactions from "./Interactions";
import Advanced from "./Advanced";
import Popularity from "./Popularity";

const pages = {
  Main: Planning,
  Interactions,
  Advanced,
  Popularity,
};
export default pages;
