import Tooltip from "components/popovers/Tooltip";
import { twMerge } from "tailwind-merge";

export const LeftNavButton: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { active: boolean; required?: boolean }
> = ({ active, className, children, required, ...attributes }) => {
  return (
    <div
      role="button"
      className={twMerge(
        "relative flex h-full min-h-[1.5rem] select-none flex-row items-center justify-center font-semibold text-primary hover:text-primary-dark",
        !active && "text-gray-700",
        className
      )}
      {...attributes}
    >
      <div
        className={twMerge(
          "absolute left-0 m-auto w-0 rounded-r-lg bg-primary transition-all",
          active ? "h-full w-1" : "h-0"
        )}
      />
      <div className="px-2" />
      {children}
      {required && (
        <Tooltip tip="Obbligatorio">
          <span className="px-1 font-bold text-primary">*</span>
        </Tooltip>
      )}
    </div>
  );
};
export default LeftNavButton;
