import Tooltip from "components/popovers/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {  getCachedOrders } from "./campaign";
import { Campaign } from "graphql/dsp/generated";
import { useEffect, useState } from "react";

/** Checks whether the order status is about to start and not yet on the "delivered materials" state */
const getRejectedOrders = async (campaign: Partial<Campaign> | undefined) => {
  if (!campaign?.id || !campaign.orders) return 0;
  const orders = await getCachedOrders(
    campaign.id,
    campaign.orders.filter((id): id is string => id !== null)
  );
  if (!orders) return 0;
  const rejectStates = ["rejected", "pass", "canceled"];
  const rejectedOrders = orders.filter((o) =>
    rejectStates.includes(String(o.status))
  );
  return rejectedOrders.length;
};
type Props = {
  campaign: Partial<Campaign> | undefined;
};
export const CampaignOrdersTip: React.FC<Props> = ({ campaign }) => {
  const [rejectedOrders, setRejectedOrders] = useState(0);
  const totalOrders = campaign?.orders?.length || 0;
  useEffect(() => {
    getRejectedOrders(campaign).then((res) => setRejectedOrders(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex items-center justify-center gap-1">
      <div className="w-20 text-center">
        <code className="font-bold">{totalOrders - rejectedOrders}/{totalOrders}</code>
      </div>
      {!!rejectedOrders && <InfoTip />}
    </div>
  );
};
const InfoTip: React.FC = () => {
  return (
    <Tooltip
      placement="bottom-end"
      tip={
        <div className="max-w-[300px] whitespace-normal">
          <h3 className="mb-1">
            <b>Pianificazione parziale</b>
          </h3>
          <span className="text-gray-500">
            Una o più concessionarie hanno rifiutato il tuo ordine entro le 24
            ore consentite. Verrai rimborsato.
          </span>
        </div>
      }
    >
      <InformationCircleIcon className="w-6 h-6 stroke-2 text-primary" />
    </Tooltip>
  );
};
