import { Billboard } from "graphql/ssp/generated";
import { DeleteBillboardModal } from "./Delete";
import { BatchDeleteBillboardModal } from "./BatchDelete";
import { AvailabilityModal } from "./Availability";
import { PricingModal } from "./Pricing";
import EditBillboardModal from "./Edit";
import VisibilityBillboardModal from "components/lists/Billboards/Actions/Visibility";

type Props = {
  action: [string, Billboard | Billboard[]] | null;
  closeAction: () => void;
};
/** Handles actions and actions confirmation for multiple or single row selections */
export const BillboardsActionsManager = ({ action, closeAction }: Props) => {
  if (!action) return <></>;
  const [actionName, actionData] = action;

  if (Array.isArray(actionData)) {
    // Group actions
    return (
      <>
        <BatchDeleteBillboardModal
          data={actionData}
          open={actionName === "delete"}
          close={closeAction}
        />
        <PricingModal
          data={actionData}
          open={actionName === "pricing"}
          close={closeAction}
        />
        <VisibilityBillboardModal
          data={actionData}
          open={actionName === "visibility"}
          close={closeAction}
        />
      </>
    );
  }
  // Row actions
  return (
    <>
      <DeleteBillboardModal
        data={actionData}
        open={actionName === "delete"}
        close={closeAction}
      />
      <AvailabilityModal
        data={actionData}
        open={actionName === "availabilities"}
        close={closeAction}
      />
      <PricingModal
        data={[actionData]}
        open={actionName === "pricing"}
        close={closeAction}
      />
      <EditBillboardModal
        data={actionData}
        open={actionName === "edit"}
        close={closeAction}
      />
    </>
  );
};

export default BillboardsActionsManager;
