import { Billboard } from "graphql/ssp/generated";
import { downloadBlob } from "utils/downloadBlob";

export const csvExportBillboards = (data: Billboard[]) => {
  if (data.length < 1) return false;
  const { taxes, ...rest } = data[0];
  const flatteredData = data.map(({ taxes, ...rest }) => ({
    ...taxes,
    ...rest,
  }));
  const flatteredHeaders = { ...taxes, ...rest };
  const headers = Object.keys(flatteredHeaders) as (keyof Billboard)[];
  exportItemList(flatteredData, headers);
};
export const exportItemList = (data: Object[], customHeaders?: string[]) => {
  const headers = customHeaders || Object.keys(data[0]);
  const arrayData = data.map((item: any) =>
    headers.map((header: any) => item[header] || "")
  );
  const content = [headers].concat(arrayData);
  const csv = content
    .map((row) =>
      row
        .map(String)
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(",")
    )
    .join("\r\n");
  const currentDate = new Date().toISOString().slice(0, 10);
  downloadBlob(csv, currentDate + ".csv", "text/csv; header=present");
};
