import { ArrowLeftIcon } from "@heroicons/react/24/outline";
//import { uploadCsv } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import FixedSpaceContainer from "components/containers/FixedSpace";
import { SingleFile } from "components/inputs/SingleFile";
import BillaloLogo from "components/others/BillaloLogo";
import LoadingSpinner from "components/others/LoadingSpinner";
import CsvRemapper from "pages/BatchUpload/CsvRemapper";
import { CsvTable } from "pages/BatchUpload/CsvTable";
import applyCorrections from "pages/BatchUpload/corrections";
import { uploadCsvParsedBillboards } from "pages/BatchUpload/uploadCsvParsedBillboards";
import {
  CsvBillboardInput,
  exclusion,
  flipEntries,
  generateInput,
  inputKeys,
  listCsvItems,
} from "pages/BatchUpload/utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { twMerge } from "tailwind-merge";
import readTextFile from "utils/readTextFile";
import csvToArray from "utils/strings/csvToArray";

// Read the file
// if conflict show mapping options and remap
// show table

export const BatchUpload: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [csvArray, setCsvArray] = useState<string[][]>();
  const [csvInput, setCsvInput] = useState<CsvBillboardInput[] | undefined>();
  const dispatch = useDispatch();
  /** 
    Represents non-intersecting fields (base input object against csv Object)
    [0] -> Extra fields
    [1] -> Missing fields
  */
  const [exclusionFields, setExclusionFields] =
    useState<[string[], string[]]>();

  const resetAll = () => {
    setFile(undefined);
    setCsvInput(undefined);
    setExclusionFields(undefined);
  };
  const handleFile = async (file: File) => {
    setIsLoading(true);
    const fileContent = await readTextFile(file);

    if (!fileContent) {
      setFile(undefined);
      setIsLoading(false);
      return;
    }
    try {
      const csvArr = csvToArray(fileContent);
      setCsvArray(csvArr);
      // Transform csv into an object list (where first row are paremeters names)
      const csvItemsList = listCsvItems(csvArr);
      // const baseInputs = listBaseInputs(rawCsvItems);
      // Compare csvObject parameters against CsvBillboardInput parameters
      const [extraFields, missingFields] = exclusion(
        Object.keys(csvItemsList[0]),
        inputKeys
      );
      if (extraFields.length && missingFields.length) {
        setExclusionFields([extraFields, missingFields]);
      } else {
        const generatedInput =
          generateInput(csvItemsList).map(applyCorrections);
        setCsvInput(generatedInput);
      }
      // Feedback on unrecognized parameters and remapping prompt ONLY if at least 1 parameters is missing on the csv object
    } catch (e) {
      console.log(e);
      alert("C'è stato un errore nella lettura del file");
      resetAll();
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col items-center w-full gap-5 p-5 grow ">
      <div className="flex flex-col items-end text-lg font-extrabold text-primary">
        <BillaloLogo className="h-7" />
        ADMIN
      </div>
      <FixedSpaceContainer
        className="w-full overflow-y-scroll hover-scrollbar"
        bodyClassName="items-start"
      >
        <div
          className={twMerge(
            "absolute z-50 flex h-full w-full items-center justify-center bg-white opacity-50",
            !isLoading && "hidden"
          )}
        >
          <LoadingSpinner className="h-10" />
        </div>
        {!file && (
          <BasicContainer
            loading={isLoading}
            className="flex max-w-[630px] flex-col bg-transparent p-0"
          >
            <SingleFile
              accept="text/csv"
              pattern=".*\.csv"
              onFile={handleFile}
              file={file}
              setFile={setFile}
            />
          </BasicContainer>
        )}
        {exclusionFields && csvArray && (
          <CsvRemapper
            extraFields={exclusionFields[0]}
            missingFields={exclusionFields[1]}
            onComplete={(remappedFields) => {
              setExclusionFields(undefined);
              const flippedRemap = flipEntries(remappedFields);
              const updatedCsvArray = [
                csvArray[0].map((header) => {
                  return flippedRemap[header] || header;
                }),
                ...csvArray.slice(1, -1),
              ];
              setCsvInput(generateInput(listCsvItems(updatedCsvArray)));
            }}
          />
        )}
        {csvInput && (
          <CsvTable
            items={csvInput}
            itemKeys={inputKeys}
            setItems={setCsvInput}
            uploadItems={async (input) => {
              setIsLoading(true);
              try {
                await uploadCsvParsedBillboards(input);
                alert("Operazione avvenuta con successo");
                dispatch(billboardsActions.willFetchBillboards());
                resetAll();
              } catch (e) {
                alert(
                  "Si è verificato un errore durante l'operazione, controllare la console di sviluppo"
                );
              }
              setIsLoading(false);
            }}
          />
        )}
      </FixedSpaceContainer>

      <div className="flex flex-col items-center justify-center max-w-2xl p-5 text-center">
        {file && (
          <BasicButton
            className="mt-10 bg-gray-400 w-fit"
            onClick={() => resetAll()}
          >
            <div className="flex items-center justify-center gap-2">
              <ArrowLeftIcon className="h-4" />
              <span>Torna indietro</span>
            </div>
          </BasicButton>
        )}
      </div>
    </div>
  );
};
export default BatchUpload;
