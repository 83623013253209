import { resetPassword } from "api/auth";
import BasicButton from "components/buttons/Basic";
import BasicInput from "components/inputs/Basic";
import BasicModal from "components/modals/Basic";
import useNotification from "hooks/useNotification";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";

type Props = {};

export const ChangePasswordModal: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [cognitoUser] = useSelector(userSelectors.cognitoUser);
  const { toast } = useNotification();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!cognitoUser) return;
    setIsLoading(true);
    const res = await resetPassword(cognitoUser, oldPassword, newPassword);
    setIsLoading(false);
    console.log(res)
    toast({
      title: "Cambio password",
      text: "La tua password è stata modificata con successo",
      type: "SUCCESS",
    });
    setOpen(false);
  };
  return (
    <>
      <BasicButton className="w-fit" onClick={() => setOpen(true)}>
        Cambio password
      </BasicButton>
      <BasicModal
        title="Cambio password"
        open={open}
        close={() => setOpen(false)}
      >
        <form
          id={"change-password"}
          className="flex flex-col gap-3 px-12"
          onSubmit={handleSubmit}
        >
          <div className="text-gray-500">
            Inserisci la tua vecchia password:
          </div>
          <BasicInput
            onChange={(e) => setOldPassword(e.target.value)}
            className="self-center w-64"
            type="password"
            required
          />
          <div className="text-gray-500">Nuova password:</div>
          <BasicInput
            onChange={(e) => setNewPassword(e.target.value)}
            className="self-center w-64"
            type="password"
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
            title="La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero"
            required
          />
          <div className="text-gray-500">Conferma nuova password:</div>
          <BasicInput
            className="self-center w-64"
            type="password"
            pattern={newPassword}
            title="Le password devono corrispondere"
            required
          />
          <div className="flex justify-end gap-4">
            <BasicButton
              className="py-1 bg-gray-400 w-fit "
              type="reset"
              onClick={() => setOpen(false)}
            >
              Annulla
            </BasicButton>
            <BasicButton
              disabled={!newPassword && !oldPassword}
              loading={isLoading}
              className="py-1 w-fit"
              type="submit"
            >
              Conferma
            </BasicButton>
          </div>
        </form>
      </BasicModal>
    </>
  );
};
