import IndexCard from "components/cards/Billboard/IndexCard";
import BasicContainer from "components/containers/Basic";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";
import { Order } from "graphql/ssp/generated";
import Table from "components/lists/table";
import { orderF, orderS } from "utils/formatters";
import { currencyFormatter } from "utils/currencyFormatter";
import {
  ArrowRightIcon,
  BuildingLibraryIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import { useNavigate } from "react-router-dom";
import { BillboardsOverview } from "components/lists/Billboards/Overview";
import LoadingSpinner from "components/others/LoadingSpinner";
import useMyBillboards from "hooks/useMyBillboards";
import useMyOrders from "hooks/useMyOrders";
import useMyClients from "hooks/useMyClients";
import { billboardsSelectors } from "store/slices/billboards";
import { ordersSelectors } from "store/slices/orders";
import { clientsSelectors } from "store/slices/clients";
import BillboardsMap from "components/maps/BillboardsMap";
import { OrderStatus } from "types/Statuses";
import { AdvertiserMini } from "components/cards/UserMini";

const getComputedOrdersData = (orders: Order[]) => {
  let visIndexMLength = 0,
    visIndexNLength = 0;
  const data = {
    amountTotal: 0,
    visIndexM: 0,
    visIndexN: 0,
    soldBillboards: 0,
    ordersReceived: orders.length,
  };
  const paidStatuses: OrderStatus[] = [
    "paid",
    "delivered_materials",
    "posted",
    "completed",
    "confirmed",
  ];
  const isPaid = (status: any) => paidStatuses.includes(status);
  orders.forEach((o) => {
    if (!o.billboards) return;
    const paid = isPaid(o.status);
    if (paid) data.soldBillboards += o.billboards.length;
    o.billboards.forEach((b) => {
      if (b && b.iV_normalized) {
        data.visIndexN += b.iV_normalized;
        visIndexNLength++;
      }
      if (b && b.iV_municipality) {
        data.visIndexM += b.iV_municipality;
        visIndexMLength++;
      }
      if (b && b.price && paid) {
        data.amountTotal += b.price;
      }
    });
  });
  // Calculates the averages and rounds up
  if (visIndexMLength)
    data.visIndexM = Math.round((data.visIndexM / visIndexMLength) * 10) / 10;
  if (visIndexNLength)
    data.visIndexN = Math.round((data.visIndexN / visIndexNLength) * 10) / 10;
  return data;
};
export const MediaOwnerDashboard = () => {
  const [userAttributes] = useSelector(userSelectors.userAttributes);

  const [myBillboards] = useSelector(billboardsSelectors.myBillboards);
  const [myOrders] = useSelector(ordersSelectors.myOrders);
  const [myClients] = useSelector(clientsSelectors.myClients);

  const ordersData = getComputedOrdersData(myOrders || []);
  const navigate = useNavigate();

  useMyOrders();
  useMyBillboards();
  useMyClients();

  if (myClients === null || myBillboards === null || myOrders === null)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner />
      </div>
    );
  return (
    <div className="flex justify-center h-full overflow-scroll hover-scrollbar animate-down-up">
      <div className="flex flex-col max-w-5xl gap-4 ">
        <div className="flex justify-between pb-5">
          {userAttributes && (
            <div className="text-2xl font-semibold my-7 animate-down-up">
              Ciao {userAttributes.given_name}!
            </div>
          )}
        </div>

        <div className="flex min-w-[800px] animate-down-up justify-between gap-3">
          {myOrders && (
            <BasicContainer className="grow">
              <div className="w-full font-semibold text-left">
                Totale vendite
              </div>
              <b className="text-2xl">
                {currencyFormatter.format(ordersData.amountTotal)}
              </b>
            </BasicContainer>
          )}

          <BasicContainer className="grow">
            <div className="w-full font-semibold text-left">Spazi venduti</div>
            <b className="text-2xl">{ordersData.soldBillboards}</b>
          </BasicContainer>
          <BasicContainer className="grow ">
            <div className="w-full font-semibold text-left">
              Ordini ricevuti
            </div>
            <b className="text-2xl">{ordersData.ordersReceived}</b>
          </BasicContainer>
        </div>
        <div className="flex gap-3 animate-down-up">
          <BasicContainer className="justify-start grow">
            <div className="flex items-center justify-between w-full">
              <div className="w-full font-semibold text-left">
                Ultimi ordini
              </div>
              <TransparentButton
                className="gap-2 text-gray-400 whitespace-nowrap hover:text-gray-700"
                onClick={() => navigate("/orders")}
              >
                <div>Mostra Tutti</div> <ArrowRightIcon className="h-4" />
              </TransparentButton>
            </div>
            <div className="w-full text-center h-60">
              {!myOrders.length ? (
                <i className="w-full text-xl text-gray-500">
                  Nessun ordine effettuato
                </i>
              ) : (
                <Table
                  data={myOrders.slice(
                    0,
                    myOrders.length > 4 ? 3 : myOrders.length
                  )}
                  className="w-full"
                  cols={{
                    advertiser: ["Cliente", orderF["advertiser"]],
                    from: ["Inizio/Fine", orderF["from"]],
                    amount: ["Totale", orderF["amount"], orderS["amount"]],
                    status: ["Stato", orderF["status"]],
                  }}
                  showHeader={false}
                />
              )}
            </div>
          </BasicContainer>
          <BasicContainer className="justify-start w-fit">
            <div className="w-full font-semibold text-left">Nuovi clienti</div>
            <div className="flex w-[200px] flex-col gap-3">
              {myClients
                .slice(0, myClients.length > 5 ? 5 : myClients.length - 1)
                .map((adv) => {
                  return (
                    <AdvertiserMini
                      key={adv.advertiser}
                      advertiser={adv}
                      className="w-full"
                      buttonClassName="w-full justify-start"
                    />
                  );
                })}
            </div>
          </BasicContainer>
        </div>
        <div className="flex w-full gap-3 pb-20 animate-down-up">
          <BasicContainer className="justify-start pt-0 bg-transparent border-r rounded-none h-fit shrink-0">
            <BillboardsOverview myBillboards={myBillboards} />
          </BasicContainer>
          <BillboardsMap
            billboards={myBillboards.map((b) => {
              return {
                ...b,
                lat: b.latitude || 0,
                lng: b.longitude || 0,
              };
            })}
            className="z-0 aspect-[4/3] h-full w-full min-w-[440px] rounded-xl"
            clustersSize={45}
          />
          {!!ordersData.visIndexM && !!ordersData.visIndexN && (
            <BasicContainer className="h-fit w-[248px] shrink-0 items-stretch">
              <IndexCard
                amount={ordersData.visIndexM}
                amountMax={10}
                Icon={HomeIcon}
                amountText={(val, max) => val + "/" + max}
                className="w-full py-0 bg-white"
              >
                <div className="text-xs font-medium text-gray-400">
                  Indice visibilità medio Comune
                </div>
              </IndexCard>
              <hr />
              <IndexCard
                amount={ordersData.visIndexN}
                amountMax={10}
                Icon={BuildingLibraryIcon}
                amountText={(val, max) => val + "/" + max}
                className="w-full py-0 bg-white"
                color="#3DD598"
              >
                <div className="text-xs font-medium text-gray-400">
                  Indice visibilità medio Nazione
                </div>
              </IndexCard>
            </BasicContainer>
          )}
        </div>
      </div>
    </div>
  );
};
export default MediaOwnerDashboard;
