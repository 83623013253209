import { PlusIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BillboardsActionsManager from "./Actions";
import Table, { Props as TableProps } from "components/lists/table";
import Tooltip from "components/popovers/Tooltip";
import { Billboard } from "graphql/ssp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { billboardF, billboardS } from "utils/formatters";

// TODO: Multi selection bugfix (resets the first checkbox onclick probably due to rerender)
export type Props = {
  selection: Billboard[];
  setSelection: React.Dispatch<React.SetStateAction<Billboard[]>>;
  myBillboards: Billboard[];
  setFilteredBillboards?: (billboards: Billboard[]) => void;
  onRowClick?: (billboard: Billboard) => void;
  setHovered?: (data: Billboard | undefined) => void;
  className?: string;
  hideActions?: boolean;
} & Omit<TableProps<Billboard>, "data" | "cols">;
export const BillboardsPlanningList = ({
  selection,
  setSelection,
  myBillboards,
  className,
  setHovered,
  setFilteredBillboards,
  onRowClick,
  hideActions,
  ...tableAttributes
}: Props) => {
  const [currentAction, setCurrentAction] = useState<
    null | [string, Billboard | Billboard[]]
  >(null);
  return (
    <>
      <Table
        data={myBillboards}
        className={twMerge("min-h-[250px]", className)}
        filteredElementsSetter={setFilteredBillboards}
        onRowClick={onRowClick}
        setHovered={setHovered}
        noSelect
        rowActions={[
          //[
          //  (billboard) => {
          //    if (!billboard) return <></>;
          //    if (!billboard.pricing_id) return <></>;
          //    return (
          //      <Tooltip
          //        tip={"Scontato"}
          //        placement="top"
          //      >
          //        <div className="flex items-center justify-center w-6 h-6 font-bold text-white bg-green-400 rounded-full ">
          //          %
          //        </div>
          //      </Tooltip>
          //    );
          //  },
          //],
          [
            (billboard) => {
              if (!billboard) return <></>;
              return (
                <Tooltip
                  tip={
                    selection.includes(billboard)
                      ? "Rimuovi dalla selezione"
                      : "Aggiungi alla selezione"
                  }
                  placement="right"
                >
                  <BasicButton
                    onClick={(e) => {
                      e.stopPropagation();
                      // setCurrentAction(["availabilities", billboard]);
                      setSelection((prev) => {
                        prev.includes(billboard)
                          ? prev.splice(selection.indexOf(billboard), 1)
                          : prev.unshift(billboard);
                        return [...prev];
                      });
                    }}
                    className={twMerge(
                      "w-fit rounded-full",
                      selection.includes(billboard)
                        ? "bg-gray-400 text-white"
                        : "hover:bg-primary-light"
                    )}
                  >
                    <PlusIcon
                      className={twMerge(
                        "h-4 rotate-0 transition-transform",
                        selection.includes(billboard) && "rotate-45"
                      )}
                    />
                  </BasicButton>
                </Tooltip>
              );
            },
          ],
        ]}
        cols={{
          address: ["Località", billboardF["address"]],
          format: ["Formato", billboardF["format"], billboardS["format"]],
        }}
        {...tableAttributes}
      />
      <BillboardsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
export default BillboardsPlanningList;
