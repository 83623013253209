import { ArrowRightIcon, BriefcaseIcon } from "@heroicons/react/24/outline";
import { batchGetOrders } from "api/gql/dsp";
import IndexCard from "components/cards/Billboard/IndexCard";
import CampaignMiniCard from "components/cards/CampaignMini";
import BasicContainer from "components/containers/Basic";
import BillaloMini from "components/others/BillaloMini";
import { Billboard, Campaign, Order } from "graphql/dsp/generated";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter } from "utils/currencyFormatter";
import { userSelectors } from "store/slices/user";
import { campaignsSelectors } from "store/slices/campaigns";
import LoadingSpinner from "components/others/LoadingSpinner";
import useMyCampaigns from "hooks/useMyCampaigns";
import TransparentButton from "components/buttons/Transparent";
import { useNavigate } from "react-router-dom";
import averageIndex from "components/cards/Order/averageIndex";

const getAverageVisIndex = (orders: Order[]) => {
  const billboards = orders.flatMap((o) => o.billboards as Billboard[]);
  const { visIndexM } = averageIndex(billboards);
  return visIndexM;
};

const getLatestCampaign = (campaigns: Campaign[]) => {
  return campaigns
    .filter((c) => c.status !== "draft" && c.status !== "canceled")
    .reduce((prev, current) => {
      const prevDate = prev.order_date || "0";
      const currentDate = current.order_date || "0";
      return prevDate > currentDate ? prev : current;
    });
};
export const AdvertiserDashboard = () => {
  const [userAttributes] = useSelector(userSelectors.userAttributes);
  const [myCampaigns] = useSelector(campaignsSelectors.myCampaigns);
  const [isLoading, setIsLoading] = useState(false);
  const campaignsOrders = myCampaigns
    ? myCampaigns.map((c) => c.orders || []).flat()
    : [];
  const [[latestCampaign, latestOrders], setLatestCampaign] = useState<
    [Campaign, Order[]] | [null, null]
  >([null, null]);
  const calculatedFee =
    Math.round(
      (Number(latestCampaign?.fee) / Number(latestCampaign?.total_amount)) *
        1000
    ) / 10;
  const navigate = useNavigate();
  useMyCampaigns();
  useEffect(() => {
    if (!(myCampaigns?.length && !latestCampaign)) return;
    setIsLoading(true);
    const getLatestCampaignAndOrders = async () => {
      const campaign = getLatestCampaign(myCampaigns);
      console.log(campaign);
      const orders = campaign.orders
        ? await batchGetOrders(
            String(campaign.id),
            campaign.orders.map((o) => String(o))
          )
        : [];
      setLatestCampaign([campaign, orders || []]);
    };
    getLatestCampaignAndOrders();
    setIsLoading(false);
  }, [myCampaigns, latestCampaign]);
  if (!myCampaigns) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="hover-scrollbar flex h-full animate-down-up justify-center overflow-scroll">
      <div className="flex max-w-5xl flex-col">
        <div className="flex justify-between">
          {userAttributes && (
            <div className="my-7 animate-down-up text-2xl font-semibold">
              Ciao {userAttributes.given_name}!
            </div>
          )}
        </div>
        <div className="flex min-w-[800px] justify-between gap-3">
          <BasicContainer loading={isLoading} className="grow">
            <div className="w-full text-left font-semibold">Fee</div>
            <b className="text-2xl">{calculatedFee || 0}%</b>
          </BasicContainer>
          <BasicContainer loading={isLoading} className="grow">
            <div className="w-full text-left font-semibold">
              Campagne realizzate
            </div>
            <b className="text-2xl">{myCampaigns.length}</b>
          </BasicContainer>
          <BasicContainer loading={isLoading} className="grow">
            <div className="w-full text-left font-semibold">
              Spazi acquistati
            </div>
            <b className="text-2xl">{campaignsOrders.length}</b>
          </BasicContainer>
        </div>

        {!latestCampaign && !isLoading && (
          <div className="my-6 flex w-full animate-down-up justify-center">
            <b className="text-2xl text-gray-400">
              Non sono ancora state create delle campagne{" "}
            </b>
          </div>
        )}
        {latestCampaign && (
          <BasicContainer className="my-6 animate-down-up border bg-transparent p-3">
            <div className="flex w-full items-center justify-between text-left font-medium text-gray-500">
              <div className="font-semibold text-black">Ultima campagna</div>
              <TransparentButton
                className="gap-2 py-1 text-sm"
                onClick={() =>
                  navigate(
                    `/campaigns/details/${latestCampaign.advertiser}/${latestCampaign.id}`
                  )
                }
              >
                Vai al dettaglio campagna
                <ArrowRightIcon className="h-4" />
              </TransparentButton>
            </div>
            <div className="flex gap-4">
              {/** Passes in the latest campaign */}
              <CampaignMiniCard
                data={latestCampaign}
                className="min-w-[300px]"
              />

              <BasicContainer className="items-start">
                <IndexCard
                  Icon={BillaloMini}
                  amount={latestOrders ? getAverageVisIndex(latestOrders) : 0}
                  amountMax={10}
                  amountText={(val, max) => val + "/" + max}
                  className="bg-white py-0"
                >
                  <div className="text-xs font-medium text-gray-400">
                    Indice visibilità medio
                  </div>
                </IndexCard>
                <IndexCard
                  Icon={BriefcaseIcon}
                  amount={latestCampaign.total_amount || 0}
                  amountMax={latestCampaign.total_amount || 0}
                  amountText={(val) => currencyFormatter.format(val)}
                  color="#3DD598"
                  className="bg-white py-0"
                >
                  <div className="text-xs font-medium text-gray-400">
                    Importo speso
                  </div>
                </IndexCard>
              </BasicContainer>
            </div>
          </BasicContainer>
        )}
      </div>
    </div>
  );
};
export default AdvertiserDashboard;
