import {
  normalizeBillboards,
  NormalizedBillboard,
} from "utils/billboards/normalizeBillboards";
import { useEffect, useState } from "react";
import BillboardsMap from "components/maps/BillboardsMap";
import BillboardCard from "components/cards/Billboard";
import { twMerge } from "tailwind-merge";
import Tooltip from "components/popovers/Tooltip";
import { Billboard, FilterInput } from "graphql/dsp/generated";
import BillboardsPlanningList from "components/lists/Billboards/Planning";
import { startCampaignCreation } from "pages/Planning/Advanced/Plan/startCampaignCreation";
import { useGmap } from "components/maps/GoogleMapComponent/hooks";
import {
  toStreetViewLocation,
  zoomToPlace,
} from "components/maps/GoogleMapComponent/utils";
import useNotification from "hooks/useNotification";
import { useDispatch } from "react-redux";
import { campaignsActions } from "store/slices/campaigns";
import TosAgreementButton from "pages/Planning/Advanced/Plan/TosAgreement";

type Props = {
  filters: FilterInput;
  campaignId: string;
  campaignName: string;
  billboards: Billboard[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setLockedSteps: React.Dispatch<React.SetStateAction<number[]>>;
  selectedBillboards: Billboard[];
  setSelectedBillboards: React.Dispatch<React.SetStateAction<Billboard[]>>;
};
export const Plan: React.FC<Props> = ({
  filters,
  campaignId,
  campaignName,
  billboards,
  selectedBillboards,
  setSelectedBillboards,
  setCurrentStep,
  setLockedSteps,
}) => {
  // TODO: Somehow highlight discounted billboards and display the discount for each one
  const [isLoading, setIsLoading] = useState(false);
  const gmap = useGmap<NormalizedBillboard>();
  /** NOTE: These billboards are the results of map search filters, not input filters */
  const [filteredBillboards, setFilteredBillboards] = useState<
    NormalizedBillboard[]
  >([]);
  const { toast } = useNotification();
  const dispatch = useDispatch();
  //const { toast } = useNotification();
  /** Creates the campaign and then goes to the next step */
  const handleConfirm = async (printer?: boolean) => {
    setIsLoading(true);
    const res = await startCampaignCreation({
      billboards: selectedBillboards,
      filters,
      campaignId,
      campaignName,
      printer,
    });
    res &&
      toast({
        title: "Creazione campagna",
        text: `La bozza per la campagna ${campaignName} è stata creata con successo`,
        type: "SUCCESS",
      });
    setIsLoading(false);

    // Refreshes the campaigns page entries
    dispatch(campaignsActions.willFetchCampaigns());
    setCurrentStep((prev) => prev + 1);
  };

  useEffect(() => {
    setLockedSteps((locked) => {
      if (!selectedBillboards.length || !campaignName) {
        return locked.includes(2) ? locked : [...locked, 2];
      } else if (locked.includes(2)) {
        locked.splice(locked.indexOf(2), 1);
        return [...locked];
      }
      return locked;
    });
  }, [selectedBillboards, setLockedSteps, campaignName]);
  return (
    <div className="flex h-full w-full gap-6 p-4">
      {gmap.selected && (
        <BillboardCard
          zoomIn={(b) => zoomToPlace(b, gmap.map)}
          streetView={(b) => toStreetViewLocation(b, gmap.sview, gmap.pano)}
          onClose={() => {
            gmap.setSelected(undefined);
            if (gmap.pano) {
              gmap.pano.setZoom(1);
              gmap.pano.setVisible(false);
            }
          }}
          className="w-96 animate-down-up"
          data={gmap.selected}
        />
      )}
      <div
        className={twMerge(
          "flex h-full w-96 flex-col justify-between",
          gmap.selected && "hidden"
        )}
      >
        <BillboardsPlanningList
          myBillboards={billboards}
          selection={selectedBillboards}
          setSelection={setSelectedBillboards}
          setHovered={(data) => {
            gmap.setHovered(data as NormalizedBillboard);
          }}
          setFilteredBillboards={(b) =>
            setFilteredBillboards(b as NormalizedBillboard[])
          }
          onRowClick={(billboard) =>
            gmap.setSelected(billboard as NormalizedBillboard)
          }
        />
        <Tooltip
          tip={
            !selectedBillboards.length
              ? "Seleziona almeno un impianto"
              : "Inserisci il nome della campagna"
          }
          disabled={!!selectedBillboards.length && !!campaignName}
        >
          <TosAgreementButton
            disabled={!selectedBillboards.length || !campaignName}
            handleConfirm={handleConfirm}
            role="submit"
            isLoading={isLoading}
          >
            Conferma selezione{" "}
            {selectedBillboards.length > 1 && `(${selectedBillboards.length})`}
          </TosAgreementButton>
        </Tooltip>
      </div>
      <div className="grow">
        <BillboardsMap
          showStreetView
          trafficLayerControl
          billboards={normalizeBillboards(filteredBillboards)}
          gmap={gmap}
          className="z-0 h-full w-full rounded-xl"
        />
      </div>
    </div>
  );
};
export default Plan;
