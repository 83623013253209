import { Placement } from "@floating-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import Tooltip from "components/popovers/Tooltip";
import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  options: React.ReactNode[];
  className?: string;
  optionsClassName?: string;
  chevron?: boolean;
  offsets?: [number, number];
  placement?: Placement;
  containerClassName?: string;
};
export const ComponentsDropdown: React.FC<Props> = ({
  options,
  children,
  className,
  containerClassName,
  optionsClassName,
  disabled,
  chevron,
  placement,
  offsets,
  ...btnAttributes
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  if (isOpen) setTimeout(() => divRef.current?.focus(), 0);
  return (
    <div tabIndex={0}>
      <Tooltip
        controlled
        active={true}
        disabled={disabled}
        placement={placement || "bottom-start"}
        offsets={offsets}
        content={
          <div
            role="menu"
            className={twMerge(
              "absolute bottom-0 z-50 min-w-full translate-y-full flex-col bg-gray-50",
              "shadow overflow-hidden rounded-lg bg-transparent",
              containerClassName,
              !isOpen&&"hidden"
            )}
            tabIndex={0}
            ref={divRef}
            onBlur={() => setIsOpen(false)}
            onClick={() => setIsOpen(false)}
          >
            {options.map((Component, i) => {
              return (
                <div
                  role="menuitem"
                  key={i}
                  className={twMerge(
                    "rounded-none  last:rounded-b-lg hover:bg-slate-200",
                    "border text-gray-500",
                    "bg-white first:rounded-t-lg hover:border-primary whitespace-nowrap p-1",
                    optionsClassName
                  )}
                >
                  {Component}
                </div>
              );
            })}
          </div>
        }
      >
        <TransparentButton
          role="button"
          aria-haspopup="menu"
          className={twMerge(isOpen && "bg-gray-100 bg-opacity-50", className)}
          onMouseDown={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          disabled={disabled}
          {...btnAttributes}
        >
          {children}
          {chevron && (
            <ChevronDownIcon
              className={twMerge(
                "ml-1 h-4 rotate-0 transition-transform",
                isOpen && "rotate-180"
              )}
            />
          )}
        </TransparentButton>
      </Tooltip>
    </div>
  );
};
export default ComponentsDropdown;
