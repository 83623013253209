import * as SSPApi from "api/gql/ssp";
import { Advertiser } from "graphql/ssp/generated";
import { put, takeLatest } from "redux-saga/effects";
import { clientsActions } from "store/slices/clients";
import { sortByKey } from "utils/dateFormatter";

function* fetchClients() {
  const clients: Advertiser[] = yield SSPApi.getMediaOwnerClients() || [];
  sortByKey(clients, "subscription_date");

  yield put(clientsActions.didFetchClients(clients));
}
export function* sagas() {
  yield takeLatest(clientsActions.willFetchClients.type, fetchClients);
}
