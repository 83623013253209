import Tooltip from "components/popovers/Tooltip";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import "./style.css";
type Props = {
  handleChange?: (values: [number, number]) => void;
  minTotal?: number;
  maxTotal?: number;
  displayValues?: boolean;
};
export const TwoWayRange: React.FC<Props> = ({
  handleChange,
  minTotal = 0,
  maxTotal = 10,
  displayValues,
}) => {
  const [[min, max], setValues] = useState<number[]>([minTotal, maxTotal]);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = Number(e.currentTarget.value);
    const isMax = e.currentTarget.id === "max";
    const temp = [min, max];
    temp[Number(isMax)] = val;
    setValues([...temp]);
    const [actualMin, actualMax] = temp.sort();
    handleChange?.([actualMin, actualMax]);
  };
  const calcWidth = () => {
    return Math.abs(((max - min) / (maxTotal - minTotal)) * 100);
  };
  const calcLeft = () => {
    // const value = calcWidth();
    return (Math.min(min, max) / (maxTotal - minTotal)) * 100;
  };
  return (
    <div className={twMerge("w-full", displayValues && "mt-4 mb-8")}>
      <div className={twMerge("range-slider relative w-full")}>
        <div
          style={{
            width: calcWidth() + "%",
            left: calcLeft() + "%",
          }}
          className="absolute bottom-0 z-10 h-[5px] w-full bg-primary"
        />
        {displayValues && (
          <>
            <Tooltip
              placement="top"
              active
              controlled
              offsets={[0, 22]}
              tooltipWrapperClass="z-[55]"
              style={{
                position: "absolute",
                left: calcLeft() + "%",
                transform: `translateX(-${50 + calcLeft() - 50}%)`,
              }}
              content={
                <div className="px-2 font-semibold text-white rounded-lg select-none bg-primary-light">
                  {(min > max ? max : min).toFixed(1)}
                </div>
              }
            >
              <div />
            </Tooltip>
            <Tooltip
              placement="bottom"
              active
              controlled
              offsets={[0, 0]}
              tooltipWrapperClass="z-50"
              style={{
                position: "absolute",
                left: calcLeft() + calcWidth() + "%",
                transform: `translateX(-${
                  50 + calcLeft() + calcWidth() - 50
                }%)`,
              }}
              content={
                <div className="px-2 font-semibold text-white rounded-lg select-none bg-primary-light">
                  {(min <= max ? max : min).toFixed(1)}
                </div>
              }
            >
              <div />
            </Tooltip>
          </>
        )}
        <input
          onChange={onChange}
          defaultValue={minTotal}
          type="range"
          id="min"
          min={minTotal}
          max={maxTotal}
          step="0.1"
        />
        <input
          onChange={onChange}
          defaultValue={maxTotal}
          type="range"
          id="max"
          min={minTotal}
          max={maxTotal}
          step="0.1"
        />
      </div>
    </div>
  );
};

export default TwoWayRange;
