import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import ImageDiv from "components/others/ImageDiv";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { PostNotification } from "types/notifications";
import { relativeTimeFromDates } from "utils/dateFormatter";

type Props = { className?: string } & PostNotification;

export const NotificationCard: React.FC<Props> = ({
  id,
  title,
  text,
  type,
  imageUrl,
  route,
  date,
  className,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={twMerge(
        "flex w-full items-center gap-3 rounded-xl bg-white p-1 px-4",
        className
      )}
    >
      {imageUrl && (
        <ImageDiv className="w-10 h-10 rounded-full" src={imageUrl} />
      )}
      <div className="flex flex-col grow">
        <div className="flex gap-2">
          <div className="font-medium">
            {title}: <span className="text-sm font-normal">{text}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm text-gray-400">
            {date && relativeTimeFromDates(date)}
          </div>
          {route && (
            <div
              role="button"
              onClick={() => navigate(route[0])}
              className="flex items-center gap-1 px-2 font-medium text-white rounded w-fit bg-primary"
            >
              {route[1]}
              <ArrowUturnLeftIcon className="h-4" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
