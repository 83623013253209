import { CalendarIcon } from "@heroicons/react/24/outline";
import { statuses, formatStatus, months } from "./utils";
import { twMerge } from "tailwind-merge";

type Props = {
  dateFrom: Date;
  dateTo: Date;
  status: typeof statuses[number];
};

export const ListItem: React.FC<Props> = ({ dateFrom, dateTo, status }) => {
  const [statusClass, statusText] = formatStatus(status);
  return (
    <div className="grid items-center w-full grid-cols-2 px-2 py-4 text-sm text-gray-600 border-b">
      <div className="flex items-center gap-1">
        <CalendarIcon className="h-6" />
        <div>{dateFrom.getDate() + " " + months[dateFrom.getMonth()]}</div>
        <div>-</div>
        <div>{dateTo.getDate() + " " + months[dateTo.getMonth()]}</div>
      </div>
      <div className="flex items-center gap-2">
        <div className={twMerge("h-3 w-3 rounded-full", statusClass)} />
        <div className="text-sm font-medium text-black">{statusText}</div>
      </div>
    </div>
  );
};
export default ListItem;
