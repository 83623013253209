import { CheckIcon } from "@heroicons/react/24/outline";
import { acceptAdvertiserUser } from "api/gql/dsp";
import { acceptMediaOwnerUser, resourcesUrl } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import { MiniButton } from "components/buttons/Mini";
import RejectUserButton from "components/lists/Team/Actions/RejectUser";
import BasicModal from "components/modals/Basic";
import Tooltip from "components/popovers/Tooltip";
import { AdvertiserUser } from "graphql/dsp/generated";
import { MediaOwnerUser } from "graphql/ssp/generated";
import useNotification from "hooks/useNotification";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { teamActions } from "store/slices/team";
import { userSelectors } from "store/slices/user";

export const AcceptUserButton: React.FC<{
  user: MediaOwnerUser | AdvertiserUser;
}> = ({ user }) => {
  // const acceptUser = acceptMediaOwnerUser({target_user:user?.sub});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { toast } = useNotification();

  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const acceptGroupUser = isMediaOwner
    ? acceptMediaOwnerUser
    : acceptAdvertiserUser;
    
  const acceptUser = async () => {
    const id = String(user.id);
    setIsLoading(true);
    // Accept user
    await acceptGroupUser({ id });
    // Refetch team
    dispatch(teamActions.willFetchTeam());
    // Toast notification
    toast({
      title: `Utente approvato`,
      text: `Hai approvato l'utente ${user.given_name} ${user.family_name} nel tuo team`,
      imageUrl: `${resourcesUrl}pfp_personal_${user.id}.png`,
      type: `SUCCESS`,
    });
    setIsLoading(false);
    setIsOpen(false);
  };
  return (
    <>
      <Tooltip tip="Accetta l'utente">
        <MiniButton
          onClick={() => setIsOpen(true)}
          className="bg-primary/50 bg-opacity-100 stroke-2 text-white hover:bg-primary/75"
        >
          <CheckIcon className="h-4" />
        </MiniButton>
      </Tooltip>
      <BasicModal
        open={isOpen}
        loading={isLoading}
        close={() => setIsOpen(false)}
        title={`Conferma abilitazione utente ${user.given_name} ${user.family_name}`}
      >
        <div className="flex flex-col gap-5">
          <div className="text-lg">
            Sei sicuro di voler abilitare questo utente?
          </div>
          <div className="text-gray-400">
            Una volta effettuata questa operazione, l'utente avrà accesso
            completo alla piattaforma
          </div>
          <div className="flex justify-end gap-3">
            <BasicButton
              className="w-fit bg-gray-400 py-1"
              onClick={() => setIsOpen(false)}
            >
              Annulla operazione
            </BasicButton>
            <BasicButton className="w-fit py-1" onClick={() => acceptUser()}>
              Abilita
            </BasicButton>
          </div>
        </div>
      </BasicModal>
    </>
  );
};
export default RejectUserButton;
