
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import FileDetails from "components/inputs/FilesUploader/FileDetails";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  files: File[];
  setFiles: (files: File[]) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export const FilesUploader: React.FC<Props> = ({
  files,
  setFiles,
  className,
  ...inputAttributes
}) => {
  const [hovered, setHovered] = useState(false);

  const addFiles = (fileList: FileList) => {
    const inputFiles: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileItem = fileList.item(i);
      if (!fileItem) {
        console.log("error");
        continue;
      }
      if (
        !files.find((f) => {
          const nameEqual = f.name === fileItem.name;
          const sizeEqual = f.size === fileItem.size;
          return nameEqual && sizeEqual;
        })
      ) {
        inputFiles.push(fileItem);
      }
    }
    const updatedFiles = files.concat(...inputFiles);
    setFiles(updatedFiles);
  };

  return (
    <>
      <div className={twMerge("flex gap-2", className)}>
        <label
        role="button"
          className={twMerge(
            "relative flex h-40 w-full flex-1 select-none flex-col items-center justify-center gap-3",
            "group rounded-2xl border-2 p-2 text-slate-500 ",
            "hover:border-slate-300 ",
            hovered &&
              "border-dashed border-softblue [&>*]:pointer-events-none"
          )}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(evt) => {
            evt.preventDefault();
            const fileList = evt.dataTransfer.files;
            if (!fileList) return;
            addFiles(fileList);
            setHovered(false);
          }}
          onDragEnter={(evt) => {
            evt.preventDefault();
            setHovered(true);
          }}
          onDragLeave={(evt) => {
            evt.preventDefault();
            setHovered(false);
          }}
        >
          <input
            className="hidden"
            name="files-uploader"
            type="file"
            multiple
            onChange={(e) => {
              console.log("change");
              const fileList = e.target.files;
              if (!fileList) return;
              addFiles(fileList);
              e.target.value = "";
            }}
            {...inputAttributes}
          />
          <DocumentPlusIcon className="h-16" />
          <div className="bottom-0 text-sm font-medium text-center">
            Clicca qui o trascina un file per aggiungere alla selezione
          </div>
        </label>
        <div className="flex flex-col flex-1 h-40 min-w-0 overflow-hidden border-2 rounded-xl hover:border-slate-300 text-slate-500">
          <div className="sticky top-0 p-1 px-2 rounded-t-lg bg-slate-50">
            File selezionati ({files.length}):
          </div>
          <div className="flex flex-col gap-1 px-2 hover-scrollbar grow">
            {files.map((file, i) => (
              <FileDetails
                key={i}
                file={file}
                className="border-b text-slate-500 border-slate-600"
                remove={(file) => {
                  setFiles(
                    files.filter((f) => {
                      const nameEqual = f.name === file.name;
                      const sizeEqual = f.size === file.size;
                      return !(nameEqual && sizeEqual);
                    })
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default FilesUploader;
