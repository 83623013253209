import BasicButton from "components/buttons/Basic";
import { DropdownContainer } from "components/containers/Dropdown";
import Select from "components/inputs/Select";
import { Fragment, useState } from "react";

type Props = {
  missingFields: string[];
  extraFields: string[];
  onComplete: (remappedFields: Record<string, string>) => void;
};
export const CsvRemapper: React.FC<Props> = ({
  missingFields,
  extraFields,
  onComplete,
}) => {
  const [remappedFields, setRemappedFields] = useState<Record<string, string>>(
    missingFields.reduce((acc, field) => ({ ...acc, [field]: "" }), {})
  );
  console.log(remappedFields);
  return (
    <div className="flex flex-col gap-4 ">
      <div className="w-full text-xl shrink">
        Alcuni campi del csv sono discordanti con quelli aspettati. Seleziona
        <br />i campi del CSV con cui sostituire i campi mancanti.{" "}
        <span className="text-gray-500">(opzionale)</span>
      </div>
      <div className="flex justify-end">
        <BasicButton
          className="py-1 w-fit"
          onClick={() => onComplete(remappedFields)}
        >
          Continua
        </BasicButton>
      </div>
      <div className="flex gap-10">
        <div className="flex flex-col gap-3 ">
          {(
            [
              [missingFields, "text-red-500", "mancanti"],
              [extraFields, "text-yellow-500", "extra"],
            ] as const
          ).map(([list, color, type]) => (
            <div key={type} className="flex flex-col gap-1">
              <DropdownContainer
                title={
                  <div className="whitespace-nowrap">
                    <span className={"mr-1 font-medium " + color}>
                      Campi {type}
                    </span>
                    ({list.length})
                  </div>
                }
              >
                <div className="flex flex-col text-sm">
                  {list.map((f) => (
                    <div key={f}>{f}</div>
                  ))}
                </div>
              </DropdownContainer>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2 grow">
          <div className="text-lg">
            Selezionare "<i>Nessuna</i>" se il CSV non contiene il dato.{" "}
          </div>
          <div className="grid h-fit grid-cols-[auto_auto] gap-y-1">
            <div className="text-gray-400">Campi mancanti</div>
            <div className="text-gray-400">Campo CSV da associare</div>
            {missingFields.map((field) => {
              return (
                <Fragment key={field}>
                  <div>
                    <b>
                      <code>{field}</code>
                    </b>
                    :
                  </div>
                  <Select
                    defaultValue=""
                    onChange={(e) =>
                      setRemappedFields((prev) => {
                        const csvField = e.target.value;
                        return { ...prev, [field]: csvField };
                      })
                    }
                  >
                    <option value="">Nessuna</option>
                    {extraFields.map((field) => (
                      <option key={field} value={field}>
                        {field}
                      </option>
                    ))}
                  </Select>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CsvRemapper;
