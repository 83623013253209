import { useState } from "react";
import {
  DayPicker,
  ModifiersClassNames,
  CustomModifiers,
  DayPickerSingleProps,
} from "react-day-picker";
import it from "date-fns/locale/it";
import "./style.css";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  selected: Date | undefined;
  double?: boolean | undefined;
  setSelected: React.Dispatch<React.SetStateAction<Date | undefined>>;
} & Omit<DayPickerSingleProps, "mode">;
// Display a calendar with the week selector
export const CalendarSingle: React.FC<Props> = ({
  className,
  selected,
  setSelected,
  onMonthChange,
  double,
  ...attributes
}) => {
  const classNames: ModifiersClassNames = {
    range_start: "text-white bg-primary rounded",
    range_end: "text-white bg-primary rounded",
    range_middle: "bg-primary bg-opacity-10",
    maintenance: "bg-yellow-400 text-white cursor-default",
  };
  const modifiers: CustomModifiers = {
    // maintenance: [(date) => getYearWeek(date)[1] % 2 === 0],
  };
  // adds border radius to the first and last day of the week
  for (const modifiersClass in classNames) {
    classNames[modifiersClass] += "";
  }
  const [month, setMonth] = useState<Date>(new Date());
  const nextMonth = new Date(month.toISOString());
  nextMonth.setMonth(month.getMonth() + 1);
  return (
    <>
      <div className="flex flex-col">
        <DayPicker
          mode="single"
          className={twMerge("w-fit", className)}
          selected={selected}
          modifiersClassNames={classNames}
          onSelect={(date) => setSelected(date)}
          onMonthChange={(date) => {
            onMonthChange?.(date);
            setMonth(date);
          }}
          modifiers={modifiers}
          locale={it}
          fixedWeeks
          weekStartsOn={1}
          showOutsideDays
          month={month}
          {...attributes}
        />
        {double && (
          <DayPicker
            mode="single"
            className={twMerge("hide-nav w-fit", className)}
            selected={selected}
            month={nextMonth}
            modifiersClassNames={classNames}
            onSelect={(date) => setSelected(date)}
            modifiers={modifiers}
            locale={it}
            fixedWeeks
            weekStartsOn={1}
            showOutsideDays
            {...attributes}
          />
        )}
      </div>
    </>
  );
};
export default CalendarSingle;
