import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
type Props = {} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export const BillboardScroll: React.FC<Props> = ({  ...attributes }) => {
  return (
    <button
      className="absolute bottom-0 z-10 left-1/2 animate-bounce hover:animate-none"
      {...attributes}
    >
      <ArrowDownIcon
        strokeWidth={2}
        className={twMerge(
          "w-8 p-1 rounded-full border-lighter-gray bg-slate-400 text-lighter-gray opacity-80 -translate-x-1/2",
          "transition-all hover:scale-125  hover:opacity-100"
        )}
      />
    </button>
  );
};
export default BillboardScroll;
