import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Billboard, BillboardInput } from "graphql/ssp/generated";
import {
  Params,
  Location,
  MiscData,
} from "pages/Billboards/NewBillboard/forms";
import { createRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { StepForm, parseFormData } from "components/Stepper";
import toBillboardInput from "utils/billboards/toBillboardInput";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { uploadImage } from "api/gql/ssp";

type Props = {
  data: Partial<Billboard>;
  open: boolean;
  close: () => void;
};

export const EditBillboardModal: React.FC<Props> = ({ data, open, close }) => {
  const [openAccordion, openAccordionSetter] = useState<string>();
  const [editData, setEditData] = useState<BillboardInput & { image?: File }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  console.log(editData);
  const forms = [
    ["Parametri", Params],
    ["Posizione", Location],
    ["Altri dati", MiscData],
  ] as [string, StepForm<BillboardInput>][];
  const setOpenAccordion = (val: string | undefined) => {
    openAccordionSetter((prev) => {
      if (prev === val) return undefined;
      return val;
    });
  };
  const formsRef = forms.map(() => createRef<HTMLFormElement>());
  return (
    <BasicModal
      open={open}
      close={close}
      loading={isLoading}
      title={"Modifica impianto " + (data.address || data.location)}
      closableOnOverlayClick
    >
      <div className="flex flex-col gap-3 px-2 py-3">
        {forms.map(([alias, Form], i) => {
          const isOpen = openAccordion === alias;
          return (
            <div key={alias} className="flex-col w-full">
              <div
                className="flex items-center justify-between p-1 px-3 text-gray-500 bg-gray-100 rounded-lg select-none"
                role="button"
                onClick={() => setOpenAccordion(alias)}
              >
                <div className="text-xl font-medium">{alias}</div>
                <ChevronDownIcon
                  className={twMerge(
                    "h-5 -rotate-90 transition-all",
                    isOpen && "rotate-0"
                  )}
                />
              </div>
              <div className={twMerge("mt-3 w-full", !isOpen && "hidden")}>
                <Form
                  editHandler={(fData) => {
                    const data = parseFormData<BillboardInput & {}>(fData);
                    setEditData({ ...editData, ...data });
                  }}
                  isCurrentStep={isOpen}
                  formRef={formsRef[i]}
                  inputData={data}
                  prefill
                />
              </div>
            </div>
          );
        })}
        <div className="flex justify-end w-full gap-3">
          <BasicButton
            className="text-gray-600 w-fit bg-slate-200"
            onClick={close}
          >
            Annulla
          </BasicButton>
          <BasicButton
            className="w-fit"
            onClick={async () => {
              setIsLoading(true);
              if (!(data.id && data.city_code)) return;
              // TODO: take out non-conforming data, input dates as and output as aws date
              const imageId = `billboard_${data.id}`;
              const imageUrl = editData.image?.name
                ? await uploadImage(editData.image, imageId)
                : data.imageUrl;
              const inputData = toBillboardInput(
                { ...data, ...editData, imageUrl } as Billboard,
                true
              );
              if (inputData.authorization_expiration) {
                inputData.authorization_expiration = new Date(
                  inputData.authorization_expiration
                ).toISOString();
              }
              if (data.last_maintenance) {
                inputData.last_maintenance = new Date(
                  inputData.last_maintenance
                ).toISOString();
              }
              if (inputData.taxes?.last_payment) {
                inputData.taxes = {
                  ...inputData.taxes,
                  last_payment: new Date(
                    inputData.last_maintenance
                  ).toISOString(),
                };
              }
              dispatch(
                billboardsActions.willEditBillboard({
                  city_code: data.city_code!,
                  id: data.id!,
                  input: inputData,
                })
              );
              setIsLoading(false);
              close();
            }}
          >
            Applica modifiche
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
};

export default EditBillboardModal;
