import { endOfWeek, startOfWeek } from "date-fns";
import { DateRange } from "react-day-picker";

/**
 * Returns the year and week number for the given date.
 * @see https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php/6117889#6117889
 */
export function getYearWeek(d: Date): [number, number] {
  const dayMilliSeconds = 86400000;
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil(((+d - +yearStart) / dayMilliSeconds + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo + 1];
}

export function getDateRangeFromYearWeek([year, week]: [
  number,
  number
]): DateRange {
  const d = new Date(year, 0, 1);
  const dayMilliSeconds = 86400000;
  // If the week is the last of the year, we need to get the last day of the year
  const day = d.getDay() || 7;
  d.setDate(
    d.getDate() +
      7 * week -
      day +
      (day > 4 ? -7 : 0) +
      (d.getTimezoneOffset() > d.getTimezoneOffset() - 60 ? -1 : 0)
  );
  return {
    from: new Date(d.getTime() + dayMilliSeconds * 1),
    to: new Date(d.getTime() + dayMilliSeconds * 7),
  };
}
// Returns the first and last day of the week
export function getWeekBounds(date: Date) {
  return {
    from: startOfWeek(date, { weekStartsOn: 1 }),
    to: endOfWeek(date, { weekStartsOn: 1 }),
  };
}
