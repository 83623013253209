import { twMerge } from "tailwind-merge";

type Props = {
  bbClass: string;
};

export const BillboardClass: React.FC<Props> = ({ bbClass }) => {
  const classColor = {
    Outdoor: "bg-outdoor",
    Indoor: "bg-indoor",
    Dinamico: "bg-dinamico",
  } as { [key: string]: string | undefined };

  return (
    <div
      className={twMerge(
        "rounded p-1 text-white",
        classColor[bbClass] || "bg-indoor"
      )}
    >
      {bbClass}
    </div>
  );
};
export default BillboardClass;
