import { call, put, select, takeLatest } from "redux-saga/effects";
import { userActions, userSelectors } from "store/slices/user";
import * as Auth from "api/auth";
import * as SSPApi from "api/gql/ssp";
import * as DSPApi from "api/gql/dsp";
import { MediaOwner } from "graphql/ssp/generated";
import { Advertiser } from "graphql/dsp/generated";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { CognitoAttributesObject } from "types/CognitoAttributesObject";

type Group<M> = M extends true ? MediaOwner : Advertiser;

/** Requests to get the cognitoUser and the cognitoUserAttributes */
function* login({ payload }: ReturnType<typeof userActions.willLogin>): any {
  const { email, password, isMediaOwner } = payload;
  Auth.configure(isMediaOwner);
  try {
    const cognitoUser: CognitoUser = yield Auth.login(email, password);
    const cognitoAttributes = yield getAttributes(cognitoUser);
    let group = yield getGroup(isMediaOwner);
    yield put(userActions.didLogin([cognitoUser, cognitoAttributes, group]));
  } catch (error) {
    console.log(error);
    yield put(
      userActions.didLogin([error as Error, error as Error, error as Error])
    );
  }
}

/** Returns a mediaowner or advertiser (true) */
async function getGroup<M extends boolean>(
  isMediaOwner: M
): Promise<Group<M> | Error> {
  try {
    if (isMediaOwner) {
      const mediaOwner = await SSPApi.getMediaOwner();
      if (!mediaOwner) throw new Error("No Media Owner found");
      return mediaOwner as Group<M>;
    } else {
      const advertiser = await DSPApi.getAdvertiser();
      if (!advertiser) throw new Error("No Advertiser found");
      return advertiser as Group<M>;
    }
  } catch (err) {
    return new Error("getGroup error");
  }
}

function* logout() {
  try {
    yield call(Auth.logout);
    // Resets the application's state
    yield put({ type: "USER_LOGOUT" });
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error: Logout unsuccessful.\n${error.message}`);
    }
  }
}

/** Runs at app launch, manages AWS configuration*/
function* initialize({
  payload,
}: ReturnType<typeof userActions.willInitialize>): any {
  // Applies configuration depending on user type
  const { isMediaOwner, loadCached } = payload;
  Auth.configure(isMediaOwner);

  if (loadCached) {
    yield loadCachedUser(isMediaOwner);
  }
  yield put(userActions.didInitialize(isMediaOwner));
}

/** Loads the user from cache */
function* loadCachedUser(isMediaOwner: boolean): any {
  // Checks if there's a logged in cognitoUser, if so logs them in
  try {
    const cachedUser: CognitoUser = yield Auth.getAuthenticatedUser();
    const cachedIsMediaOwner =
      localStorage.getItem("isMediaOwner") === String(isMediaOwner);

    if (cachedIsMediaOwner) {
      console.log(
        `Logging in cached user (${isMediaOwner ? "MediaOwner" : "Advertiser"})`
      );
      const cognitoUserAttributes = yield getAttributes(cachedUser);
      console.log(cognitoUserAttributes);
      let group: Error | Advertiser | MediaOwner = yield getGroup(isMediaOwner);
      yield put(
        userActions.didLogin([cachedUser, cognitoUserAttributes, group])
      );
    } else {
      console.log("User type mismatch, not loading from cache");
    }
  } catch (error) {
    console.log("Load cached user: No cached user.", error);
  }
}

/** Gets the user data as ADVERTISER */
function* getAdvertiser() {
  try {
    const result: Advertiser = yield DSPApi.getAdvertiser();
    yield put(userActions.didGetAdvertiser(result));
    console.log("getAdvertiser", result);
  } catch (error) {
    console.log("Error while getting advertiser", error);
    yield put(userActions.didGetAdvertiser(error as Error));
  }
}

/** Checks in the localStorage whether the current user has already exported freshdesk
 * - if yes: does nothing
 * - if true: exports to freshdesk and puts the current cognito sub in localStorage
 */
function* exportFreshdesk() {
  const [user]: ReturnType<typeof userSelectors.userAttributes> = yield select(
    userSelectors.userAttributes
  );
  if (!user?.sub) return;

  const isMediaOwner: ReturnType<typeof userSelectors.isMediaOwner> =
    yield select(userSelectors.isMediaOwner);
  let storageData = window.localStorage.getItem(
    isMediaOwner ? "freshdeskExportsSSP" : "freshdeskExportsDSP"
  );
  const exportedUsers = storageData
    ? (JSON.parse(storageData) as string[])
    : ([] as string[]);

  if (exportedUsers.includes(user.sub)) {
    console.log("%cUser already exported to FD", "color:#4444bb");
    return;
  }
  exportedUsers.push(user.sub);
  yield isMediaOwner ? SSPApi.exportFreshdesk() : DSPApi.exportFreshdesk();
  console.log("Freshdesk exported");
  window.localStorage.setItem(
    isMediaOwner ? "freshdeskExportsSSP" : "freshdeskExportsDSP",
    JSON.stringify(exportedUsers)
  );
}

/** Updates/Creates Advertiser */
function* addAdvertiser({
  payload,
}: ReturnType<typeof userActions.willAddAdvertiser>): any {
  try {
    console.log("addAdvertiser payload:", payload);
    const result = yield DSPApi.addAdvertiser({ input: payload });
    console.log("addAdvertiser result:", result);
    yield getAdvertiser();
  } catch (error) {
    console.log("Error while updating advertiser", error);
    yield put(userActions.didGetAdvertiser(error as Error));
  }
}

/** Gets the user data as MEDIA OWNER */
function* getMediaOwner() {
  try {
    const result: MediaOwner = yield SSPApi.getMediaOwner();
    // console.log(result);
    yield put(userActions.didGetMediaOwner(result));
  } catch (error) {
    console.log("Error while getting mediaOwner", error);
    yield put(userActions.didGetMediaOwner(error as Error));
  }
}

/** Updates MEDIA OWNER */
function* addMediaOwner({
  payload,
}: ReturnType<typeof userActions.willAddMediaOwner>): any {
  try {
    console.log("addMediaOwner payload:", payload);
    const result = yield SSPApi.addMediaOwner({ input: payload });
    console.log("addMediaOwner result", result);
    yield getMediaOwner();
  } catch (error) {
    console.log("Error while updating mediaOwner", error);
    yield put(userActions.didGetMediaOwner(error as Error));
  }
}

/** Switches between SSP/DSP api configurations */
function* setIsMediaOwner({
  payload,
}: ReturnType<typeof userActions.setIsMediaOwner>) {
  yield call(Auth.configure, payload);
}

/** Fetches and updates in store cognito attributes */
function* fetchCognitoAttributes({
  payload,
}: ReturnType<typeof userActions.willFetchAttributes>) {
  const attributes: Awaited<ReturnType<typeof getAttributes>> =
    yield getAttributes(payload);
  yield put(userActions.didFetchAttributes(attributes));
}
const getAttributes = async (cognitoUser: CognitoUser) => {
  const userAttributes: CognitoUserAttribute[] = await Auth.getUserAttributes(
    cognitoUser
  );
  return userAttributes?.reduce((acc, curr) => {
    acc[curr.getName() as keyof CognitoAttributesObject] = curr.getValue();
    return acc;
  }, {} as CognitoAttributesObject);
};
export function* sagas() {
  yield takeLatest(userActions.willLogin.type, login);
  yield takeLatest(userActions.willLogout.type, logout);
  yield takeLatest(userActions.willInitialize.type, initialize);
  yield takeLatest(userActions.willGetAdvertiser.type, getAdvertiser);
  yield takeLatest(userActions.willAddAdvertiser.type, addAdvertiser);
  yield takeLatest(userActions.willGetMediaOwner.type, getMediaOwner);
  yield takeLatest(userActions.willAddMediaOwner.type, addMediaOwner);
  yield takeLatest(
    userActions.willFetchAttributes.type,
    fetchCognitoAttributes
  );
  yield takeLatest(userActions.willExportFreshdesk.type, exportFreshdesk);
  yield takeLatest(userActions.setIsMediaOwner.type, setIsMediaOwner);
}
