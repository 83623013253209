import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "components/others/LoadingSpinner";
import CampaignsList from "components/lists/Campaigns";
import { useSelector } from "react-redux";
import { campaignsSelectors } from "store/slices/campaigns";
import { isDraftExpired } from "components/cards/Campaign/DraftTimer";
import { Campaign } from "graphql/dsp/generated";
import { CampaignStatus } from "types/Statuses";

/** Shows advertiser campaigns */
export const MyCampaigns = () => {
  const [searchParams] = useSearchParams();
  const [myCampaigns] = useSelector(campaignsSelectors.myCampaigns);
  const archiveView = searchParams.get("view") === "archive";
  if (myCampaigns === null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className="fill-primary-light" />
      </div>
    );
  }
  const [currentCampaigns, archivedCampaigns] = myCampaigns.reduce(
    (prev, campaign) => {
      const status = campaign.status as CampaignStatus;

      const okStatuses: CampaignStatus[] = [
        "confirmed",
        "posted",
        "draft",
        "scheduled",
      ];
      
      okStatuses.includes(status) &&
      !(status === "draft" && isDraftExpired(campaign.order_date))
        ? prev[0].push(campaign)
        : prev[1].push(campaign);
      return prev;
    },
    [[], []] as [Campaign[], Campaign[]]
  );
  return (
    <>
      <div className="flex justify-center w-full p-3 animate-down-up">
        {archiveView ? (
          <CampaignsList key="archive" myCampaigns={archivedCampaigns} />
        ) : (
          <CampaignsList key="current" myCampaigns={currentCampaigns} />
        )}
      </div>
    </>
  );
};
export default MyCampaigns;
