import Advertiser from "./Advertiser";
import MediaOwner from "./MediaOwner";
import Personal from "./Personal";

const pages = {
  Advertiser,
  MediaOwner,
  Personal
};
export default pages;
