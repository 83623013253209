import BillaloLogo from "components/others/BillaloLogo";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import ProfileMiniCard from "components/cards/ProfileMini";
import Tooltip from "components/popovers/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import { twMerge } from "tailwind-merge";

type Props ={
  /** Whether it is rendering over a primary colored background or a white one */
  primary?:boolean;
}
/** This navbar appears whenever the user appear to not be part of an userGroup */
export const SimplifiedNavbar: React.FC<Props> = ({primary}) => {
  const dispatch = useDispatch();
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);

  const [userAttributes] = useSelector(userSelectors.userAttributes);

  // Fetches user data depending on the user type (advertiser or mediaOwner)

  return (
    <div
      className="sticky top-0 z-10 grid w-full grid-flow-col p-2 px-3 auto-cols-fr"
      // style={{
      //   background:
      //     "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)",
      // }}
    >
      <div>
        <div className="relative flex items-baseline gap-1 m-5 mb-7 w-fit">
          <BillaloLogo fill={primary?"white":"#0062ff"} className="self-center h-auto w-52" />

          <div className={twMerge("absolute bottom-0 right-0 text-sm font-extrabold tracking-tight translate-y-full",primary?"text-white":"text-primary")}>
            {isMediaOwner ? "Media Owner SSP" : "Advertiser DSP"}
          </div>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-end h-full gap-4">
          {/** Profile mini-card */}
          <div className={twMerge("flex items-center rounded-xl",primary?"bg-white":"bg-primary")}>
            <ProfileMiniCard
              className={twMerge("bg-opacity-50",primary?"text-primary":"text-white")}
              buttonClassName="hover:bg-transparent cursor-default"
              noPicture
              name={
                userAttributes?.given_name + " " + userAttributes?.family_name
              }
            />

            <Tooltip tip="Logout">
              {
                <TransparentButton
                  className={twMerge("h-full text-slate-200 hover:text-white bg-primary-dark")}
                  onClick={() => {
                    dispatch(userActions.willLogout());
                  }}
                >
                  <ArrowRightOnRectangleIcon className="h-6" />
                </TransparentButton>
              }
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimplifiedNavbar;
