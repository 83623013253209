import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'

const options = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
}
zxcvbnOptions.setOptions(options)

/** Returns the score of the password ranked from 0 to 4 */
export const passwordStrength=(password:string)=>{
    return zxcvbn(password).score
}
export default passwordStrength 
