/** Complete registration (ADVERTISER) */

import BasicContainer from "components/containers/Basic";
import AdvertiserKycForm from "./AdvertiserKyc";
export const CompleteRegistration = () => {
  return (
    <div className="flex flex-col items-center w-full h-full items">
      <span className="max-w-2xl mb-10 text-4xl font-semibold text-center text-primary">
        Inserisci i dati della tua azienda
      </span>
      <BasicContainer className="min-w-[480px] block text-gray-700 shadow-lg">
        <AdvertiserKycForm/>
      </BasicContainer>
    </div>
  );
};

export default CompleteRegistration;
