const defaultOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};
export const formatAwsDate = (awsDate: string, options = defaultOptions) => {
  return new Date(awsDate)
    .toLocaleDateString("it-IT", options)
    .replaceAll("-", "/");
};

/** Will take an element list and sort it by one of the items property */
export function sortByKey<T extends {}>(items: T[], key: keyof T) {
  items.sort((a, b) => {
    const safeA = a[key] || "",
      safeB = b[key] || "";
    if (safeA === safeB) return 0;
    return safeB > safeA ? 1 : -1;
  });
}

/** Converts a date to timezone agnostic date string */
export const noTimezoneDate = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};
/** Returns a list of every monday in between 2 dates */
export const getMondays = (from: Date, to: Date) => {
  const mondays = [];
  const iterator = new Date(from);
  iterator.setDate(iterator.getDate() + ((1 - iterator.getDay() + 7) % 7));
  while (iterator < to) {
    const newMonday=new Date(+iterator);
    mondays.push(newMonday);
    iterator.setDate(iterator.getDate() + 7);
  }
  return mondays;
};
export const getMonday = (date:Date)=>{
  date.setDate(date.getDate()-(date.getDay()||7)+1);
  return date
}

const getIsoWeek = (d: Date) => {
  // Copy date so it won't modify the original variable
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((+d - +yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
};

export const getWeekNumbersInRange = (from: Date, to: Date) => {
  const iterator = new Date(from);
  const weekNumbers: number[] = [];
  console.log(iterator);

  while (iterator < to) {
    const currWeek = getIsoWeek(new Date(+iterator));
    if (weekNumbers[weekNumbers.length - 1] !== currWeek) {
      weekNumbers.push(currWeek);
    }
    iterator.setDate(iterator.getDate() + 1);
  }
  return weekNumbers;
};

//console.log(getMondays(new Date("2023-02-06"), new Date("2023-03-06")));

export const toIso8061 = (date: string) => {
  return new Date(date).toISOString();
};
const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: "year", ms: 31536000000 },
  { unit: "month", ms: 2628000000 },
  { unit: "day", ms: 86400000 },
  { unit: "hour", ms: 3600000 },
  { unit: "minute", ms: 60000 },
  { unit: "second", ms: 1000 },
];
const rtf = new Intl.RelativeTimeFormat("it", { numeric: "auto" });

/**
 * Get language-sensitive relative time message from Dates.
 * @param relative  - the relative dateTime, generally is in the past or future
 * @param pivot     - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDates(
  relative: Date | null,
  pivot: Date = new Date()
): string {
  if (!relative) return "";
  const elapsed = relative.getTime() - pivot.getTime();
  return relativeTimeFromElapsed(elapsed);
}

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(elapsed: number): string {
  for (const { unit, ms } of units) {
    if (Math.abs(elapsed) >= ms || unit === "second") {
      return rtf.format(Math.round(elapsed / ms), unit);
    }
  }
  return "";
}
