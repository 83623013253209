import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Billboard } from "graphql/ssp/generated";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { billboardF } from "utils/formatters";

type Props = {
  data: Partial<Billboard>[];
  open: boolean;
  close: () => void;
};
export const VisibilityBillboardModal: React.FC<Props> = ({
  data,
  open,
  close,
}) => {
  const dispatch = useDispatch();
  const batchChangeVisibility = (visible: boolean) => {
    const editParams = data
      .filter((b) => !!b.visible !== visible)
      .map(({ id, city_code }) => ({
        id: id!,
        city_code: city_code!,
        input: { visible },
      }));
    dispatch(billboardsActions.willBatchEditBillboards(editParams));
  };
  const visibleRef = useRef<HTMLInputElement>(null);
  return (
    <BasicModal
      open={open}
      close={close}
      title={"Imposta visibilità al pubblico"}
      closableOnOverlayClick
    >
      <div className="flex flex-col px-2">
        Imposta i seguenti impianti come:
        <div className="mx-6 mt-1">
          <label className="flex items-center gap-1">
            <input
              type="radio"
              defaultChecked
              name="visible"
              className="peer"
            />
            <span className="peer-checked:font-semibold">Non visibili</span>
          </label>
          <label className="flex items-center gap-1">
            <input
              ref={visibleRef}
              type="radio"
              name="visible"
              className="peer"
            />
            <span className="peer-checked:font-semibold">Visibili</span>
          </label>
        </div>
        <div className="hover-scrollbar my-4 flex max-h-40 flex-col gap-1 px-1">
          {data.map((billboard, i) => {
            return (
              <div
                key={i}
                className="flex items-center gap-3 rounded-lg p-2 shadow"
              >
                {billboardF["address"] &&
                  billboardF["address"](billboard.address, billboard)}
                {billboardF["format"] &&
                  billboardF["format"](billboard.format, billboard)}
                {billboardF["media_type"] &&
                  billboardF["media_type"](billboard.media_type, billboard)}
              </div>
            );
          })}
        </div>
        <div className="flex w-full justify-end gap-3">
          <BasicButton
            className="w-fit bg-slate-200 text-gray-600"
            onClick={close}
          >
            Annulla
          </BasicButton>
          <BasicButton
            className="w-fit"
            onClick={() => {
              batchChangeVisibility(!!visibleRef.current?.checked);
              close();
            }}
          >
            Conferma
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
};
export default VisibilityBillboardModal;
