import { GraphQLResult } from "@aws-amplify/api";
/** Returns the result of a query promise or null */
export async function errorCatcher<T>(promise: Promise<GraphQLResult<T>>) {
  try {
    const result = await promise;
    if(result.errors){
        throw result.errors
    }
    return result.data;
  } catch (e) {
    console.log("Error",e)
    // Handle error
    return null;
  }
}
