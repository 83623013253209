/**
 *
 * - draft: The campaign has been created (but not payed for)
 * - confirmed: The campaign has been payed for
 * - canceled: ???
 * - pass: An error occured
 * - scheduled: Campaign has been confirmed by every mediaowner
 * - canceled: ???
 * - posted: ???
 * - completed: ???
 * - rejected: ???
 *
 */
export type CampaignStatus = keyof (typeof campaignStateMachine)["states"];

export type OrderStatus = keyof (typeof orderStateMachine)["states"];

/** state machines code */

export const orderStateMachine = {
  id: "payment",
  initial: "draft",
  states: {
    draft: {
      // massimo mezz'ora
      on: {
        pay: "confirmed", // va rimesso a confirm per mandarloin accepted, post modifiche frontend alberto
        confirm: "confirmed",
        reject: "rejected",
        "*": "pass",
      },
    },
    rejected: {
      on: {
        confirm: "confirmed",
        cancel: "canceled",
        "*": "pass",
      },
    },
    confirmed: {
      on: {
        transfer_funds: "scheduled",
        reject: "rejected",
        "*": "pass",
      },
    },
    scheduled: {
      // programmato
      on: {
        unlock_funds: "paid",
        cancel: "canceled",
        "*": "pass",
      },
    },
    paid: {
      //ordine programmato, fondi sbloccati (media_owner riceve i soldi)
      on: {
        put_materials: "delivered_materials",
        // Not necessary anymore (the states after this one will be processed by frontend)
        // post: "posted",
        cancel: "canceled",
        "*": "pass",
      },
    },
    delivered_materials: {
      on: {
        post: "posted",
        cancel: "canceled",
        "*": "pass",
      },
    },
    posted: {
      on: {
        //complete: "completed",
        "*": "pass",
      },
    },
    completed: {
      on: {},
    },
    canceled: {
      on: {},
    },
    pass: { on: {} },
  },
};

export const campaignStateMachine = {
  id: "payment",
  initial: "draft",
  states: {
    draft: {
      // massimo mezz'ora
      on: {
        pay: "confirmed", // va rimesso a confirm per mandarloin accepted, post modifiche frontend alberto
        cancel: "canceled",
        "*": "pass",
      },
    },
    rejected: {
      on: {
        confirm: "confirmed",
        cancel: "canceled",
        "*": "pass",
      },
    },
    confirmed: {
      //equivale a pagato --> per 24 ore
      on: {
        transfer_funds: "scheduled",
        cancel: "canceled",
        "*": "pass",
      },
    },
    scheduled: {
      // programmato
      on: {
        // Not necessary anymore (the states after this one will be processed by frontend)
        // post: "posted",
        cancel: "canceled",
        "*": "pass",
      },
    },
    posted: {
      on: {
        complete: "completed",
        "*": "pass",
      },
    },
    completed: {
      on: {},
    },
    canceled: {
      on: {},
    },
    pass: { on: {} },
  },
};
