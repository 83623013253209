import BillboardMiniCard from "components/cards/Billboard/Mini";
import GoogleMapComponent from "components/maps/GoogleMapComponent";
import { GmapHookObject } from "components/maps/GoogleMapComponent/hooks";
import { Billboard } from "graphql/ssp/generated";
import { getMarkerUrl } from "utils/billboards/BillboardSvg";
import { NormalizedBillboard } from "utils/billboards/normalizeBillboards";
import { validCoordsOrUndef } from "utils/location/validCoordsOrUndef";

export const calcSelCenter = (selectedBillboard?: Billboard) => {
  const sLat = selectedBillboard?.latitude;
  const sLng = selectedBillboard?.longitude;
  return validCoordsOrUndef({
    lat: Number(sLat),
    lng: Number(sLng),
  });
};
type MapProps = JSX.LibraryManagedAttributes<
  typeof GoogleMapComponent,
  React.ComponentProps<typeof GoogleMapComponent<NormalizedBillboard>>
>;
type Props = {
  billboards: NormalizedBillboard[];
  gmap?: GmapHookObject<NormalizedBillboard>;
} & Omit<MapProps, "dataArray">;
/** A google map component preconfigured to display billboards */
export const BillboardsMap: React.FC<Props> = ({
  billboards,
  gmap,
  ...googleMapArguments
}) => {
  return (
    <GoogleMapComponent
      dataArray={billboards}
      markerIconSelector={(data) =>
        getMarkerUrl(data.billboard_class, data.media_type)
      }
      selected={gmap?.selected}
      onMapLoad={gmap?.setMap}
      onPanoramaLoad={gmap?.setPano}
      onSviewServiceLoad={gmap?.setSview}
      onMarkerClick={gmap?.setSelected}
      setSelected={gmap?.setSelected}
      setHovered={gmap?.setHovered}
      overlay={
        (gmap?.hovCenter &&
          gmap?.hovered && [
            gmap?.hovCenter,
            <BillboardMiniCard data={gmap?.hovered} />,
          ]) ||
        undefined
      }
      {...googleMapArguments}
    />
  );
};

export default BillboardsMap;
