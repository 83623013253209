import {
  ArrowLeftIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BillaloLogo from "components/others/BillaloLogo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSelectors, userActions } from "store/slices/user";

export const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  return (
    <div className="flex justify-center h-full">
      <div className="flex flex-col items-center justify-center h-full max-w-2xl p-5 text-center">
        <BillaloLogo className="h-40 mt-20 w-60" />
        <div className="font-semibold text-gray-300 text-9xl">403</div>
        <div className="text-xl text-gray-500">
          Non possiedi le autorizzazioni necessarie a visualizzare questa
          pagina. Forse intendevi loggarti in veste di{" "}
          <i>{isMediaOwner ? "Advertiser" : "Media Owner"}?</i>
        </div>

        <BasicButton
          className="mt-10 w-fit"
          onClick={() => navigate("/dashboard")}
        >
          <div className="flex items-center justify-center gap-2">
            <ArrowLeftIcon className="h-4" />
            <span>Vai alla tua Dashboard</span>
          </div>
        </BasicButton>
        <BasicButton
          className="mt-10 w-fit"
          onClick={() => {
            dispatch(userActions.setIsMediaOwner(!isMediaOwner));
            dispatch(userActions.willLogout());
          }}
        >
          <div className="flex items-center justify-center gap-2">
            <ArrowLeftOnRectangleIcon className="h-4" />
            <span>
              Login come {isMediaOwner ? "Advertiser" : "Media Owner"}
            </span>
          </div>
        </BasicButton>
      </div>
    </div>
  );
};
export default Unauthorized;
