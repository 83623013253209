import { Campaign, Order } from "graphql/dsp/generated";
import { CampaignStatus } from "types/Statuses";
import { currencyFormatter } from "utils/currencyFormatter";
import { formatAwsDate } from "utils/dateFormatter";
import { CampaignStatusTip } from "./CampaignStatusTip";
import { batchGetOrders } from "api/gql/dsp";
import { CampaignOrdersTip } from "utils/formatters/campaign/CampaignOrdersTip";

export const campaignStatuses: Record<CampaignStatus, string[]> = {
  canceled: ["Annullata", "bg-red-100 text-red-500"],
  rejected: ["Rifiutata", "bg-red-400 text-white"],
  pass: ["Errore", "bg-gray-100 text-red-500"],
  confirmed: ["Pending", "bg-yellow-400 text-white"],
  scheduled: ["Pianificata", "bg-green-100 text-green-500"],
  posted: ["In corso", "bg-primary-light text-white"],
  completed: ["Completata", "bg-gray-100 text-gray-500"],
  draft: ["Draft", "bg-yellow-400 text-white"],
};
export const formatter: FormatterObj<Partial<Campaign>> = {
  status: (_val, obj) => <CampaignStatusTip campaign={obj} />,
  from: (val, campaign) => {
    if (!campaign) return "ERROR";
    return (
      <small className="">
        {formatAwsDate(val)}
        <br />
        {formatAwsDate(campaign["to"])}
      </small>
    );
  },
  order_date: (val) => formatAwsDate(val),
  n_municipality: (val) => <div className="text-center">{val}</div>,
  orders: (_val,obj) => <CampaignOrdersTip campaign={obj}/>,
  total_amount: (val) => (
    <div className="text-end">{currencyFormatter.format(val ?? 0)}</div>
  ),
  print_amount: (_, campaign) => (
    <div className="text-end">
      {currencyFormatter.format(
        (campaign?.total_amount ?? 0) +
          (campaign?.fee ?? 0) +
          (campaign?.discount ?? 0)
      )}
    </div>
  ),
  fee: (val) => (
    <div className="text-end">{val ? currencyFormatter.format(val) : "--"}</div>
  ),
};
export const sorter: SorterObj<Partial<Campaign>> = {
  print_amount: (_v1, _v2, c1, c2) => {
    const a = (c1?.total_amount ?? 0) + (c1?.fee ?? 0) + (c1?.discount ?? 0);
    const b = (c2?.total_amount ?? 0) + (c2?.fee ?? 0) + (c2?.discount ?? 0);
    return Number(a) > Number(b) ? 1 : -1;
  },
  orders: (a, b) => ((a?.length ?? 0) > (b?.length ?? 0) ? 1 : -1),
};

export const campaignOrderCache: Record<string, Order[] | null> = {};
export const getCachedOrders = async (campaignId: string, order_ids: string[]) => {
  if (campaignOrderCache[campaignId]) {
    return campaignOrderCache[campaignId];
  }
  const orders = await batchGetOrders(campaignId, order_ids);
  campaignOrderCache[campaignId] = orders;
  return orders;
};