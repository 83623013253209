import { MarkerF, MarkerProps } from "@react-google-maps/api";
import { useState } from "react";

export type Props<T> = {
  /** Arbitrary data structure */
  data: T;
  /** The map on which the marker is displayed */
  map: google.maps.Map | null;
  /** The street view on which the marker is displayed, if the marker is to be displayed on a street view */
  streetView?: google.maps.StreetViewPanorama | null;
  /** Callback to define what happens when the user drags this marker, if unset the marker won't be draggable
   * @param event The google.map mouse event (contains the new position)
   * */
  onMarkerMove?: (event: google.maps.MapMouseEvent) => void;
  /** Callback to define what happens when the user clicks this marker
   * @param data The data object attached to this marker
   * @param event The google.map mouse event
   * @param map The map on which the marker is displayed
   */
  onClick: (
    data: T,
    event: google.maps.MapMouseEvent,
    map: google.maps.Map | null
  ) => void;
  onEnter: (
    data: T,
    event: google.maps.MapMouseEvent,
    map: google.maps.Map | null
  ) => void;
  onLeave: (
    data: T,
    event: google.maps.MapMouseEvent,
    map: google.maps.Map | null
  ) => void;
  selected?: T | null;
  isSelected?: boolean | null;
} & Omit<MarkerProps, "onClick">;

/** Creates a marker with a data object attached to it */
export function MarkerWithData<T extends Coords>({
  position,
  data,
  onClick,
  onEnter,
  onLeave,
  onMarkerMove,
  map,
  streetView,
  selected,
  ...attributes
}: Props<T>) {
  const draggable = onMarkerMove ? true : false;
  const faded = selected && data !== selected;
  const [hovered, setHovered] = useState(false);
  return (
    <>
      <MarkerF
        position={position}
        key={position.toString()}
        options={
          streetView
            ? { map: streetView, draggable }
            : {
                ...defaultMarkerOptions,
                draggable,
                opacity: faded && !hovered ? 0.4 : 1,
              }
        }
        onDragEnd={onMarkerMove || undefined}
        onMouseOver={(mouseEvent) => {
          setHovered(true);
          onEnter(data, mouseEvent, map);
        }}
        onMouseOut={(mouseEvent) => {
          setHovered(false);
          onLeave(data, mouseEvent, map);
        }}
        onClick={(mouseEvent) => {
          onClick(data, mouseEvent, map);
          !streetView && map?.panTo(data);
          // The following will zoom in on the marker
          // map?.setZoom(16)
        }}
        {...attributes}
      />
    </>
  );
}
export default MarkerWithData;
const defaultMarkerOptions: google.maps.MarkerOptions = {
  animation: 0,
  clickable: true,
  draggable: false,
  opacity: 1,
  visible: true,
  zIndex: 1,
};
