import BasicContainer from "components/containers/Basic";
import MutableField from "components/inputs/MutableField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import EditApply from "components/buttons/EditApply";
import ImageDivInput from "components/inputs/ImageDiv";
import { CognitoAttributesObject } from "types/CognitoAttributesObject";
import { uploadImage } from "api/gql/ssp";
import { ChangePasswordModal } from "components/others/ChangePasswordModal";

export const Personal = () => {
  const dispatch = useDispatch();
  type FormatterFn<T> = (key: T) => string;
  const [editRegistry, setEditRegistry] = useState(false);

  const [updatingCognito, setUpdatingCognito] = useState(false);
  const [cognitoUser] = useSelector(userSelectors.cognitoUser);
  const [cognitoAttributes] = useSelector(userSelectors.userAttributes);
  const [cognitoData, setCognitoData] = useState(
    cognitoAttributes as CognitoAttributesObject
  );
  console.log(cognitoAttributes)

  const editCognitoAttributes = (
    flag: boolean,
    updated?: typeof cognitoData
  ) => {
    if (!(flag && cognitoUser)) return;
    const updatedCognitoData = updated || cognitoData;
    console.log("editing cognito attributes");
    setUpdatingCognito(true);
    // Filters out the immutable attributes before updatingCognito
    const immutables = ["email_verified", "sub"];
    const newCognitoAttrs = Object.entries(updatedCognitoData)
      .filter(([key]) => !immutables.includes(key))
      .map(([key, value]) => ({
        Name: key,
        Value: value,
      }));
    cognitoUser.updateAttributes(newCognitoAttrs, (err, result) => {
      setUpdatingCognito(false);
      if (!(result && cognitoAttributes)) return;
      dispatch(
        userActions.didLogin([
          cognitoUser,
          { ...cognitoAttributes, ...updatedCognitoData },
        ])
      );
    });
  };

  type UserFieldArray = [
    keyof typeof cognitoData,
    string,
    string,
    FormatterFn<string>?
  ];

  const userFields: UserFieldArray[] = [
    ["given_name", "Nome", "given_name"],
    ["family_name", "Cognome", "family_name"],
    ["email", "Email", "email"],
  ];
  const placeholder = "/assets/images/placeholder_profile.png";
  const [image, setImage] = useState<File | null>(null);
  return (
    <>
      <BasicContainer className="flex-row w-full p-5 overflow-visible animate-down-up">
        <ImageDivInput
          image={image}
          setImage={setImage}
          className="m-4 mr-12 h-24 w-24 shrink-0 translate-x-1/4 -translate-y-[40%] scale-[1.7] rounded-xl border-4 border-lighter-gray"
          src={cognitoData?.picture}
          placeholder={placeholder}
          alt="Business pic/logo"
          withModal
          onImageChange={(image) => {
            if (!cognitoData?.sub) return;
            const imageId = `pfp_personal_${cognitoData.sub}`;
            uploadImage(image, imageId).then((url) => {
              if (!url) return;
              const updatedCognitoData = { ...cognitoData, picture: url };
              setCognitoData(updatedCognitoData);
              editCognitoAttributes(true, updatedCognitoData);
            });
          }}
        />
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-2xl font-bold">
              {cognitoData?.given_name} {cognitoData?.family_name}
            </h1>
          </div>
          <div className="grid grid-cols-[auto_auto_auto_auto] gap-2">
            <div className="text-gray-600 ">Ruolo</div>
            <div>Admin</div>
          </div>
        </div>
      </BasicContainer>
      <BasicContainer className="flex-col w-full p-5 animate-down-up">
        <div className="flex items-start justify-between w-full">
          <div className="font-semibold">Anagrafica</div>
          <EditApply
            flag={editRegistry}
            setter={setEditRegistry}
            pending={updatingCognito}
            onClick={(flag) => editCognitoAttributes(flag)}
          />
        </div>
        <div className="grid w-full grid-cols-[auto_auto_auto_auto] gap-2">
          {userFields.map(([stateKey, label], i) => (
            <MutableField
              key={i}
              setter={setCognitoData}
              state={cognitoData}
              switchInput={editRegistry}
              stateKey={stateKey}
              label={label}
            />
          ))}
        </div>
      </BasicContainer>
      <BasicContainer className="flex-col w-full p-5 animate-down-up">
        <div className="flex items-start justify-between w-full">
          <div className="font-semibold">Azioni account</div>
          <div/>
        </div>
        <div className="self-start">
        <ChangePasswordModal/>
        </div>
      </BasicContainer>
    </>
  );
};
export default Personal;
