import React from "react";
import billboardOptions from "utils/formatters/billboardOptions";

/** Returns the path to the marker image based on the type of the marker */
export const getMarkerUrl = (
  billboard_class: string | null | undefined,
  media_type: string | null | undefined
) => {
  const defaultMarker = [
    "/assets/markers/indoor/altro.svg",
    "/assets/markers/outdoor/altro.svg",
  ];

  const normType = String(media_type?.toLowerCase().replaceAll(" ", "_"));
  const normTypeClass = String(
    billboard_class?.toLowerCase().replaceAll(" ", "_")
  );
  const typeClassIndex = normTypeClass.toLowerCase() === "outdoor" ? 1 : 0;
  const isTypeClassCorrect = billboardOptions.billboard_class
    .map((str) => str.toLowerCase())
    .includes(normTypeClass);

  const isTypeCorrect = billboardOptions.media_type[typeClassIndex]
    .map((str) => str.toLowerCase())
    .includes(normType);

  if (!isTypeClassCorrect || !isTypeCorrect) {
    return defaultMarker[typeClassIndex];
  }
  return `${window.location.protocol}//${window.location.host}/assets/markers/${normTypeClass}/${normType}.svg`;
};
type CustomMarkerProps = {
  typeClass: string | number | boolean | null | undefined;
  type: string | number | boolean | null | undefined;
  size?: number;
};
export const BillBoardSvg: React.FC<CustomMarkerProps> = ({
  typeClass,
  type,
  size = "auto",
}) => {
  return (
    <img
      alt={String(type) || "marker"}
      title={String(type)}
      width={size}
      height={size}
      src={getMarkerUrl(String(typeClass), String(type))}
    />
  );
};
