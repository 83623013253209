import BasicContainer from "components/containers/Basic";
import PricingCard from "components/cards/Pricing";
import { useEffect } from "react";
/** Shows every billboard */
export const PricingRules = () => {
  useEffect(() => {
    // Get billboards discounts
    // Get clients? define flow
  });
  // Maybe should be a list with no header?
  return (
    <div className="w-full max-w-[940px]">
      <div className="my-8 text-2xl font-semibold">Regole pricing</div>
      <BasicContainer className="w-full p-3 px-5 bg-transparent border">
        <div className="w-full font-bold text-left text-gray-500">
          Recenti
        </div>
        <PricingCard />
      </BasicContainer>
    </div>
  );
};
export default PricingRules;
