import CountdownTimer from "components/others/CountdownTimer";
import { useState } from "react";

type Props = {
  callback?: () => void;
};
/** Time to wait before being able to resend the confirmation email */
const RESEND_TIMEOUT = 60;
export const ResendRequestButton: React.FC<Props> = ({ callback }) => {
  const [isExpired, setIsExpired] = useState(true);
  const [resendTimeOut, setResendTimeOut] = useState(
    window.localStorage.getItem("resend_timeout")
  );
  const resend = () => {
    const resend_timeout = window.localStorage.getItem("resend_timeout");
    if (!resend_timeout || Number(resend_timeout) < Date.now()) {
      const expiry = String(Date.now() + RESEND_TIMEOUT * 1000);
      window.localStorage.setItem("resend_timeout", expiry);
      setResendTimeOut(expiry);
      setIsExpired(false);
      callback?.();
    }
  };
  return (
    <button
      type="button"
      className="text-primary disabled:opacity-50 "
      onClick={() => {
        setIsExpired(false);
        resend();
      }}
      disabled={!isExpired}
    >
      Invia di nuovo
      {!isExpired && (
        <span>
          (
          <CountdownTimer
            setIsExpired={setIsExpired}
            expireTime={Number(resendTimeOut)}
          />
          )
        </span>
      )}
    </button>
  );
};



export default ResendRequestButton;
