import { StandaloneSearchBox } from "@react-google-maps/api";
import BasicInput from "components/inputs/Basic";
import { useState } from "react";

type Props = {
  /** Callback invoked when the user selects a place from the inputs */
  onPlaceSelected: (
    location: google.maps.LatLng,
    bounds: google.maps.LatLngBounds,
    placeResult: google.maps.places.PlaceResult
  ) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;


/** 
 * This component is used to search for a location on the map
 * 
 *  **NOTE:** Works only as a child of the Map component
 */
const SearchInput = ({
  onPlaceSelected,
  ...inputAttributes
}: Props) => {
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  return (
    <StandaloneSearchBox
      onLoad={(e) => {
        setSearchBox(e);
      }}
      onUnmount={() => setSearchBox(null)}
      onPlacesChanged={() => {
        if (!searchBox) {
          console.log("Searchbox has not been initialized!");
          return;
        }
        const places = searchBox.getPlaces();
        if (!places) {
          console.log("No places found!");
          return;
        }
        const location = places[0].geometry?.location;
        const bounds = places[0].geometry?.viewport;
        const address = places[0];
        if (!location || !bounds) {
          console.log("No coordinates for this place!");
          return;
        }
        onPlaceSelected(location, bounds, address);
      }}
    >
      <BasicInput
        type="text"
        placeholder="Cerca indirizzo..."
        name="google-map-search"
        className="absolute left-1/2 z-50 -ml-[120px] mt-1 w-60 text-ellipsis bg-gray-50 shadow-xl "
        autoComplete="off"
        {...inputAttributes}
      />
    </StandaloneSearchBox>
  );
};
export default SearchInput;
