import { BellIcon } from "@heroicons/react/24/outline";
import { resourcesUrl } from "api/gql/ssp";
import TransparentButton from "components/buttons/Transparent";
import Tooltip from "components/popovers/Tooltip";
import useHasActiveStripe from "hooks/useHasActiveStripe";
import useMyOrders from "hooks/useMyOrders";
import useMyTeam from "hooks/useMyTeam";
import useNotification from "hooks/useNotification";
import { hasCompleteKyc } from "pages/wrappers/HasKyc";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { notificationsSelectors } from "store/slices/notifications";
import { ordersSelectors } from "store/slices/orders";
import { teamSelectors } from "store/slices/team";
import { userSelectors } from "store/slices/user";
import { orderStatuses } from "utils/formatters/order";

// TODO: (Backend) Implement actual notifications system
let checkedOrders = false;
let checkedTeam = false;
export const NotificationBell: React.FC = () => {
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [mediaOwner] = useSelector(userSelectors.mediaOwner);
  const posts = useSelector(notificationsSelectors.posts);
  const hasPosts = !!posts.length;
  const [orders] = useSelector(ordersSelectors.myOrders);
  const [team] = useSelector(teamSelectors.myTeam);
  const hasActiveStripe = useHasActiveStripe();
  const { post, toast } = useNotification();
  useMyOrders();
  useMyTeam();

  useEffect(() => {
    if (!orders || posts.length || checkedOrders) return;
    checkedOrders = true;
    orders &&
      orders.forEach((o) => {
        o.status === "confirmed" &&
          post({
            title: `Campagna ${o.campaign_name}`,
            text: `Lo stato della campagna è passato a 
        ${
          orderStatuses?.[o.status as keyof typeof orderStatuses]?.[0] ||
          o.status
        }`,
            imageUrl: resourcesUrl + `pfp_business_${o.advertiser}.png`,
            date: new Date(o.order_date),
            route: ["/orders", "Vai alla lista degli ordini"],
          });
      });
  }, [orders, post, posts.length]);
  useEffect(() => {
    if (checkedTeam || !team) return;
    team.forEach((member) => {
      if (!!member.pending) {
        post({
          title: `Nuovo membro del team`,
          text: `${member.given_name} ${member.family_name} è in attesa di approvazione`,
          imageUrl: `/assets/images/unknown.png`,
          date: new Date(member.creation_date),
          route: ["/profile/team", "Vai al tuo team"],
        });
      }
    });
    checkedTeam = true;
  }, [post, team]);

  // Notifies the SSP user about missing Stripe info
  useEffect(() => {
    if (hasActiveStripe || !isMediaOwner || !hasCompleteKyc(mediaOwner)) return;
    post({
      title: "Attivazione account Stripe",
      text: `Prima di poter rendere i tuoi impianti visibili all'acquisto e ricevere pagamenti è necessario attivare l'account Stripe dal tuo profilo`,
      imageUrl: `/assets/logos/stripe-square.png`,
      route: ["/profile/business", "Vai al profilo"],
    });
    toast({
      title: "Attivazione account Stripe",
      text: `Vai nel tuo profilo per collegare un account Stripe e poter ricevere pagamenti`,
      imageUrl: `/assets/logos/stripe-square.png`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip tip={`Notifiche ${hasPosts ? `(${posts.length})` : ""}`}>
      <TransparentButton className="relative h-full text-gray-600 hover:text-black">
        {hasPosts && (
          <div className="absolute top-1 right-2 h-3 w-3 rounded-full border-[1.5px] border-white bg-red-400" />
        )}
        <BellIcon className="h-6" />
      </TransparentButton>
    </Tooltip>
  );
};
