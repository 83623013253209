import { Column, ColumnInstance } from "react-table";
import { twMerge } from "tailwind-merge";

export function selectionHook<T extends {}>(
  columns: ColumnInstance<T>[]
): Column<T>[] {
  return [
    {
      id: "selection",
      Header: ({ toggleAllRowsSelected, toggleAllRowsSelectedProps }) => {
        // toggleAllRowsSelected is missing in react-table types declaration...
        return (
          <input
            {...toggleAllRowsSelectedProps}
            type="checkbox"
            className={twMerge(
              "my-1 h-4 w-4 cursor-pointer rounded-xl stroke-white accent-primary-light"
            )}
            onChange={(e) => {
              toggleAllRowsSelected(e.target.checked);
            }}
          />
        );
      },
      Cell: ({ row }: { row: any }) => {
        const { indeterminate, ...props } = row.getToggleRowSelectedProps();
        return (
          <input
            {...props}
            type="checkbox"
            className={twMerge(
              "z-10 h-4 w-4 cursor-pointer rounded-xl text-white accent-primary-light"
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
            title="Aggiungi alla selezione"
          />
        );
      },
    },
    ...columns,
  ];
}
export default selectionHook;
