import { twMerge } from "tailwind-merge";

type Props = {
  innerRef?: React.Ref<HTMLInputElement>;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const BasicInput: React.FC<Props> = ({
  className,
  innerRef,
  ...attributes
}) => {
  return (
    <input
      className={twMerge(
        "w-full rounded-lg border border-gray-200 bg-gray-50 px-2 py-1 outline-0 focus-within:bg-white",
        className
      )}
      ref={innerRef}
      required
      {...attributes}
    />
  );
};

export default BasicInput;
