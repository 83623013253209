import Advanced from "./Advanced";
import Search from "./Search";
import Plan from "./Plan";
import Confirm from "./Confirm";
const pages = {
  Main: Advanced,
  Search,
  Plan,
  Confirm,
};
export default pages;
