import { Auth } from "aws-amplify";

export const getIdToken = async () => {
  const JWT_TOKEN = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    Authorization: JWT_TOKEN,
  };
};
window.addEventListener("keydown", async (e) => {
  if (e.key === "F2") {
    const JWT_TOKEN = (await getIdToken()).Authorization;
    navigator.clipboard.writeText(JWT_TOKEN);
    console.log(
      "%cJWT copied to clipboard",
      "color:#0062ff;padding:5px;border-radius:5px;background-color:white;margin:5px;font-weight:bolder;"
    );
  }
  if (e.key === "F4") {
    const elements = document.getElementsByTagName("*");
    for (let el of elements) {
      const style = el.getAttribute("style");

      const newStyle = `background-size:cover;background-image:url(http://placekitten.com/${
        el.clientWidth - (el.clientWidth % 50) || 50
      }/${
        el.clientHeight - (el.clientHeight % 50) || 50
      });background-fit: no-repeat fixed center;font-family: "Comic Sans MS", "Comic Sans", cursive;color:white !important;text-shadow:
      3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;`;
      el.setAttribute(
        "style",
        (style ? style : "") + (style || "").includes(newStyle) ? newStyle : ""
      );
    }
  }
});
export const configuration: any = {
  dev: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "eu-west-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_VjG99WG0m", //dev

      userPoolWebClientId: "54g3o40bjnm9vkj2cdb2cssmb0",

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },

    // OPTION 1
    graphql_headers: getIdToken,

    aws_appsync_graphqlEndpoint:
      "https://mv5es7tnxzgdlmtcdz4jd4li4e.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
  stage: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "eu-west-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_gHCqJJCQe", //stage
      userPoolWebClientId: "3njj9uddctiblonmb3s8dfks9s",
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },

    // OPTION 1
    graphql_headers: getIdToken,

    aws_appsync_graphqlEndpoint:
      "https://vpvlbrtmorbnxc7rg3mxykqzua.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
};

export default configuration;
