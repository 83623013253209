import { BillboardCard } from "components/cards/Billboard";
import BillboardsMap from "components/maps/BillboardsMap";
import {
  toStreetViewLocation,
  useGmap,
} from "components/maps/GoogleMapComponent";
import LoadingSpinner from "components/others/LoadingSpinner";
import useAllBillboards from "hooks/useAllBillboards";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { billboardsSelectors } from "store/slices/billboards";
import { NormalizedBillboard } from "utils/billboards/normalizeBillboards";

/** Shows every billboard */
export const Explore: React.FC = () => {
  const [allBillboards] = useSelector(billboardsSelectors.allBillboards);
  useAllBillboards();
  const gmap = useGmap<NormalizedBillboard>();
  const selected = gmap.selected
    ? allBillboards?.find((b) => b.id === gmap.selected?.id)
    : undefined;
  useEffect(() => {
    if (!gmap.map) return;
    const bounds = gmap.map.getBounds();
    if (!bounds) return;
    const northEast = bounds.getNorthEast().toJSON();
    const southWest = bounds.getSouthWest().toJSON();
    const northWest = { lat: northEast.lat, lng: southWest.lng };
    const southEast = { lat: southWest.lat, lng: northEast.lng };
    console.log(
      JSON.stringify({
        lat_bot_rigth: southEast.lat,
        lon_bot_rigth: southEast.lng,
        lat_top_left: northWest.lat,
        lon_top_left: northWest.lng,
      })
    );
  }, [gmap]);
  if (allBillboards === null) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner className="fill-primary-light" />
      </div>
    );
  }
  return (
    <>
      {selected && (
        <BillboardCard
          streetView={(b) => toStreetViewLocation(b, gmap.sview, gmap.pano)}
          onClose={() => {
            gmap.setSelected(undefined);
            if (gmap.pano) {
              gmap.pano.setZoom(1);
              gmap.pano.setVisible(false);
            }
          }}
          className="m-3 w-96 animate-down-up"
          data={selected}
        />
      )}
      <div className="grow">
        <BillboardsMap
          showStreetView
          trafficLayerControl
          placesApi
          billboards={allBillboards}
          gmap={gmap}
          onMapClick={(e) => {
            console.log(e?.toJSON());
          }}
          className="z-0 w-full h-full p-3 rounded-xl"
        />
      </div>
    </>
  );
};
export default Explore;
