import {
  billboardsSelectors,
  billboardsActions,
} from "store/slices/billboards";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const useAllBillboards = () => {
  const [allBillboards, status] = useSelector(billboardsSelectors.allBillboards);
  const dispatch = useDispatch();
  useEffect(() => {
    if (allBillboards === null && status === "idle") {
      console.log("No billboards in store, fetching...");
      dispatch(billboardsActions.willFetchAllBillboards());
    }
  }, [allBillboards, status, dispatch]);
  return;
};
export default useAllBillboards;
