import { addOrderImage, getUploadOrderImageUrl } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import { FilesUploader } from "components/inputs/FilesUploader";
import BasicModal from "components/modals/Basic";
import Tooltip from "components/popovers/Tooltip";
import { useState } from "react";

type Props = {
  open: boolean;
  close: () => void;
  update: () => void;
  orderId?: string | null;
  campaignId?: string | null;
};
export const ReportImagesUploader: React.FC<Props> = ({
  open,
  close,
  update,
  orderId,
  campaignId,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const uploadOrderImages = async () => {
    setIsLoading(true);
    const promises = files.map(async (image) => {
      const imageId = crypto.randomUUID();
      const uploadDate = new Date().toISOString();

      const uploadUrl = await getUploadOrderImageUrl({
        order_id: orderId,
        id: imageId,
        type: image.type,
      });
      console.log(uploadUrl)
      const result:Response = await fetch(uploadUrl!, {
        headers: {
          "Content-Type": image.type,
        },
        method: "PUT",
        body: image,
      }).then((res)=>{
        console.log(res)
        return res
      }).catch((err)=>{
        console.log(err)
        return err
      });
      console.log(result)
      if (result.ok) {
        const res2=await addOrderImage({
          campaign: campaignId,
          order_id: orderId,
          id: imageId,
          upload_date: uploadDate,
          caption: "Test " + imageId,
        });
        console.log(res2)
      }
      console.log("put result", result);
    });
    await Promise.all(promises);
    setIsLoading(false);
    setFiles([]);
    update();
    close();
  };
  return (
    <BasicModal
      loading={isLoading}
      title="Caricamento foto report"
      open={open}
      close={close}
    >
      <div className="flex flex-col gap-3">
        <FilesUploader files={files} setFiles={setFiles} accept="image/*" />
        <Tooltip
          disabled={!!files.length}
          tip="Carica almeno un file per proseguire"
        >
          <BasicButton onClick={uploadOrderImages} disabled={files.length < 1}>
            Conferma
          </BasicButton>
        </Tooltip>
      </div>
    </BasicModal>
  );
};

export default ReportImagesUploader;
