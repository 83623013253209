import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign, MutationUpdateCampaignStateArgs, Order } from "graphql/dsp/generated";
import { RootState } from "store/reducers";

const initialState = {
  myCampaigns: [null, "idle"] as DataWithState<Campaign[]>,
  myCampaignsOrders: [null, "idle"] as DataWithState<Order[]>,
};
// type UserState = typeof initialState;
export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    willFetchCampaigns: (state) => {
      state.myCampaigns = [state.myCampaigns[0], "pending"];
      return state;
    },
    didFetchCampaigns: (state, { payload }: PayloadAction<Campaign[]>) => {
      state.myCampaigns = [payload, "idle"];
      return state;
    },
    willUpdateCampaignState: (
      state,
      _payload: PayloadAction<MutationUpdateCampaignStateArgs>
    ) => {
      state.myCampaigns = [state.myCampaigns[0], "pending"];
      return state;
    },
    // Add campaign
    // Edit campaign
    // Delete campaign
  },
});

export const campaignsSelectors = {
  myCampaigns: (state: RootState) => state.campaigns.myCampaigns,
  myCampaignsOrders: (state: RootState) => state.campaigns.myCampaignsOrders,
};

const { actions, reducer } = campaignsSlice;
export { reducer, actions as campaignsActions };
