import { Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {
  isMediaOwner: boolean;
  forType: "advertiser" | "mediaOwner";
};
/** Checks if the user type corresponds to the page supposed user type */
export const UserTypeWrapper: React.FC<Props> = ({ isMediaOwner, forType }) => {
  const location = useLocation();
  const forMediaOwners = forType === "mediaOwner";
  return isMediaOwner === forMediaOwners ? (
    <Outlet />
  ) : (
    <Navigate replace to={`/unauthorized?path=${location.pathname}`} />
  );
};
export default UserTypeWrapper;
