import Auth from "./Auth";
import Logout from "./Logout";
import UserType from "./UserType";
import HasGroup from "./HasGroup";
import HasSubscription from "./HasSubscription";
import HasKyc from "./HasKyc";
import IsApproved from "./IsApproved";

const wrappers = {
  Auth,
  UserType,
  HasGroup,
  HasSubscription,
  Logout,
  HasKyc,
  IsApproved,
};

export default wrappers;
