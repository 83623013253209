import { Billboard } from "graphql/ssp/generated";
import { billboardF } from "utils/formatters";

const uniqueFormats = (billboards: Billboard[]) => {
  type Format = [string, number];
  const formats: Format[] = [];
  billboards.forEach((b) => {
    if (!b.format) return;
    const f = b.format.toLowerCase();
    const index = formats.findIndex(([fs]) => fs === f);
    if (index === -1) {
      formats.push([f, 1]);
      return;
    }
    formats[index][1]++;
  });
  return formats
    .sort(([a], [b]) => {
      const getArea = (val: typeof a) =>
        String(val)
          .split("x")
          .map((v) => Number(v))
          .reduce((a, b) => a * b, 1);
      return getArea(a) > getArea(b) ? 1 : -1;
    })
    .reverse();
};

type Props = {
  billboards: Billboard[];
};
export const UniqueFormats: React.FC<Props> = ({ billboards }) => {
  const formats = uniqueFormats(billboards);
  return (
    <>
      <div className="font-bold">Formati ({formats.length})</div>
      <div className="flex flex-col justify-between w-full">
        {formats.map(([formatString, amount]) => {
          return (
            <div
              key={formatString}
              className="flex items-center justify-between w-full py-2"
            >
              {billboardF["format"] && billboardF["format"](formatString)}
              <span className="font-medium text-gray-800">{amount}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default UniqueFormats;
