import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { updateOrderState } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import CopyButton from "components/buttons/Copy";
import CardSection from "components/cards/Section";
import BasicModal from "components/modals/Basic";
import { Timer } from "components/others/Timer";
import Tooltip from "components/popovers/Tooltip";
import { Order } from "graphql/ssp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { sleep } from "utils/sleep";
import { shorten } from "utils/strings/shorten";

type OrderAction = "reject" | "confirm";
type Props = {
  order: Order;
  /** Callback to refresh an order state after an update */
  refresh?: () => void;
};
export const OrderWidget: React.FC<Props> = ({ order, refresh }) => {
  const orderDate = new Date(order.order_date);
  const deadline = new Date(orderDate);
  deadline.setHours(deadline.getHours() + 24);

  const changeOrderState = async (orderAction: OrderAction) => {
    await updateOrderState({
      action: orderAction,
      campaign: order.campaign!,
      id: order.id!,
    });
    await sleep(1000);
    refresh?.();
  };
  // TODO: disable unavailable actions
  return (
    <CardSection className="items-stretch gap-0 p-5 py-2 font-medium text-start">
      <small>ID ORDINE</small>
      <small className="flex items-start text-primary">
        <CopyButton longString={String(order.id)} className="p-0 h-fit">
          {shorten(String(order.id), 20)}
        </CopyButton>
      </small>
      {
        /* deadline.valueOf() > Date.now() && */ <>
          <hr className="mt-2 mb-3" />
          <div className="flex justify-between">
            <div className="grid grid-cols-[auto_auto_auto] gap-1 text-xl font-semibold [&>*]:justify-self-center">
              <Timer end={deadline.valueOf()} />
              <div className="text-base font-medium text-gray-500">ore</div>
              <div className="text-base font-medium text-gray-500">min</div>
              <div className="text-base font-medium text-gray-500">sec</div>
            </div>
            <div className="flex items-center gap-1">
              <Tooltip
                tip="Questo ordine è già stato confermato"
                disabled={order.status !== "confirmed"}
              >
                <ConfirmActionModal
                  disabled={order.status === "confirmed"}
                  action="confirm"
                  update={changeOrderState}
                />
              </Tooltip>
              <Tooltip
                tip="Questo ordine è già stato rifiutato"
                disabled={order.status !== "rejected"}
              >
                <ConfirmActionModal
                  disabled={order.status === "rejected"}
                  action="reject"
                  update={changeOrderState}
                />
              </Tooltip>
              {/* <BasicButton
                disabled
                className="p-1 bg-transparent border h-fit text-primary"
              >
                <ArrowPathIcon className="h-6" />
              </BasicButton> */}
            </div>
          </div>
          <hr className="my-3" />
          <div className="text-sm font-normal">
            Hai 24 ore di tempo per rifiutare l’ordine. Decorso questo tempo
            l’ordine verrà automaticamente confermato.
          </div>
        </>
      }
    </CardSection>
  );
};
export default OrderWidget;

type ModalProps = {
  action: "confirm" | "reject";
  update: (orderAction: OrderAction) => Promise<void>;
  disabled?: boolean;
};
const ConfirmActionModal: React.FC<ModalProps> = ({
  action,
  update,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await update(action);
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <>
      <BasicButton
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        className={twMerge(
          "h-fit border bg-transparent p-1 hover:text-white",
          action === "confirm" && "text-green-500 hover:bg-green-600",
          action === "reject" && "text-red-400 hover:bg-red-400"
        )}
      >
        {action === "confirm" && <CheckIcon className="h-6" />}
        {action === "reject" && <NoSymbolIcon className="h-6" />}
      </BasicButton>
      <BasicModal
        loading={isLoading}
        open={isOpen}
        close={() => setIsOpen(false)}
        title={action === "confirm" ? "Conferma ordine" : "Rifiuta ordine"}
      >
        <div className="font-semibold">
          Si è sicuri di voler{" "}
          {action === "confirm" ? "confermare" : "rifiutare"} questo ordine?
        </div>
        <div className="my-4 text-sm text-gray-500">
          {action === "reject" &&
            `Rifiutando l’ordine gli impianti prenotati torneranno immediatamente
            disponibili per le date richieste e il cliente non riceverà nessun
            addebito.`}
          {action === "confirm" &&
            `Cliccando su conferma stai accettando l’ordine, rendendo
            indisponibili gli impianti prenotati per il periodo selezionato`}
        </div>
        <form onSubmit={handleSubmit} className="flex justify-end gap-2">
          <BasicButton
            className="py-1 bg-gray-400 w-fit"
            onClick={() => setIsOpen(false)}
          >
            Annulla
          </BasicButton>
          <BasicButton className="py-1 w-fit" type="submit">
            Conferma
          </BasicButton>
        </form>
      </BasicModal>
    </>
  );
};
