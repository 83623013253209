import { PlusIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import FloatingBasic from "components/inputs/Floating/Basic";
import BasicModal from "components/modals/Basic";
import { Office } from "graphql/ssp/generated";
import Municipalities from "pages/Profile/MediaOwner/Offices/AddMunicipalities";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";

type Props = {
  offices: Office[];
  addOffice: (office: Office) => void;
};
export const AddOfficeModal: React.FC<Props> = ({ offices, addOffice }) => {
  const [open, setOpen] = useState(false);
  const [, status] = useSelector(userSelectors.mediaOwner);
  const [officeData, setOfficeData] = useState<Office>();
  const officeParams: [Partial<keyof Office>, string, string][] = [
    ["name", "Nome", "text"],
    ["address", "Indirizzo", "address"],
    ["phone", "Telefono", "phone"],
    ["email", "Email", "email"],
  ];
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!officeData) return;
    addOffice(officeData);
    setOpen(false);
  };
  return (
    <>
      <BasicButton
        onClick={() => setOpen(true)}
        className="gap-1 py-1 text-gray-600 bg-gray-200 w-44"
      >
        Aggiungi ufficio
        <PlusIcon className="h-4" />
      </BasicButton>
      <BasicModal
        open={open}
        close={() => setOpen(false)}
        title="Aggiungi ufficio"
        bodyClassName="flex flex-col gap-4"
      >
        <form
          onSubmit={handleSubmit}
          id="office-form"
          className="grid grid-cols-2 gap-4"
        >
          {officeParams.map(([key, label, type]) => {
            return (
              <FloatingBasic
                required
                autoComplete={"off"}
                key={key}
                type={type}
                label={label}
                name="none"
                onChange={(e) => {
                  setOfficeData((prev) => ({
                    ...prev,
                    [key]: e.target.value,
                  }));
                }}
              />
            );
          })}
          <Municipalities officeData={officeData} setOfficeData={setOfficeData}/>
        </form>
        <div className="flex justify-end gap-2">
          <BasicButton
            role="reset"
            onClick={() => setOpen(false)}
            loading={status === "pending"}
            className="w-32 py-1 bg-gray-400"
          >
            Annulla
          </BasicButton>
          <BasicButton
            role="submit"
            form={"office-form"}
            loading={status === "pending"}
            className="w-32 py-1"
          >
            Conferma
          </BasicButton>
        </div>
      </BasicModal>
    </>
  );
};
export default AddOfficeModal;
