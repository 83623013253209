import {
  GetUploadOrderImageUrlQuery,
  ListOrderImagesQuery,
  OrderImage,
  QueryGetUploadOrderImageUrlArgs,
  QueryListOrderImagesArgs,
} from "./../../../graphql/ssp/generated";
import { errorCatcher } from "../errorCatcher";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { loader } from "graphql.macro";
import {
  Order,
  GetMediaOwnerQuery,
  ListMyOrdersQuery,
  ListMediaOwnerUsersQuery,
  ListAdvertisersQuery,
  GetOrderQuery,
  ListMyBillboardsQuery,
  Advertiser,
  MediaOwnerUser,
  ListBillboardAvailabilityByRangeQuery,
  QueryListBillboardAvailabilityByRangeArgs,
  Billboard,
  MediaOwner,
  BillboardAvailability,
  GetResourceUrlQuery,
  GetBillboardPricingQuery,
  Pricing,
  QueryGetBillboardPricingArgs,
  QueryGetBillboardArgs,
  GetBillboardQuery,
  CreateStripeLoginLinkQuery,
  QueryCreateStripeLoginLinkArgs,
  GetUploadCsvUrlQuery,
  QueryGetUploadCsvUrlArgs,
  ListFilteredBillboardsQuery,
  QueryListFilteredBillboardsArgs,
  ListMyClientsQuery,
} from "graphql/ssp/generated";
import { resizeImage } from "utils/imageResizer";
import { processOrderState } from "api/gql/utils";

// Used to type the query results
type ListMyBillboards = GraphQLQuery<ListMyBillboardsQuery>;
type GetMediaOwner = GraphQLQuery<GetMediaOwnerQuery>;
type ListMyOrders = GraphQLQuery<ListMyOrdersQuery>;
type ListMyClients = GraphQLQuery<ListMyClientsQuery>;
type ListMediaOwnerUsers = GraphQLQuery<ListMediaOwnerUsersQuery>;
type ListAdvertisers = GraphQLQuery<ListAdvertisersQuery>;
type GetOrder = GraphQLQuery<GetOrderQuery>;
type GetResourceUrl = GraphQLQuery<GetResourceUrlQuery>;
type ListBillboardAvailabilityByRange =
  GraphQLQuery<ListBillboardAvailabilityByRangeQuery>;
type GetBillboardPricing = GraphQLQuery<GetBillboardPricingQuery>;
type GetBillboard = GraphQLQuery<GetBillboardQuery>;
type CreateStripeLoginLink = GraphQLQuery<CreateStripeLoginLinkQuery>;
type GetUploadCsvUrl = GraphQLQuery<GetUploadCsvUrlQuery>;
type ListFilteredBillboards = GraphQLQuery<ListFilteredBillboardsQuery>;
type GetUploadOrderImageUrl = GraphQLQuery<GetUploadOrderImageUrlQuery>;
type ListOrderImages = GraphQLQuery<ListOrderImagesQuery>;
export const getMediaOwner = async () => {
  const query = loader("../../../graphql/ssp/getMediaOwner.gql");
  const promise = API.graphql<GetMediaOwner>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.getMediaOwner;
  return result ? (result satisfies MediaOwner) : null;
};

const _listMyOrders = async (nextToken: string) => {
  const query = loader("../../../graphql/ssp/listMyOrders.gql");
  const promise = API.graphql<ListMyOrders>(
    graphqlOperation(query, { limit: 500, nextToken })
  );
  const result = await errorCatcher(promise);
  return result?.listMyOrders || null;
};

/** Returns all the orders of a MediaOwner */
export const getMediaOwnerOrders = async () => {
  let nextToken: string = "";
  try {
    const aggregatedResult: Order[] = [];
    do {
      const result = await _listMyOrders(nextToken);
      if (result) {
        const orders = result.items?.filter(
          (o): o is Order => o !== null
        ) satisfies Order[] | undefined;
        if (orders) aggregatedResult.push(...orders);
        nextToken = result.nextToken || "";
      } else {
        nextToken = "";
      }
    } while (nextToken);
    return aggregatedResult.map(processOrderState);
  } catch (error) {
    console.log("Failed query", error);
    return null;
  }
};

const _listMyClients = async (nextToken: string) => {
  const query = loader("../../../graphql/ssp/listMyClients.gql");
  const promise = API.graphql<ListMyClients>(
    graphqlOperation(query, { limit: 500, nextToken })
  );
  const result = await errorCatcher(promise);
  return result?.listMyOrders || null;
};
/** Returns all the clients of a MediaOwner */
export const getMediaOwnerClients = async () => {
  let nextToken: string = "";
  try {
    const aggregatedResult: Advertiser[] = [];
    do {
      const result = await _listMyClients(nextToken);
      if (result?.items) {
        const advertisers: Advertiser[] = result.items
          ?.map((item) => item?.advertiser_data) // Remove empty entries if any
          .filter((adv): adv is Advertiser => !!adv) // Assert only existing entries
          .reduce<Advertiser[]>((acc, curr, i) => {
            if (acc.find(({ advertiser }) => advertiser === curr.advertiser)) {
              return acc;
            }
            return [...acc, curr];
          }, []); // only unique entries

        if (advertisers) aggregatedResult.push(...advertisers);
        nextToken = result.nextToken || "";
      } else {
        nextToken = "";
      }
    } while (nextToken);
    return aggregatedResult;
  } catch (error) {
    console.log("Failed query", error);
    return null;
  }
};

export const listAdvertisers = async () => {
  const query = loader("../../../graphql/ssp/listAdvertisers.gql");
  const promise = API.graphql<ListAdvertisers>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.listAdvertisers;
  return result ? (result as Advertiser[]) : null;
};

export const getUploadCSVUrl = async (inputArgs: QueryGetUploadCsvUrlArgs) => {
  const query = loader("../../../graphql/ssp/getUploadCSVUrl.gql");
  const promise = API.graphql<GetUploadCsvUrl>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.getUploadCSVUrl;
  console.log(result);
  return result;
};

/** Fetches the team components */
export const listMediaOwnerUsers = async () => {
  const query = loader("../../../graphql/ssp/listMediaOwnerUsers.gql");
  const promise = API.graphql<ListMediaOwnerUsers>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.listMediaOwnerUsers;
  const filtered = result?.filter((u): u is MediaOwnerUser => u !== null);
  return filtered ? (filtered satisfies MediaOwnerUser[]) : null;
};
export const getOrder = async (campaign: string, id: string) => {
  const query = loader("../../../graphql/ssp/getOrder.gql");
  const promise = API.graphql<GetOrder>(
    graphqlOperation(query, { campaign, id })
  );
  const result = (await errorCatcher(promise))?.getOrder;

  return result ? processOrderState(result satisfies Order) : null;
};

export const getBillboard = async (inputArgs: QueryGetBillboardArgs) => {
  const query = loader("../../../graphql/ssp/getBillboard.gql");
  const promise = API.graphql<GetBillboard>(graphqlOperation(query, inputArgs));
  const result = (await errorCatcher(promise))?.getBillboard;
  return result ? (result satisfies Billboard) : null;
};

export const listBillboardAvailabilityByRange = async (
  inputArgs: QueryListBillboardAvailabilityByRangeArgs
) => {
  const query = loader(
    "../../../graphql/ssp/listBillboardAvailabilityByRange.gql"
  );
  const promise = API.graphql<ListBillboardAvailabilityByRange>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))
    ?.listBillboardAvailabilityByRange;
  return result ? (result as BillboardAvailability[]) : null;
};

const _listMyBillboards = async (nextToken: string) => {
  const query = loader("../../../graphql/ssp/listMyBillboards.gql");
  const promise = API.graphql<ListMyBillboards>(
    graphqlOperation(query, { limit: 1001, nextToken })
  );
  const result = (await errorCatcher(promise))?.listMyBillboards;
  return result || null;
};

/** Returns all the billboards owned by a MediaOwner */
export const getMediaOwnerBillboards = async () => {
  let nextToken: string = "";
  const aggregatedResult: Billboard[] = [];
  do {
    const result = await _listMyBillboards(nextToken);
    if (result) {
      const billboards = result.items ? (result.items as Billboard[]) : [];
      aggregatedResult.push(...billboards);
      nextToken = result.nextToken || "";
    } else {
      nextToken = "";
    }
  } while (nextToken);
  return aggregatedResult;
};

export const createStripeLoginLink = async (
  args: QueryCreateStripeLoginLinkArgs
) => {
  const query = loader("../../../graphql/ssp/createStripeLoginLink.gql");
  const promise = API.graphql<CreateStripeLoginLink>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.createStripeLoginLink;
  return result ? result : null;
};

/** Todo: find out how these APIs are called in the old repo */
export const getUploadOrderImageUrl = async (
  args: QueryGetUploadOrderImageUrlArgs
) => {
  const query = loader("../../../graphql/ssp/getUploadOrderImageUrl.gql");
  const promise = API.graphql<GetUploadOrderImageUrl>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.getUploadOrderImageUrl;
  return result ? result : null;
};

export const _listOrderImages = async (args: QueryListOrderImagesArgs) => {
  const query = loader("../../../graphql/ssp/listOrderImages.gql");
  const promise = API.graphql<ListOrderImages>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.listOrderImages;
  return result ? result : null;
};
/** Returns all the billboards owned by a MediaOwner */
export const listOrderImages = async (order_id: string) => {
  let nextToken: string = "";
  const aggregatedResult: OrderImage[] = [];
  do {
    const result = await _listOrderImages({ nextToken, limit: 20, order_id });
    if (result) {
      const orderImages = result.items ? (result.items as OrderImage[]) : [];
      aggregatedResult.push(...orderImages);
      nextToken = result.nextToken || "";
    } else {
      nextToken = "";
    }
  } while (nextToken);
  return aggregatedResult;
};

export const getBillboardPricing = async (
  inputArgs: QueryGetBillboardPricingArgs
) => {
  const query = loader("../../../graphql/ssp/getBillboardPricing.gql");
  const promise = API.graphql<GetBillboardPricing>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.getBillboardPricing;
  return result ? (result satisfies Pricing) : null;
};

export const getResourceUrl = async (fileName: string) => {
  const query = loader("../../../graphql/ssp/getResourceUrl.gql");
  const promise = API.graphql<GetResourceUrl>(
    graphqlOperation(query, { fileName })
  );
  const result = (await errorCatcher(promise))?.getResourceUrl;
  return result || null;
};

export const listFilteredBillboards = async (
  args: QueryListFilteredBillboardsArgs
) => {
  const query = loader("../../../graphql/ssp/listFilteredBillboards.gql");
  const promise = API.graphql<ListFilteredBillboards>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.listFilteredBillboards;
  return result || null;
};

/**
 * Uploads an image to the S3 bucket. Returns resource url if successful
 * imageId formatting:
 * - Billboard: "billboard_"+ UUID
 * - Business(Media Owner): "pfp_business_" + mediaowner
 * - Personal(Media Owner): "pfp_personal_" + cognito_sub
 * - Business(Advertiser): "pfp_business_" + advertiser
 *
 * Note: the `imageId` mustn't include the file extension but when fetching it will need to, as ".png"
 */
export const uploadImage = async (
  image: File,
  imageId: string,
  makePreview: boolean = true
) => {
  // Gets the upload URL
  const imageIdPng = imageId + ".png";
  const uploadUrl = await getResourceUrl(imageIdPng);
  // If there is no upload URL, return null
  if (!uploadUrl) return null;
  // Uploads the image to S3
  const imageUrl = await fetch(uploadUrl, {
    headers: {
      "Content-Type": "image/jpeg",
      "Cache-Control": "max-age=604800",
    },
    method: "PUT",
    body: image,
  })
    .then((_data) => {
      return resourcesUrl + imageIdPng;
    })
    .catch((err) => console.log(err));
  if (!imageUrl) return;
  // Immeadiately call the resource with `no-cache` header to replace old resource if any
  await fetch(imageUrl, {
    headers: {
      "Cache-Control": "no-cache",
    },
    method: "GET",
  });
  console.log(`Uploaded ${imageId}`);
  if (makePreview) {
    await uploadPreview(image, imageId);
  }
  return imageUrl;
};


export const uploadCsv = async (csv: File) => {
  // Gets the upload URL
  const uploadUrl = await getUploadCSVUrl({ name: csv.name, type: csv.type });
  // If there is no upload URL, return null
  if (!uploadUrl) return null;
  // Uploads the csv to S3
  const csvUrl = await fetch(uploadUrl, {
    headers: {
      "Content-Type": "text/csv",
      "Cache-Control": "max-age=604800",
    },
    method: "PUT",
    body: csv,
  })
    .then((_data) => {
      return resourcesUrl;
    })
    .catch((err) => console.log(err));
  if (!csvUrl) return;
  return csvUrl;
};
const uploadPreview = async (image: File, imageId: string) => {
  const SIZE = 60;
  const imagePreviewId = imageId + "_preview";
  const imagePreview = await resizeImage(image, SIZE);
  if (!imagePreview) return console.warn("Err: Failed to resize");
  await uploadImage(imagePreview, imagePreviewId, false);
};
export const resourcesUrl =
  `https://084313811367-billalo-dmp-${process.env.REACT_APP_ENV}-resources.s3.eu-west-1.amazonaws.com/resources/` as const;
