import { BillboardInput } from "graphql/ssp/generated";

export const inputDefault:BillboardInput ={
    base: 0.0,
    billalo_code: "",
    billboard_class: "",
    calendar: false,
    cod_cimasa: "",
    facade: "",
    format: "",
    height: 0.0,
    height_ground: "",
    iV_municipality: 0.0,
    iV_normalized: 0.0,
    //id: "",
    imageUrl: "",
    indice_visibilita: 0.0,
    is_draft: false,
    lighting: "",
    media_owner: "",
    media_owner_type: "",
    media_type: "",
    n_authorization: "",
    n_sheet: 0,
    notes: "",
    orientation: "",
    panel_index: 0.0,
    press_type: "",
    price: 0.0,
    pricing_id: "",
    size: "",
    visible: false,
    property_type: "",
    location: "",
    address: "",
    location_name: "",
    //city_code: "",
    municipality: "",
    other_location: "",
    latitude: 0.0,
    longitude: 0.0,
    min_period: 0,
}
export default inputDefault;