import { Params, Location, MiscData, Confirm } from "./forms";
import inputDefault from "./forms/inputDefault";
import { BillboardInput, TaxesInput } from "graphql/ssp/generated";
import { useState } from "react";
import { StepForm } from "components/Stepper";
import StepModal from "components/modals/StepModal";
import { uploadImage } from "api/gql/ssp";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import useNotification from "hooks/useNotification";
import { toIso8061 } from "utils/dateFormatter";
type Props = {
  open: boolean;
  close: () => void;
};
export const NewBillboardForm: React.FC<Props> = ({ open, close }) => {
  const dispatch = useDispatch();
  const { toast } = useNotification();
  const [inputData, setInputData] = useState<BillboardInput>(inputDefault);
  const stepForms: [string, StepForm<Partial<BillboardInput>>][] = [
    ["Parametri", Params],
    ["Posizione", Location],
    ["Dati", MiscData],
    ["Conferma", Confirm],
  ];
  return (
    <>
      <StepModal
        open={open}
        close={close}
        title="Carica un nuovo impianto"
        handleComplete={async (data) => {
          const UUID = crypto.randomUUID();
          const {
            id,
            image,
            city_code,
            postal_code,
            "google-map-search": googleMapsSearch,
            last_maintenance,
            authorization_expiration,
            municipal_tax,
            last_payment,
            "": _,
            notes, // Needs sanitization
            ...correctedData
          } = data as BillboardInput & {
            ""?: string;
            postal_code: string;
            image: File | null;
            city_code: string;
            id: string;
            "google-map-search": string;
            maintenance_status: string | null;
            municipal_tax: string | null;
            last_payment: string | null;
          };
          /** Standardizes whitespaces on the description string */
          const sanitizedNotes = singleWhiteSpaces(notes || "");
          const imageId = `billboard_${UUID}`;

          const taxes: TaxesInput | undefined =
            municipal_tax && last_payment
              ? {
                  municipal_tax: Number(municipal_tax),
                  last_payment: toIso8061(last_payment),
                }
              : undefined;
          try {
            const imageUrl = image?.name
              ? await uploadImage(image, imageId)
              : undefined;

            dispatch(
              billboardsActions.willAddBillboard({
                id: UUID,
                city_code: String(city_code),
                input: {
                  ...correctedData,
                  notes: sanitizedNotes,
                  last_maintenance: last_maintenance
                    ? toIso8061(last_maintenance)
                    : undefined,
                  authorization_expiration: authorization_expiration
                    ? toIso8061(authorization_expiration)
                    : undefined,
                  imageUrl,
                  taxes: taxes || undefined,
                },
              })
            );
            toast({
              title: "Creazione impianto",
              imageUrl: imageUrl || undefined,
              text: `L'impianto situato in "${
                correctedData.address || correctedData.location_name
              }" è stato creato con successo`,
              type: "SUCCESS",
            });
          } catch (e) {
            console.log(e);
            toast({
              title: "Errore nella creazione dell' impianto",
              text: `Ci scusiamo per l'inconveniente`,
              type: "DANGER",
            });
          } finally {
            setInputData(inputDefault);
            return data;
          }
        }}
        {...{ inputData, setInputData, stepForms }}
      />
    </>
  );
};
const singleWhiteSpaces = (str: string) => str.replace(/\s+/g, " ").trim();
export default NewBillboardForm;
