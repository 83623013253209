import {
  MapPinIcon,
  CalendarIcon,
  RectangleGroupIcon,
  ViewfinderCircleIcon,
  CurrencyEuroIcon,
  EyeIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import FilterTag, { TagType } from "pages/Planning/Advanced/Search/FilterInput/FilterTag";
import { FilterInput } from "graphql/dsp/generated";

type Props = {
  filterInput: FilterInput;
  setFilterInput?: React.Dispatch<React.SetStateAction<FilterInput>>;
};

export const TagsContainer: React.FC<Props> = ({
  filterInput,
  setFilterInput,
}) => {
  return Object.keys(filterInput).length ? (
    <div className="flex flex-wrap w-full gap-2">
      {(
        [
          [
            "from",
            CalendarIcon,
            "",
            (_, fi) => (
              <div className="flex items-baseline gap-1 text-gray-500">
                Dal:
                <code className="text-base font-semibold text-black">
                  {fi.from.slice(0, 10)}
                </code>
                Al:
                <code className="text-base font-semibold text-black">
                  {fi.to.slice(0, 10)}
                </code>
              </div>
            ),
            (prev) => {
              delete prev.from;
              delete prev.to;
              return { ...prev };
            },
          ],
          ["city", MapPinIcon, "Comuni:", (val) => val.length],
          [
            "media_type",
            RectangleGroupIcon,
            "Indoor: ",
            (_, fi) => fi.media_type?.indoor?.length,
            (prev) => {
              if (!prev.media_type) return prev;
              if (!prev.media_type.outdoor) {
                delete prev.media_type;
                return prev;
              }
              delete prev.media_type.indoor;
              console.log(prev);
              return { ...prev };
            },
          ],
          [
            "media_type",
            RectangleGroupIcon,
            "Outdoor: ",
            (_, fi) => fi.media_type?.outdoor?.length,
            (prev) => {
              if (!prev.media_type) return prev;
              if (!prev.media_type.indoor) {
                delete prev.media_type;
                return prev;
              }
              delete prev.media_type.outdoor;
              return { ...prev };
            },
          ],
          ["format", ViewfinderCircleIcon, "Dimensioni: ", (val) => val.length],
          ["price_from", CurrencyEuroIcon, "Da: "],
          ["price_to", CurrencyEuroIcon, "Fino a: "],
          ["iV_municipality_from", EyeIcon, "Da: "],
          ["iV_municipality_to", EyeIcon, "Fino a: "],
          ["media_owner", BriefcaseIcon, "Concessionarie:", (val) => val.length],
        ] as TagType<keyof FilterInput>[]
      ).map(([filterKey, Icon, text, parser, remove], i) => {
        return (
          <FilterTag
            key={i}
            Icon={Icon}
            text={text}
            filterKey={filterKey}
            filterInput={filterInput}
            setFilterInput={setFilterInput}
            parser={parser}
            remove={remove}
          />
        );
      })}
    </div>
  ) : (
    <i className="text-gray-400">Nessun filtro applicato</i>
  );
};

export default TagsContainer;
