import OnboardingLayout from "layouts/Onboarding";
import MainLayout from "layouts/Main";
import GuestLayout from "layouts/Guest";
import Pages from "pages";
import { Routes, Route, Navigate } from "react-router-dom";
import { userSelectors, userActions } from "store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Wrappers from "pages/wrappers";
function App() {
  const isInitialized = useSelector(userSelectors.isInitialized);
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);
  const isMediaOwner = useSelector(userSelectors.isMediaOwner);
  const [advertiser, advStatus] = useSelector(userSelectors.advertiser);
  const [mediaOwner, mdStatus] = useSelector(userSelectors.mediaOwner);
  const [userAttributes] = useSelector(userSelectors.userAttributes);
  const group = [advertiser, mediaOwner][Number(isMediaOwner)];
  const groupStatus = [advStatus, mdStatus][Number(isMediaOwner)];
  const pendingStatus = userAttributes?.["custom:pending"];
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isInitialized) {
      dispatch(userActions.willInitialize({ isMediaOwner, loadCached: true }));
    }
  }, [isInitialized, dispatch, isMediaOwner]);

  if (!isInitialized || (advStatus === "pending" && mdStatus === "pending")) {
    return <Pages.Loading />;
  }
  return (
    <Routes>
      <Route
        index
        element={
          isLoggedIn ? <Navigate to="dashboard" /> : <Navigate to="login" />
        }
      />
      <Route path="guest" element={<GuestLayout />}>
        <Route path=":advertiser/:campaignId" element={<Pages.Guest.Main />} />
      </Route>

      <Route element={<Wrappers.Auth isAuthenticated={isLoggedIn} />}>
        <Route path="" element={<MainLayout />}>
          <Route
            element={<Wrappers.HasGroup group={group} status={groupStatus} />}
          >
            <Route element={<Wrappers.IsApproved pending={pendingStatus} />}>
              <Route
                element={<Wrappers.HasKyc group={group} status={groupStatus} />}
              >
                <Route element={<Wrappers.HasSubscription group={group!} />}>
                  <Route
                    path="dashboard"
                    element={
                      isMediaOwner ? (
                        <Pages.Dashboard.MediaOwner />
                      ) : (
                        <Pages.Dashboard.Advertiser />
                      )
                    }
                  />
                  <Route
                    path="notifications"
                    element={<Pages.Notifications />}
                  />

                  <Route path="profile">
                    {isMediaOwner ? (
                      <Route
                        path=""
                        element={<Pages.Profile.MediaOwner.Main />}
                      >
                        <Route index element={<Navigate to="business" />} />
                        <Route
                          path="business"
                          element={<Pages.Profile.MediaOwner.Business />}
                        />
                        <Route
                          path="offices"
                          element={<Pages.Profile.MediaOwner.Offices />}
                        />
                        <Route
                          path="personal"
                          element={<Pages.Profile.MediaOwner.Personal />}
                        />
                        <Route
                          path="team"
                          element={<Pages.Profile.MediaOwner.Team />}
                        />
                        <Route
                          path="clients"
                          element={<Pages.Profile.MediaOwner.Clients />}
                        />
                      </Route>
                    ) : (
                      <Route
                        path=""
                        element={<Pages.Profile.Advertiser.Main />}
                      >
                        <Route index element={<Navigate to="business" />} />
                        <Route
                          path="business"
                          element={<Pages.Profile.Advertiser.Business />}
                        />
                        <Route
                          path="personal"
                          element={<Pages.Profile.Advertiser.Personal />}
                        />
                        <Route
                          path="team"
                          element={<Pages.Profile.Advertiser.Team />}
                        />
                      </Route>
                    )}
                  </Route>
                  <Route
                    element={
                      <Wrappers.UserType
                        isMediaOwner={isMediaOwner}
                        forType="mediaOwner"
                      />
                    }
                  >
                    <Route
                      path="admin/batchUpload"
                      element={<Pages.BatchUpload />}
                    />
                    <Route
                      path="billboards"
                      element={<Pages.Billboards.Main />}
                    >
                      <Route
                        index
                        element={<Pages.Billboards.MyBillboards />}
                      />
                      <Route
                        path="explore"
                        element={<Pages.Billboards.Explore />}
                      />
                      <Route
                        path="pricing-rules"
                        element={<Pages.Billboards.PricingRules />}
                      />
                    </Route>

                    <Route path="orders" element={<Pages.Orders.Main />}>
                      <Route index element={<Pages.Orders.MyOrders />} />
                      <Route
                        path="details/:campaignId/:orderId"
                        element={<Pages.Orders.Details />}
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <Wrappers.UserType
                        isMediaOwner={isMediaOwner}
                        forType="advertiser"
                      />
                    }
                  >
                    <Route path="planning">
                      <Route index element={<Pages.Planning.Main />} />
                      <Route
                        path="advanced"
                        element={<Pages.Planning.Advanced.Main />}
                      />
                      <Route
                        path="popularity"
                        element={<Pages.Planning.Popularity />}
                      />
                      <Route
                        path="interactions"
                        element={<Pages.Planning.Interactions />}
                      />
                    </Route>
                    <Route path="campaigns" element={<Pages.Campaigns.Main />}>
                      <Route index element={<Pages.Campaigns.MyCampaigns />} />
                      <Route
                        path="details/:advertiser/:campaignId"
                        element={<Pages.Campaigns.Details />}
                      />
                    </Route>
                  </Route>

                  <Route path="help" element={<Pages.Help />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="unauthorized" element={<Pages.Unauthorized />} />

        <Route
          path="subscription"
          element={<Wrappers.HasKyc group={group} status={groupStatus} />}
        >
          <Route element={<Wrappers.HasSubscription group={group!} not />}>
            <Route element={<OnboardingLayout navbar />}>
              <Route path="" element={<Pages.Subscription />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="complete-registration"
          element={<OnboardingLayout navbar />}
        >
          {/** Must NOT be part of a group to access this page */}
          <Route
            element={
              <Wrappers.HasGroup group={group} status={groupStatus} not />
            }
          >
            <Route
              path="business-name"
              element={<Pages.CompleteRegistration.BusinessName />}
            />
            <Route
              path="backoffice-approval"
              element={<Pages.CompleteRegistration.BackofficeApproval />}
            />
          </Route>
          <Route element={<Wrappers.IsApproved pending={pendingStatus} />}>
            {/** Must NOT have kyc to access this page */}
            <Route
              element={
                <Wrappers.HasKyc group={group} status={groupStatus} not />
              }
            >
              <Route
                path="kyc"
                element={
                  isMediaOwner ? (
                    <Pages.CompleteRegistration.MediaOwner />
                  ) : (
                    <Pages.CompleteRegistration.Advertiser />
                  )
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<Wrappers.IsApproved pending={pendingStatus} not />}>
        <Route element={<OnboardingLayout navbar />}>
          <Route path="pending-approval" element={<Pages.PendingApproval />} />
        </Route>
      </Route>
      <Route path="login" element={<Pages.Login />} />
      <Route path="password-recovery" element={<Pages.PasswordRecovery />} />
      {/** Requires the user to be logged out in order to access */}
      <Route element={<Wrappers.Logout isAuthenticated={isLoggedIn} />}>
        <Route path="signup" element={<OnboardingLayout primary hideLogo />}>
          <Route index element={<Pages.Signup.Main />} />
        </Route>
        <Route path="signup" element={<OnboardingLayout primary />}>
          <Route path="confirm" element={<Pages.Signup.Confirmation />} />
        </Route>
      </Route>
      <Route path="*" element={<Pages.NotFound />} />
    </Routes>
  );
}

export default App;
