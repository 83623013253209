import { useRef, useEffect } from "react";

const placesOptions: google.maps.places.AutocompleteOptions = {
  types: ["administrative_area_level_3"],
  componentRestrictions: { country: "it" },
};
type Props = {
  options?: google.maps.places.AutocompleteOptions;
  onPlaceChanged?: (place: google.maps.places.PlaceResult) => void;
};
export const useLocationAutocomplete = ({
  onPlaceChanged = () => {},
  options = placesOptions,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autocompleteRef.current.addListener("place_changed", async function () {
        const place = autocompleteRef.current?.getPlace();
        if (place === undefined || place === null) return;
        // If there is only one property (name) it means the place was not found
        const length = Object.keys(place).length;
        if (length <= 1 || !place.name) return;
        onPlaceChanged(place);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      });
    }
  }, [autocompleteRef, onPlaceChanged, options]);
  return inputRef;
};
export default useLocationAutocomplete;
