import { twMerge } from "tailwind-merge";

export const TransparentButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...attributes }) => {
  return (
    <button
      className={twMerge(
        "flex w-fit select-none items-center justify-center rounded-lg bg-gray-100 bg-opacity-0 p-2 font-medium transition-all hover:bg-opacity-100",
        className
      )}
      {...attributes}
    >
      {children}
    </button>
  );
};
export default TransparentButton;
