import Stepper, { StepForm } from "components/Stepper";
import { MediaOwnerInput } from "graphql/ssp/generated";
import { useState } from "react";
import Overview from "./forms/Overview";
import Contact from "./forms/Contact";
import HeadOffice from "./forms/HeadOffice";
import Confirm from "./forms/Confirm";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userActions } from "store/slices/user";
import { uploadImage } from "api/gql/ssp";

type MediaOwnerInputExtra = MediaOwnerInput & {
  mediaowner: string;
  business_name: string;
  "google-map-search": string;
  "image-file"?: File | null;
  "image-fakepath"?: string;
  ignore?: string;
};
type Props = {
  className?: string;
};
export const MediaOwnerKycForm: React.FC<Props> = ({ className }) => {
  const [mediaOwner] = useSelector(userSelectors.mediaOwner);
  const [inputData, setInputData] = useState(
    mediaOwner as Partial<MediaOwnerInputExtra>
  );
  const dispatch = useDispatch();

  if (!mediaOwner) return null;
  const stepForms: [string, StepForm<Partial<MediaOwnerInputExtra>>][] = [
    ["Anagrafica", Overview],
    ["Contatti", Contact],
    ["Sede aziendale", HeadOffice],
    ["Conferma", Confirm],
  ];
  return (
    <Stepper
      className={className}
      stepForms={stepForms}
      inputData={inputData}
      setInputData={setInputData}
      handleComplete={async (data) => {
        const {
          mediaowner,
          business_name,
          "image-fakepath": imageFakepath,
          "image-file": imageFile,
          "google-map-search": googleMapSearch,
          ...inputData
        } = data;

        // Image upload
        let imageUrl: string | null |undefined = "";
        if (imageFile) {
          const imageId = `pfp_business_${mediaowner}`;
          imageUrl =  await uploadImage(imageFile, imageId);
        }
        console.log({ imageUrl });

        const inputFixed: MediaOwnerInput = {
          ...inputData,
          // Conditionally add an image if any
          ...(imageUrl && { avatar: imageUrl }),
          // If subscription date is not present fill it with the current date
          subscription_date:
            inputData.subscription_date ||
            new Date().toISOString().substring(0, 10),
        };
        dispatch(userActions.willAddMediaOwner(inputFixed));
        return data;
      }}
    />
  );
};

export default MediaOwnerKycForm;
