import { Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {
  isAuthenticated: boolean;
};
export const AuthWrapper: React.FC<Props> = ({ isAuthenticated }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      replace
      to={`/login?path=${queryParams.get("path") || location.pathname}`}
    />
  );
};
export default AuthWrapper;
