import { Order } from "graphql/ssp/generated";
import { OrderStatus } from "types/Statuses";
import { currencyFormatter } from "utils/currencyFormatter";
import { formatAwsDate } from "utils/dateFormatter";
import { OrderStatusTip } from "utils/formatters/order/OrderStatusTip";

export const orderStatuses: Record<OrderStatus, string[]> = {
  canceled: ["Annullato", "bg-red-100 text-red-500"],
  rejected: ["Rifiutato", "bg-red-400 text-white"],
  pass: ["Errore", "bg-gray-100 text-red-500"],
  confirmed: ["Pending", "bg-yellow-400 text-white"],
  draft: ["Draft", "bg-yellow-400 text-white"],

  scheduled: ["Pianificato", "bg-green-100 text-green-500"],
  paid: ["Pianificato", "bg-green-100 text-green-500"],
  delivered_materials: ["Pianificata", "bg-green-100 text-green-500"],

  posted: ["In corso", "bg-primary-light text-white"],
  completed: ["Terminato", "bg-gray-100 text-gray-500"],
};


export const formatter: FormatterObj<Partial<Order>> = {
  status: (_val,obj) => <OrderStatusTip order={obj}/>,
  advertiser: (val) => (
    <b className="font-medium">{val && val.replaceAll("_", " ")}</b>
  ),
  from: (val, order) => {
    if (!order) return "Error";
    return (
      <small className="">
        {formatAwsDate(val)}
        <br />
        {formatAwsDate(order["to"])}
      </small>
    );
  },
  order_date: (val) => <small>{formatAwsDate(val)}</small>,
  amount: (val) => val && currencyFormatter.format(val),
  printer: (val) => (
    <span className="flex justify-center font-semibold">
      {!val || val === "false" ? "No" : "Si"}
    </span>
  ),
  billboards: (val) => (
    <span className="flex justify-center font-semibold">
      {!val || val.length}
    </span>
  ),
};

export const sorter: SorterObj<Partial<Order>> = {
  amount: (a, b) => (Number(a) > Number(b) ? 1 : -1),
};
