import { BillboardsActionsManager } from "components/lists/Billboards";
import Table from "components/lists/table";
import { Billboard, Order } from "graphql/ssp/generated";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  NormalizedBillboard,
  normalizeBillboards,
} from "utils/billboards/normalizeBillboards";
import { billboardF } from "utils/formatters";
import { orderStatuses } from "utils/formatters/order";
import { isValidOrderStatus } from "utils/formatters/order/OrderStatusTip";

type Props = {
  orders?: Order[];
  setHoveredBillboard?: (billboard?: NormalizedBillboard) => void;
  setFilteredBillboards?: (billboards: NormalizedBillboard[]) => void;
  setSelectedBillboard?: (billboard?: NormalizedBillboard) => void;
};

type BillboardWithStatus = NormalizedBillboard & { status: Order["status"] };
const createOrderBillboards = (orders?: Order[]) => {
  if (!orders) return undefined;
  const billboards: BillboardWithStatus[] = orders.flatMap((o) => {
    const bbs = normalizeBillboards(o.billboards as Billboard[]);

    return bbs.map((b) => ({ ...b, status: o.status }));
  });
  return billboards;
};
export const BillboardOrdersList: React.FC<Props> = ({
  orders,
  setSelectedBillboard,
  setHoveredBillboard,
  setFilteredBillboards,
}) => {
  console.log("Rerender");
  const [currentAction, setCurrentAction] = useState<
    null | [string, Billboard | Billboard[]]
  >(null);
  const [billboards,setBillboards] = useState<BillboardWithStatus[]>([])
  useEffect(()=>{
    setBillboards(createOrderBillboards(orders)||[])
  },[orders])
    console.log(billboards)
  return (
    <>
      {billboards && (
        <Table
          data={billboards}
          className={twMerge("min-h-[250px]")}
          filteredElementsSetter={(b) =>
            setFilteredBillboards?.(b as NormalizedBillboard[])
          }
          onRowClick={(billboard) =>
            setSelectedBillboard?.(billboard as NormalizedBillboard)
          }
          setHovered={(data) => {
            const assertNormalized = data as NormalizedBillboard;
            setHoveredBillboard?.(assertNormalized);
          }}
          cols={{
            address: ["Località", billboardF["address"]],
            status: ["Stato", (status)=>{
                return isValidOrderStatus(status!) ? (
                    <div className="flex items-center justify-center gap-1">
                      <span
                        className={twMerge(
                          "flex flex-nowrap justify-center rounded-lg p-1 px-2 text-center text-xs grow",
                          orderStatuses[status] && orderStatuses[status][1]
                        )}
                      >
                        {orderStatuses[status][0]}
                      </span>
                    </div>
                  ) : (
                    <div className="text-center">{status}</div>
                  );
            }],
          }}
          noSelect
        />
      )}
      <BillboardsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
