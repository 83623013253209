import {
  startPayment,
  updateCampaignState,
  updateOrderState,
  batchAddBillboardAvailability,
} from "api/gql/dsp";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import { Campaign, Order, PricingInput } from "graphql/dsp/generated";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stripe, {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "react-stripe-elements";
import { campaignsActions } from "store/slices/campaigns";
import { userSelectors } from "store/slices/user";
import { currencyFormatter } from "utils/currencyFormatter";
import { getMondays } from "utils/dateFormatter";

// For each order
// update the order state to "pay"
// update campaign state to "pay"
type Props = {
  orders: Order[];
  campaign: Campaign;
  pricingInput: PricingInput;
  setResult?: React.Dispatch<
    React.SetStateAction<"idle" | "error" | "success">
  >;
} & Stripe.ReactStripeElements.InjectedStripeProps;
export const CheckoutForm: React.FC<Props> = ({
  pricingInput,
  stripe,
  elements,
  campaign,
  orders,
  setResult,
}) => {
  const [cognitoAttributes] = useSelector(userSelectors.userAttributes);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const campaignId = campaign.id!;
  const ordersIds = campaign?.orders?.map((o) => o!) || [];

  const handleSubmit = async () => {
    // willpayorder iterations
    let styles = "color:#b849b0;font-weight:semibold;";
    let styles2 = "color:#69098f;font-weight:semibold;";
    if (!stripe || !elements) return false;
    const name = cognitoAttributes?.given_name;
    const surname = cognitoAttributes?.family_name;
    if (!name || !surname) return false;
    const cardElement = elements.getElement("cardNumber");
    if (!cardElement) return false;
    const payment_method = {
      card: cardElement,
      billing_details: {
        name: name + " " + surname,
      },
    };

    console.log("%cCalling startPayment query...", styles);

    const paymentJson = await startPayment({
      campaignId,
      pricing_input: pricingInput,
    });
    if (!paymentJson) return false;

    console.log("%cResult:", styles2, { res: JSON.parse(paymentJson) });

    const { client_secret } = JSON.parse(paymentJson);
    const clientSecret = client_secret as string | undefined;
    if (!clientSecret) return false;
    console.log("%cCalling stripe.confirmCardPayment...", styles);
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method }
    );

    console.log("%cResult:", styles2, { paymentIntent, error });

    paymentIntent
      ? console.log("SUCCESS", { paymentIntent })
      : console.log("ERROR", { error });

    const promises = ordersIds.map((id) => {
      return updateOrderState({ campaign: campaignId, id, action: "pay" });
    });
    console.log("%cUpdating orders states to 'pay'...", styles);
    await Promise.all(promises);
    console.log("%cUpdating campaign state to 'pay'...", styles);
    await updateCampaignState({ id: campaignId, action: "pay" });
    console.log("%cRefetching updated campaigns...", styles);

    const availabilityInput = {
      campaign_id: campaign.id,
      dates: getMondays(new Date(campaign.from), new Date(campaign.to)).map(
        (d) => d.toISOString()
      ),
      input: orders.map((o) => ({
        order_id: o.id!,
        billboards: o.billboards!.map((o) => String(o!.id)),
      })),
    };
    console.log(
      "%cCalling batchAddBillboardAvailability mutation with inputs:",
      styles,
      { availabilityInput }
    );
    const res = await batchAddBillboardAvailability(availabilityInput);
    console.log("%cbatchAddBillboardAvailability result:", styles2, { res });
    dispatch(campaignsActions.willFetchCampaigns());
    return true;
  };

  return (
    <BasicContainer loading={loading} className="w-full p-0">
      <div className="flex justify-center gap-2 text-xl font-semibold text-gray-400">
        <div>Totale da pagare:</div>
        <div className="text-gray-800">
          {currencyFormatter.format(
            (campaign.total_amount ?? 0) +
              (campaign.fee ?? 0) +
              (campaign.discount ?? 0)
          )}
        </div>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          const success = await handleSubmit();
          setResult?.(success ? "success" : "error");
          setLoading(false);
        }}
        className="grid w-full grid-cols-2 gap-5 m-auto text-sm text-gray-600"
      >
        <label className="flex flex-col col-span-2 gap-1 ">
          Intestatario
          <BasicInput
            autoComplete="cc-name"
            className="p-2 border rounded"
            placeholder="Inserire nome e cognome"
          />
        </label>
        <label className="flex flex-col col-span-2 gap-1">
          Numero carta
          <CardNumberElement
            id="cardNumber"
            showIcon
            className="p-2 border rounded"
            placeholder="Inserire numero carta"
          />
        </label>
        <label className="flex flex-col gap-1">
          Data Scadenza
          <CardExpiryElement id="cardExpiry" className="p-2 border rounded" />
        </label>
        <label className="flex flex-col gap-1">
          CVC
          <CardCvcElement id="cardCvc" className="p-2 border rounded" />
        </label>
        <BasicButton className="col-span-2">Acquista</BasicButton>
        <div
          role="button"
          onClick={() => window.open("https://stripe.com")}
          className="flex items-center justify-center col-span-2 py-3"
        >
          <div className="text-lg font-semibold">Powered by</div>
          <img
            className="h-12"
            src="/assets/logos/stripe.png"
            alt="Stripe logo"
          />
        </div>
      </form>
    </BasicContainer>
  );
};

export default CheckoutForm;
