import { Order } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";
import { OrderStatus } from "types/Statuses";
import { orderStatuses } from "./order";
import Tooltip from "components/popovers/Tooltip";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const MAX_DAYS_BEFORE_WARNING = 30;

/** Checks whether the order status is unexpected */
export const isValidOrderStatus = (val: string): val is OrderStatus => {
  const isValid = Object.keys(orderStatuses).includes(val);
  !isValid && console.warn("Unexpected order status: ", val);
  return isValid;
};
/** Checks whether the order status is about to start and not yet on the "delivered materials" state */
const hasMissingMaterials = (order: Partial<Order> | undefined) => {
  if (!order) return false;
  if (order.status !== "paid") return false;
  const msDifference = new Date(order.from).getTime() - new Date().getTime();
  const days = msDifference / (1000 * 3600 * 24);
  if (days >= 0 && days <= MAX_DAYS_BEFORE_WARNING) {
    return true;
  }
  return false;
};
type Props = {
  order: Partial<Order> | undefined;
};
export const OrderStatusTip: React.FC<Props> = ({ order }) => {
  const status = order?.status;
  if (!status) return <></>;
  return isValidOrderStatus(status) ? (
    <div className="flex items-center justify-center gap-1">
      {hasMissingMaterials(order) && <WarningTip />}
      <span
        className={twMerge(
          "flex flex-nowrap justify-center rounded-lg p-1 px-2 text-center text-sm grow",
          orderStatuses[status] && orderStatuses[status][1]
        )}
      >
        {orderStatuses[status][0]}
      </span>
    </div>
  ) : (
    <div className="text-center">{status}</div>
  );
};
const WarningTip: React.FC = () => {
  return (
    <Tooltip
      placement="bottom-end"
      tip={
        <div className="max-w-[300px] whitespace-normal">
          <h3 className="mb-1">
            <b>Materiali mancanti</b>
          </h3>
          <span className="text-gray-500">
            Una o più concessionarie non hanno ricevuto i materiali per la campagna.
          </span>
        </div>
      }
    >
      <ExclamationTriangleIcon className="w-6 h-6 text-yellow-400 stroke-2" />
    </Tooltip>
  );
};
