import { Advertiser } from "graphql/dsp/generated";
import { MediaOwner } from "graphql/ssp/generated";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { userActions } from "store/slices/user";

type Props<T extends Advertiser | MediaOwner | null> = {
  group: T;
  not?: boolean;
  status: Status;
};
export const isAdv = (group: Advertiser | MediaOwner): group is Advertiser => {
  return group.hasOwnProperty("advertiser");
};
export const isMd = (group: Advertiser | MediaOwner): group is MediaOwner => {
  return group.hasOwnProperty("mediaowner");
};

const requiredAdvFields: (keyof Advertiser)[] = [
  "advertiser",
  "society_form",
  "name",
];
const requiredMdFields: (keyof MediaOwner)[] = [
  "mediaowner",
  "society_form",
  "name",
];

export const hasCompleteKyc = (group: Advertiser | MediaOwner | null) => {
  if (!group) return false;
  if (isAdv(group)) {
    return requiredAdvFields.every((field) => {
      return group[field];
    });
  }
  if (isMd(group)) {
    return requiredMdFields.every((field) => {
      return group[field];
    });
  }
  return false;
};

/** Check whenever the user has access to normal content or the kyc pages */
export function HasKycWrapper<T extends Advertiser | MediaOwner | null>({
  group,
  not,
  status,
}: Props<T>) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasCompleteKyc(group)) {
      dispatch(userActions.willExportFreshdesk());
    }
  });
  if (status === "pending") return <Outlet />;
  // console.log("haskyc", { group, status, not });
  if (!group) {
    return <Navigate replace to={"/complete-registration/business-name"} />;
  }
  let hasKyc = false;
  const missingFields: string[] = [];
  if (isAdv(group)) {
    hasKyc = requiredAdvFields.every((field) => {
      !group[field] && missingFields.push(field);

      return group[field];
    });
  }
  if (isMd(group)) {
    hasKyc = requiredMdFields.every((field) => {
      !group[field] && missingFields.push(field);
      return group[field];
    });
  }
  
  //console.log({ missingFields });

  // When the user already has kyc and is trying to access a kyc page
  if (not && hasKyc) {
    return <Navigate replace to={"/dashboard"} />;
  }
  // When the user doesn't have kyc and is trying to access a normal page
  if (!not && !hasKyc) {
    return <Navigate replace to={"/complete-registration/kyc"} />;
  }
  return <Outlet />;
}
export default HasKycWrapper;
