import {
  campaignsSelectors,
  campaignsActions,
} from "store/slices/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export const useMyCampaigns = () => {
  const [campaigns, status] = useSelector(campaignsSelectors.myCampaigns);
  const dispatch = useDispatch();
  useEffect(() => {
    if (campaigns === null && status === "idle") {
      console.log("No campaigns in store, fetching...");
      dispatch(campaignsActions.willFetchCampaigns());
    }
  }, [campaigns, status, dispatch]);
  return;
};
export default useMyCampaigns;
