import ProgressBar from "components/others/Progress/Bar";
import { useTimer } from "hooks/useTimer";
import { twMerge } from "tailwind-merge";

type Props = {
  id?: string;
  duration: number;
  paused?: boolean;
  handleEnd?: () => void;
} & Omit<Parameters<typeof ProgressBar>[0], "value">;
export const ProgressCountDown: React.FC<Props> = ({
  duration = 5,
  paused = false,
  className,
  handleEnd = () => {},
  ...attributes
}) => {
  const tick = 100;
  const progress = useTimer({ duration, paused, tick });
  return (
    <ProgressBar
      barStyle={{ transition: `${tick}ms linear` }}
      value={progress}
      inverted
      onTransitionEnd={progress >= 100 ? handleEnd : undefined}
      containerClassName={twMerge("flex justify-end rotate-180")}
      className={twMerge(className, paused && "opacity-50")}
      {...attributes}
    />
  );
};

export default ProgressCountDown;
