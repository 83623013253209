
import {
  ArchiveBoxIcon,
  ArrowLeftIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import FixedSpaceContainer from "components/containers/FixedSpace";
import SubNavbar from "components/navbars/Sub";
import SubNavButton from "components/navbars/SubNavButton";
import useMyOrders from "hooks/useMyOrders";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

// TODO: Handle status changes to status actions defined in the state machine

/** Shows ongoing and archived orders for the user */
const Orders = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname: currentLocation } = useLocation();
  useMyOrders();
  const inDetails = currentLocation.includes("details");
  const archiveView = searchParams.get("view") === "archive";

  return (
    <div className="flex flex-col w-full h-full">
      <SubNavbar>
        <TransparentButton
          className={twMerge("gap-2", !inDetails && "hidden")}
          onClick={() => navigate("/orders")}
        >
          <ArrowLeftIcon className="h-4" /> Torna agli ordini
        </TransparentButton>
        <div className={twMerge("flex gap-1", inDetails && "hidden")}>
          <SubNavButton
            newParams={[["view", "current"]]}
            active={!archiveView}
            Icon={ClockIcon}
          >
            Attive
          </SubNavButton>
          <SubNavButton
            newParams={[["view", "archive"]]}
            active={archiveView}
            Icon={ArchiveBoxIcon}
          >
            Archivio
          </SubNavButton>
        </div>
        {/* <div className="flex items-center">
          <BasicButton className="py-1">Nuovo Ordine</BasicButton>
        </div> */}
      </SubNavbar>
      <FixedSpaceContainer>
        <Outlet />
      </FixedSpaceContainer>
    </div>
  );
};
export default Orders
