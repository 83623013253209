import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import App from "./App";
import "./index.tailwind.css";
import { LoadScript } from "@react-google-maps/api";
import NotificationsContainer from "components/notifications/Container";
import { StripeProvider } from "react-stripe-elements";

const store = configureStore({});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const { REACT_APP_GMAP_KEY, REACT_APP_STRIPE_KEY } = process.env;
root.render(
  <Provider store={store}>
    <StripeProvider apiKey={REACT_APP_STRIPE_KEY!}>
      <LoadScript
        loadingElement={<></>}
        googleMapsApiKey={REACT_APP_GMAP_KEY!}
        // Used for the search bar
        libraries={["places", "geometry"] as LoadScript["props"]["libraries"]}
      >
        <BrowserRouter>
          <NotificationsContainer />
          <App />
        </BrowserRouter>
      </LoadScript>
    </StripeProvider>
  </Provider>
);
