import { twMerge } from "tailwind-merge";

type Props = {
  user?: string;
  className?: string;
};
const stringToIdx = (string: string, max: number) =>
  [...string].reduce((prev, curr) => prev + curr.charCodeAt(0), 0) % max;

const colors = [
  "#0062FF",
  "#FF974A",
  "#FC5A5A",
  "#82C43C",
  "#A461D8",
  "#50B5FF",
  "#FF9AD5",
  "#FFC542",
  "#3DD598",
];

function hashValue<T>(string: string, arr: Array<T>) {
  return arr[stringToIdx(string, arr.length)];
}
export const PfpHash: React.FC<Props> = ({ user = "user", className }) => {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center font-medium rounded-full text-white w-full h-full",
        className
      )}
      style={{ backgroundColor: hashValue(user, colors) }}
    >
      <div>{user.toLocaleUpperCase().charAt(0)}</div>
    </div>
  );
};
export default PfpHash;
