export const TermsAndConditions:React.FC = () => {
  return (
    <span>
      <b>TERMINI E CONDIZIONI GENERALI DI UTILIZZO ADVERTISER</b>
      <br />
      <br />
      I presenti Termini e Condizioni Generali di utilizzo Advertiser (o
      “Termini Generali") disciplinano l'uso della Piattaforma Billalo da parte
      dell’Advertiser, in conformità con le Condizioni d’Uso di Billalo
      (inserire link), e forniscono informazioni sul Servizio Billalo (il
      “Servizio") e i rapporti intercorrenti con le Concessionarie. Creando un
      profilo Utente o usando Billalo, l'Advertiser accetta i presenti Termini e
      Condizioni generali di utilizzo.
      <br />
      Il Servizio Billalo è fornito dalla Società Billalo S.r.l., con sede
      legale in Cagliari nella via Ravenna n.7 CAP 09125, codice fiscale e
      partita IVA 03889300921, in persona del suo legale rappresentante Nicola
      Palmas. <br />
      Le presenti Condizioni costituiscono l’accordo tra l’Advertiser e la
      Concessionaria, nonché il rapporto tra l’Advertiser e Billalo anche in
      conformità ai suddetti Termini d’Uso di Billalo.
      <br />
      ________________________________________
      <br />
      1. DEFINIZIONI
      <br />
      a) Ai fini dei presenti Termini e Condizioni Generali, fatte salve le
      definizioni altrove attribuite, i termini e le espressioni di seguito
      elencati assumono il significato attribuito a ciascuno di essi, rimanendo
      inteso che il plurale include il singolare e viceversa. Advertiser:
      persona fisica o giuridica, registrata sulla Piattaforma Billalo, che ha
      ideato, realizzato e pianificato la Campagna Pubblicitaria; Billalo:
      Società Billalo S.r.l. Startup Innovativa, proprietaria della Piattaforma
      Billalo; Campagna Pubblicitaria: campagna pubblicitaria che viene ideata
      dall’Advertiser e veicolata dalla Concessionaria negli impianti e Spazi
      Pubblicitari outdoor di cui dispone quest’ultima, previa programmazione
      effettuata sulla piattaforma Billalo; Concessionaria: la concessionaria
      pubblicitaria, pubblica e/o privata, registrata sulla Piattaforma Billalo,
      che promuove sulla medesima i propri impianti e Spazi Pubblicitari
      outdoor; Condizioni d’Uso: insieme di norme generali che governano la
      Piattaforma Billalo e individuano regole generiche valide nei confronti
      degli utenti, Advertiser e Concessionarie; Conferma d'Ordine: documento
      inviato all'Advertiser che riepiloga le condizioni contrattuali
      nell'ambito del Rapporto con la Concessionaria, compresi, senza
      limitazione, gli Spazi Pubblicitari prescelti nonché le Spese; Data
      d’Incarico: data specificata nell'e-mail di conferma; DPA: Diritti sulle
      Pubbliche Affissioni comunali; Giorno Lavorativo: dal lunedì al venerdì
      compreso, ad eccezione di qualsiasi giorno festivo; ICP: imposta comunale
      sulla pubblicità, da intendersi quali gli oneri e imposte amministrative
      sulla pubblicità e affissioni outdoor; Illustrazione Iniziale: eventuale
      fase iniziale, consistente nell’ideazione della Campagna Pubblicitaria.
      Può essere realizzata dall’Advertiser e inviata alla Concessionaria per la
      pubblicazione, ovvero commissionata dall’Advertiser alla Concessionaria
      dietro pagamento delle relative spese. Impianti Pubblicitari: spazi
      pubblicitari di tipologia variabile (a titolo non esaustivo: Affissioni,
      Impalcature su facciate, Spazi speciali all’interno di location di Eventi,
      Campi Sportivi) situati su suolo pubblico o privato, dotati delle apposite
      autorizzazioni da parte degli enti ed autorità competenti, in gestione
      diretta o affidamento alla Concessionaria. Materiali Pubblicitari:
      supporti pubblicitari stampati realizzati dall’Advertiser e consegnati
      alla Concessionaria per la veicolazione della Campagna Pubblicitaria negli
      Spazi Pubblicitari outdoor. A mero titolo esemplificativo e non esaustivo
      essi includono manifesti, poster, banner in diversi materiali, striscioni;
      Mese: è di durata di 28 giorni; Ordine: ordine che incorpora i presenti
      Termini Generali e la Conferma d'Ordine, dato da un Advertiser e accettato
      dalla Concessionaria per l'esposizione di Materiali Pubblicitari;
      Piattaforma Billalo: piattaforma digitale web operativa attraverso il sito
      internet https://www.billalo.com, che consente all’Advertiser di
      pianificare la Campagna Pubblicitaria e alla Concessionaria di promuovere
      i propri impianti e Spazi Pubblicitari outdoor, veicolare la Campagna
      Pubblicitaria stessa e effettuare i relativi pagamenti; Rapporto: rapporto
      tra Advertiser e Concessionaria, instaurato a seguito dei primi contatti
      tra Advertiser e Concessionaria sulla Piattaforma Billalo, che procede con
      la creazione della Campagna Pubblicitaria, conferma dell’Ordine e relativo
      pagamento delle Spese sulla Piattaforma Billalo; Servizio Stampa &
      Consegna: servizio - che potrà, dietro espressa richiesta, essere messo a
      disposizione da Billalo srl - attraverso la Piattaforma Billalo che
      consente di prenotare online da Terzi Fornitori, ovvero rimanere a carico
      dell’Advertiser, ovvero ancora essere richiesto alla Concessionaria, la
      stampa dei Materiali Pubblicitari necessari per la veicolazione della
      Campagna Pubblicitaria ed organizzare la Consegna alla Concessionaria
      Pubblicitaria entro le tempistiche previste; Spazio Pubblicitario:
      Impianto Pubblicitario noleggiato temporaneamente (durata minima una
      Uscita) dalla Concessionaria all’Advertiser al fine di veicolare la
      Campagna Pubblicitaria; Spese: spese indicate nella Conferma d'Ordine che
      indicano gli importi e le tariffe per la prenotazione degli Spazi
      Pubblicitari outdoor, le fee di Billalo S.r.l. nonché gli oneri e imposte
      amministrative sulla pubblicità e affissioni outdoor - ICP; Termini e
      Condizioni Generali di Utilizzo Advertiser: insieme di norme che governano
      le modalità di accesso alla Piattaforma Billalo, le funzionalità e le
      modalità operative finalizzate a realizzare la Campagna Pubblicitaria,
      nonché il rapporto intercorrente tra l'Advertiser e la Concessionaria,
      anche in conformità con le Condizioni d’Uso, oltreché regolare i rapporti
      tra l'Advertiser e Billalo; Territorio: territorio in cui verrà veicolata
      la Campagna Pubblicitaria outdoor; Terzi Fornitori: terzi subappaltatori
      che possono essere incaricati dalla Concessionaria al fine di svolgere,
      materialmente, le attività di veicolazione e pubblicazione della Campagna
      Pubblicitaria negli Spazi Pubblicitari outdoor; Testo pubblicitario:
      slogan, testi scritti, copy, ovvero ogni altra scritta alfanumerica e/o
      simboli, che potranno essere pubblicati sui manifesti e/o qualsiasi altro
      materiale pubblicitario destinato alla veicolazione da parte della
      Concessionaria e che dovrà conformarsi alla normativa vigente in materia;
      Uscita: periodo minimo di occupazione dell’Impianto Pubblicitario, durata
      14 giorni. Utente: Advertiser o Concessionaria, siano essi persona fisica
      e/o giuridica, che, a seguito dell’accettazione delle presenti Condizioni
      d’Uso della Piattaforma, nonché dei Termini e Condizioni generali di
      utilizzo, si registra sulla Piattaforma Billalo;
      <br />
      <br />
      2. ACCETTAZIONE DEI TERMINI E CONDIZIONI GENERALI
      <br />
      a) I presenti Termini e Condizioni sono da considerarsi clausole generali
      che regolano i rapporti tra Advertiser e Concessionaria, nonché nei
      rapporti tra Advertiser e Billalo e che vengono sin d’ora accettate
      dall'Advertiser attraverso lo sbarramento dell’apposita casella mediante
      flag in modalità cosiddetta “click and accept” posta al termine del
      presente modulo. <br />
      <br />
      3. MODIFICHE DEI TERMINI E DELLE CONDIZIONI GENERALI
      <br />
      a) La Piattaforma Billalo si riserva il diritto di modificare
      unilateralmente i Termini Generali con un preavviso di 3 mesi.
      L'Advertiser, a seguito di ciò, avrà il diritto e la facoltà di eliminare
      il proprio account in caso di non accettazione delle modifiche ai Termini
      Generali, restando inteso che non potranno essere cancellati
      dall'Advertiser quegli Ordini già in fase di creazione dell'Illustrazione
      Iniziale, ovvero in fase di esecuzione e/o veicolazione, restando inteso
      invece che potranno essere cancellati quelli aventi una data successiva
      alle tre mensilità di preavviso. In ogni caso troverà espressa
      applicazione l’Art. 8 dei presenti Termini Generali.
      <br />
      b) Resta in ogni caso inteso che troveranno comunque applicazione le
      Condizioni d’Uso alle quali espressamente si rimanda (inserire link),
      nella parte in cui è impregiudicata la facoltà per l'Advertiser di
      eliminare il proprio account in qualsiasi momento anche in assenza di
      eventuali modifiche dei presenti Termini Generali.
      <br />
      <br />
      4. ACCOUNT ADVERTISER
      <br />
      a) L’Advertiser, a seguito della registrazione nella Piattaforma Billalo
      come indicato nelle Condizioni d’Uso [inserire link], creerà il proprio
      account personale su Billalo. L’Advertiser che avrà effettuato
      correttamente la registrazione potrà effettuare il log-in alla Piattaforma
      mediante l’inserimento del proprio nome utente (indirizzo email) e
      password. L’Advertiser, una volta effettuato il log-out, potrà mantenere
      l’accesso automatico senza la necessità di inserire nuovamente le proprie
      credenziali.
      <br />
      b) L’Advertiser, una volta effettuato il log-in come indicato al comma che
      precede, potrà: - accedere all’area riservata “Profilo”, ove potrà
      modificare i propri dati personali, aggiornarli e/o completare il profilo,
      oltreché procedere all’attivazione/disattivazione delle notifiche, ovvero
      effettuare il log-out; - visualizzare, anche attraverso la mappa
      geo-localizzata, le Concessionarie registrate alla Piattaforma, ovvero
      ricercarli per nome, verificare le Schede delle Concessionarie, contenente
      altresì le recensioni; - visualizzare i Territori e gli Spazi Pubblicitari
      outdoor offerti dalle singole Concessionarie, quindi aggiungerli alle
      Campagne Pubblicitarie e procedere all’Ordine; - a seguito dell’Ordine,
      l’Advertiser riceverà la Conferma d’Ordine e potrà pagare le Spese; - una
      volta terminato il Rapporto, l’Advertiser potrà lasciare una recensione
      nella sezione dedicata (inserire link); <br />
      <br />
      5. CREAZIONE DELL’ILLUSTRAZIONE INIZIALE <br />
      a) A seguito dei primi contatti, l’Advertiser invierà alla Concessionaria
      l’Illustrazione iniziale nel formato concordato. <br />
      b) Se l’Illustrazione Iniziale dovesse essere realizzata dalla
      Concessionaria, i diritti di proprietà intellettuale della medesima
      saranno trasferiti all’Advertiser al buon esito dell'integrale pagamento
      delle Spese, restando inteso che la Concessionaria non potrà avanzare
      alcuna pretesa, né vantare alcun diritto di sfruttamento (anche
      commerciale) dell'opera realizzata, salvo ne acquisti la titolarità
      dall’Advertiser, restando comunque impregiudicata la paternità autorale
      dell'opera stessa.
      <br />
      c) Nel caso di cui al comma a), la Concessionaria potrà proporre delle
      modifiche di natura meramente grafica e/o di impaginazione e/o di stampa,
      entro 30 (trenta) giorni dalla presentazione dell’Illustrazione Iniziale
      e/o comunque in tempo utile per la successiva pubblicazione e veicolazione
      della Campagna Pubblicitaria. L’Advertiser si impegna a fornire una
      risposta su tali modifiche entro 14 (quattordici) giorni dalla ricezione
      delle medesime, salvo richiesta di proroga da inoltrarsi attraverso la
      Piattaforma Billalo. In difetto la Concessionaria riterrà tacitamente
      accettate le proposte di adattamento grafico e/o di impaginazione e/o di
      stampa necessarie per la veicolazione della Campagna Pubblicitaria.
      <br />
      d) Qualora la Concessionaria dovesse svolgere le attività previste ai
      commi b) e c) che immediatamente precedono, l’Advertiser è tenuto a
      corrispondere eventuali spese e/o oneri sostenuti per il servizio svolto,
      impegnandosi fin d’ora a manlevare Billalo da qualsiasi pretesa che possa
      derivare dalla Concessionaria.
      <br />
      <br />
      6. REALIZZAZIONE DELLA CAMPAGNA PUBBLICITARIA
      <br />
      a) L’Advertiser, qualora necessario ed esclusivamente attraverso la
      Piattaforma Billalo, prenderà contatti con la Concessionaria al fine di
      eseguire le attività necessarie all’organizzazione e realizzazione della
      Campagna Pubblicitaria, che dovranno poi essere inserite nella Conferma
      d’Ordine per la successiva pubblicazione e veicolazione della Campagna
      Pubblicitaria stessa. <br />
      b) La Campagna Pubblicitaria dovrà essere concordata tra l’Advertiser e la
      Concessionaria. L’Advertiser ha piena autorità per tutto ciò che concerne
      l’immissione di Ordini, nonché per la modifica e l’approvazione della
      Campagna Pubblicitaria. <br />
      c) L’Advertiser potrà scegliere gli Spazi Pubblicitari Outdoor sulla base
      della disponibilità della Concessionaria. Qualora quest’ultima lo ritenga
      opportuno, anche a seguito dell’accordo sugli Spazi Pubblicitari outdoor,
      potrà proporre all’Advertiser entro 30 giorni antecedenti l’avvio della
      Campagna Pubblicitaria, nuovi Spazi Pubblicitari outdoor, che abbiano le
      medesime caratteristiche di quelli concordati nella Conferma d’Ordine.
      L’Advertiser potrà accettare o meno e, in quest’ultimo caso, sempre che la
      Concessionaria non intenda dare esecuzione alla Conferma d’Ordine e
      assegnare all’Advertiser gli Spazi Pubblicitari colà richiesti,
      l’Advertiser avrà diritto a un indennizzo da parte della Concessionaria,
      restando inteso che Billalo è sin d’ora manlevata e tenuta indenne da ogni
      responsabilità e/o richiesta e/o pretesa di indennizzo. Nel caso, invece,
      di eventuali situazioni di overbooking e/o di indisponibilità degli Spazi
      Pubblicitari outdoor richiesti, ancorché confermati nell’ambito della
      Conferma d’Ordine, per cause ascrivibili alla responsabilità della
      Concessionaria, quest’ultima avrà l’onere di proporre all’Advertiser di
      utilizzare Spazi Pubblicitari outdoor alternativi aventi le medesime
      caratteristiche di quelli precedentemente prescelti, che potranno essere
      accettati o meno dall’Advertiser e, in quest’ultimo caso, l’Advertiser
      avrà diritto a un indennizzo da parte della Concessionaria, restando
      inteso che Billalo è sin d’ora manlevata e tenuta indenne da ogni
      responsabilità e/o richiesta e/o pretesa di indennizzo. Fatto salvo e
      impregiudicato tutto quanto sopra indicato, resta inteso che Billalo sarà
      unicamente responsabile per eventuali disservizi della Piattaforma, così
      come indicato nelle Condizioni d’Uso alle quali si rimanda espressamente.
      <br />
      d) L‘Advertiser invierà l’Ordine alla Concessionaria esclusivamente
      attraverso la Piattaforma, effettuando un pagamento online che verrà
      trattenuto dalla Piattaforma per 24 ore. La concessionaria avrà quindi
      facoltà, entro 24 ore, di rifiutare l’ordine con conseguente rimborso
      dell’Advertiser da parte della Piattaforma. Decorse 24 ore senza un
      esplicito rifiuto dell’Ordine da parte della Concessionaria, l’Ordine
      verrà confermato e l’Advertiser riceverà attraverso la Piattaforma la
      Conferma d’Ordine. L'Advertiser è l’unico responsabile del pagamento delle
      Spese per la Campagna Pubblicitaria, le quali verranno elencate e inserite
      analiticamente nella Conferma d’Ordine.
      <br />
      e) La Concessionaria potrà evidenziare in sede di presentazione della
      Campagna Pubblicitaria, e comunque sempre prima dell’inizio della fase di
      veicolazione e pubblicazione, eventuali rischi di violazione dei messaggi
      pubblicitari rispetto ai diritti di terzi e/o al Codice di Autodisciplina
      Pubblicitaria, pur non rappresentando per la Concessionaria stessa alcun
      obbligo specifico. Di conseguenza, laddove l’Advertiser intendesse
      procedere con la veicolazione e pubblicazione della Campagna
      Pubblicitaria, nonostante i suggerimenti della Concessionaria,
      quest’ultima non sarà responsabile in caso di specifica approvazione da
      parte dell’Advertiser.
      <br />
      f) La Campagna Pubblicitaria sarà esposta sugli Spazi Pubblicitari outdoor
      resi disponibili dalla Concessionaria, sulla base della disponibilità
      della medesima e comunque resi prenotabili nei calendari disponibili sulla
      Piattaforma Billalo e successivamente confermati nella Conferma d’Ordine.
      g) La Concessionaria potrà affidare qualsiasi fase della realizzazione
      della Campagna Pubblicitaria a Terzi Fornitori. In tal caso la
      Concessionaria sarà da considerarsi come unica responsabile della corretta
      pubblicazione e veicolazione della Campagna Pubblicitaria. Gli accordi
      intercorrenti tra la Concessionaria e i Terzi Fornitori non potranno in
      alcun modo compromettere la corretta esecuzione della Campagna
      Pubblicitaria. In caso di pregiudizi, danni e/o qualsiasi altra
      circostanza che possa andare a violare tale clausola, risponderà
      esclusivamente la Concessionaria nei confronti dell’Advertiser, lasciando
      indenne la Piattaforma da qualsiasi pretesa. <br />
      h) La Campagna Pubblicitaria avrà la durata stabilita nella Conferma
      d’Ordine, con possibilità di proroga qualora vi siano le condizioni e
      dietro prenotazione degli Spazi Pubblicitari outdoor attraverso la
      Piattaforma Billalo.
      <br />
      i) Qualora la Concessionaria non realizzi la Campagna Pubblicitaria
      secondo quanto richiesto dall’Advertiser, anche in sede di modifica
      successiva, quest’ultimo avrà diritto al risarcimento del danno (da
      quantificarsi in separata sede). In ogni caso la Concessionaria si impegna
      a mantenere indenne la Piattaforma da qualsiasi pretesa dell’Advertiser.{" "}
      <br />
      <br />
      7. DIRITTI E OBBLIGHI DELL’ADVERTISER
      <br />
      a) L’Advertiser si impegna a manlevare Billalo da qualsiasi responsabilità
      civile e/o penale e/o amministrativa che dovesse derivare dalla Campagna
      Pubblicitaria. In particolare, l’Advertiser dichiara e garantisce
      espressamente di manlevare e tenere Billalo indenne da ogni danno, diretto
      o indiretto, responsabilità, pretesa, pregiudizio, azione giudiziaria, e
      ogni relativo costo e/o richiesta di risarcimento dei danni da parte
      dell’Advertiser e/o della Concessionaria e/o da parte di terzi, restando
      inteso che Billalo agisce solo e esclusivamente in qualità di operatore
      tecnologico e proprietario della Piattaforma Billalo e che, pertanto,
      nessuna condotta potrà essere ritenuta ascrivibile a Billalo e alla
      Piattaforma Billalo, restando inteso che ogni e eventuale danno e/o
      responsabilità di quest’ultima è espressamente indicata e garantita
      nell’ambito delle Condizioni Generali d’Uso della Piattaforma Billalo e
      comunque nell’ambito dei rapporti intercorrenti tra Billalo, l’Advertiser
      e/o la Concessionaria.
      <br />
      b) L’Advertiser si impegna affinché la Campagna Pubblicitaria non violi
      nessuna norma legislativa e/o regolamentare, ovvero non sia contraria
      all’ordine pubblico e/o agli usi. In ogni caso l’Advertiser si impegna a
      manlevare la Piattaforma Billalo da qualsiasi responsabilità. <br />
      c) L’Advertiser si impegna a ottenere tutte le licenze e i consensi –
      compresi quelli attinenti al diritto d’autore - necessari per la creazione
      della Campagna Pubblicitaria, manlevando la Piattaforma Billalo da
      qualsiasi pretesa in tal senso che possa provenire dalla Concessionaria
      e/o da terzi. <br />
      d) In ogni caso di inadempimento, totale e/o parziale, la cui
      responsabilità si ascrivibile alla Concessionaria, che possa determinare
      una non corretta veicolazione e/o pubblicazione della Campagna
      Pubblicitaria, ovvero che possa compromettere l’immagine e/o il messaggio
      pubblicitario, la Concessionaria avrà l’onere di porvi rimedio, a propria
      cura e spese, entro un termine di _____ (______) giorni, così da
      consentire all’Advertiser di proseguire con la pubblicazione e la
      veicolazione della Campagna Pubblicitaria. Rimane, in ogni caso, salvo e
      impregiudicato il diritto dell’Advertiser di richiedere e ottenere il
      risarcimento del maggior danno subito. Non potrà, comunque, essere
      avanzata alcuna pretesa nei confronti della Piattaforma Billalo, né da
      parte della Concessionaria, né da parte dell’Advertiser. e) L’Advertiser
      avrà diritto ad avere immediata comunicazione da parte della
      Concessionaria qualora si dovessero verificare delle circostanze (es.
      interruzione della corrente, deviazione del traffico, chiusura della
      strada) che potrebbero determinare una variazione nella qualità dello
      Spazio Pubblicitario outdoor. La Concessionaria potrà proporre
      all’Advertiser, al fine di ovviare a tali situazioni, altri Spazi
      Pubblicitari outdoor ovvero la restituzione di parte delle Spese che
      eventualmente siano già state sostenute da parte dell’Advertiser, ovvero
      un posticipo dell’inizio della veicolazione della Campagna Pubblicitaria
      sugli Spazi Pubblicitari outdoor della Concessionaria. In ogni caso,
      l’Advertiser non avrà nulla a pretendere dalla Piattaforma.
      <br />
      f) Qualora si verifichino cause di forza maggiore, ivi comprese epidemie
      e/o pandemie, inclusa la contingente epidemia da Sars-Cov19, la
      Concessionaria, anche in ragione di provvedimenti amministrativi, potrà
      sospendere la Campagna Pubblicitaria. Compatibilmente con le disponibilità
      della Concessionaria, pur non rappresentando alcun obbligo rimediale,
      Advertiser e Concessionaria potranno concordare nuove e differenti
      soluzioni di pubblicazione e veicolazione della Campagna Pubblicitaria,
      restando inteso che Billalo S.r.l. e la Piattaforma Billalo si ritengono
      sin d’ora mallevate e tenute indenni da ogni responsabilità.
      <br />
      g) L’Advertiser si impegna a mantenere indenne la Piattaforma Billalo da
      qualsiasi richiesta che dovesse provenire dalla Concessionaria in ordine
      ai corrispettivi, ai sensi dell’art. 10.
      <br />
      <br />
      8. CANCELLAZIONE DELL’ORDINE DA PARTE DELL’ADVERTISER
      <br />
      a) Se l’Advertiser cancella un Ordine, gli saranno addebitati i seguenti
      costi: <br />
      1. Nessun costo se avviene entro 24 ore dalla prenotazione; <br />
      2. I costi di produzione dell’Illustrazione Iniziale, qualora questa sia
      stata realizzata dalla Concessionaria ai sensi dell’art. 5, ma non si sia
      proseguito oltre;
      <br />
      b) Se l’Advertiser cancella un Ordine a seguito dell’invio della Conferma
      d’Ordine: <br />
      1. Se il Periodo della Campagna è più di 90 giorni dalla Data di Incarico,
      all'Advertiser viene addebitato il 15% delle spese, escluse le spese per
      la produzione dell'Illustrazione Iniziale, che saranno quantificate a
      parte qualora sia stata realizzata dalla Concessionaria. 2. Se il Periodo
      della Campagna è di 90 giorni o meno ma più di 75 giorni dalla Data di
      Incarico, all'Advertiser viene addebitato il 30% delle spese, esclusi i
      costi per la produzione dell'Illustrazione Iniziale, che saranno
      quantificate a parte qualora sia stata realizzata dalla Concessionaria. 3.
      Se il Periodo della Campagna è di 75 giorni o meno ma più di 60 giorni
      dalla Data di Incarico, all'Advertiser viene addebitato il 40% delle
      spese, esclusi i costi per la produzione dell'Illustrazione Iniziale, che
      saranno quantificate a parte qualora sia stata realizzata dalla
      Concessionaria. 4. Se il Periodo della Campagna è di 60 giorni o meno ma
      più di 45 giorni dalla Data di Incarico, all'Advertiser viene addebitato
      il 60% delle spese, esclusi i costi per la produzione dell'Illustrazione
      Iniziale, che saranno quantificate a parte qualora sia stata realizzata
      dalla Concessionaria. 5. Se il Periodo della Campagna è di 45 giorni o
      meno ma più di 30 giorni dalla Data di Incarico, all'Advertiser viene
      addebitato l'80% delle spese, esclusi i costi per la produzione
      dell'Illustrazione Iniziale, che saranno quantificate a parte qualora sia
      stata realizzata dalla Concessionaria. 6. Se il Periodo della Campagna è
      di 30 giorni o meno dalla Data di Incarico, all'Advertiser viene
      addebitato il 100% delle spese, esclusi i costi per la produzione
      dell'Illustrazione Iniziale, che saranno quantificate a parte qualora sia
      stata realizzata dalla Concessionaria. 9. INDENNIZZO PER L’ADVERTISER IN
      CASO DI ANNULLAMENTO DELL’ORDINE DA PARTE DELLA CONCESSIONARIA a) La
      Concessionaria ha facoltà di rifiutare, attraverso la Piattaforma, un
      Ordine entro le 24 ore successive al ricevimento dello stesso da parte
      dell’Advertiser. Decorso tale periodo, per annullare un Ordine confermato
      le saranno addebitati i seguenti costi: 1. Nessun costo se avviene fino a
      90 giorni prima dell’avvio della Campagna Pubblicitaria; <br />
      2) Se la Concessionaria richiede l’annullamento di un Ordine a seguito
      dell’invio della Conferma d’Ordine e nei 90 giorni precedenti l’avvio
      della Campagna Pubblicitaria, verrà concordato un eventuale indennizzo per
      l’Advertiser, salvo quanto stabilito all’art. 6. In ogni caso la
      Concessionaria terrà indenne la Piattaforma da qualsiasi pretesa possa
      derivare dall’Advertiser. <br />
      <br />
      10. CORRISPETTIVI E MODALITÀ DI PAGAMENTO
      <br />
      a) Il corrispettivo per l’attività svolta dalla Concessionaria dovrà
      essere specificato nella Conferma d’Ordine e sarà valutato dalla medesima
      con assoluta discrezione. Le fee della Piattaforma, come meglio
      specificato nelle Condizioni d’Uso [inserire link] saranno pari al ___%
      (___ per cento) del corrispettivo.
      <br />
      b) La Conferma d’Ordine sarà inviata dalla Concessionaria all'Advertiser
      attraverso la Piattaforma, secondo le modalità previste dalle Condizioni
      d’Uso [inserire link]. <br />
      c) I pagamenti dovranno avvenire nel momento in cui l’Advertiser riceve la
      Conferma d’Ordine, salvo esplicite richieste che possano essere formulate
      dall’Advertiser antecedentemente all’invio della Conferma d’Ordine.
      Qualora la Concessionaria non riceva il pagamento entro la data di
      scadenza, l’Advertiser sarà tenuto al pagamento di una mora alla
      Concessionaria, parametrata al ritardo e al valore dell’affare. <br />
      d) La Concessionaria ha il diritto di annullare qualsiasi Ordine per il
      quale non sia stato effettuato il pagamento entro i termini stabiliti,
      ovvero di rifiutarsi di esporre la Campagna Pubblicitaria, restando in
      ogni caso salva la possibilità di richiedere un risarcimento del danno
      all’Advertiser. La Piattaforma Billalo sarà in ogni caso mallevata e
      tenuta indenne da ogni richiesta che possa pervenire dalla Concessionaria.{" "}
      <br />
      e) In caso di mancato rispetto di una qualsiasi delle disposizioni della
      presente clausola, la Concessionaria ha il diritto di richiedere, mediante
      messaggio all’Advertiser tramite la Piattaforma, che i conti futuri siano
      trattati in conformità con il punto che segue. <br />
      f) Nel caso in cui qualsiasi parte di un pagamento richiesto dalla
      Concessionaria sia contestato dall'Advertiser, il pagamento in relazione a
      tale parte può essere sospeso in attesa della risoluzione della
      controversia. Il pagamento non contestato seguirà la disciplina prevista
      in questo articolo. <br />
      g) Qualora, per cause eccezionali, la Concessionaria dovesse affrontare
      spese straordinarie, non quantificate in sede di Conferma d’Ordine,
      riconducibili all’esecuzione delle attività di veicolazione e
      pubblicazione della Campagna Pubblicitaria, la cui responsabilità sia
      ascrivibile per qualsivoglia causa e/o ragione all’Advertiser,
      quest’ultimo sarà tenuto a rimborsare alla Concessionaria la differenza
      degli importi entro ____ (_____) giorni dall’invio della richiesta tramite
      la Piattaforma Billalo. In ogni caso la Concessionaria e l’Advertiser
      terranno indenni la Piattaforma da qualsiasi richiesta. <br />
      <br />
      11. RISERVATEZZA a) L’Advertiser e la Concessionaria manterranno la più
      assoluta riservatezza, anche dopo la cessazione del Rapporto, su tutte le
      informazioni di carattere tecnico, economico e commerciale di cui fossero
      venute reciprocamente a conoscenza in occasione dell’esecuzione della
      Campagna Pubblicitaria, salvo per quanto sia necessario comunicare a soci,
      dipendenti, amministratori, rappresentanti, consulenti e finanziatori per
      l’attuazione di quanto in essa previsto.
      <br />
      b) Advertiser e Concessionaria si impegnano a:
      <br />
      1. Utilizzare le informazioni ed i dati acquisiti esclusivamente per le
      finalità previste dal Rapporto; 2. Ai sensi dell’art. 1381 cod. civ.,
      imporre i medesimi obblighi di riservatezza e confidenzialità anche al
      proprio eventuale personale dipendente, nonché a consulenti/collaboratori
      esterni utilizzati per l’adempimento delle prestazioni oggetto dei
      presenti Termini e Condizioni; 3. Adottare ogni altra misura necessaria a
      garantire il rispetto dei menzionati obblighi.
      <br />
      c) L’obbligo previsto nel presente articolo, permane anche successivamente
      alla cessazione del Rapporto per qualsivoglia causa intervenuta, fatta
      eccezione per le seguenti informazioni:
      <br />
      1. Informazioni che siano divenute di dominio pubblico per motivi diversi
      dalla violazione degli accordi sottostanti al Rapporto; 2. Informazioni
      delle quali Advertiser e/o Concessionaria fossero già legittimamente in
      possesso al momento della rivelazione; 3. Informazioni che si renda
      necessario rivelare per ordine di un’Autorità giurisdizionale o
      amministrativa o in adempimento di obblighi di legge, purché vengano
      comunque predisposte le misure necessaria per la tutela delle esigenze di
      riservatezza. <br />
      <br />
      12. COMUNICAZIONI
      <br />
      a) Tutte le comunicazioni inerenti al Rapporto dovranno essere effettuate
      a mezzo lettera raccomandata a.r. indirizzata ai domicili eletti dalle
      Parti ovvero comunicate attraverso la Piattaforma Billalo, e avranno
      effetto dal momento della loro ricezione.
      <br />
      <br />
      13. CLAUSOLA RISOLUTIVA ESPRESSA
      <br />
      a) Il Rapporto si intenderà risolto di diritto ex art. 1456 c.c. in caso
      di inadempimento o inesatto adempimento delle rispettive obbligazioni
      previste all’artt. 6, co. l) e 7, co. m). <br />
      b) In tali ipotesi, verrà data comunicazione contenente l’intimazione a
      porvi rimedio entro 15 (quindici) giorni dal ricevimento della stessa,
      così come disposto dall’art. 1454 c.c.
      <br />
      c) Decorso inutilmente tale periodo, il Rapporto si intenderà risolto di
      diritto e senza necessità di comunicazione alcuna. <br />
      <br />
      14. PRIVACY E TRATTAMENTO DEI DATI PERSONALI
      <br />
      a) L’Advertiser dichiara di essere informato e presta il consenso al
      trattamento dei dati ai sensi del Regolamento Europeo sulla Protezione dei
      Dati Personali (Reg. UE n.679 del 2016). <br />
      b) L’Advertiser autorizza l’inserimento in banca dati dei suoi dati
      biografici e di quelli relativi alla Campagna Pubblicitari ed il loro
      conseguente utilizzo. <br />
      <br />
      15. COOKIE POLICY E PRIVACY POLICY
      <br />
      a) Consulta le norme sulla Privacy e sui Cookie della Piattaforma per
      ulteriori informazioni.
      <br />
      <br />
      16. RECLAMI
      <br />
      a) Qualora dovessero insorgere delle contestazioni tra Advertiser e
      Concessionaria, nonché nei confronti della Piattaforma Billalo, queste
      dovranno essere presentate, preliminarmente, presso la sezione dedicata
      della Piattaforma.
      <br />
      b) Nell’ipotesi in cui, entro 15 (quindici) giorni dalla presentazione
      della contestazione, non sia stato dato riscontro, l’Utente potrà agire
      come indicato all’art. 17.
      <br />
      <br />
      17. GIURISDIZIONE
      <br />
      a) I presenti Termini e Condizioni sono regolati dalla legge italiana.
      <br />
      b) L’Advertiser e la Piattaforma concordano di eleggere come esclusivo, ex
      artt. 28 e 29 c.p.c., il Foro di Cagliari in caso di controversia che
      dovesse sorgere tra le stesse in relazione all’interpretazione, validità,
      esecuzione e/o violazione dei presenti Termini e Condizioni. <br />
      c) Laddove all’esito dei Reclami, di cui all’art. 16, non si addivenisse a
      una soluzione bonaria della controversia, il Foro competente sarà
      individuato di volta in volta sulla base delle esigenze dell’Advertiser e
      della Concessionaria.
      <br />
      <br />
      18. NORME FINALI
      <br />
      a) I presenti Termini e Condizioni, da interpretarsi secondo buona fede,
      costituiscono l’accordo integrale, indivisibile ed efficace tra
      l’Advertiser e la Piattaforma. <br />
      b) Laddove una o più disposizioni fossero da ritenersi nulle o inefficaci,
      per qualsivoglia ragione, detta circostanza non comporterà la nullità o
      l’inefficacia dei presenti Termini e Condizioni Generali. <br />
      <br />
      L’Advertiser accetta le seguenti clausole ai sensi degli artt. 1341 e 1342
      codice civile: <br />
      Articolo 5, commi c), d)
      <br />
      Articolo 6, commi c), d), l)
      <br />
      Articolo 7, commi a), b), c), d), e) f), g), h)
      <br />
      Articolo 10, commi d), g)
      <br />
      Articolo 13, comma a)
      <br />
      Articolo 17, comma b)
    </span>
  );
};
export default TermsAndConditions;
