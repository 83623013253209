import ActionsButton from "components/buttons/Actions";
import { csvExportBillboards } from "components/CsvUploader/billboards/export";
import BillboardsActionsManager from "components/lists/Billboards/Actions";
import Table, { Props as TableProps } from "components/lists/table";
import { Billboard } from "graphql/ssp/generated";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { billboardF, billboardS } from "utils/formatters";
// TODO: Multi selection bugfix (resets the first checkbox onclick probably due to rerender)
export type Props = {
  myBillboards: Billboard[];
  setFilteredBillboards?: (billboards: Billboard[]) => void;
  onRowClick?: (billboard: Billboard) => void;
  setHovered?: (data: Billboard | undefined) => void;
  className?: string;
  hideActions?: boolean;
} & Omit<TableProps<Billboard>, "data" | "cols">;
export const BillboardsMiniList = ({
  myBillboards,
  className,
  setHovered,
  setFilteredBillboards,
  onRowClick,
  hideActions,
  ...tableAttributes
}: Props) => {
  console.log("rerender")
  const [currentAction, setCurrentAction] = useState<
    null | [string, Billboard | Billboard[]]
  >(null);
  return (
    <>
      <Table
        data={myBillboards}
        className={twMerge("min-h-[250px]", className)}
        filteredElementsSetter={setFilteredBillboards}
        onRowClick={onRowClick}
        setHovered={setHovered}
        actions={
          hideActions
            ? undefined
            : [
                ["Esporta CSV", (selection) => csvExportBillboards(selection)],
                [
                  <span className="text-red-400">Elimina impianti</span>,
                  (data) => setCurrentAction(["delete", data]),
                ],
              ]
        }
        rowActions={
          hideActions
            ? undefined
            : [
                [
                  (billboard) => (
                    <ActionsButton
                      data={billboard || {}}
                      actions={[
                        [
                          <span className="text-red-400">
                            Elimina impianto
                          </span>,
                          (data) =>
                            setCurrentAction(["delete", data as Billboard]),
                        ],
                      ]}
                    />
                  ),
                ],
              ]
        }
        cols={{
          address: ["Località", billboardF["address"]],
          format: ["Formato", billboardF["format"], billboardS["format"]],
        }}
        {...tableAttributes}
      />
      <BillboardsActionsManager
        action={currentAction}
        closeAction={() => setCurrentAction(null)}
      />
    </>
  );
};
export default BillboardsMiniList;
