import BillboardScroll from "components/cards/Billboard/Scroll";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  deps?: React.DependencyList;
  containerClassName?:string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ScrollableContainer: React.FC<Props> = ({
  className,
  containerClassName,
  children,
  deps=[],
  ...attributes
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const el = e.currentTarget;
    const bottom = el.scrollHeight - el.scrollTop - el.clientHeight;
    const computedIsScrolled = bottom < 20;
    if (computedIsScrolled !== isScrolled) setIsScrolled(computedIsScrolled);
  };
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // Checks if the container is overflowing and is scrollable
    if (!containerRef.current) return;
    const el = containerRef.current;
    const bottom = el.scrollHeight - el.scrollTop - el.clientHeight;
    if (bottom > 0) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
      setIsScrolled(false);
      return;
    }
    setIsScrolled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return (
    <div
      className={twMerge("relative max-h-full w-full shrink-0", className)}
      {...attributes}
    >
      <div
        onScroll={handleScroll}
        className={twMerge("flex flex-col justify-start w-full max-h-full gap-3 p-0 pb-5 overflow-y-scroll text-xs hover-scrollbar rounded-xl",containerClassName)}
        ref={containerRef}
      >
        {children}
        {/** A ref to be scrolled to when the scroll button is clicked, must stay down here */}
        <div ref={bottomRef} />

        {/* Button appearing when theres more content to view, scrolls to bottom onClick */}
        {!isScrolled && (
          <BillboardScroll
            onClick={() => {
              bottomRef.current &&
                bottomRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScrollableContainer;
