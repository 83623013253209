import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import CalendarSingle from "components/inputs/calendar/Single";
import Select from "components/inputs/Select";
import { FilterInput } from "graphql/dsp/generated";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { noTimezoneDate } from "utils/dateFormatter";
import options from "utils/formatters/billboardOptions";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};
const formatDate = (date: Date) => {
  return noTimezoneDate(date).split("-").reverse().join("/");
};
const daysDiff = (date1: Date, date2: Date) =>
  Math.ceil(
    Math.abs(date1.valueOf() - date2.valueOf()) / (1000 * 60 * 60 * 24)
  );
/** Checks whether the given monday date is a "quattordicina" monday by confronting against a factual "quattordicina" */
const isBiweek = (date: Date) => {
  const quattordicina = new Date("2023-01-02");
  return !!((daysDiff(date, quattordicina) / 7) % 2);
};
export const Period: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  // Imports the previous filters but omitting the time and timezone
  let filterInputRange: DateRange = {
    from: filterInput.from
      ? new Date(filterInput.from.slice(0, 10))
      : undefined,
    to: filterInput.to ? new Date(filterInput.to.slice(0, 10)) : undefined,
  };
  const calcWeeks = (date1: Date, date2: Date) => {
    return Math.ceil(daysDiff(date1, date2) / 7);
  };

  const [range, setRange] = useState<DateRange | undefined>(filterInputRange);
  const test =
    filterInputRange.to && filterInputRange.from
      ? calcWeeks(filterInputRange.from, filterInputRange.to)
      : 2;
  const [period, setPeriod] = useState<number | undefined>(test);
  const [selected, setSelected] = useState<Date | undefined>(
    filterInputRange.from
  );
  useEffect(() => {
    let to = undefined;
    if (selected && period) {
      to = new Date(selected);
      to.setDate(to.getDate() + period * 7 - 1);
    }
    setRange({ from: selected, to: to });
  }, [selected, period, setRange]);

  return (
    <BasicContainer className="items-start gap-0">
      <div className="mb-3 font-medium">Periodo di disponibilità</div>
      <div className="grid w-full grid-cols-2 gap-2 px-4">
        <div className="flex items-center gap-1">
          <div>Dal: </div>
          {range?.from ? (
            <div className="text-sm font-medium text-gray-500">
              {formatDate(range.from)}
            </div>
          ) : (
            <i className="text-gray-400">
              <small>seleziona</small>
            </i>
          )}
        </div>
        <div className="flex items-center gap-3">
          <div>Al: </div>
          {range?.to ? (
            <div className="text-sm font-medium text-gray-500">
              {formatDate(range.to)}
            </div>
          ) : (
            <i className="text-gray-400">
              <small>seleziona</small>
            </i>
          )}
        </div>
      </div>

      <CalendarSingle
        modifiers={{
          disabled: [
            (date) => date.getDay() !== 1 || isBiweek(date),
            (date) => date < new Date(),
          ],
        }}
        selected={selected}
        setSelected={setSelected}
        className="single-calendar"
      />
      <div className="flex flex-col w-full">
        <div className="text-sm font-semibold text-gray-500">
          Seleziona la durata della campagna
        </div>
        <Select
          defaultValue={period}
          onChange={(e) => {
            const value = Number(e.target.value);
            setPeriod(value);
          }}
          required
        >
          {options.min_period.map(([alias, value], i) => (
            <option key={i} value={value}>
              {alias.replaceAll("_", " ")}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex justify-end w-full mt-4">
        <BasicButton
          disabled={!range?.from || !range?.to}
          className="p-1 px-3 w-fit"
          onClick={() => {
            // Removes the timezone offsets before setting the filters timestamps
            setFilterInput((prev) => {
              if (!range?.from || !range?.to) return prev;
              return {
                ...prev,
                from: range.from
                  ? new Date(noTimezoneDate(range.from)).toISOString()
                  : undefined,
                to: range.to
                  ? new Date(noTimezoneDate(range.to)).toISOString()
                  : undefined,
              };
            });
          }}
        >
          Applica
        </BasicButton>
      </div>
    </BasicContainer>
  );
};

export default Period;
