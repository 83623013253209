import { getLatLng, HasLatLng } from "./utils";
import { useState } from "react";

export function useGmap<T extends HasLatLng>() {
  const [map, setMap] = useState<google.maps.Map>();
  const [pano, setPano] = useState<google.maps.StreetViewPanorama>();
  const [sview, setSview] = useState<google.maps.StreetViewService>();
  const [selected, setSelected] = useState<T>();
  const [hovered, setHovered] = useState<T>();

  const selCenter = selected ? getLatLng(selected) : undefined;
  const hovCenter = hovered ? getLatLng(hovered) : undefined;

  return {
    /** Google map instance */
    map,
    /** Google map instance  setter*/
    setMap,
    /** StreetViewPanorama instance */
    pano,
    /** StreetViewPanorama instance setter */
    setPano,
    /** StreetViewService instance */
    sview,
    /** StreetViewService instance setter */
    setSview,
    /** The currently selected marker/data in map */
    selected,
    /** Setter for the currently selected marker/data in map */
    setSelected,
    /** The currently hovered marker/data in map */
    hovered,
    /** Setter for the currently hovered marker/data in map */
    setHovered,
    /** The selected element's center position, undefined if null */
    selCenter,
    /** The selected element's center position, undefined if null */
    hovCenter,
  };
}
export type GmapHookObject<T extends HasLatLng> = ReturnType<typeof useGmap<T>>