import { useState, useEffect } from "react";

/** @param end deadline in unix format */
export const useCountdown = (end: number) => {
  const time = end - Date.now().valueOf();
  const [[hours, minutes, seconds], setTime] = useState(convertMsToTime(time));
  useEffect(() => {
    if (Date.now().valueOf() >= end) {
      setTime(["00", "00", "00"]);
      return;
    }
    setTimeout(() => {
      const updated = convertMsToTime(end - Date.now().valueOf());
      setTime(updated);
    }, 999);
  }, [end, seconds]);
  return [hours, minutes, seconds];
};
function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}
function convertMsToTime(milliseconds: number) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  return [padTo2Digits(hours), padTo2Digits(minutes), padTo2Digits(seconds)];
}
export default useCountdown;
