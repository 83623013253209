export const PendingApproval: React.FC = () => {
  return (
    <>
      <div className="text-lg font-medium text-gray-500">
        L'azienda indicata ha ricevuto una richiesta di accettazione.
      </div>
      <div className="text-2xl font-semibold text-gray-700">
        Rimani in attesa di venire accettato dalla tua azienda
      </div>
    </>
  );
};
export default PendingApproval;
