import { addBillboard } from "api/gql/ssp";
import { BillboardInput } from "graphql/ssp/generated";
import { CsvBillboardInput } from "pages/BatchUpload/utils";
import { toIso8061 } from "utils/dateFormatter";
import municipalities from "utils/location/municipalities.json";

export const uploadCsvParsedBillboards = async (
  csvBillboards: CsvBillboardInput[]
) => {
  const promises = csvBillboards.map((b) => {
    return addBillboard({
      city_code: municipalities.find(
        ([m]) => m === String(b.municipality).toLowerCase()
      )![1],
      id: crypto.randomUUID(),
      input: processCsvBillboard(b),
    });
  });
  return await Promise.all(promises)
};

const processCsvBillboard = (billboard: CsvBillboardInput) => {
  const adjusted = { ...billboard };

  // Numbers
  adjusted["price"] = Number(adjusted["price"]);
  adjusted["min_period"] = Number(adjusted["min_period"]);
  adjusted["base"] = Number(adjusted["base"]);
  adjusted["height"] = Number(adjusted["height"]);
  adjusted["latitude"] = Number(adjusted["latitude"]);
  adjusted["longitude"] = Number(adjusted["longitude"]);

  // Numbers or undefined
  adjusted["content_duration"] = billboard.content_duration
    ? Number(billboard["content_duration"])
    : undefined;
  adjusted["content_rotation"] = billboard.content_rotation
    ? Number(billboard["content_rotation"])
    : undefined;
  adjusted["municipal_tax"] = billboard.municipal_tax
    ? Number(billboard["municipal_tax"])
    : undefined;

  // Dates or undefined
  adjusted["last_maintenance"] = adjusted["last_maintenance"]
    ? toIso8061(adjusted["last_maintenance"])
    : undefined;
  adjusted["authorization_expiration"] = adjusted["authorization_expiration"]
    ? toIso8061(adjusted["authorization_expiration"])
    : undefined;
  adjusted["last_payment"] = adjusted["last_payment"]
    ? toIso8061(adjusted["last_payment"])
    : undefined;

  // Check if taxes
  if (adjusted["last_payment"] && adjusted["municipal_tax"]) {
    adjusted["taxes"] = {
      last_payment: adjusted["last_payment"],
      municipal_tax: adjusted["municipal_tax"],
    };
  }
  delete adjusted["last_payment"];
  delete adjusted["municipal_tax"];

  // Add missing params
  adjusted["size"] = adjusted["base"] * adjusted["height"] + "";
  adjusted["format"] = adjusted["base"] + "x" + adjusted["height"];
  return adjusted as BillboardInput;
};
