import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { loader } from "graphql.macro";
import { errorCatcher } from "../errorCatcher";
import {
  AddAdvertiserGroupMutation,
  MutationAddAdvertiserArgs,
  MutationAddAdvertiserGroupArgs,
  Advertiser,
  UpdateOrderStateMutation,
  AddAdvertiserMutation,
  MutationUpdateOrderStateArgs,
  AddCampaignMutation,
  MutationAddCampaignArgs,
  MutationUpdateOrderStatusArgs,
  UpdateOrderStatusMutation,
  BatchAddOrdersMutation,
  MutationBatchAddOrdersArgs,
  Order,
  Campaign,
  BatchDeleteOrdersMutation,
  MutationBatchDeleteOrdersArgs,
  MutationUpdateCampaignStateArgs,
  UpdateCampaignStateMutation,
  ExportFreshdeskMutation,
  AcceptAdvertiserUserMutation,
  MutationAcceptAdvertiserUserArgs,
  MutationRejectAdvertiserUserArgs,
  RejectAdvertiserUserMutation,
  BatchAddBillboardAvailabilityMutation,
  MutationBatchAddBillboardAvailabilityArgs,
  CreateCampaignMutation,
  MutationCreateCampaignArgs,
} from "graphql/dsp/generated";

type UpdateOrderState = GraphQLQuery<UpdateOrderStateMutation>;
type UpdateCampaignState = GraphQLQuery<UpdateCampaignStateMutation>;
type UpdateOrderStatus = GraphQLQuery<UpdateOrderStatusMutation>;
type AddAdvertiserGroup = GraphQLQuery<AddAdvertiserGroupMutation>;
type AddAdvertiser = GraphQLQuery<AddAdvertiserMutation>;
type AddCampaign = GraphQLQuery<AddCampaignMutation>;
type BatchAddOrders = GraphQLQuery<BatchAddOrdersMutation>;
type BatchDeleteOrders = GraphQLQuery<BatchDeleteOrdersMutation>;
type ExportFreshdesk = GraphQLQuery<ExportFreshdeskMutation>;
type AcceptAdvertiserUser = GraphQLQuery<AcceptAdvertiserUserMutation>;
type RejectAdvertiserUser = GraphQLQuery<RejectAdvertiserUserMutation>;
type BatchAddBillboardAvailability =
  GraphQLQuery<BatchAddBillboardAvailabilityMutation>;
  type CreateCampaign =
    GraphQLQuery<CreateCampaignMutation>;

export const addAdvertiser = async (args: MutationAddAdvertiserArgs) => {
  const query = loader("../../../graphql/dsp/addAdvertiser.gql");
  const promise = API.graphql<AddAdvertiser>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.addAdvertiser;
  return result ? (result as Advertiser) : null;
};
/** Creates a new group (if group doesn't exist) and adds the cognito `username` user to it */
export const addAdvertiserGroup = async (
  advertiser: string,
  username: string
) => {
  const args: MutationAddAdvertiserGroupArgs = { advertiser, username };
  const query = loader("../../../graphql/dsp/addAdvertiserGroup.gql");
  const promise = API.graphql<AddAdvertiserGroup>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.addAdvertiserGroup;
  return result || null;
};

export const updateOrderState = async (args: MutationUpdateOrderStateArgs) => {
  const query = loader("../../../graphql/dsp/updateOrderState.gql");
  const promise = API.graphql<UpdateOrderState>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.updateOrderState;
  return result ? result : null;
};

export const createCampaign = async (args: MutationCreateCampaignArgs) => {
  const query = loader("../../../graphql/dsp/createCampaign.gql");
  const promise = API.graphql<CreateCampaign>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.createCampaign;
  return result ? result : null;
};

export const exportFreshdesk = async () => {
  const query = loader("../../../graphql/dsp/exportFreshdesk.gql");
  const promise = API.graphql<ExportFreshdesk>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.exportFreshdesk;
  return result ? result : null;
};

export const updateCampaignState = async (
  args: MutationUpdateCampaignStateArgs
) => {
  const query = loader("../../../graphql/dsp/updateCampaignState.gql");
  const promise = API.graphql<UpdateCampaignState>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.updateCampaignState;
  return result ? result : null;
};

export const updateOrderStatus = async (
  args: MutationUpdateOrderStatusArgs
) => {
  const query = loader("../../../graphql/dsp/updateOrderStatus.gql");
  const promise = API.graphql<UpdateOrderStatus>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.updateOrderStatus;
  return result ? result : null;
};

export const batchDeleteOrders = async (
  args: MutationBatchDeleteOrdersArgs
) => {
  const query = loader("../../../graphql/dsp/batchDeleteOrders.gql");
  const promise = API.graphql<BatchDeleteOrders>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.batchDeleteOrders;
  return result ? result : null;
};

export const addCampaign = async (args: MutationAddCampaignArgs) => {
  const query = loader("../../../graphql/dsp/addCampaign.gql");
  const promise = API.graphql<AddCampaign>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.addCampaign;
  return result ? (result as Campaign) : null;
};

export const batchAddOrders = async (args: MutationBatchAddOrdersArgs) => {
  if (!args.orders) return null;
  const query = loader("../../../graphql/dsp/batchAddOrders.gql");
  const promises = [];
  for (let i = 0; i < args.orders.length; i += 25) {
    promises.push(
      errorCatcher(
        API.graphql<BatchAddOrders>(
          graphqlOperation(query, {
            input: args.input,
            orders: args.orders.slice(i, i + 25),
          })
        )
      )
    );
  }
  const promisesResult = await Promise.all(promises);
  const result: Order[] = promisesResult
    .map((val) => val?.batchAddOrders)
    .flat()
    .filter((val): val is Order => !!val);
  //const result = (await errorCatcher(promise))?.batchAddOrders;
  return result ? result : null;
};

/** Accepts a pending target_user into the current user's group */
export const acceptAdvertiserUser = async (
  inputArgs: MutationAcceptAdvertiserUserArgs
) => {
  const query = loader("../../../graphql/dsp/acceptAdvertiserUser.gql");
  const promise = API.graphql<AcceptAdvertiserUser>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.acceptAdvertiserUser;
  return result ? result : null;
};
export const rejectAdvertiserUser = async (
  inputArgs: MutationRejectAdvertiserUserArgs
) => {
  const query = loader("../../../graphql/dsp/rejectAdvertiserUser.gql");
  const promise = API.graphql<RejectAdvertiserUser>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.rejectAdvertiserUser;
  return result ? result : null;
};
export const batchAddBillboardAvailability = async (
  inputArgs: MutationBatchAddBillboardAvailabilityArgs
) => {
  const query = loader(
    "../../../graphql/dsp/batchAddBillboardAvailability.gql"
  );
  const promise = API.graphql<BatchAddBillboardAvailability>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.batchAddBillboardAvailability;
  return result ? result : null;
};
