import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import { FilterInput } from "graphql/dsp/generated";
import { useState } from "react";
import billboardOptions from "utils/formatters/billboardOptions";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};
export const Format: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  const [isModified, setIsModified] = useState(false);
  const [selectedFormats, setSelectedFormats] = useState<string[]>(
    filterInput?.format?.map((s) => String(s)) || []
  );

  const toggleFormat = (format: string) => {
    setSelectedFormats((prev) => {
      prev.includes(format)
        ? prev.splice(prev.indexOf(format), 1)
        : prev.push(format);
      return [...prev];
    });
  };
  return (
    <BasicContainer className="items-start">
      <form
        className="flex flex-col gap-3"
        onSubmit={(e) => {
          e.preventDefault();
          setFilterInput((prev) => {
            if (!selectedFormats.length) {
              const { format, ...rest } = prev;
              return rest;
            }
            return { ...prev, format: selectedFormats };
          });
          setIsModified(false);
        }}
        onChange={() => setIsModified(true)}
      >
        <div className="font-medium">Dimensioni</div>
        <div>Seleziona i formati (base x altezza)</div>
        <div className="flex flex-col py-2">
          <div className="flex flex-col font-medium">
            {billboardOptions["format"].map((format) => {
              return (
                <label
                  key={format}
                  className="flex items-center justify-between gap-4 border-b cursor-pointer"
                >
                  <div className="flex items-center gap-2 select-none">
                    {format}
                  </div>
                  <input
                    className="w-5 h-5"
                    readOnly
                    type="checkbox"
                    checked={selectedFormats.includes(format)}
                    onClick={() => toggleFormat(format)}
                  />
                </label>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end w-full">
          <BasicButton
            role="submit"
            disabled={!isModified}
            className="p-1 px-3 w-fit"
          >
            Applica
          </BasicButton>
        </div>
      </form>
    </BasicContainer>
  );
};

export default Format;
