import { XMarkIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import BasicInput from "components/inputs/Basic";
import { FilterInput } from "graphql/dsp/generated";
import useLocationAutocomplete from "hooks/useLocationAutocomplete";
import { useState } from "react";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};

export const Location: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  const filterInputCities = filterInput?.city?.filter((c): c is string => !!c);
  const [locations, setLocations] = useState<string[]>(filterInputCities || []);
  const [isModified, setIsModified] = useState(false);

  const addLocation = (location: string) => {
    setLocations((curr) => {
      return Array.from(new Set([...curr, location]));
    });
  };
  const removeLocation = (location: string) => {
    setLocations((curr) => {
      const idx = curr.indexOf(location);
      if (idx >= 0) curr.splice(idx, 1);
      return [...curr];
    });
  };
  const inputRef = useLocationAutocomplete({
    onPlaceChanged: (place) => addLocation(String(place.name)),
  });

  return (
    <BasicContainer className="items-start max-w-sm">
      <div className="flex flex-col gap-3">
        <div className="font-medium">Località</div>
        <div className="text-sm">
          Digita nel campo qui sotto, e seleziona uno dei suggerimenti per
          aggiungere al filtro.
        </div>
        <div className="flex items-center gap-2">
          <BasicInput
            onBlur={(e) => {
              // e.currentTarget.value = "";
            }}
            onChange={() => !isModified && setIsModified(true)}
            type="search"
            placeholder="Seleziona un comune"
            required={false}
            innerRef={inputRef}
          />
        </div>
        <div className="w-full">
          <div className="pb-2 text-gray-600">
            Località selezionate
            {locations.length ? `(${locations.length})` : null}:
          </div>
          <div className="flex flex-wrap justify-center gap-2">
            {!locations.length && <i className="text-gray-400">Nessuna</i>}
            {locations.map((location) => (
              <LocationTag
                key={location}
                location={location}
                removeLocation={removeLocation}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-end w-full">
          <BasicButton
            disabled={!isModified}
            role="button"
            className="p-1 px-3 w-fit"
            onClick={() => {
              console.log("clicked");
              setIsModified(false);
              if (!locations.length) return;
              setFilterInput((filters) => ({
                ...filters,
                city: locations,
              }));
            }}
          >
            Applica
          </BasicButton>
        </div>
      </div>
    </BasicContainer>
  );
};

type TagProps = {
  location: string;
  removeLocation?: (location: string) => void;
};
export const LocationTag: React.FC<TagProps> = ({
  location,
  removeLocation,
}) => {
  return (
    <div className="flex items-center gap-1 px-1 bg-gray-100 border rounded-lg">
      {removeLocation && (
        <div
          role="button"
          className="text-white bg-gray-400 rounded-full hover:bg-gray-700"
          onClick={() => removeLocation(location)}
        >
          <XMarkIcon className="h-4" />
        </div>
      )}
      <div>{location}</div>
    </div>
  );
};
export default Location;
