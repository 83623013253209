import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import TwoWayRange from "components/inputs/TwoWayRange";
import { FilterInput } from "graphql/dsp/generated";
import { useState } from "react";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
};
export const Period: React.FC<Props> = ({ filterInput, setFilterInput }) => {
  const [visibility, setVisibility] = useState<{
    iV_municipality_from?: FilterInput["iV_municipality_from"];
    iV_municipality_to?: FilterInput["iV_municipality_to"];
  }>({});
  const [isModified, setIsModified] = useState(false);
  return (
    <BasicContainer className="items-start">
      <form
        className="flex flex-col gap-3"
        onSubmit={(e) => {
          e.preventDefault();

          setFilterInput((filters) => ({ ...filters, ...visibility }));
          setIsModified(false);
        }}
        onChange={() => setIsModified(true)}
      >
        <div className="font-medium">Indice di Visibilità</div>
        <div className="max-w-[430px]">L'indice di visibilità è un coefficiente calcolato attraverso gli algoritmi di Billalo. Indica la visibilità in un punteggio da 0 a 10. </div>
        <div className="mx-6 my-5 ">
          <TwoWayRange
            displayValues
            handleChange={(values) => {
              const temp = [...values];
              temp.sort((a,b)=>a-b)
              const [min, max] = temp;
              setVisibility({
                iV_municipality_from: min,
                iV_municipality_to: max,
              });
            }}
          />
        </div>
        <div className="flex justify-end w-full">
          <BasicButton
            role="submit"
            disabled={!isModified}
            className="p-1 px-3 w-fit"
          >
            Applica
          </BasicButton>
        </div>
      </form>
    </BasicContainer>
  );
};

export default Period;
