import {
  GoogleMap,
  GoogleMapProps,
  OverlayViewF,
  StreetViewPanorama,
  StreetViewService,
  TrafficLayer,
} from "@react-google-maps/api";

/** To create a new style: https://mapstyle.withgoogle.com/ */
import customMapType from "./mapType.json";

import { memo, useEffect, useRef, useState } from "react";
import SearchInput from "components/maps/GoogleMapComponent/SearchInput";

import MarkerClusterer, { Props as MarkerClustererProps } from "./Clusterer";

import { Props as MarkerWithDataProps } from "./MarkerWithData";
import { twMerge } from "tailwind-merge";
import { LayerToggler } from "components/maps/GoogleMapComponent/LayerToggler";

const defaultCenter = { lat: 41, lng: 12 };

//const ITALY_BOUNDS = {
//  west: 6.2499552751,
//  south: 36.319987291,
//  east: 18.9802470232,
//  north: 47.2153931748,
//};

type MapProps<T> = {
  dataArray: T[];
  /** Callback to get the marker data */
  onMarkerClick?: MarkerWithDataProps<T>["onClick"];
  /**
   * Defines what happens when the user drags a marker.
   * If this is not defined, the marker will not be draggable.
   */
  onMarkerMove?: (event: google.maps.MapMouseEvent) => void;
  /** Callback to set the marker icon */
  onMapClick?: (
    latLng: google.maps.LatLng | null,
    map: google.maps.Map
  ) => void;
  /** Callback to get the instance of the map */
  onMapLoad?: (map: google.maps.Map) => void;
  /** Callback to get the streetViewPanorama instance */
  onPanoramaLoad?: (pano: google.maps.StreetViewPanorama) => void;
  /** Callback to get the streetViewPanorama instance */
  onSviewServiceLoad?: (sview: google.maps.StreetViewService) => void;
  /** Callback to determine the icon to set on the marker, depending on its data */
  markerIconSelector?: MarkerClustererProps<T>["markerIconSelector"];
  /** Callback to set a selected marker */
  setSelected?: (data: T) => void;
  /** Callback to set the hovered marker */
  setHovered?: (data: T | undefined) => void;
  /** Callback to set a selected marker */
  selected?: T;
  /** The map container className prop */
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
  /** The map default center */
  center?: Coords;
  /** Use this define the center if based on a state from the parent component */
  newCenter?: Coords | undefined;
  /** The map zoom */
  zoom?: number;
  /** If true, the street view will be shown */
  showStreetView?: boolean;
  /** If true, the places api will be enabled */
  placesApi?: boolean;
  /** If true, enables the trafficLayer button */
  trafficLayerControl?: boolean;
  /** The size of the clusters
   *  @default 50
   *  @see https://developers.google.com/maps/documentation/javascript/marker-clustering#cluster_styles
   */
  clustersSize?: number;
  /** An optional overlay to eventually display at a given latLng */
  overlay?: [{ lat: number; lng: number }, React.ReactNode];
  /** Callback to get the places API result */
  onPlaceChanged?: (
    latLng: google.maps.LatLng,
    bounds: google.maps.LatLngBounds,
    placeResult: google.maps.places.PlaceResult
  ) => void;

  searchInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
} & GoogleMapProps;
const defaultZoom = 6;
/** ### NOTE
 * To properly use this component you first need to wrap the react app within the LoadScript component (from `@react-google-maps/api`).
 *  Read this to better understand this implementation: https://react-google-maps-api-docs.netlify.app
 */
function GoogleMapComponent<T extends Coords & MaybeHasId>({
  dataArray,
  onMapClick,
  onMarkerClick,
  onMarkerMove,
  className = "",
  markerIconSelector,
  center = defaultCenter,
  newCenter,
  zoom = defaultZoom,
  placesApi = false,
  onPlaceChanged,
  showStreetView = false,
  trafficLayerControl = false,
  clustersSize,
  searchInputProps,
  selected,
  setSelected,
  setHovered,
  onMapLoad = () => {},
  onPanoramaLoad = () => {},
  onSviewServiceLoad = () => {},
  overlay,
  ...attributes
}: MapProps<T>) {
  const map = useRef<google.maps.Map | null>(null);
  const pano = useRef<google.maps.StreetViewPanorama | null>(null);
  const sview = useRef<google.maps.StreetViewService | null>(null);
  const [panoVisible, setPanoVisible] = useState<boolean>(false);

  // Map layers
  const [showTraffic, setShowTraffic] = useState<boolean>(false);

  useEffect(() => {
    selected && !panoVisible && map.current?.panTo(selected);
  }, [panoVisible, selected]);
  return (
    <div className={twMerge("relative h-full w-full", className)}>
      {!panoVisible&& trafficLayerControl && (
        <LayerToggler
          toggleTraffic={() => setShowTraffic((prev) => !prev)}
          traffic={showTraffic}
        />
      )}
      <GoogleMap
        mapContainerClassName={twMerge("h-full w-full", className)}
        center={center}
        zoom={zoom}
        onClick={({ latLng }) => onMapClick?.(latLng, map.current!)}
        onLoad={(googleMap) => {
          map.current = googleMap;
          newCenter && map.current.setCenter(newCenter);
          onMapLoad(googleMap);
        }}
        onUnmount={() => {
          map.current = null;
        }}
        options={{
          styles: customMapType,
          streetViewControl: showStreetView,

          fullscreenControl: false,
          mapTypeControl: false,
          noClear: true,
          zoomControl: false,
          maxZoom: 20,
          minZoom: 3,
          // Uncomment this to add bounds
          // Note: fitbounds won't work correctly when zoomed out
          //
          //restriction: {
          //  latLngBounds: ITALY_BOUNDS,
          //  strictBounds: false,
          //},
        }}
        {...attributes}
      >
        <>
          {showTraffic && <TrafficLayer />}
          {placesApi && (
            <SearchInput
              required={false}
              onPlaceSelected={(location, bounds, placeResult) => {
                if (!map.current) return;
                if (onPlaceChanged) {
                  onPlaceChanged(location, bounds, placeResult);
                }
                // map.panToBounds(bounds)
                // console.log(location.toJSON(), bounds.toJSON())
                map.current.fitBounds(bounds);
              }}
              {...searchInputProps}
            />
          )}
          <StreetViewPanorama
            onLoad={(e) => {
              pano.current = e;
              onPanoramaLoad(e);
              pano.current.setZoom(1);
            }}
            onVisibleChanged={() => {
              const visible: boolean = pano.current?.get("visible");
              setPanoVisible(visible);
              // console.log("visible", visible);
            }}
            onUnmount={(e) => {
              pano.current?.setZoom(1);
              pano.current = null;
              setPanoVisible(false);
            }}
          />
          <StreetViewService
            onLoad={(e) => {
              sview.current = e;
              e && onSviewServiceLoad(e);
            }}
          />
          <MarkerClusterer
            map={map.current}
            streetView={panoVisible && pano.current ? pano.current : null}
            iconSize={clustersSize}
            selected={selected}
            dataArray={dataArray}
            markerIconSelector={markerIconSelector}
            onMarkerMove={onMarkerMove}
            onMarkerClick={(data: T, event, googleMap) => {
              setSelected?.(data);
              onMarkerClick?.(data, event, googleMap);
            }}
            onMarkerEnter={(data) => setHovered?.(data)}
            onMarkerLeave={() => setHovered?.(undefined)}
          />
          {overlay && (
            <OverlayViewF
              mapPaneName="markerLayer"
              position={new google.maps.LatLng(overlay[0].lat, overlay[0].lng)}
              zIndex={1}
            >
              {overlay[1]}
            </OverlayViewF>
          )}
        </>
      </GoogleMap>
    </div>
  );
}
// NOTE: Added `typeof` because memo() removes generics
export default memo(GoogleMapComponent) as typeof GoogleMapComponent;
