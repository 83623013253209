import * as SSPApi from "api/gql/ssp";
import { Billboard } from "graphql/ssp/generated";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { normalizeBillboards } from "utils/billboards/normalizeBillboards";
import { sortByKey } from "utils/dateFormatter";
import { billboardsActions } from "../slices/billboards";
import { notificationsActions } from "../slices/notifications";

function* addBillboard({
  payload,
}: ReturnType<typeof billboardsActions.willAddBillboard>) {
  const res: Billboard | null = yield SSPApi.addBillboard(payload);
  if (res) {
    notificationsActions.addToast({
      title: "Creazione impianto",
      imageUrl: res.imageUrl || undefined,
      text: `L'impianto situato in "${
        res.address || res.location_name
      }" è stato creato con successo`,
      type: "SUCCESS",
    });
  } else {
    notificationsActions.addToast({
      title: "Errore nella creazione dell' impianto",
      text: `Ci scusiamo per l'inconveniente`,
      type: "DANGER",
    });
  }
  yield fetchBillboards();
}

function* editBillboard({
  payload,
}: ReturnType<typeof billboardsActions.willEditBillboard>) {
  yield SSPApi.editBillboard(payload);
  yield fetchBillboards();
}

function* batchEditBillboards({
  payload,
}: ReturnType<typeof billboardsActions.willBatchEditBillboards>) {
  console.log("batchEditBillboard");
  const promises = payload.map((editParams) =>
    SSPApi.editBillboard(editParams).then((res) => console.log(res))
  );
  yield Promise.all(promises);
  yield fetchBillboards();
}

function* deleteBillboard({
  payload,
}: ReturnType<typeof billboardsActions.willDeleteBillboard>) {
  const billboard: Billboard = yield SSPApi.deleteBillboard(payload);
  yield put(
    notificationsActions.addToast({
      title: "Eliminazione impianto",
      imageUrl: billboard.imageUrl || undefined,
      text: `L'impianto situato in "${
        billboard.address || billboard.location_name
      }" è stato eliminato con successo`,
      type: "INFO",
    })
  );
  yield fetchBillboards();
}

function* batchDeleteBillboards({
  payload,
}: ReturnType<typeof billboardsActions.willBatchDeleteBillboards>) {
  yield all(
    payload.map((deleteArgs) => call(SSPApi.deleteBillboard, deleteArgs))
  );
  yield put(
    notificationsActions.addToast({
      title: "Eliminazione impianti",
      text:
        payload.length > 1
          ? `L'eliminazione dei ${payload.length} impianti selezionati è avvenuta con successo`
          : `L'impianto selezionato è stato eliminato con successo`,
      type: "INFO",
    })
  );
  yield fetchBillboards();
}
function* fetchBillboards() {
  const billboards: Billboard[] = yield SSPApi.getMediaOwnerBillboards() || [];
  const normalized = normalizeBillboards(billboards);
  sortByKey(normalized, "creation_date");
  yield put(billboardsActions.didFetchBillboards(normalized));
}

// TODO: Replace with new API once ready
function* fetchAllBillboards() {
  const billboards: Billboard[] = yield SSPApi.getMediaOwnerBillboards() || [];
  const normalized = normalizeBillboards(billboards);
  sortByKey(normalized, "creation_date");
  yield put(billboardsActions.didFetchAllBillboards(normalized));
}

export function* sagas() {
  yield takeLatest(billboardsActions.willAddBillboard.type, addBillboard);
  yield takeLatest(billboardsActions.willEditBillboard.type, editBillboard);
  yield takeLatest(
    billboardsActions.willBatchEditBillboards.type,
    batchEditBillboards
  );
  yield takeLatest(billboardsActions.willDeleteBillboard.type, deleteBillboard);
  yield takeLatest(
    billboardsActions.willBatchDeleteBillboards.type,
    batchDeleteBillboards
  );
  yield takeLatest(billboardsActions.willFetchBillboards.type, fetchBillboards);
  yield takeLatest(
    billboardsActions.willFetchAllBillboards.type,
    fetchAllBillboards
  );
}
