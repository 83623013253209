import { ShareIcon } from "@heroicons/react/24/outline";
import { MiniButton } from "components/buttons/Mini";
import Tooltip from "components/popovers/Tooltip";
import { useState } from "react";

type Props = {
  link: string;
};

export const ShareLinkButton: React.FC<Props> = ({ link }) => {
  const shareTip = "Copia link di condivisione",
    copiedTip = "Copiato negli appunti";
  const [tip, setTip] = useState<string>(shareTip);

  return (
    <>
      <Tooltip tip={tip} placement="top">
        <MiniButton
          className="h-full"
          onClick={() => {
            setTip(copiedTip);
            navigator.clipboard.writeText(link);
            setTimeout(() => setTip(shareTip), 1000);
          }}
        >
          <ShareIcon className="h-4" />
        </MiniButton>
      </Tooltip>
    </>
  );
};
