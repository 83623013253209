import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { loader } from "graphql.macro";
import { errorCatcher } from "../errorCatcher";
import {
  GetAdvertiserQuery,
  ListFilteredBillboardsQuery,
  BatchGetOrdersQuery,
  GetAdvertiserCampaignQuery,
  ListMyCampaignsQuery,
  Advertiser,
  Billboard,
  Campaign,
  Order,
  QueryListFilteredBillboardsArgs,
  QueryGetAdvertiserCampaignArgs,
  QueryStartPaymentArgs,
  StartPaymentQuery,
  ListAdvertiserUsersQuery,
  AdvertiserUser,
} from "graphql/dsp/generated";
import { processCampaignState } from "api/gql/utils";

// Used to type the query results
type GetAdvertiser = GraphQLQuery<GetAdvertiserQuery>;
type ListMyCampaigns = GraphQLQuery<ListMyCampaignsQuery>;
type BatchGetOrders = GraphQLQuery<BatchGetOrdersQuery>;
type GetAdvertiserCampaign = GraphQLQuery<GetAdvertiserCampaignQuery>;
type ListFilteredBillboards = GraphQLQuery<ListFilteredBillboardsQuery>;
type StartPayment = GraphQLQuery<StartPaymentQuery>;
type ListAdvertiserUsers = GraphQLQuery<ListAdvertiserUsersQuery>;

/** Fetches the team components */
export const listAdvertiserUsers = async () => {
  const query = loader("../../../graphql/dsp/listAdvertiserUsers.gql");
  const promise = API.graphql<ListAdvertiserUsers>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.listAdvertiserUsers;
  const filtered = result?.filter((u): u is AdvertiserUser => u !== null);
  return filtered ? (filtered satisfies AdvertiserUser[]) : null;
};

/** Returns a set of campaigns of an Advertiser */
const _listMyCampaigns = async (nextToken: string) => {
  const query = loader("../../../graphql/dsp/listMyCampaigns.gql");
  const promise = API.graphql<ListMyCampaigns>(
    graphqlOperation(query, { limit: 500, nextToken })
  );
  const result = (await errorCatcher(promise))?.listMyCampaigns;
  return result || null;
};
export const getAdvertiser = async () => {
  const query = loader("../../../graphql/dsp/getAdvertiser.gql");
  const promise = API.graphql<GetAdvertiser>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.getAdvertiser;
  return result ? (result satisfies Advertiser) : null;
};

export const startPayment = async (inputArgs: QueryStartPaymentArgs) => {
  const query = loader("../../../graphql/dsp/startPayment.gql");
  const promise = API.graphql<StartPayment>(graphqlOperation(query, inputArgs));
  const result = (await errorCatcher(promise))?.startPayment;
  return result ? result : null;
};

export const listFilteredBillboards = async (
  args: QueryListFilteredBillboardsArgs
) => {
  const query = loader("../../../graphql/dsp/listFilteredBillboards.gql");
  const promise = API.graphql<ListFilteredBillboards>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.listFilteredBillboards;
  return result ? (result as Billboard[]) : null;
};

/** Gets a list of orders, given a campaign id and the orders id */
export const batchGetOrders = async (campaign_id: string, orders: string[]) => {
  const query = loader("../../../graphql/dsp/batchGetOrders.gql");
  const promise = API.graphql<BatchGetOrders>(
    graphqlOperation(query, { campaign: campaign_id, orders: orders })
  );
  const result = (await errorCatcher(promise))?.batchGetOrders;
  return result ? (result as Order[]) : null;
};

/** API to get and advertiser campaign by adv name and campaign ID (should work for unauthorized users) */
export const getAdvertiserCampaign = async (
  inputArgs: QueryGetAdvertiserCampaignArgs
) => {
  const query = loader("../../../graphql/dsp/getAdvertiserCampaign.gql");
  const promise = API.graphql<GetAdvertiserCampaign>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.getAdvertiserCampaign;
  return result ? processCampaignState(result as Campaign) : null;
};

/** Returns all the campaigns of the currently connected Advertiser */
export const listAdvertiserCampaigns = async () => {
  let nextToken: string = "";
  try {
    const aggregatedResult: Campaign[] = [];
    do {
      const result = await _listMyCampaigns(nextToken);
      if (result) {
        const campaigns = result.items as Campaign[];
        aggregatedResult.push(...campaigns);
        nextToken = result.nextToken || "";
      } else {
        nextToken = "";
      }
    } while (nextToken);
    return aggregatedResult.map(processCampaignState);
  } catch (error) {
    return null;
  }
};
