import { twMerge } from "tailwind-merge";

export const CardSection: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children,className, ...attrs }) => (
  <div className={twMerge("flex w-full flex-col p-2 px-3 text-gray-700",className)} {...attrs}>
    {children}
  </div>
);

export default CardSection;
