import { Navigate, Outlet } from "react-router-dom";
type Props = {
  pending: string|undefined;
  not?: boolean;
};

/** Checks if the user has the `pending` attribute.  */
export const IsApproved: React.FC<Props> = ({ pending, not }) => {
  const isPending = !!pending;
  if (!not && isPending) {
    return <Navigate replace to={"/pending-approval"} />;
  }
  if (not && !isPending) {
    return <Navigate replace to={"/dashboard"} />;
  }
  return <Outlet />;
};
export default IsApproved;
