
import { Campaign } from "graphql/dsp/generated";
import UpdateStateModal from "./UpdateState";

type Props = {
  action: [string, Campaign] | null;
  closeAction: () => void;
};
/** Handles actions and actions confirmation for multiple or single row selections */
export const CampaignsActionsManager = ({ action, closeAction }: Props) => {
  if (!action) return <></>;
  const [actionName, actionData] = action;

  if (Array.isArray(actionData)) {
    return <></>;
  }
  return (
    <>
      <UpdateStateModal
        campaign={actionData}
        open={actionName === "update"}
        close={closeAction}
      />
    </>
  );
};

export default CampaignsActionsManager;
