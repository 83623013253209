import {
  ExclamationTriangleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { MiniButton } from "components/buttons/Mini";
import Tooltip from "components/popovers/Tooltip";
import { Billboard } from "graphql/ssp/generated";
import useHasActiveStripe from "hooks/useHasActiveStripe";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  billboardsActions,
  billboardsSelectors,
} from "store/slices/billboards";
import { twMerge } from "tailwind-merge";

type Props = {
  billboard: Billboard | undefined;
  className?: string;
};

export const VisibilityToggle: React.FC<Props> = ({ billboard, className }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [, billboardsStatus] = useSelector(billboardsSelectors.myBillboards);
  const hasActiveStripe = useHasActiveStripe();
  useEffect(() => {
    if (billboardsStatus === "idle" && isLoading) {
      setIsLoading(false);
    }
  }, [billboardsStatus, isLoading]);
  return (
    <Tooltip
      tip={
        hasActiveStripe ? (
          billboard?.visible ? (
            "Imposta impianto come non visibile"
          ) : (
            "Imposta impianto come visibile"
          )
        ) : (
          <div className="max-w-[400px] whitespace-normal text-center">
            <ExclamationTriangleIcon className="inline h-5 text-adv" />
            <b>Profilo incompleto</b>
            <ExclamationTriangleIcon className="inline h-5 text-adv" />
            <br />
            Per poter rendere gli impianti visibili all'acquisto è necessario
            attivare il tuo account Stripe
          </div>
        )
      }
    >
      <MiniButton
        loading={isLoading}
        className={twMerge("", className)}
        onClick={(e) => {
          e.stopPropagation();
          if (!billboard) return;
          if (!hasActiveStripe) return;
          setIsLoading(true);
          const { id, city_code, visible } = billboard;
          dispatch(
            billboardsActions.willEditBillboard({
              id,
              city_code,
              input: { visible: !visible },
            })
          );
        }}
      >
        {billboard?.visible ? (
          <EyeIcon className="h-6" />
        ) : (
          <EyeSlashIcon className="h-6 opacity-50" />
        )}
      </MiniButton>
    </Tooltip>
  );
};
export default VisibilityToggle;
