import {
  BuildingLibraryIcon,
  ChevronLeftIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { Billboard, Order } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";
import { orderF, orderS } from "utils/formatters";
import averageIndex from "components/cards/Order/averageIndex";
import CardSection from "components/cards/Section";
import ScrollableContainer from "components/containers/Scrollable";
import IndexCard from "components/cards/Billboard/IndexCard";
import { useState } from "react";
import TransparentButton from "components/buttons/Transparent";
import BillboardsMiniList from "components/lists/Billboards/Mini";
import BillboardCard from "components/cards/Billboard";
// import ImageDiv from "components/others/ImageDiv";
import CoveredMunicipalities from "./CoveredMunicipalities";
import InfoList from "./InfoList";
import UniqueFormats from "./UniqueFormats";
import OrderWidget from "components/cards/Order/OrderWidget";
import {
  normalizeBillboards,
  NormalizedBillboard,
} from "utils/billboards/normalizeBillboards";
import BasicButton from "components/buttons/Basic";
import ReportImagesUploader from "components/cards/Order/ReportImagesUploader";
import useReportImages from "hooks/useReportImages";
import ImageDiv from "components/others/ImageDiv";
import { useSelector } from "react-redux";
import { userSelectors } from "store/slices/user";
import { currencyFormatter } from "utils/currencyFormatter";

const subscriptionPlans = {
  base: 0.07,
  pro: 0.045,
};

type Props = {
  data: Order;
  setSelectedBillboard?: (billboard?: NormalizedBillboard) => void;
  selectedBillboard?: NormalizedBillboard;
  zoomIn?: (billboard: Billboard) => void;
  streetView?: (billboard: Billboard) => void;
  onClose?: () => void;
  /** Callback to refresh the order state after an update */
  refresh?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
export const planFees = {
  base: 0.07,
  pro: 0.045,
  premium: 0,
};
export const OrderCard: React.FC<Props> = ({
  data,
  className,
  setSelectedBillboard,
  selectedBillboard,
  onClose,
  zoomIn,
  streetView,
  refresh,
  ...attributes
}) => {
  const MAX_PREVIEW = 5;
  /** Billboards */
  const bbs = data.billboards;
  // const billboardsPreview =
  //   bbs && bbs.length > MAX_PREVIEW ? bbs.slice(MAX_PREVIEW - 1) : bbs;
  const [showList, setShowList] = useState(false);
  const [openImagesUploader, setOpenImagesUploader] = useState(false);
  const [reportImages, updateReportImages] = useReportImages(data.id);
  const [mediaOwner] = useSelector(userSelectors.mediaOwner);
  const planFee = planFees[mediaOwner?.plan || "base"];
  const rawTotal = (data.amount || 0) / (1 - planFee);
  const infoList: Col<Partial<typeof data & { fee: string; initial: string }>> =
    {
      status: ["Stato", orderF["status"]],
      advertiser: ["Cliente", orderF["advertiser"]],
      from: ["Inizio - Fine", orderF["from"]],
      order_date: ["Data creazione campagna", orderF["order_date"]],
      printer: ["Stampa e consegna", orderF["printer"]],
      initial: ["Totale", () => currencyFormatter.format(rawTotal)],
      fee: [
        "Fee Billalo",
        () => currencyFormatter.format(rawTotal - (data.amount || 0)),
      ],
      amount: ["Totale (netto Fee)", orderF["amount"], orderS["amount"]],
    };
  const safeBbs = bbs ? (bbs.filter((b) => b !== null) as Billboard[]) : [];
  const computedIndexes = averageIndex(safeBbs);
  const pendingConfirmation =
    data.status === "rejected" || data.status === "confirmed";
  if (selectedBillboard) {
    return (
      <BillboardCard
        className={twMerge(
          "relative max-h-full w-full bg-transparent",
          className
        )}
        data={selectedBillboard}
        onClose={onClose}
        zoomIn={zoomIn}
        streetView={streetView}
      />
    );
  }
  return (
    <ScrollableContainer
      className={twMerge(
        "relative max-h-full w-full bg-transparent",
        className
      )}
      {...attributes}
    >
      {pendingConfirmation && (
        <div className="w-full p-1 text-base bg-white rounded-xl">
          <OrderWidget refresh={refresh} order={data} />
        </div>
      )}
      {data.billboards && (
        <div className={twMerge(!showList && "hidden")}>
          <TransparentButton
            className="gap-4"
            onClick={() => setShowList(false)}
          >
            <ChevronLeftIcon className="h-4" />
            Dettagli ordine
          </TransparentButton>
          <div className="flex items-center justify-between w-full text-sm font-bold">
            <div className="p-3">
              Impianti prenotati ({data.billboards.length})
            </div>
          </div>
          <BillboardsMiniList
            myBillboards={(data.billboards || []) as Billboard[]}
            hideActions
            onRowClick={(b) =>
              setSelectedBillboard?.(normalizeBillboards([b])[0])
            }
          />
        </div>
      )}
      <div
        className={twMerge(
          "flex w-full flex-col items-center gap-3 rounded-xl bg-white p-1 text-base",
          showList && "hidden"
        )}
      >
        <CardSection className="items-start gap-3 p-4 font-medium bg-white rounded-xl">
          {data.billboards && (
            <>
              <div className="flex items-center justify-between w-full text-sm font-bold">
                <div>Impianti prenotati ({data.billboards.length})</div>
                <TransparentButton
                  className="font-semibold text-gray-500 border rounded-lg"
                  onClick={() => setShowList(true)}
                >
                  Vedi elenco
                </TransparentButton>
              </div>
              {!!reportImages.length && (
                <div className="flex flex-wrap w-full gap-3">
                  {reportImages.slice(0, MAX_PREVIEW).map((image, i) => (
                    <ImageDiv
                      key={image.id || i}
                      className="h-20 grow rounded-xl"
                      title={image.caption || "Impianto"}
                      src={String(image.url)}
                      withModal
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </CardSection>
        <CardSection className="items-start gap-2 mb-3 font-medium rounded-xl">
          <b>Foto report</b>
          <div className="font-normal">
            Carica le foto giustificative della campagna
          </div>
          <BasicButton onClick={() => setOpenImagesUploader(true)}>
            Carica foto report
          </BasicButton>
          <ReportImagesUploader
            open={openImagesUploader}
            orderId={data.id}
            campaignId={data.campaign}
            close={() => setOpenImagesUploader(false)}
            update={updateReportImages}
          />
        </CardSection>
        <CardSection className="items-start gap-3 text-base font-medium bg-white rounded-xl">
          <InfoList data={data} infoList={infoList} />
        </CardSection>

        <CardSection className="items-start gap-3 mb-3 text-base font-medium bg-white rounded-xl">
          <CoveredMunicipalities billboards={safeBbs} />
        </CardSection>
        <div className="flex flex-col items-center w-full gap-3">
          <IndexCard
            Icon={HomeIcon}
            amount={computedIndexes?.visIndexM}
            amountMax={10}
            amountText={(amt, max) => amt.toFixed(1) + "/" + max}
          >
            <div className="text-xs font-medium text-gray-400">
              Indice visibilità <b>medio</b> <br />
              Comune
            </div>
          </IndexCard>
          <IndexCard
            Icon={BuildingLibraryIcon}
            color="#3DD598"
            amount={computedIndexes?.visIndexN}
            amountMax={10}
            amountText={(amt, max) => amt.toFixed(1) + "/" + max}
          >
            <div className="text-xs font-medium text-gray-400">
              Indice visibilità <b>medio</b> <br />
              Italia
            </div>
          </IndexCard>
        </div>

        <CardSection className="items-start gap-3 mb-3 text-base font-medium bg-white rounded-xl">
          <UniqueFormats billboards={safeBbs} />
        </CardSection>
      </div>
    </ScrollableContainer>
  );
};
export default OrderCard;
