import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import {userActions } from "store/slices/user";
import { useEffect } from "react";
import Loading from "pages/Loading";
type Props = {
  isAuthenticated: boolean;
};
/** Wrappers which logouts the user before accessing the requested route*/
export const AuthWrapper: React.FC<Props> = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.willLogout());
    }
  }, [isAuthenticated, dispatch]);
  return !isAuthenticated ? <Outlet /> : <Loading />;
};
export default AuthWrapper;
