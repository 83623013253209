import { ReceiptPercentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { setBillboardPricing } from "api/gql/ssp";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import { Billboard, Pricing } from "graphql/ssp/generated";
import { useDispatch } from "react-redux";
import { billboardsActions } from "store/slices/billboards";
import { twMerge } from "tailwind-merge";
import { currencyFormatter } from "utils/currencyFormatter";
import { formatAwsDate } from "utils/dateFormatter";

type Props = {
  pricing: Pricing;
  billboard: Partial<Billboard>;
  refresh: () => void;
};
export const AppliedRule: React.FC<Props> = ({
  pricing,
  billboard,
  refresh,
}) => {
  const dispatch = useDispatch();
  const removeRule = async () => {
    // const { pricing_id, ...rest } = billboard;
    // billboard = { ...rest };
    const res = await setBillboardPricing({
      pricing_id: null,
      id: billboard.id,
      city_code: billboard.city_code,
    });
    console.log(res);
    refresh();
    dispatch(billboardsActions.willFetchBillboards());
  };

  return (
    <div className="flex flex-col w-full">
      <div className="text-gray-400">Regola esistente:</div>
      {!pricing ? (
        <div className="h-[120px]" />
      ) : (
        <AppliedRuleCard pricing={pricing} />
      )}
      <div className="flex justify-end w-full">
        <BasicButton
          onClick={removeRule}
          className="gap-2 p-1 px-2 bg-red-400 w-fit"
        >
          Rimuovi regola <XMarkIcon className="h-5" />
        </BasicButton>
      </div>
    </div>
  );
};
export default AppliedRule;

type RuleCardProps = { pricing: Pricing; className?: string };
export const AppliedRuleCard: React.FC<RuleCardProps> = ({
  pricing,
  className,
}) => {
  return (
    <BasicContainer className="items-start w-full gap-1 py-3 my-4 border animate-down-up">
      <div
        className={twMerge(
          "flex items-center gap-2 text-xl font-semibold",
          className
        )}
      >
        <ReceiptPercentIcon
          className={twMerge(
            "h-6 stroke-2",
            pricing?.rule_type === "Sconto"
              ? "text-green-500"
              : "text-yellow-500"
          )}
        />{" "}
        {pricing?.rule_type} del {pricing?.percentage}%
      </div>
      <div className="text-gray-400 ">
        Si applica secondo le seguenti condizioni:
      </div>
      <ul className="px-6 text-lg list-disc">
        {pricing?.from && (
          <li>
            A partire dal{" "}
            <span className="font-semibold">{formatAwsDate(pricing.from)}</span>{" "}
            fino al{" "}
            <span className="font-semibold">{formatAwsDate(pricing?.to)}</span>
          </li>
        )}
        {pricing?.amount_1 && pricing?.condition_1 && (
          <li>
            Per una spesa totale {pricing.condition_1.toLocaleLowerCase()} di{" "}
            <span className="font-semibold">
              {currencyFormatter.format(pricing?.amount_1)}
            </span>
          </li>
        )}
        {pricing?.customers && (
          <li>
            Applicata in esclusiva ai clienti:{" "}
            <span className="font-semibold">
              {pricing?.customers.join(", ")}
            </span>
          </li>
        )}
      </ul>
    </BasicContainer>
  );
};
