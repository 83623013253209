import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  CurrencyEuroIcon,
  EyeIcon,
  MapPinIcon,
  RectangleGroupIcon,
  UsersIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import LeftNavButton from "components/buttons/LeftNav";
import BasicContainer from "components/containers/Basic";
import BasicButton from "components/buttons/Basic";
import Tooltip from "components/popovers/Tooltip";
import Period from "./Period";
import Class from "./Class";
import Price from "./Price";
import Format from "./Format";
import Visibility from "./Visibility";
import MediaOwner from "./MediaOwner";
import Location from "./Location";
import TagsContainer from "pages/Planning/Advanced/Search/FilterInput/TagsContainer";
import { Billboard, FilterInput } from "graphql/dsp/generated";
import ScrollableContainer from "components/containers/Scrollable";

type Props = {
  filterInput: FilterInput;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
  handleSubmit?: (data: FilterInput) => void;
  disabled?: boolean;
  billboards: Billboard[];
} & React.FormHTMLAttributes<HTMLFormElement>;

type Filter = keyof FilterInput | ("price" | "date" | "index" | "format");
/** Used during the user registration (both for clients and mediaOwners) */
export const FilterInputForm: React.FC<Props> = ({
  className,
  filterInput,
  setFilterInput,
  handleSubmit,
  billboards,
  disabled,
  ...attributes
}) => {
  const [currentFilterPopover, setCurrentFilterPopover] =
    useState<Filter | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const filters: Partial<{
    [key in Filter]: [
      string,
      typeof UsersIcon,
      React.FC<{
        filterInput: FilterInput;
        setFilterInput: React.Dispatch<React.SetStateAction<FilterInput>>;
      }>
    ];
  }> = {
    city: ["Località", MapPinIcon, Location],
    date: ["Periodo", CalendarIcon, Period],
    media_type: ["Classe e tipologia", RectangleGroupIcon, Class],
    format: ["Dimensioni", ViewfinderCircleIcon, Format],
    price: ["Prezzo (per uscita)", CurrencyEuroIcon, Price],
    index: ["Indice Visibilità", EyeIcon, Visibility],
    media_owner: ["Concessionarie", BriefcaseIcon, MediaOwner],
  };

  useEffect(() => {
    setCurrentFilterPopover(null);
  }, [filterInput]);
  return (
    <>
      <Tooltip
        placement="right-start"
        controlled
        anchorEl={containerRef.current || undefined}
        offsets={[-10, 15]}
        active={!!currentFilterPopover}
        content={(() => {
          if (!currentFilterPopover) return <></>;
          const El = filters[currentFilterPopover]?.[2];
          return El ? (
            <El setFilterInput={setFilterInput} filterInput={filterInput} />
          ) : (
            <></>
          );
        })()}
      />

      <ScrollableContainer containerClassName="text-base" className={twMerge("w-96 shrink-0 ", className)}>
        <BasicContainer
          className="items-start justify-start w-full m-0 bg-white"
          innerRef={containerRef}
        >
          <form
            className={twMerge(
              "flex w-full flex-col items-center gap-5 rounded-3xl",
              className
            )}
            onSubmit={(e) => {
              e.preventDefault();
              e.currentTarget.reportValidity();
              handleSubmit?.(filterInput);
            }}
            {...attributes}
          >
            <BasicContainer className="items-start w-full p-0 -ml-5 rounded-none">
              {Object.entries(filters).map(([filterKey, [alias, Icon]], i) => {
                const filter = filterKey as Filter;
                const active = currentFilterPopover === filter;
                return (
                  <LeftNavButton
                    key={i}
                    active={active}
                    required={filterKey === "date" || filterKey === "city"}
                    onClick={() => {
                      active
                        ? setCurrentFilterPopover(null)
                        : setCurrentFilterPopover(filter);
                    }}
                  >
                    <Icon className="h-5 mr-1" />
                    <span>{alias}</span>
                  </LeftNavButton>
                );
              })}
            </BasicContainer>
            <div className="flex flex-col w-full my-1">
              <div>Filtri applicati:</div>
              <TagsContainer
                filterInput={filterInput}
                setFilterInput={setFilterInput}
              />
            </div>
            <div className="flex items-baseline w-full gap-3">
              <div>Impianti trovati: </div>
              <div
                className={twMerge(
                  "text-lg font-semibold",
                  billboards.length && "text-primary"
                )}
              >
                {billboards.length}
              </div>
            </div>

            <div className="flex justify-around w-full gap-2">
              <BasicButton
                type="button"
                className="gap-2 text-gray-600 bg-gray-200"
                onClick={() => {
                  setFilterInput({});
                }}
              >
                Rimuovi tutti
                <XMarkIcon className="h-4" />
              </BasicButton>
              <Tooltip
                tip={
                  filterInput.from && filterInput.city
                    ? "La ricerca deve restituire almeno un risultato"
                    : "Inserire il periodo e la località"
                }
                disabled={
                  filterInput.from && filterInput.city && billboards.length
                }
                className="w-full"
              >
                <BasicButton
                  type="submit"
                  className="gap-2"
                  disabled={disabled}
                >
                  Conferma filtri
                  <CheckIcon className="h-4" />
                </BasicButton>
              </Tooltip>
            </div>
          </form>
        </BasicContainer>
      </ScrollableContainer>
    </>
  );
};
export default FilterInputForm;
