import FloatingBasic from "components/inputs/Floating/Basic";
import {
  formDataGenerator,
  StepForm,
} from "components/Stepper";
import { MediaOwnerInput } from "graphql/ssp/generated";
import { useEffect } from "react";

export const Contact: StepForm<MediaOwnerInput> = ({
  inputData,
  editHandler,
  formRef,
  isCurrentStep,
  ...attributes
}) => {

  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    editHandler(form, valid);
  };
  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      handleChange(formRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      onChange={(e) => handleChange(e.currentTarget)}
      ref={formRef}
      {...attributes}
    >
      <div className="grid w-full grid-cols-2 gap-3">
        <FloatingBasic
          className="col-span-2"
          underline
          stayUp
          type="email"
          label="Email aziendale"
          defaultValue={inputData?.email || ""}
          name="email"
          required
        />
        <FloatingBasic
          underline
          stayUp
          type="email"
          label="PEC"
          defaultValue={inputData?.pec || ""}
          name="pec"
          required
        />
        <FloatingBasic
          underline
          stayUp
          type="tel"
          label="Numero di telefono"
          defaultValue={inputData?.phone || ""}
          required
          name="phone"
        />
      </div>
    </form>
  );
};
export default Contact;
