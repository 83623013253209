import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { loader } from "graphql.macro";
import { errorCatcher } from "../errorCatcher";
import {
  AddMediaOwnerMutation,
  Billboard,
  AddBillboardMutation,
  DeleteBillboardMutation,
  MutationAddBillboardArgs,
  MutationDeleteBillboardArgs,
  MutationAddMediaOwnerArgs,
  MediaOwner,
  UpdateOrderStateMutation,
  MutationUpdateOrderStateArgs,
  BillboardAvailability,
  BatchAddBillboardAvailabilityMutation,
  BatchDeleteBillboardAvailabilitiesMutation,
  MutationBatchAddBillboardAvailabilityArgs,
  PutPricingMutation,
  MutationPutPricingArgs,
  MutationSetBillboardPricingArgs,
  SetBillboardPricingMutation,
  PricingInput,
  EditBillboardMutation,
  MutationEditBillboardArgs,
  MutationBatchDeleteBillboardAvailabilitiesArgs,
  AddMediaOwnerGroupMutation,
  MutationAddMediaOwnerGroupArgs,
  RegisterStripeAccountMutation,
  ExportFreshdeskMutation,
  MutationAcceptMediaOwnerUserArgs,
  MutationRejectMediaOwnerUserArgs,
  RejectMediaOwnerUserMutation,
  AcceptMediaOwnerUserMutation,
  MutationAddOrderImageArgs,
  AddOrderImageMutation,
  OrderImage,
} from "graphql/ssp/generated";

type AddBillboard = GraphQLQuery<AddBillboardMutation>;
type AddMediaOwnerGroup = GraphQLQuery<AddMediaOwnerGroupMutation>;
type EditBillboard = GraphQLQuery<EditBillboardMutation>;
type BatchAddBillboardAvailability =
  GraphQLQuery<BatchAddBillboardAvailabilityMutation>;
type BatchDeleteBillboardAvailabilities =
  GraphQLQuery<BatchDeleteBillboardAvailabilitiesMutation>;
type DeleteBillboard = GraphQLQuery<DeleteBillboardMutation>;
type AddMediaOwner = GraphQLQuery<AddMediaOwnerMutation>;
type UpdateOrderState = GraphQLQuery<UpdateOrderStateMutation>;
type PutPricing = GraphQLQuery<PutPricingMutation>;
type SetBillboardPricing = GraphQLQuery<SetBillboardPricingMutation>;
type RegisterStripeAccount = GraphQLQuery<RegisterStripeAccountMutation>;
type ExportFreshdesk = GraphQLQuery<ExportFreshdeskMutation>;
type RejectMediaOwnerUser = GraphQLQuery<RejectMediaOwnerUserMutation>;
type AddOrderImage = GraphQLQuery<AddOrderImageMutation>;
type AcceptMediaOwnerUser = GraphQLQuery<AcceptMediaOwnerUserMutation>;
/** Creates a new group (if group doesn't exist) and adds the cognito `username` user to it */
export const addMediaOwnerGroup = async (
  media_owner: string,
  username: string
) => {
  const args: MutationAddMediaOwnerGroupArgs = { media_owner, username };
  const query = loader("../../../graphql/ssp/addMediaOwnerGroup.gql");
  const promise = API.graphql<AddMediaOwnerGroup>(
    graphqlOperation(query, args)
  );
  const result = (await errorCatcher(promise))?.addMediaOwnerGroup;
  return result || null;
};
export const addMediaOwner = async (args: MutationAddMediaOwnerArgs) => {
  const query = loader("../../../graphql/ssp/addMediaOwner.gql");
  console.log(args);
  const promise = API.graphql<AddMediaOwner>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.addMediaOwner;
  return result ? (result as MediaOwner) : null;
};

export const exportFreshdesk = async () => {
  const query = loader("../../../graphql/ssp/exportFreshdesk.gql");
  const promise = API.graphql<ExportFreshdesk>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.exportFreshdesk;
  return result ? result : null;
};

export const registerStripeAccount = async () => {
  const query = loader("../../../graphql/ssp/registerStripeAccount.gql");
  const promise = API.graphql<RegisterStripeAccount>(graphqlOperation(query));
  const result = (await errorCatcher(promise))?.registerStripeAccount;
  return result ? result : null;
};

export const addBillboard = async (args: MutationAddBillboardArgs) => {
  const query = loader("../../../graphql/ssp/addBillboard.gql");
  console.log(args);
  const promise = API.graphql<AddBillboard>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.addBillboard;
  return result ? (result as Billboard) : null;
};

export const editBillboard = async (args: MutationEditBillboardArgs) => {
  const query = loader("../../../graphql/ssp/editBillboard.gql");
  console.log(args);
  const promise = API.graphql<EditBillboard>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.editBillboard;
  return result ? (result as Billboard) : null;
};

export const deleteBillboard = async (args: MutationDeleteBillboardArgs) => {
  const query = loader("../../../graphql/ssp/deleteBillboard.gql");
  const promise = API.graphql<DeleteBillboard>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.deleteBillboard;
  return result ? (result as Billboard) : null;
};

export const updateOrderState = async (args: MutationUpdateOrderStateArgs) => {
  const query = loader("../../../graphql/ssp/updateOrderState.gql");
  console.log(args);
  const promise = API.graphql<UpdateOrderState>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.updateOrderState;
  return result ? result : null;
};
export const addOrderImage = async (args: MutationAddOrderImageArgs) => {
  const query = loader("../../../graphql/ssp/addOrderImage.gql");
  console.log(args);
  const promise = API.graphql<AddOrderImage>(graphqlOperation(query, args));
  const result = (await errorCatcher(promise))?.addOrderImage;
  return result ? result satisfies OrderImage : null;
};

export const batchAddBillboardAvailability = async (
  inputArgs: MutationBatchAddBillboardAvailabilityArgs
) => {
  const query = loader(
    "../../../graphql/ssp/batchAddBillboardAvailability.gql"
  );
  const promise = API.graphql<BatchAddBillboardAvailability>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.batchAddBillboardAvailability;
  return result ? (result as BillboardAvailability[]) : null;
};

export const batchDeleteBillboardAvailabilities = async (
  inputArgs: MutationBatchDeleteBillboardAvailabilitiesArgs
) => {
  console.log(inputArgs);
  const query = loader(
    "../../../graphql/ssp/batchDeleteBillboardAvailabilities.gql"
  );
  const promise = API.graphql<BatchDeleteBillboardAvailabilities>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))
    ?.batchDeleteBillboardAvailabilities;
  console.log(result);
  return result ? (result as BillboardAvailability[]) : null;
};

/** Used to create a pricing rule */
const putPricing = async (inputArgs: MutationPutPricingArgs) => {
  const query = loader("../../../graphql/ssp/putPricing.gql");
  const promise = API.graphql<PutPricing>(graphqlOperation(query, inputArgs));
  const result = (await errorCatcher(promise))?.putPricing;
  return result ? result : null;
};

/** Used to assign a pricing rule to a billboard */
export const setBillboardPricing = async (
  inputArgs: MutationSetBillboardPricingArgs
) => {
  const query = loader("../../../graphql/ssp/setBillboardPricing.gql");
  const promise = API.graphql<SetBillboardPricing>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.setBillboardPricing;
  return result ? result : null;
};

/** Accepts a pending target_user into the current user's group */
export const acceptMediaOwnerUser = async (
  inputArgs: MutationAcceptMediaOwnerUserArgs
) => {
  const query = loader("../../../graphql/ssp/acceptMediaOwnerUser.gql");
  const promise = API.graphql<AcceptMediaOwnerUser>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.acceptMediaOwnerUser;
  return result ? result : null;
};
export const rejectMediaOwnerUser = async (
  inputArgs: MutationRejectMediaOwnerUserArgs
) => {
  const query = loader("../../../graphql/ssp/rejectMediaOwnerUser.gql");
  const promise = API.graphql<RejectMediaOwnerUser>(
    graphqlOperation(query, inputArgs)
  );
  const result = (await errorCatcher(promise))?.rejectMediaOwnerUser;
  return result ? result : null;
};

/** Rejects a pending target_user from the current user's group */

/** Creates a pricing rules and assigns it to a list of billboards */
export const batchSetPricing = async (
  billboardList: {
    id: string;
    city_code: string;
  }[],
  pricingInput: PricingInput
) => {
  const generatedId = crypto.randomUUID();
  const pricing = await putPricing({
    id: generatedId,
    pricing_data: pricingInput,
  });

  if (!pricing) return null;
  const promises = billboardList.map(({ id, city_code }) => {
    return setBillboardPricing({ id, city_code, pricing_id: pricing.id });
  });
  const results = await Promise.all(promises);
  console.log(results);
  return results;
};
