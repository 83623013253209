import { resourcesUrl } from "api/gql/ssp";
import PfpHash from "components/lists/Team/PfpHash";
import { Advertiser, Maybe, MediaOwner } from "graphql/ssp/generated";
import { twMerge } from "tailwind-merge";

type Props = {
  image?: string;
  userId?: string;
  businessName?: string;
  client?: Advertiser;
  className?: string;
  buttonClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;
export const UserMini: React.FC<Props> = ({
  image,
  userId,
  businessName,
  className = "",
  buttonClassName = "",
  ...attributes
}) => {
  return (
    <div
      className={twMerge("flex flex-nowrap items-center gap-3", className)}
      {...attributes}
    >
      {image ? (
        <img
          className="object-cover object-center w-8 h-8 bg-gray-200 rounded-full shadow aspect-square "
          alt="User profile"
          src={String(resourcesUrl) + "pfp_business_" + userId + "_preview.png"}
        />
      ) : (
        <PfpHash user={businessName || ""} className="w-8 h-8 aspect-square" />
      )}
      <div className="flex flex-col self-center justify-center overflow-hidden text-start">
        <span
          title={String(businessName).replaceAll("_", " ")}
          className="w-full h-full overflow-hidden font-medium grow text-ellipsis whitespace-nowrap"
        >
          {String(businessName).replaceAll("_", " ")}
        </span>
        <span
          title={String("@" + userId)}
          className="w-full overflow-hidden text-xs font-medium text-gray-400 text-ellipsis whitespace-nowrap"
        >
          @{userId}
        </span>
      </div>
    </div>
  );
};

type GroupProps = {
  className?: string;
  buttonClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const MediaOwnerMini: React.FC<
  {
    mediaOwner?: Maybe<MediaOwner>;
  } & GroupProps
> = ({ mediaOwner, ...attributes }) => {
  if (!mediaOwner) return <></>;
  return (
    <UserMini
      image={mediaOwner.avatar || ""}
      businessName={mediaOwner.name || ""}
      userId={mediaOwner.mediaowner || ""}
      {...attributes}
    />
  );
};
export const AdvertiserMini: React.FC<
  {
    advertiser?: Maybe<Advertiser>;
  } & GroupProps
> = ({ advertiser, ...attributes }) => {
  if (!advertiser) return <></>;
  return (
    <UserMini
      image={advertiser.image || ""}
      businessName={advertiser.name || ""}
      userId={advertiser.advertiser || ""}
      {...attributes}
    />
  );
};
export default UserMini;
