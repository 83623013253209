import { twMerge } from "tailwind-merge";

export const TextButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...attributes }) => {
  return (
    <span
      role="button"
      className={twMerge(
        "select-none opacity-80 hover:opacity-100 active:opacity-80",
        className
      )}
      {...attributes}
    >
      {children}
    </span>
  );
};
export default TextButton;
