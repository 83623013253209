import { XCircleIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicModal from "components/modals/Basic";
import { Campaign, PricingInput, Order } from "graphql/dsp/generated";
import CheckoutForm from "pages/Planning/Advanced/Confirm/PaymentModal/CheckoutForm";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Elements, injectStripe } from "react-stripe-elements";
import { userSelectors } from "store/slices/user";
import { twMerge } from "tailwind-merge";

type Props = {
  campaign: Campaign;
  orders: Order[];
  pricingInput: PricingInput;
  className?: string;
};

type PaymentState = "success" | "error" | "idle";
export const PaymentModal: React.FC<Props> = ({
  campaign,
  orders,
  pricingInput,
  className,
}) => {
  const [advertiser] = useSelector(userSelectors.advertiser);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState<PaymentState>("idle");
  const navigate = useNavigate();
  const StripeCheckoutForm = injectStripe(CheckoutForm);
  const stateElements: Record<PaymentState, React.ReactNode> = {
    idle: (
      <Elements locale={"it"}>
        <StripeCheckoutForm
          orders={orders}
          campaign={campaign}
          pricingInput={pricingInput}
          setResult={setResult}
        />
      </Elements>
    ),
    success: (
      <div className="flex flex-col items-center gap-4">
        <div className="text-2xl font-bold text-primary">
          Il pagamento è andato a buon fine
        </div>
        <div className="text-gray-500">
          Da questo momento le concessionarie da cui hai acquistato gli spazi
          pubblicitari hanno <b>24 ore</b> di tempo per accettare o rifiutare
          l'ordine
        </div>
        <div className="text-gray-500">
          Se tutti gli spazi verranno confermati entro 24 ore lo stato della tua
          campagna risulterà{" "}
          <i>
            <b>programmata</b>
          </i>
          . Qualora una o più concessionarie dovessero rifiutare l'ordine
          ricevuto, verrai contattato per una rimodulazione della campagna per
          ricevere l'eventuale rimborso.
        </div>
        <div className="flex justify-end w-full mt-8">
          <BasicButton
            onClick={() => {
              if (!advertiser) return;
              navigate(
                "/campaigns/details/" +
                  advertiser.advertiser +
                  "/" +
                  campaign.id
              );
            }}
          >
            Vai al dettaglio della campagna
          </BasicButton>
        </div>
      </div>
    ),
    error: (
      <>
        <div className="flex flex-col items-center gap-2">
          <XCircleIcon className="w-10 h-10 text-red-400 " />
          <div className="text-2xl font-medium text-red-400">
            Errore durante il pagamento
          </div>
          <div className="flex justify-end w-full mt-6">
            <BasicButton
              className="bg-gray-400"
              onClick={() => setResult("idle")}
            >
              Riprova
            </BasicButton>
          </div>
        </div>
      </>
    ),
  };
  return (
    <>
      <BasicModal
        title={
          result === "idle"
            ? "Inserisci i dati della tua carta"
            : "Esito pagamento"
        }
        open={open}
        close={() => setOpen(false)}
      >
        {stateElements[result]}
      </BasicModal>
      <BasicButton
        onClick={() => setOpen(true)}
        className={twMerge("", className)}
      >
        Acquista
      </BasicButton>
    </>
  );
};

export default PaymentModal;
