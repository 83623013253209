import { XMarkIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

export const CardClose: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ onClick, ...attributes }) => {
  return (
    <button
      onClick={onClick}
      className="absolute right-0 z-10 translate-x-1/3 -translate-y-1/3"
    >
      <XMarkIcon
      strokeWidth={2}
        className={twMerge(
          "w-8 rounded-full border-4 border-lighter-gray bg-slate-400 text-lighter-gray",
          "hover:bg-slate-500 hover:scale-110 transition-all"
        )}
      />
    </button>
  );
};
export default CardClose;
