import { Billboard } from "graphql/ssp/generated";

export const averageIndex = (billboards: Billboard[]) => {
  let amount = 0;
  const computed = {
    visIndexM: 0,
    visIndexN: 0,
  };
  billboards.forEach((b) => {
    if (b.iV_municipality && b.iV_normalized) {
      computed.visIndexM += b.iV_municipality;
      computed.visIndexN += b.iV_normalized;
      amount++;
    }
  });

  computed.visIndexM = Math.round((computed.visIndexM / amount) * 10) / 10;
  computed.visIndexN = Math.round((computed.visIndexN / amount) * 10) / 10;
  return computed;
};
export default averageIndex;
