import { Billboard as BillboardS } from "graphql/ssp/generated";
import { Billboard as BillboardD } from "graphql/ssp/generated";
import { OrderBillboard as OrderBillboardS } from "graphql/ssp/generated";
import { OrderBillboard as OrderBillboardD } from "graphql/dsp/generated";

type BillboardT = BillboardS | BillboardD;
type BillboardTArr =
  | BillboardS[]
  | BillboardD[]
  | OrderBillboardS[]
  | OrderBillboardD[];
/**
 * Standardizes/fixes the billboards by adding coordinates and visibility indexes, since some api calls return them in different ways
 * Note: It modifies the original array
 *
 * adds: **lat, lng, iV_normalized, iV_municipality, indice_visibilita, panel_index**
 */
export type NormalizedBillboard = BillboardT &
  Coords & {
    billalo_index?: {
      iV_normalized: number;
      iV_municipality: number;
      indice_visibilita: number;
      panel_index: number;
    };
  };
export const normalizeBillboards = (
  billboards: BillboardTArr | void
): NormalizedBillboard[] => {
  if (!billboards) return [];
  const billboardsWithCoords = billboards as NormalizedBillboard[];
  billboardsWithCoords.forEach((b) => {
    b.lat = Number(b.latitude);
    b.lng = Number(b.longitude);
    if (!b.iV_normalized) b.iV_normalized = b.billalo_index?.iV_normalized;
    b.iV_normalized = roundUp(b.iV_normalized);
    if (!b.iV_municipality)
      b.iV_municipality = b.billalo_index?.iV_municipality;
    b.iV_municipality = roundUp(b.iV_municipality);
    if (!b.indice_visibilita)
      b.indice_visibilita = b.billalo_index?.indice_visibilita;
    b.indice_visibilita = roundUp(b.indice_visibilita);
    if (!b.panel_index) b.panel_index = b.billalo_index?.panel_index;
    b.panel_index = roundUp(b.panel_index);
  });
  return billboardsWithCoords;
};
export const deNormalizeBillboards = (
  billboards: NormalizedBillboard[]
): (BillboardS|BillboardD)[] => {
  if (!billboards) return [];
  return billboards.map((b) => {
    const {lat,lng,...rest} = b;
    return rest
  })

};
function roundUp(num: number | undefined | null) {
  if (num === null || num === undefined) return null;
  return Math.round(num * 10) / 10;
}
