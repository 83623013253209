import { Campaign } from "graphql/dsp/generated";
import { Order } from "graphql/dsp/generated";

/** The order states after scheduled are processed by frontend only
 *  If the order state is scheduled, and current date is above order.from,
 *  make the order status "posted" (if included between from and to)
 *  make the order status "completed" (if value is greater than order.to)
 */
export const processOrderState = (order: Order): Order => {
  const okStates = ["scheduled", "delivered_materials"];
  if (!okStates.includes(String(order.status))) return order;
  const currentDateTime = new Date();
  const orderFrom = new Date(order.from);
  const orderTo = new Date(order.to);
  if (currentDateTime < orderFrom) return order;
  order.status = currentDateTime > orderTo ? "completed" : "posted";
  return order;
};
/** The order states after scheduled are processed by frontend only
 *  If the order state is scheduled, and current date is above order.from,
 *  make the order status "posted" (if included between from and to)
 *  make the order status "completed" (if value is greater than order.to)
 */
export const processCampaignState = (campaign: Campaign): Campaign => {
  if (campaign.status !== "scheduled") return campaign;
  const currentDateTime = new Date();
  const orderFrom = new Date(campaign.from);
  const orderTo = new Date(campaign.to);
  if (currentDateTime < orderFrom) return campaign;
  campaign.status = currentDateTime > orderTo ? "completed" : "posted";
  return campaign;
};
