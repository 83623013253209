import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import TransparentButton from "components/buttons/Transparent";
import { BillboardOrdersList } from "components/cards/Campaign/BillboardOrdersList";
import BillboardsStatusList from "components/cards/Campaign/BillboardsStatusList";
// import BillboardsStatusList from "components/cards/Campaign/BillboardsStatusList";
import { CancelCampaign } from "components/cards/Campaign/CancelCampaign";
import DraftTimer, {
  isDraftExpired,
} from "components/cards/Campaign/DraftTimer";
import { ShareLinkButton } from "components/cards/Campaign/ShareLinkButton";
import MediaOwnerCountCard from "components/cards/MediaOwnerCount";
import CoveredMunicipalities from "components/cards/Order/CoveredMunicipalities";
import InfoList from "components/cards/Order/InfoList";
import UniqueFormats from "components/cards/Order/UniqueFormats";
import CardSection from "components/cards/Section";
import BasicContainer from "components/containers/Basic";
import ScrollableContainer from "components/containers/Scrollable";
import ComponentsDropdown from "components/inputs/ComponentsDropdown";
import { Campaign, Order } from "graphql/dsp/generated";
import { Billboard } from "graphql/dsp/generated";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { NormalizedBillboard } from "utils/billboards/normalizeBillboards";
import { campaignF } from "utils/formatters";

type Props = {
  campaign: Campaign;
  orders?: Order[];
  billboards?: NormalizedBillboard[];
  setSelectedBillboard?: (billboard?: NormalizedBillboard) => void;
  setHoveredBillboard?: (billboard?: NormalizedBillboard) => void;
  setFilteredBillboards?: (billboards: NormalizedBillboard[]) => void;
};
const { REACT_APP_ENV } = process.env;
function getCampaignUriParams(campaign: Campaign) {
  const filters = JSON.stringify(campaign.filters);
  const billboards = JSON.stringify(
    campaign.billboards?.map((b) => b?.billboard_id)
  );
  const name = JSON.stringify(campaign.name);
  const id = JSON.stringify(campaign.id);
  return (
    `?id=${encodeURIComponent(id)}` +
    `&name=${encodeURIComponent(name)}` +
    `&filters=${encodeURIComponent(filters)}` +
    `&billboards=${encodeURIComponent(billboards)}`
  );
}
export const CampaignCard: React.FC<Props> = ({
  campaign,
  orders,
  billboards,
  setSelectedBillboard,
  setHoveredBillboard,
  setFilteredBillboards,
}) => {
  const shareUrl = `https://app-${REACT_APP_ENV}.billalo.it/guest/${campaign.advertiser}/${campaign.id}`;
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);
  const [showMediaOwners, setShowMediaOwners] = useState(false);
  const infoList: Col<Partial<typeof campaign>> = {
    status: ["Stato", campaignF["status"]],
    from: ["Inizio - Fine", campaignF["from"]],
    order_date: ["Data creazione campagna", campaignF["order_date"]],
    discount: [
      (campaign.discount ?? 0) <= 0 ? "Sconto" : "Maggiorazione",
      campaignF["discount"],
    ],
    fee: ["Fee Billalo", campaignF["fee"]],
    total_amount: ["Spesa iniziale", campaignF["total_amount"]],
  };
  const isGuest = window.location.pathname.includes("guest");
  const isPending = ["confirmed", "rejected"].includes("" + campaign.status);
  const isNotExpiredDraft =
    campaign.status === "draft" && !isDraftExpired(campaign.order_date);

  return (
    <ScrollableContainer className="m-3 w-96 shrink-0 animate-down-up">
      <div
        className={twMerge(
          "flex w-full flex-col gap-3 rounded-xl p-1 text-sm",
          showList && "hidden"
        )}
      >
        {isNotExpiredDraft && (
          <BasicButton
            onClick={() =>
              navigate("/planning/advanced" + getCampaignUriParams(campaign))
            }
            className="gap-2 text-base font-semibold"
          >
            Vai al checkout campagna{" "}
            <DraftTimer date={String(campaign?.order_date)} />
            <ArrowTopRightOnSquareIcon className="h-5 stroke-2" />
          </BasicButton>
        )}
        <BasicContainer
          className={twMerge(
            "w-full gap-1 py-3",
            isPending && "border-2 border-yellow-400"
          )}
        >
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <div className="font-semibold text-gray-500">CAMPAGNA</div>
              <div className="font-semibold text-primary">{campaign.name}</div>
            </div>
            <div className="flex gap-2">
              {!isGuest && (
                <ComponentsDropdown
                  options={[
                    <CancelCampaign
                      data={campaign}
                      className="w-full h-full"
                    />,
                  ]}
                >
                  <EllipsisHorizontalIcon className="h-6 text-gray-600" />
                </ComponentsDropdown>
              )}
              <ShareLinkButton link={shareUrl} />
            </div>
          </div>
          {isPending && (
            <>
              <hr />
              <div className="pb-2 text-sm">
                Dal momento dell’ordine le concessionarie hanno 24 ore di tempo
                per rifiutare o modificare l’ordine. Decorso questo tempo senza
                azioni, la campagna sarà confermata.
              </div>
            </>
          )}
        </BasicContainer>
        <div className="p-2 bg-white rounded-xl">
          <CardSection className="items-start gap-3 p-4 font-medium rounded-xl">
            {billboards && (
              <>
                <div className="flex items-center justify-between w-full font-bold">
                  <div>Impianti prenotati ({billboards.length})</div>
                  <TransparentButton
                    className="font-semibold text-gray-500 border rounded-lg"
                    onClick={() => setShowList(true)}
                  >
                    Vedi elenco
                  </TransparentButton>
                </div>
                <BillboardsStatusList orders={orders} />
              </>
            )}
          </CardSection>
          <CardSection className="items-start mb-3 font-medium rounded-xl">
            <div
              role="button"
              onClick={() => setShowMediaOwners((prev) => !prev)}
              className="flex items-center justify-between w-full font-bold"
            >
              <div>Concessionarie ({campaign.orders?.length})</div>
              <div role="button" className="h-6">
                <ChevronDownIcon
                  className={twMerge(
                    "h-full -rotate-90 transition-all",
                    showMediaOwners && "rotate-0"
                  )}
                />
              </div>
            </div>
            <div
              className={twMerge(
                "flex w-full flex-col gap-2 py-2",
                !showMediaOwners && "hidden"
              )}
            >
              {orders &&
                orders.map(({ media_owner, billboards,status }) => {
                  return (
                    <MediaOwnerCountCard
                      key={media_owner}
                      mediaowner={String(media_owner)}
                      billboardsAmount={billboards?.length}
                      rejected={["rejected","canceled"].includes(status!)}
                    />
                  );
                })}
            </div>
          </CardSection>
          <CardSection className="items-start gap-2 mb-3 font-medium rounded-xl">
            <b>Foto report</b>
            <div className="font-normal">
              In fase di uscita degli ordini, in questa sezione troverai le foto
              giustificative della campagna
            </div>
          </CardSection>

          <CardSection className="items-start mb-3 font-medium rounded-xl">
            <InfoList data={campaign} infoList={infoList} />
            <div className="flex flex-col justify-between w-full border-b">
              <div className="flex items-center justify-between py-2 border-b">
                <span className="text-gray-500">Spesa Totale</span>
                <span className="font-medium text-gray-800">
                  {campaignF["print_amount"]?.(campaign.print_amount, campaign)}
                </span>
              </div>
            </div>
          </CardSection>
          <CardSection className="gap-3 mb-3 font-medium rounded-xl">
            <UniqueFormats billboards={billboards as Billboard[]} />
          </CardSection>
          <CardSection className="gap-3 mb-3 font-medium rounded-xl">
            <CoveredMunicipalities billboards={billboards as Billboard[]} />
          </CardSection>
        </div>
      </div>
      <div
        className={twMerge(
          "flex w-full flex-col gap-3 rounded-xl p-1 text-sm",
          !showList && "hidden"
        )}
      >
        <div className={twMerge(" max-h-full ", !showList && "hidden")}>
          <TransparentButton
            className="gap-4"
            onClick={() => setShowList(false)}
          >
            <ChevronLeftIcon className="h-4" />
            Dettagli campagna
          </TransparentButton>
          <div className="flex items-center justify-between w-full text-sm font-bold">
            <div className="p-3">
              Impianti prenotati ({billboards?.length || 0})
            </div>
          </div>
          <BillboardOrdersList
            orders={orders}
            setSelectedBillboard={setSelectedBillboard}
            setHoveredBillboard={setHoveredBillboard}
            setFilteredBillboards={setFilteredBillboards}
          />
        </div>
      </div>
    </ScrollableContainer>
  );
};
export default CampaignCard;
