import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BillaloLogo from "components/others/BillaloLogo";
import { useNavigate } from "react-router-dom";

export const NotFound:React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full justify-center">
      <div className="flex h-full flex-col items-center justify-center p-5 text-center max-w-2xl">
        <BillaloLogo className="mt-20 h-40 w-60" />
        <div className="text-9xl font-semibold text-gray-300">404</div>
        <div className="text-xl text-gray-500">
          La pagina definita in questo indirizzo non è stata trovata
        </div>
        <BasicButton
          className="mt-10 w-fit"
          onClick={() => navigate("/dashboard")}
        >
          <div className="flex items-center justify-center gap-2">
            <ArrowLeftIcon className="h-4" />
            <span>Torna alla home</span>
          </div>
        </BasicButton>
      </div>
      </div>
  );
};
export default NotFound;
