import { twMerge } from "tailwind-merge";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
type Props = {
  title: string;
  description: string;
  image: string;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const PlanningCard: React.FC<Props> = ({
  title,
  description,
  className,
  image,
  disabled,
  ...attributes
}) => {
  return (
    <div
      role={disabled ? "none" : "button"}
      className={twMerge(
        "group relative flex flex-col overflow-hidden rounded-xl border border-slate-400 bg-white p-4 transition-all",
        !disabled && "hover:-translate-y-1 hover:shadow-xl",
        className
      )}
      {...attributes}
    >
      <img className="h-40" alt={title} src={image} />
      <div
        className={twMerge(
          "mb-3 flex w-full items-center justify-start text-2xl font-medium",
          !disabled && "group-hover:text-primary"
        )}
      >
        <span>{title}</span>
        <div
          className={twMerge(
            "mr-4 flex h-4 grow justify-end opacity-0 transition-all",
            !disabled &&
              " group-hover:mr-0 group-hover:text-end group-hover:opacity-100"
          )}
        >
          <ArrowRightIcon className="inline h-4" />
        </div>
      </div>
      <div className="text-gray-500">{description}</div>
      {disabled && (
        <>
          <div className="absolute inset-0 bg-black bg-opacity-20" />
          <div className="absolute top-0 left-0 w-full font-bold text-center text-white bg-yellow-400">
            Coming soon
          </div>
        </>
      )}
    </div>
  );
};

export default PlanningCard;
