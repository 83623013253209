import { formDataGenerator, StepForm } from "components/Stepper";
import { createRef, useEffect, useRef, useState } from "react";
import FloatingBasic from "components/inputs/Floating/Basic";
import LocationSelector from "components/LocationSelector";
import { getAddressKey, geocodeAddress } from "utils/location/geocoding";
import { twMerge } from "tailwind-merge";
import { MediaOwnerInput } from "graphql/ssp/generated";

export const HeadOffice: StepForm<MediaOwnerInput> = ({
  inputData,
  editHandler,
  formRef,
  isCurrentStep,
  ...attributes
}) => {
  const [formData, setFormData] = useState<Partial<MediaOwnerInput>>({});
  const [isGeneric, setIsGeneric] = useState(false);
  const searchInputRef = useRef<null | HTMLInputElement>(null);
  const addressRef = createRef<HTMLInputElement>();

  const handleLocationChange = (
    latLng: google.maps.LatLng,
    locationData: google.maps.GeocoderResult | google.maps.places.PlaceResult
  ) => {
    if (!latLng) return;
    const comps = locationData.address_components;
    if (!comps) return;
    const municipality = getAddressKey(comps, "political")?.short_name || "";
    const postal_code = getAddressKey(comps, "postal_code")?.short_name || "";
    const street =
      getAddressKey(comps, "route")?.short_name ||
      getAddressKey(comps, "plus_code")?.short_name;
    const street_number =
      getAddressKey(comps, "street_number")?.short_name || "";
    const country = getAddressKey(comps, "country")?.short_name;
    let address = `${street} ${street_number}, ${municipality} (${postal_code}), ${country}`;
    let isTooGeneric = !(street && municipality && postal_code && country);
    if (isTooGeneric) address = "Indirizzo non valido";
    setIsGeneric(isTooGeneric);
    if (addressRef.current) {
      addressRef.current.value = address;
    }

    setFormData({
      ...formData,
      legal_address: address,
      legal_municipality: municipality,
      legal_cap: postal_code,
    });
    if (formRef.current) {
      const form = new FormData(formRef.current);
      form.set("legal_municipality", municipality);
      form.set("legal_cap", postal_code);
      editHandler(form, !isTooGeneric);
    }

    return locationData.geometry?.viewport;
  };
  const fields = [
    ["legal_municipality", "Comune", "text"],
    ["legal_cap", "CAP", "text"],
  ];

  // Handles the case where the inputData is already defined

  const handleChange = (e: HTMLFormElement) => {
    const [form, valid] = formDataGenerator(e);
    editHandler(form, valid);
  };
  useEffect(() => {
    if (isCurrentStep && formRef.current) {
      if (!inputData.legal_address || formData.legal_address) return;
      // Handles the case where the inputData is already defined
      const { legal_address, legal_municipality, legal_cap } = inputData;
      geocodeAddress(
        legal_address + " " + legal_municipality + " " + legal_cap
      ).then((res) => {
        if (res.results.length === 0) return;
        // Fills fields with geocode data using the address in inputData
        const latLng = res.results[0].geometry.location;
        handleLocationChange(latLng, res.results[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);
  return (
    <form
      ref={formRef}
      className="flex w-full flex-col items-center gap-5 rounded-3xl"
      onChange={(e) => handleChange(e.currentTarget)}
      {...attributes}
    >
      <div className="text-sm text-gray-600">
        <b>Istruzioni:</b>
        <br /> Seleziona la posizione digitando l'indirizzo, o cliccando sulla
        mappa.
      </div>
      <LocationSelector
        onSelected={handleLocationChange}
        // Prevent the form from submitting when pressing enter on the places searchbox
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
      <div className="grid w-full grid-cols-2 gap-3">
        <FloatingBasic
          required
          stayUp
          name={"legal_address"}
          innerRef={addressRef}
          type={"text"}
          label={"Indirizzo"}
          className={twMerge(
            "col-span-2 w-full",
            isGeneric && "peer !text-red-500"
          )}
          autoComplete="one-time-code"
        />
        {fields.map(([key, label, type, className], index) => {
          return (
            <FloatingBasic
              key={index}
              name={key}
              onClick={(e) => {
                e.preventDefault();
                searchInputRef.current?.focus();
              }}
              value={
                (formData[key as keyof typeof formData] as
                  | string
                  | number
                  | readonly string[]) || ""
              }
              type={type}
              label={label}
              title={
                "Questi campi verranno riempiti automaticamente una volta selezionata la posizione dalla mappa"
              }
              className={className + " peer select-none"}
              autoComplete="one-time-code"
              onMouseDown={(e) => e.preventDefault()}
              onFocus={(e) => e.target.blur()}
            />
          );
        })}
      </div>
    </form>
  );
};
export default HeadOffice;
