import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  file?: File;
  setFile: (file?: File) => void;
  /** Triggers when a file is given as input */
  onFile: (file: File) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const SingleFile: React.FC<Props> = ({
  file,
  setFile,
  onFile,
  className,
  ...inputAttributes
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div className={twMerge("flex gap-2", className)}>
      <label
        role="button"
        className={twMerge(
          "relative flex h-40 w-full flex-1 select-none flex-col items-center justify-center gap-3",
          "group rounded-2xl border-2 p-2 text-slate-500 ",
          "hover:border-slate-300 ",
          hovered && "border-dashed border-softblue [&>*]:pointer-events-none"
        )}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(evt) => {
          evt.preventDefault();
          const file = evt.dataTransfer.files[0];
          if (!file) return;
          setHovered(false);
          setFile(file);
          onFile(file);
        }}
        onDragEnter={(evt) => {
          evt.preventDefault();
          setHovered(true);
        }}
        onDragLeave={(evt) => {
          evt.preventDefault();
          setHovered(false);
        }}
      >
        <input
          className="hidden"
          name="files-uploader"
          type="file"
          onChange={(e) => {
            console.log("change");
            const file = e.target.files?.item(0);
            if (!file) return;
            setFile(file);
            onFile(file);
            e.target.value = "";
          }}
          {...inputAttributes}
        />
        <DocumentPlusIcon className="h-16" />
        <div className="bottom-0 text-sm font-medium text-center">
          Clicca qui o trascina un file per selezionare
        </div>
      </label>
    </div>
  );
};
