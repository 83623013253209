import { XMarkIcon } from "@heroicons/react/24/outline";
import BasicButton from "components/buttons/Basic";
import BasicContainer from "components/containers/Basic";
import { LocationTag } from "pages/Planning/Advanced/Search/FilterInput/Location";
import { Office } from "graphql/ssp/generated";
import { AddOfficeModal } from "pages/Profile/MediaOwner/Offices/AddOfficeModal";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "store/slices/user";

export const Offices = () => {
  const [mediaOwner, status] = useSelector(userSelectors.mediaOwner);
  const offices = mediaOwner?.offices ? (mediaOwner.offices as Office[]) : [];
  const dispatch = useDispatch();
  const officeFields: [Partial<keyof Office>, string][] = [
    ["address", "Indirizzo"],
    ["phone", "Telefono"],
    ["email", "Email"],
  ];
  const addOffice = (office: Office) => {
    if (!mediaOwner) return;
    const { mediaowner, ...mediaOwnerInput } = mediaOwner;
    dispatch(
      userActions.willAddMediaOwner({
        ...mediaOwnerInput,
        offices: [...offices, office],
      })
    );
  };
  const removeOffice = (office: Office) => {
    if (!mediaOwner) return;
    const removeIdx = offices.findIndex((o) => o.name === office.name);
    if (removeIdx === -1) return;
    const newOffices = [...offices];
    newOffices.splice(removeIdx, 1);
    const { mediaowner, ...mediaOwnerInput } = mediaOwner;
    dispatch(
      userActions.willAddMediaOwner({
        ...mediaOwnerInput,
        offices: [...newOffices],
      })
    );
  };
  return (
    <>
      {offices.map((office, i) => {
        return (
          <BasicContainer
            key={i}
            className={"w-full animate-down-up flex-col p-5"}
          >
            <div className="flex items-start justify-between w-full">
              <div className="font-semibold">{office.name}</div>
              <BasicButton
                className="w-24 py-1 text-sm text-gray-600 bg-gray-200"
                onClick={() => removeOffice(office)}
                loading={status === "pending"}
              >
                Rimuovi <XMarkIcon className="h-4 " />
              </BasicButton>
            </div>
            <div className="grid w-full grid-cols-[auto_auto_auto_auto] gap-2">
              {officeFields.map(([key, alias]) => {
                return (
                  <div className="flex flex-col w-fit" key={key}>
                    <div className="text-gray-400">{alias}</div>
                    <div className="text-gray-800">{office[key]}</div>
                  </div>
                );
              })}
              <div />
              <div className="flex flex-col col-span-4 w-fit">
                <div className="text-gray-400">Comuni associati:</div>
                <div className="flex gap-2">
                  {office.municipalities?office.municipalities.map((location) => (
                    <LocationTag
                      key={location}
                      location={String(location)}
                    />
                  )):<i className="text-gray-400">Nessun comune selezionato</i>}
                </div>
              </div>
            </div>
          </BasicContainer>
        );
      })}
      <AddOfficeModal offices={offices} addOffice={addOffice} />
    </>
  );
};
export default Offices;
