export type HasLatLng = Partial<{
  lat: number | null;
  lng: number | null;
  latitude: number | null;
  longitude: number | null;
}>;

export const getLatLng = (data?: HasLatLng|null) => {
  const lat = data?.latitude || data?.lat||0;
  const lng = data?.longitude || data?.lng||0;
  return { lat, lng };
};
/** Switches to streetView and points towards the given location */
export function toStreetViewLocation(
  data: Partial<HasLatLng>,
  streetViewService: google.maps.StreetViewService | undefined,
  streetViewPanorama: google.maps.StreetViewPanorama | undefined
) {
  const { lat, lng } = getLatLng(data);

  const latLng = new google.maps.LatLng(lat || 0, lng || 0);
  if (streetViewPanorama && streetViewService) {
    streetViewService.getPanorama(
      { location: latLng, radius: 300 },
      (data, status) => {
        if (
          status === google.maps.StreetViewStatus.OK &&
          data?.location?.latLng
        ) {
          streetViewPanorama.setPosition(data.location.latLng);
          const heading = google.maps.geometry.spherical.computeHeading(
            data.location.latLng,
            latLng
          );
          streetViewPanorama.setVisible(true);
          streetViewPanorama.setPov({ heading: heading, pitch: 0 });
        }
      }
    );
  }
}

/** Smoothly zooms to a place in the map */
export function zoomToPlace(
  data: Partial<HasLatLng>,
  googleMap: google.maps.Map | undefined
) {
  const { lat, lng } = getLatLng(data);
  if (googleMap) {
    const latLng = new google.maps.LatLng(lat || 0, lng || 0);
    const bounds = new google.maps.LatLngBounds(latLng);
    googleMap.fitBounds(bounds);
    googleMap.panTo(latLng);
  }
}
