import Login from "./Login";
import Help from "./Help";
import Notifications from "./Notifications";
import Loading from "./Loading";
import NotFound from "./NotFound";
import Unauthorized from "./Unauthorized";
import Subscription from "./Subscription";
import PendingApproval from "./PendingApproval";

// Secrets
import BatchUpload from "./BatchUpload/BatchUpload";

// With subroutes
import Signup from "./Signup";
import Guest from "./Guest";
import Billboards from "./Billboards";
import Orders from "./Orders";
import Planning from "./Planning";
import Campaigns from "./Campaigns";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import CompleteRegistration from "./CompleteRegistration";
  import  PasswordRecovery from "./PasswordRecovery";

/**  Contains all routable pages, when creating a new page, add it here */
const pages = {
  BatchUpload,// Hidden page used to batch upload billboards from csv
  Guest,
  Login,
  Help,
  Notifications,
  Loading,
  Subscription,
  NotFound,
  Unauthorized,
  Signup,
  Profile,
  Campaigns,
  Billboards,
  Orders,
  Planning,
  Dashboard,
  CompleteRegistration,
  PendingApproval,
  PasswordRecovery
};

export default pages;
