import TransparentButton from "components/buttons/Transparent";
import PfpHash from "components/lists/Team/PfpHash";
import { twMerge } from "tailwind-merge";

type Props = {
  name: string;
  business?: string;
  picture?: string;
  noPicture?: boolean;
  buttonClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;
export const ProfileMiniCard: React.FC<Props> = ({
  name,
  business = "",
  picture = "",
  noPicture,
  className = "",
  buttonClassName = "",
  ...attributes
}) => {
  return (
    <div
      className={twMerge("flex flex-nowrap items-center", className)}
      {...attributes}
    >
      <TransparentButton
        className={twMerge("flex h-full w-full gap-3", buttonClassName)}
      >
        {noPicture ||
          (picture ? (
            <img
              className="object-cover object-center w-8 h-8 bg-gray-200 rounded-full shadow aspect-square "
              alt="User profile"
              src={picture}
            />
          ) : (
            <PfpHash
              user={business || name}
              className="w-8 h-8 aspect-square"
            />
          ))}
        <div className="flex flex-col self-center justify-center overflow-hidden text-start">
          <span className="w-full h-full overflow-hidden text-xs grow text-ellipsis whitespace-nowrap">
            {name.replaceAll("_", " ")}
          </span>{" "}
          {business && (
            <span className="w-full overflow-hidden font-medium text-gray-600 text-ellipsis whitespace-nowrap">
              {business.replaceAll("_", " ")}
            </span>
          )}
        </div>
      </TransparentButton>
    </div>
  );
};
export default ProfileMiniCard;
