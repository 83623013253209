export const geocodeLocation = async (latLng: google.maps.LatLng) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ location: latLng });
  return response;

};
export const geocodeAddress = async (address: string) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({address});
  return response;
};
export const getAddressKey = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  key: string
) => {
  const result = addressComponents.find((component) =>
    component.types.includes(key)
  );
  return result;
};
