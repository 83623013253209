import Personal from "../Personal";
import Team from "./Team";
import Clients from "./Clients";
import Business from "./Business";
import Offices from "./Offices";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BasicContainer from "components/containers/Basic";
import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import LeftNavButton from "components/buttons/LeftNav";

export const MediaOwnerProfile = () => {
  const navigate = useNavigate();
  const { pathname: currentLocation } = useLocation();
  type Section = [string, string, typeof UsersIcon];
  const sections: Section[] = [
    ["Profilo aziendale", "/business", BuildingOfficeIcon],
    ["Personale", "/personal", UserCircleIcon],
    ["Uffici", "/offices", BuildingOffice2Icon],
    ["Team", "/team", UsersIcon],
    ["Clienti", "/clients", UserGroupIcon],
  ];
  return (
    <div className="flex justify-center w-full min-h-0 grow">
      <BasicContainer className="items-start gap-2 p-3 pl-0 pr-20 mt-10 h-fit w-fit whitespace-nowrap">
        {sections.map(([name, path, Icon], i) => (
          <LeftNavButton
            key={i}
            active={currentLocation.includes(path)}
            onClick={() => navigate("/profile" + path)}
          >
            <Icon className="h-5 mr-1" />
            <span>{name}</span>
          </LeftNavButton>
        ))}
      </BasicContainer>
      <div className="flex flex-col w-full max-w-3xl pt-10 mx-5 overflow-scroll hover-scrollbar gap-y-4 ">
        <Outlet />
      </div>
    </div>
  );
};

const pages = {
  Main: MediaOwnerProfile,
  Business,
  Personal,
  Team,
  Offices,
  Clients,
};
export default pages;
