import { Billboard as BillboardS, BillboardInput } from "graphql/ssp/generated";
import { Billboard as BillboardD } from "graphql/ssp/generated";

type BillboardT = BillboardS | BillboardD;

export const toBillboardInput = (billboard: BillboardT,removeNull?:boolean): BillboardInput => {
  const input: Required<BillboardInput> = {
    address: null,
    authorization_expiration: null,
    base: null,
    billalo_code: null,
    billalo_index: null,
    billboard_class: null,
    calendar: null,
    cod_cimasa: null,
    content_duration: null,
    content_rotation: null,
    creation_date: null,
    facade: null,
    format: null,
    height: null,
    height_ground: null,
    iV_municipality: null,
    iV_normalized: null,
    imageUrl: null,
    indice_visibilita: null,
    is_draft: null,
    last_maintenance: null,
    latitude: null,
    lighting: null,
    location: null,
    location_name: null,
    longitude: null,
    maintenance_status: null,
    media_owner: null,
    media_owner_type: null,
    media_type: null,
    min_period: null,
    municipality: null,
    n_authorization: null,
    n_sheet: null,
    notes: null,
    orientation: null,
    other_location: null,
    panel_index: null,
    press_type: null,
    price: null,
    pricing_id: null,
    property_type: null,
    size: null,
    taxes: null,
    visible: null,
  };
  Object.keys(input).forEach((key) => {
    input[key as keyof BillboardInput] = billboard[key as keyof BillboardT];
  });
  if(removeNull){
    for(const k in input){
      const key = k as keyof typeof input
      if(!input[key]){
        delete input[key]
      }
    }
  }
  return input;
};

export default toBillboardInput;
